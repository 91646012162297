<template lang="pug">
    section.ai-popular.section
        ._wrap.wrap
            h2._title 
              span(v-if="ai !== 'main'") Другие популярные нейросети
              span(v-else) Популярные нейросети
            ._list
                router-link(v-for="(ai, index) in filteredList" :key="index" :to="`/ai/${ai.url}`")._ai
                    img(:src="require(`../../../assets/images/ai-popular/${ai.code}.png`)")._ai-logo
                    span._ai-info
                        span._ai-title {{ ai.title }}
                        span._ai-description {{ ai.description }}
</template>

<script>
export default {
    name: "AiPopular",
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false,
        },
    },
    data: () => {
        return {
            list: [
                {
                    code: "chatGPT",
                    url: "chat-gpt",
                    title: "MerlinGPT",
                    description: "Общайтесь и создавайте контент",
                },
                {
                    code: "stableDiffusion",
                    url: "stable-diffusion",
                    title: "Stable Diffusion",
                    description: "Генерируйте картинки",
                },
                {
                    code: "midjourney",
                    url: "midjourney",
                    title: "Midjourney",
                    description: "Создавайте изображения",
                },
                {
                    code: "pika",
                    url: "pika",
                    title: "Pika",
                    description: "Создавайте видео",
                },
                {
                    code: "faceSwap",
                    url: "face-swap",
                    title: "FaceSwap",
                    description: "Заменяйте лица на фото",
                },
            ],
        };
    },
    computed: {
        filteredList: (state) => state.list.filter((a) => a.code !== state.ai),
    },
};
</script>

<style lang="scss" src="./ai-popular.scss"></style>
