<template lang="pug">
    .main(:class="mainClasses")
        ._site
            transition(name="install")
                install-app(
                    v-if="isUserAuth && pageWidth <= 991 && deferredPrompt && !hideInstallationPrompt",
                    @close="closeInstallAppModal",
                    :deferredPrompt="deferredPrompt"
                )
            main-header(@toggleMenu="toggleMenu" :menuStatus="menuStatus" v-if="pageName !== 'auth' && (isMobileView && pageName !== 'messages' && pageName !== 'messages-theme' && pageName !== 'messages-assistants' || isDesktopView)")
            mobile-menu(
                @toggleMenu="toggleMenu",
                :menuStatus="menuStatus",
                v-if="isUserAuth && pageWidth <= 991 && pageName !== 'messages-theme'"
            )
            personMenu(v-if="isUserAuth")

            .content.section
                ._wrap.wrap(:class="{ auth: pageName === 'auth' }")
                    l-aside(v-if="pageSize > 991 && pageName !== 'auth'")
                    blocked-u-i(v-if="noRealPhotos && isUserAuth && pageName !== 'messages' && pageName !== 'messages-theme' && pageName !== 'messages-assistants' && pageName !== 'settings' && pageName !== 'persons' && pageName !== 'Assistants' && pageName !== 'MyAssistants' && pageName !== 'CreateAssistant' && pageName !== 'EditAssistant'")
                    router-view(:photoType="photoType", :notificationTab="notificationTab" v-else)
                    transition(name="fadeInRight")
                        right-aside(
                            v-if="pageSize > 991 && notDisabledPages"
                            @changeTab="changeNotificationsTab"
                        )

        transition(name="fadeIn")
            confirm-dialog(
                v-if="confirmDialog.show && isUserAuth",
                :loading="confirmDialog.loading",
                :title="confirmDialog.title",
                :text="confirmDialog.text",
                :price="confirmDialog.price",
                :buttons="confirmDialog.buttons",
                :cancelText="confirmDialog.cancelText",
                :confirmText="confirmDialog.confirmText",
                :type="confirmDialog.type",
                :comparisonCount="confirmDialog.comparisonCount",
                :comparisonTimer="confirmDialog.comparisonTimer",
                :callback="confirmDialog.callback",
                @cancel="confirmDialog.show = false",
                @confirm="emitConfirmAction(confirmDialog.type)"
            )

        transition(name="showCompareResult")
            compare-result-dialog(
                v-if="compareResultDialog.show && isUserAuth",
                :config="compareResultDialog.data"
            )

        transition(name="showCompareResult")
            compare-result-dialog(
                v-if="compareResultDialog.show && isUserAuth",
                :config="compareResultDialog.data"
            )

        transition(name="fadeIn")
            personal-recommendations-dialog(
                v-if="personalRecommendations.status && isUserAuth"
            )
</template>

<script>
import mainHeader from "../components/app/main-header/main-header";
import personMenu from "../components/app/menu/menu";
import RightAside from "../components/app/right-aside/right-aside";
import LAside from "../components/app/left-aside/l-aside";
import CircleAnimation from "../components/circle/circle";
import MobileMenu from "../components/app/mobile-menu/mobile-menu";
import FooterLinks from "../components/app/footer-links/footer-links";
import {sharedMixins} from "../mixins/sharedMixins";
import {mapGetters} from "vuex";
import Stats from "../views/Stats";
import Auth from "../components/auth/auth";
import ConfirmDialog from "../components/confirm-dialog/confirm-dialog";
import PersonalRecommendationsDialog
    from "../components/person/personal-recommendations-dialog/personal-recommendations-dialog";
import CompareResultDialog from "../components/compare-result-dialog/compare-result-dialog";
import InstallApp from "@/components/app/install-app/install-app.vue";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";
import gql from "graphql-tag";
import getMySubscriptionsQuery from "@/graphql/queries/getMySubscriptions.query.graphql";
import subscriptionPlansQuery from "@/graphql/queries/subscriptionPlans.query.graphql";
import BlockedUI from "@/components/blocked-ui/blocked-ui.vue";

export default {
    name: "MainLayout",
    components: {
        BlockedUI,
        CompareResultDialog,
        PersonalRecommendationsDialog,
        ConfirmDialog,
        Auth,
        Stats,
        FooterLinks,
        MobileMenu,
        CircleAnimation,
        LAside,
        RightAside,
        personMenu,
        mainHeader,
        InstallApp,
        TransitionExpand,
    },
    data: function () {
        return {
            subscriptionPlans: {
                MONTHLY: [],
                ANNUALLY: [],
            },
            menuStatus: false,
            photoType: "anfas and profile",
            notificationTab: "NEW",
            friendRequests: 0,
            skipSubscription: true,
            startPooling: false,
            deferredPrompt: null,
            hideInstallationPrompt: false,
        };
    },
    computed: {
        mainClasses: function () {
            return {
                main_auth: this.pageName === "auth",
                messages: this.pageName === "messages" || this.pageName === "messages-theme" || this.pageName === "messages-assistants",
                globalOverlay: this.globalOverlay
            };
        },
        ...mapGetters({
            token: "auth/token",
            pageSize: "windowWidth",
            uuid: "person/uuid",
            unreadMessages: "getNewMessagesCounter",
            isMyUserLoaded: "user/isMyUserLoaded",
            myUserUuid: "user/userUuid",
            confirmDialog: "confirmDialog",
            compareResultDialog: "compareResultDialog",
            personalRecommendations: "personalRecommendations",
            globalOverlay: "globalOverlay",
        }),
        notDisabledPages: (state) =>
            state.pageName !== "messages" && state.pageName !== "messages-theme" && state.pageName !== "messages-assistants" && state.pageName !== "Assistants" && state.pageName !== "auth" && state.pageName !== "person" && state.pageName !== "Promocodes" && state.pageName !== "public_person" && state.pageName !== "recommended-people" && state.pageName !== "MyAssistants" && state.pageName !== "CreateAssistant" && state.pageName !== "EditAssistant",
    },
    watch: {
        token() {
            console.log("token changed");
            this.getOnBoardingTypesFromMixin();
        },
        isMyUserLoaded: function () {
            setTimeout(() => {
                localStorage.skipAuth = true;
                this.startPooling = true;
            }, 1000);
        },
        myUserUuid: function () {
            this.iAmOnline(this.myUserUuid);
            this.skipSubscription = false;
        },
    },
    created() {
        if ("serviceWorker" in navigator) {
            navigator.serviceWorker.register("/service-worker.js").then(() => {
                console.log("Service Worker Registered");
            });
            navigator.serviceWorker
                .register("/firebase-messaging-sw.js")
                .then(() => {
                    console.log("Service Worker Registered");
                });
        }

        window.addEventListener("beforeinstallprompt", (e) => {
            e.preventDefault();
            this.deferredPrompt = e;
            this.hideInstallationPrompt = this.$cookies.get(
                "hideInstallationPrompt",
            );
        });
    },
    async mounted() {
        const authToken = this.$store.getters["auth/token"];
        localStorage["skip-steps"] = [];
        if (authToken) {
            await this.loadMyUser();
            await this.getSubscriptionPlans("MONTHLY");
            await this.getSubscriptionPlans("ANNUALLY");
            await this.getActiveSubscriptions();
            await this.redirectController();
        }

        if (typeof this.myUserUuid === "undefined") {
            return;
        }
        try {
            await this.$store.dispatch("getNewMessagesCount", {
                apolloClient: this.$apollo,
            });
        } catch (e) {
            // @todo Сделать обработку возможных ошибок
            console.log(e);
        }
    },
    apollo: {
        $subscribe: {
            messageSent: {
                query: sharedMixins.queries.messageSentSubscription,
                variables() {
                    return {
                        userId: this.myUserUuid,
                    };
                },
                result(response) {
                    // skip our outbound message
                    if (
                        response.data.messageSent.senderId === this.myUserUuid
                    ) {
                        return;
                    }
                    this.$store.dispatch(
                        "setNewMessagesCounter",
                        this.unreadMessages + 1,
                    );
                },
                skip() {
                    return this.skipSubscription;
                },
                client: "chatClient",
                fetchPolicy: "no-cache",
            },
        },
    },
    methods: {
        async getActiveSubscriptions() {
            let userSubscriptions = [],
                savedSubscriptions = [];
            await this.$apollo
                .query({
                    query: gql`
                        ${getMySubscriptionsQuery}
                    `,
                })
                .then((r) => {
                    r.data.getMySubscriptions.forEach((plan) => {
                        userSubscriptions.push({
                            categoryId: 0,
                            planId: plan.id,
                            planLevel: plan.level,
                            period: "",
                        });
                    });
                });
            userSubscriptions.forEach((activePlan) => {
                this.subscriptionPlans["MONTHLY"].forEach((plan) => {
                    let planId = activePlan.planId;
                    if (planId === 22) {
                        planId = 1;
                    }
                    if (planId === 23) {
                        planId = 3;
                    }
                    if (planId === 24) {
                        planId = 5;
                    }
                    if (planId === 25) {
                        planId = 7;
                    }
                    const plansFound = plan.plans.find(
                        (p) => Number(p.id) === planId,
                    );
                    if (plansFound) {
                        savedSubscriptions.push({
                            categoryId: plan.category.id,
                            planId: activePlan.planId,
                            planLevel:
                                activePlan.planId > 21
                                    ? "JUNIOR"
                                    : activePlan.planLevel,
                            period: "MONTHLY",
                        });
                    }
                });
            });
            userSubscriptions.forEach((activePlan) => {
                this.subscriptionPlans["ANNUALLY"].forEach((plan) => {
                    const plansFound = plan.plans.find(
                        (p) => Number(p.id) === activePlan.planId,
                    );
                    if (plansFound) {
                        savedSubscriptions.push({
                            categoryId: plan.category.id,
                            planId: activePlan.planId,
                            planLevel: activePlan.planLevel,
                            period: "ANNUALLY",
                        });
                    }
                });
            });
            await this.$store.dispatch(
                "user/setSubscriptionsCategory",
                savedSubscriptions,
            );
        },
        async getSubscriptionPlans(periodicity) {
            await this.$apollo
                .query({
                    query: gql`
                        ${subscriptionPlansQuery}
                    `,
                    variables: {
                        periodicity: periodicity,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.subscriptionPlans[periodicity] =
                        r.data.subscriptionPlans;
                    r.data.subscriptionPlans.forEach((category) => {
                        const junior = category.plans.find(
                            (plan) => plan.id === "21",
                        );
                        if (
                            junior &&
                            junior.price &&
                            periodicity === "MONTHLY"
                        ) {
                            this.$store.dispatch("set", {
                                name: "isJunior",
                                value: true,
                            });
                            this.$store.dispatch("set", {
                                name: "tariffJunior",
                                value: junior,
                            });
                        }
                    });
                });
        },
        closeInstallAppModal() {
            this.deferredPrompt = null;
            this.$cookies.set("hideInstallationPrompt", true, 60 * 60 * 24);
            this.hideInstallationPrompt = this.$cookies.get(
                "hideInstallationPrompt",
            );
        },
        changeNotificationsTab(tab) {
            this.notificationTab = tab;
        },
        toggleMenu(status) {
            this.menuStatus = status;
        },
        isTokenPresent() {
            return this.$store.getters["auth/token"] !== "";
        },
        iAmOnline(userId) {
            this.$apollo.mutate({
                mutation: sharedMixins.queries.userOnline,
                variables: {
                    userId,
                },
                client: "chatClient",
                fetchPolicy: "no-cache",
            });
        },
        emitConfirmAction(type) {
            this.$bus.$emit("confirmDialogAction", type);
        },
    },
};
</script>

<style lang="scss">
.content {
    &__content-inner {
        flex-grow: 1;
        position: relative;
    }
}

.main {

    &.messages {
        @media(max-width: 767px) {
            height: 100dvh !important;
        }

        .content {
            @media(max-width: 767px) {
                height: 100dvh !important;
            }
        }
    }

    &__circle {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
        width: 100%;
        height: 100vh;
    }
}

.forPersonPage {
    top: 80px;
}

//Transition for Install App Modal
.install-enter-active,
.install-leave-active {
    transition: all 0.5s ease-in;
    height: 76px;
    margin-top: 0;
    transform: translateY(0);
}

.install-enter,
.install-leave-to {
    opacity: 0;
    height: 0;
    margin-top: -20px;
    transform: translateY(-76px);
}
</style>
