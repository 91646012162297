import gql from "graphql-tag";
import { subscriptionsPlans } from "@/components/subscriptions/settings/subscriptions-plans";
import { subscriptionsCategoriesIds } from "@/components/subscriptions/settings/subscriptions-data";

export const user = {
    namespaced: true,
    state: {
        user: {
            display_name: "Создать мою личность",
            wallet: {
                coins: 0,
            },
            loaded: false,
            chatMessageAttempts: 0,
            phoneConfirmed: true,
        },
        userLimits: {
            uploadAnfasPhoto: 0,
            uploadProfilePhoto: 0,
        },
        person: {
            category: [],
            photos: {
                anfas: "",
                profile: "",
            },
            extras: {
                external_id_present: false,
            },
            celebrity_status: {
                celebrity: false,
            },
        },
        subscriptions: {
            categories: {
                [subscriptionsCategoriesIds.PROFESSION]: {
                    planId: null,
                    level: subscriptionsPlans.FREE.id,
                    period: null,
                },
                [subscriptionsCategoriesIds.SELF_ESTEEM]: {
                    planId: null,
                    level: subscriptionsPlans.FREE.id,
                    period: null,
                },
                [subscriptionsCategoriesIds.MOTIVATION]: {
                    planId: null,
                    level: subscriptionsPlans.FREE.id,
                    period: null,
                },
                [subscriptionsCategoriesIds.RELATIONS]: {
                    planId: null,
                    level: subscriptionsPlans.FREE.id,
                    period: null,
                },
            },
        },
        onBoardingTypes: [],
        mySubscriptions: [],
        assistantsCount: 0
    },
    actions: {
        set({ commit }, { name, value }) {
            commit("set", { name, value });
        },
        addMessageAttempts({ commit }, count) {
            commit("addMessageAttempts", count);
        },
        changeWelcomeCreditActive({ commit }, status) {
            commit("changeWelcomeCreditActive", status);
        },
        increaseMessageAttempts({ commit }, count) {
            commit("increaseMessageAttempts", count);
        },
        setOnBoardingTypes({ commit }, types) {
            commit("setOnBoardingTypes", types);
        },
        async setMyUserInfo({ commit }, user) {
            commit("setUserInfo", user);
            commit("setUserLimits", user.limits);
            commit("setPersonInfo", user.person);
            commit("setPhoneConfirmed", user.phone_confirmed);
        },
        async setSubscriptionsCategory({ commit }, userSubscriptions) {
            userSubscriptions.forEach((subscription) => {
                commit("setSubscriptionsCategory", { ...subscription });
            });
        },
        setMyPersonUrl({ commit }, url) {
            commit("setMyPersonUrl", url);
        },
        setNewBalance({ commit }, value) {
            commit("setNewBalance", value);
        },
        setDiscountStatus({ commit }) {
            commit("setDiscountStatus");
        },
        setTemporaryPhoto({ commit }, { photo, type }) {
            const data = { photo, type };
            commit("setTemporaryPhoto", data);
        },
        setExternalPresent({ commit }, status) {
            console.log("action setExternalPresent", status);
            commit("setExternalPresent", status);
        },
        async updateMyUserLimits({ commit }, apolloClient) {
            const query = gql`
                query {
                    getMyUser {
                        limits {
                            uploadAnfasPhoto
                            uploadProfilePhoto
                        }
                    }
                }
            `;
            await apolloClient
                .query({
                    query,
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    commit("setUserLimits", response.data.getMyUser.limits);
                });
        },
        decreaseMyUserLimits({ commit }, limitKey) {
            commit("setConcreteUserLimit", limitKey);
        },
        setPhoneConfirmed({ commit }, value) {
            commit("setPhoneConfirmed", value);
        },
        getMyBalance({ commit }, apolloClient) {
            const query = gql`
                query {
                    getMyBalance
                }
            `;

            return apolloClient
                .query({
                    query,
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    commit("setMyBalance", r.data.getMyBalance);
                });
        },
        setName({ commit }, name) {
            commit("setName", name);
        },
    },
    getters: {
        assistantsCount: state => state.assistantsCount,
        info: (state) => state.user,
        person: (state) => state.person,
        personalData: (state) => state.person.personal_data,
        eyeColor: (state) => state.person.eye_color,
        balance: (state) => state.user.wallet.coins,
        public_url: (state) => state.person.public_url,
        // TODO: почему uuid ассоциирутеся с person.uuid, не с uers.uuid?
        uuid: (state) => state.person.uuid,
        // TODO: переименовать в uuid после переименовая вариации на предыдущей строке
        userUuid: (state) => state.user.uuid,
        name: (state) => state.person.name,
        myAvatar: (state) => state.person.photos.avatar,
        myPersonCategory: (state) => state.person.category.name,
        isMyUserLoaded: (state) => state.user.loaded,
        myUserRoles: (state) => state.user.roles,
        myWallet: (state) => state.user.wallet,
        myPersonalData: (state) => state.user.personal_data,
        photos: (state) => state.person.photos,
        myLimits: (state) => state.userLimits,
        phoneConfirmed: (state) => state.user.phoneConfirmed,
        celebrity: (state) => state.person.celebrity_status.celebrity,
        externalIdPresent: (state) => state.person.extras.external_id_present,
        subscriptionCategories: (state) =>
            Object.entries(state.subscriptions.categories).map(
                ([key, value]) => ({
                    id: Number(key),
                    ...value,
                }),
            ),
        onBoardingTypes: (state) => state.onBoardingTypes,
        passedOnBoardingTypes: (state) =>
            state.onBoardingTypes.filter((t) => t.passed),
        mySubscriptions: state => state.mySubscriptions,
    },
    mutations: {
        set(state, { name, value }) {
            state[name] = value;
        },
        addMessageAttempts(state, count) {
            state.user.chatMessageAttempts =
                state.user.chatMessageAttempts + count;
        },
        increaseMessageAttempts(state, count) {
            state.user.chatMessageAttempts = count;
        },
        changeWelcomeCreditActive(state, status) {
            state.user.is_welcome_credit_active = !status;
        },
        setName(state, name) {
            state.user.display_name = name;
        },
        setExternalPresent(state, status) {
            console.log("mutations setExternalPresent", status);
            state.person.extras.external_id_present = status;
        },
        setUserInfo(state, userInfo) {
            state.user = userInfo;
            state.loaded = true;
        },
        setPersonInfo(state, personInfo) {
            state.person = personInfo;
        },
        setMyPersonUrl(state, url) {
            state.person.public_url = url;
        },
        setNewBalance(state, value) {
            state.user.wallet.coins = value;
        },
        setDiscountStatus(state) {
            console.log("set discount status");
            const date = new Date();
            state.user.is_discount_active = true;
            state.user.discount_ends_at = date.setHours(date.getHours() + 24);
        },
        setTemporaryPhoto(state, data) {
            state.person.photos[data.type] = data.photo;
        },
        setUserLimits(state, limits) {
            state.userLimits = limits;
        },
        setConcreteUserLimit(state, limitKey) {
            switch (limitKey) {
                case "uploadAnfasPhoto":
                case "uploadProfilePhoto":
                    state.userLimits[limitKey]--;
                    break;
            }
        },
        setPhoneConfirmed(state, value) {
            state.user.phoneConfirmed = value;
            this.commit("setPhoneConfirmed", value);
        },
        setMyBalance(state, balance) {
            state.user.wallet.coins = balance;
        },
        setSubscriptionsCategory(
            state,
            { categoryId, planId, planLevel, period },
        ) {
            console.log(categoryId, planId, planLevel, period);
            state.subscriptions.categories[categoryId].planId = planId;
            state.subscriptions.categories[categoryId].level = planLevel;
            state.subscriptions.categories[categoryId].period = period;
        },
        setOnBoardingTypes(state, types) {
            state.onBoardingTypes = types;
        },
    },
};
