<template lang="pug">
  .landing
    Loading(v-if="isLoading")
    template(v-else)
      landing-header(:fixed="isHeaderActive" :active-tab="tab" @update-tab="updateTab")
      landing-banner(@landing-loading="isLoading = true" @update-tab="updateTab")
      ._section(ref="about")
        landing-about
      ._section(ref="gpt")
        ai-advantages(ai="main" :grid="4")
      ._section(ref="plans")
        landing-plans
      //landing-reviews
      ._advantages(ref="get")
        landing-advantages(@landing-loading="isLoading = true")
      ai-examples(ai="main")
      ._steps(ref="steps")
        landing-steps(@landing-loading="isLoading = true")
      ai-faq(ai="main")
      ai-popular(ai="main")
      ._section(ref="gift")
        landing-gift
      ai-footer(ai="main")
</template>

<script>
import LandingHeader from "@/components/landing/landing-header/landing-header";
import LayoutDecor from "@/components/ui/layout-decor/layout-decor";
import LandingBanner from "@/components/landing/landing-banner/landing-banner";
import LandingAdvantages from "@/components/landing/landing-advantages/landing-advantages";
import LandingSteps from "@/components/landing/landing-steps/landing-steps";
import LandingMission from "@/components/landing/landing-mission/landing-mission";
import LandingReviews from "@/components/landing/reviews/reviews";
import LandingAbout from "@/components/landing/landing-about/landing-about.vue";
import AiAdvantages from "@/components/ai-landings/ai-advantages/ai-advantages.vue";
import LandingPlans from "@/components/landing/landing-plans/landing-plans.vue";
import AiExamples from "@/components/ai-landings/ai-examples/ai-examples.vue";
import AiFaq from "@/components/ai-landings/ai-faq/ai-faq.vue";
import AiPopular from "@/components/ai-landings/ai-popular/ai-popular.vue";
import LandingGift from "@/components/landing/landing-gift/landing-gift.vue";
import AiFooter from "@/components/ai-landings/ai-footer/ai-footer.vue";

export default {
    name: "LandingLayout",
    components: {
        AiFooter,
        LandingGift,
        AiPopular,
        AiFaq,
        AiExamples,
        LandingPlans,
        AiAdvantages,
        LandingAbout,
        LandingReviews,
        LandingMission,
        LandingSteps,
        LandingAdvantages,
        LandingBanner,
        LayoutDecor,
        LandingHeader,
    },
    data() {
        return {
            scrollTop: 0,
            isSecond: false,
            isHeaderActive: 0,
            isLoading: true,
            tab: "",
            scrollBlocked: false,
        };
    },
    computed: {
        decor: (state) => state.$refs.decor,
        about: (state) => state.$refs.about,
        mission: (state) => state.$refs.mission,
        get: (state) => state.$refs.get,
        gpt: (state) => state.$refs.gpt,
        plans: (state) => state.$refs.plans,
        advantages: (state) => state.$refs.advantages,
        steps: (state) => state.$refs.steps,
        price: (state) => state.$refs.price,
    },
    watch: {
        /*scrollTop(top) {
if (this.pageWidth <= 991) return;

const aboutOpts = this.about.getBoundingClientRect();
const missionOpts = this.mission.getBoundingClientRect();
if (top > aboutOpts.top + top + aboutOpts.height) {
this.decor.style.transition = "0s";
this.decor.style.top = `${missionOpts.top + top}px`;
} else if (top > window.innerHeight / 2) {
this.decor.style.transition = "";
this.isSecond = true;
this.decor.style.top = `${aboutOpts.top + top}px`;
} else {
this.decor.style.top = "";
this.decor.style.transition = "";
this.isSecond = false;
}
},*/
    },
    created() {
        const personPublicUrl = this.myPerson.public_url;
        const personUuid = this.myPerson.uuid;

        if (personUuid) {
            if (personPublicUrl) {
                if (this.$route.fullPath !== `/${personPublicUrl}`) {
                    return this.$router.push(`/${personPublicUrl}`);
                }
            }
            if (this.$route.fullPath !== `/person/${personUuid}`) {
                return this.$router.push(`/person/${personUuid}`);
            }
        }

        this.isLoading = false;
    },
    async mounted() {
        this.$nextTick(() => {
            document.addEventListener("scroll", this.scrollListener);
            document.addEventListener("resize", this.checkHeaderActive);
        });
    },
    destroyed() {
        document.removeEventListener("scroll", this.scrollListener);
        document.removeEventListener("resize", this.checkHeaderActive);
    },
    methods: {
        scrollListener() {
            this.checkHeaderActive();
            this.checkActiveTab();
        },
        checkHeaderActive() {
            this.isHeaderActive = window.pageYOffset;
            this.scrollTop = window.pageYOffset;
        },
        updateTab(tab) {
            this.tab = tab;
            this.scrollBlocked = true;
            this[tab].scrollIntoView({
                inline: "start",
                behavior: "smooth",
            });

            const missionOpts = this.mission.getBoundingClientRect();

            console.log(tab);
            if (tab === "mission") {
                this.decor.style.transition = "0s";
                this.isSecond = true;
                this.decor.style.top = `${missionOpts.top + top}px`;
            }

            setTimeout(() => {
                this.scrollBlocked = false;
            }, 1000);
        },
        checkActiveTab() {
            if (this.scrollBlocked) return;
            //const aboutOptions = this.about.getBoundingClientRect();
            if (!this.advantages || !this.steps || !this.mission) return;
            const advantagesOptions = this.advantages.getBoundingClientRect();
            const stepsOptions = this.steps.getBoundingClientRect();
            //const priceOptions = this.price.getBoundingClientRect();
            const missionOptions = this.mission.getBoundingClientRect();

            if (this.scrollTop >= missionOptions.top + this.scrollTop - 100) {
                this.tab = "mission";
                return;
            }

            //if (this.scrollTop >= priceOptions.top + this.scrollTop - 100) {
            //    this.tab = "price";
            //    return;
            //}

            if (this.scrollTop >= stepsOptions.top + this.scrollTop - 100) {
                this.tab = "steps";
                return;
            }

            if (
                this.scrollTop >=
                advantagesOptions.top + this.scrollTop - 100
            ) {
                this.tab = "advantages";
                return;
            }

            //if (this.scrollTop >= aboutOptions.top + this.scrollTop - 100) {
            //    this.tab = "about";
            //    return;
            //}

            this.tab = "";
        },
    },
};
</script>

<style lang="scss">
.landing {
    position: relative;
    min-height: 660px;
    background: #000;

    .wrap {
        width: 100%;
        max-width: 1150px;
    }

    &__decor {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 100vw;
        height: 100vh;
        z-index: -1;
        transition: 2s;
    }

    &__decor-el {
        right: -35%;
        transition: 2s;
        transform: scale(1.4);
        @media (max-width: 991px) {
            top: -25%;
            right: 0;
        }

        &.active {
            right: 0;
            transform: scale(2.2);
            @media (max-width: 991px) {
                transform: scale(1);
                opacity: 0;
            }
        }
    }
}
</style>
