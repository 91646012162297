export default {
    data: () => ({
        isDraggingPhoto: false,
        dragTimeout: null,
    }),
    mounted() {
        //window.addEventListener("drop", (e) => {
        //    e.preventDefault();
        //    this.isDraggingPhoto = false;
        //});
        //window.addEventListener("dragover", (e) => {
        //    e.preventDefault();
        //    this.isDraggingPhoto = true;
        //});
        //window.addEventListener("dragleave", (e) => {
        //    e.preventDefault();
        //    if (e.screenX === 0 && e.screenY === 0) {
        //        this.isDraggingPhoto = false;
        //    }
        //});
    },
    beforeDestroy() {
        window.removeEventListener("drop", null);
        window.removeEventListener("dragover", null);
        window.removeEventListener("dragleave", null);
    },
};
