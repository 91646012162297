<template lang="pug">
  .landing-plans.section
    ._wrap.wrap
      h3._title Тарифы
      ._list
        ._plan(v-for="plan in plans" :key="plan.title")
          ._plan-group
            ._plan-head
              ._plan-title {{ plan.title }}
              ._plan-description {{ plan.description }}
            ._plan-advantages
              ._plan-advantage(v-for="(item, index) in plan.advantages" :key="index" v-html="item")
          ._plan-group
            ._plan-footer(v-html="plan.footer")
            ._plan-price
              span._plan-price-number(:class="{free: plan.title === 'FREE'}") {{ plan.price }}
              span(v-if="plan.title !== 'FREE'") $/мес.
            router-link(v-if="plan.title !== 'FREE'" to="/welcome/login")
              ui-button(text="Попробовать бесплатно" variant="rounded")._plan-btn
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "LandingPlans",
    components: { UiButton },
    data() {
        return {
            plans: [
                {
                    title: "FREE",
                    description:
                        "Вам нужно много обращаться к нейросети по простым вопросам?",
                    advantages: [
                        "Доступен к ChatGPT-4o-mini",
                        "Генерация картинок в Midjourney, Stable Diffusion и DALLE-3",
                        "Генерация видео в Stable Diffusion и Pika",
                        "Возможность создавать собственных GPT-ассистентов",
                    ],
                    footer: "Ежедневно 20.000 символов (11 страниц А4). Ежемесячно Вы можете сгенерировать текста столько же, сколько в книге Л.Толстого «Война и мир»",
                    price: "Бесплатно",
                },
                {
                    title: "GOLD",
                    description:
                        "Вам важно качество ответов и быстрая работа с картинками?",
                    advantages: [
                        "Доступ к лучшей текстовой нейросети — ChatGPT-4o",
                        "Возможность генерировать картинки в быстром режиме у Midjourney, DALLE-3 и Stable Diffusion.",
                        "<b>Доступ к технологии FaceSwap.</b> Вставляйте свое лицо на любую фотографию.",
                        "Для ChatGPT-4o у вас есть 1,2 млн символов в месяц, они расходуются и на картинки тоже",
                    ],
                    footer: "Для активного ежедневного использования лимита может не хватить",
                    price: 19,
                },
                {
                    title: "PLATINUM",
                    description:
                        "Вам важно качество ответов и вы хотите работать с ней много?",
                    advantages: [
                        "Доступ к лучшей текстовой нейросети — ChatGPT-4o",
                        "Возможность генерировать картинки в быстром режиме у Midjourney, DALLE-3 и Stable Diffusion.",
                        "<b>Доступ к технологии FaceSwap.</b> Вставляйте свое лицо на любую фотографию.",
                        "Для ChatGPT-4o у вас есть 2.4 млн символов в месяц, они расходуются и на картинки тоже",
                    ],
                    footer: "Должно хватить на активное ежедневное использование, включая картинки",
                    price: 35,
                },
            ],
        };
    },
};
</script>

<style lang="scss" src="./landing-plans.scss"></style>
