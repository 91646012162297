<template lang="pug">
    modal(name="midjourney-modal" class="midjourney-modal-wrap" :adaptive="true" width="100%" height="100%" @before-open="beforeOpen" @before-close="beforeClose")
        perfect-scrollbar.midjourney-modal(@click.self="$modal.hide('midjourney-modal')" ref="modalScroll" :options="{handlers: ['keyboard', 'wheel', 'touch'], wheelSpeed: 1}")
            ._wrap
                ._content
                    
                    ._content-inner
                        ._close-btn(@click="$modal.hide('midjourney-modal')")
                            svg-icon(icon-name="cross")._close-icon
                        ._back(v-if="params !== ''" @click="params = ''")
                            svg-icon(icon-name="arrow-left")._back-icon
                            ._back-text К списку параметров
                        ._json-content
                            ._title(:class="{center: params === ''}") {{ title }}
                            ._text
                                template(v-for="item in activeContent.content")
                                    p(v-if="item.type === 'p'" v-html="item.value")
                                    ._img(v-if="item.type === 'img'")
                                        img(:src="require(`./img/${item.value}`)")
                                        ._img-caption {{ item.caption }}
                                    p(v-if="item.type === 'bold'")
                                        b(v-html="item.value")
                                    h2(v-if="item.type === 'h2'" v-html="item.value")
                                    ol(v-if="item.type === 'ol'")
                                        li(v-for="li in item.value" v-html="li")
                                    ul(v-if="item.type === 'ul'" :class="{'no-dots': item.style === 'none'}")
                                        li(v-for="li in item.value" v-html="li")
                                    textarea-example(v-if="item.type === 'textarea-example'" :preview="item.preview" :text="item.value" :result="item.resultImage")._textarea-example
                                    ._single-example(v-if="item.type === 'single-example'")
                                        .__single-example-wrap
                                            img(:src="require(`./img/${item.value}`)")._single-example-img
                                            ._single-example-footer
                                                ._single-example-name {{ item.title }}
                                                ui-button(@click.native="copy(item)" size="small" :color="item.copy ? 'gold' : 'gray'" type="bordered" :text="item.copy ? 'Скопировано' : 'Копировать'")._single-example-btn
                                    ._gallery(v-if="item.type === 'gallery'")
                                        ._gallery-item(v-for="gallery in item.value" :key="gallery.title")
                                            ._gallery-image-box
                                                img(:src="require(`./img/${gallery.image}`)")
                                            ._gallery-footer
                                                ._gallery-title {{ gallery.title }}
                                                ui-button(@click.native="copy(gallery)" size="small" :color="gallery.copy ? 'gold' : 'gray'" type="bordered" :text="gallery.copy ? 'Скопировано' : 'Копировать'")._gallery-copy-btn
                        ._back.-end(v-if="params !== ''" @click="params = ''")
                            svg-icon(icon-name="arrow-left")._back-icon
                            ._back-text К списку параметров
                    ._tabs(v-if="params === ''" ref="params")
                        ._tab(@click="activeTab = 'examples'" :class="{active: activeTab === 'examples'}") 
                            span Как получить классное
                                br/ фото в Midjourney?
                        ._tab(@click="activeTab = 'params'" :class="{active: activeTab === 'params'}") 
                            span Список параметров
                                br/ Midjourney
                    ._content-inner(v-if="params === ''")
                        ._json-content
                            ._params(v-if="activeTab === 'params'")
                                ._param(v-for="param in content.params.list" :key="param.code" @click="params = param.code")
                                    ._param-image
                                        img(:src="require(`./img/${param.code}.png`)")
                                    ._param-info
                                        ._param-head
                                            ._param-title {{ param.title }}
                                            ui-button(v-if="isDesktopView" text="Подробнее" color="gray" type="bordered" size="small" @click.native="params = param.code")._param-more
                                        ._param-examples
                                            ._param-example(v-for="example in param.examples" :key="example") {{ example }}
                                        ._param-desc {{ param.description }}
                                        ui-button(v-if="!isDesktopView" text="Подробнее" color="gray" type="bordered" size="small" @click.native="params = param.code")._param-more.-mobile
                            ._params(v-if="activeTab === 'examples'")
                                ._param(v-for="param in content.examples.list" :key="param.code" @click="params = param.code")
                                    ._param-image
                                        img(:src="require(`./img/${param.code}.jpg`)")
                                    ._param-info
                                        ._param-head
                                            ._param-title {{ param.title }}
                                            ui-button(v-if="isDesktopView" text="Подробнее" color="gray" type="bordered" size="small" @click.native="params = param.code")._param-more
                                        ._param-desc(v-html="param.description")
                                        ui-button(v-if="!isDesktopView" text="Подробнее" color="gray" type="bordered" size="small" @click.native="params = param.code")._param-more.-mobile
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
import TextareaExample from "@/components/modals/midjourney-modal/textarea-example/textarea-example.vue";

export default {
    name: "MidjourneyModal",
    components: {
        TextareaExample,
        UiButton,
    },
    data() {
        return {
            activeTab: "params",
            params: "",
            content: {
                main: require("./content/index.json"),
                params: require("./content/params.json"),
                examples: require("./content/examples.json"),
                ar: require("./content/ar.json"),
                chaos: require("./content/chaos.json"),
                q: require("./content/q.json"),
                seed: require("./content/seed.json"),
                stop: require("./content/stop.json"),
                style: require("./content/style.json"),
                stylize: require("./content/stylize.json"),
                no: require("./content/no.json"),
                tile: require("./content/tile.json"),
                iw: require("./content/iw.json"),
                weird: require("./content/weird.json"),
                cref: require("./content/cref.json"),
                ideas: require("./content/ideas.json"),
                external: require("./content/external.json"),
                portrait: require("./content/portrait.json"),
                lighting: require("./content/lighting.json"),
                weather: require("./content/weather.json"),
                emotion: require("./content/emotion.json"),
                camera: require("./content/camera.json"),
                lens: require("./content/lens.json"),
                films: require("./content/films.json"),
                reflection: require("./content/reflection.json"),
            },
        };
    },
    computed: {
        title: (state) => state.activeContent.title,
        activeContent: function () {
            if (this.params !== "") {
                return this.content[this.params];
            }
            return this.content.main;
        },
    },
    watch: {
        params(value) {
            if (value) {
                this.$refs.modalScroll.$el.scrollTop = 0;
            } else {
                setTimeout(() => {
                    const el = document
                        .querySelector(".midjourney-modal__params")
                        .getBoundingClientRect();
                    this.$refs.modalScroll.$el.scrollTop = el.y - 100;
                }, 200);
            }
        },
    },
    methods: {
        beforeOpen() {
            this.disableBodyScroll();
        },
        beforeClose() {
            this.enableBodyScroll();
        },
        copy(str) {
            console.log("copy", str);
            if (window.isSecureContext) {
                navigator.clipboard.writeText(str.title);
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = str.title;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    document.execCommand("copy");
                } catch (err) {
                    console.error("Unable to copy to clipboard", err);
                }
                document.body.removeChild(textArea);
            }
            str.copy = true;
            setTimeout(() => {
                str.copy = false;
            }, 2000);
        },
    },
};
</script>

<style lang="scss" src="./midjourney-modal.scss"></style>
