<template lang="pug">
    modal(name="payout" class="payout-modal-wrap" :adaptive="true" width="100%" height="100%" @before-open="beforeOpen")
        .payout-modal
            ._close-btn(@click="$modal.hide('payout')")
                svg-icon(icon-name="cross")._cross
            ._box
                ._balance
                    ._balance-title {{ locale("balance") }}
                    ._balance-number {{wallet.coins}} Р

                ._box-group(v-if="!success")
                    ._box-title {{ locale("title") }}
                    ._input-group.-add-balance
                        label(for="payout-amount")._input-label {{ locale("sum") }}
                        input(type="text" id="payout-amount" v-model="amount" placeholder="Сумма")._input
                    ._input-group-error(v-if="$v.amount.$error")
                        template(v-if="!$v.amount.required" )
                            | {{ locale("sumRequiredError") }}
                        template(v-else-if="amount < $v.amount.$params.min")
                            | {{ locale("sumMinError", {min: $v.amount.$params.minValue.min}) }}
                        template(v-else-if="amount > $v.amount.$params.maxValue.max")
                            | {{ locale("sumMaxError", {max: $v.amount.$params.maxValue.max}) }}
                    ._input-group.-column
                        ui-button(text="Вывести" @click.native="payoutRequest()" type="full" :disabled="$v.amount.$error || wallet.coins < $v.amount.$params.minValue.min")._request-btn
                        ._min-summ {{ locale("sumMinError", {min: $v.amount.$params.minValue.min}) }}
                ._box-group(v-if="success")
                    ._success-text {{ locale("success") }}

                portal(to="payout-payment-services" :disabled="pageSize > 991")
                    ._payment-services
                        ._payment-services-list
                            ._payment-services-item(v-for="item in paymentServices" :key="item")
                                svg-icon._payment-services-icon(:icon-name="item" :class="getPaymentServiceIconModifier(item)")

                ._info(v-if="!isCelebrity")
                    ._info-icon-box
                        svg-icon(icon-name="attempt")._info-icon
                    ._info-text {{ locale("newFormat") }}

            ._box
                ._tabs
                    ._tab(v-model="currentTab" @click="changeTab('DEPOSIT')" :class="{'active': currentTab === 'DEPOSIT'}") {{ locale("deposits") }}
                    ._tab(v-model="currentTab" @click="changeTab('PAYMENTS')" :class="{'active': currentTab === 'PAYMENTS'}") {{ locale("payments") }}
                    ._tab(v-model="currentTab" @click="changeTab('BONUSES')" :class="{'active': currentTab === 'BONUSES'}") {{ locale("bonuses") }}

                perfect-scrollbar(v-if="payments" infinite-wrapper)._payout-list
                    ._payout-item.-head
                        ._payout-item-date {{ locale("table.date") }}
                        ._payout-item-sum {{ locale("table.sum") }}
                        ._payout-item-desc {{ locale("table.description") }}
                        ._payout-item-residue {{ locale("table.remainder") }}
                    ._payout-item(v-for="payment in payments")
                        ._payout-item-date
                            ._payout-item-date-line {{ localizedDate(payment.created_at) }}
                            ._payout-item-date-line {{ timestampYear(payment.created_at) }}
                                span._payout-item-date-time {{ timestampHoursMinutes(payment.created_at) }}
                        ._payout-item-sum {{payment.amount}} Р
                        ._payout-item-desc {{ purchaseDescriptions.category[payment.category.name] }} {{ payment.subcategory ? purchaseDescriptions.subcategory[payment.subcategory.name] : "" }}
                        ._payout-item-residue {{payment.balance}} Р

                    infinite-loading(:identifier="infiniteId" @infinite="showMore")
                        ._infinite-msg(slot="spinner") {{ locale("table.loading") }}
                        ._infinite-msg(slot="no-more")
                            template(v-if="payments.length > 5") {{ locale("table.noMore") }}
                        ._infinite-msg(slot="no-results") {{ locale("table.no") }}

            portal-target(name="payout-payment-services")
</template>

<script>
import gql from "graphql-tag";
import { required, minValue, maxValue } from "vuelidate/lib/validators";
import InfiniteLoading from "vue-infinite-loading";
import paymentsListQuery from "@/graphql/queries/paymentsList.query.graphql";
import payoutRequestMutation from "@/graphql/mutations/payoutRequest.mutation.graphql";
import UiButton from "../../ui/ui-button/ui-button";

export default {
    name: "PayoutModal",
    components: {
        UiButton,
        InfiniteLoading,
    },
    data: () => ({
        page: 1,
        limit: 5,
        amount: 5000,
        formIsValid: false,
        currentTab: "DEPOSIT",
        payments: [],
        hasMore: true,
        infiniteId: +new Date(),
        success: false,
    }),
    computed: {
        purchaseDescriptions: (state) => state.$t("purchaseDescriptions"),
        wallet: function () {
            return this.$store.getters["user/myWallet"] || 0;
        },
        personalData: function () {
            return this.$store.getters["user/myPersonalData"];
        },
        paymentServices() {
            return [
                "visa",
                "master-card-round",
                "mir",
                "webmoney",
                "qiwi",
                "yandex-money",
            ];
        },
        pageSize() {
            return this.$store.getters["windowWidth"];
        },
    },
    validations: {
        amount: {
            required,
            minValue: minValue(5000),
            maxValue(value) {
                return maxValue(this.wallet.coins)(value);
            },
        },
    },
    methods: {
        beforeOpen() {
            this.hasMore = true;
            this.page = 1;
        },
        async showMore($state) {
            if (this.hasMore === false) {
                $state.complete();
                return;
            }

            await this.$apollo
                .query({
                    query: gql`
                        ${paymentsListQuery}
                    `,
                    variables: {
                        page: this.page,
                        limit: this.limit,
                        tab: this.currentTab,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    if (response.data.paymentsList.payments.length) {
                        this.page += 1;
                        response.data.paymentsList.payments.forEach(
                            (payment) => {
                                this.payments.push(payment);
                            },
                        );
                        $state.loaded();

                        this.hasMore = response.data.paymentsList.hasMore;
                        if (this.hasMore === false) $state.complete();
                    } else {
                        $state.complete();
                        this.hasMore = false;
                    }
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("error"),
                        this.localeError("paymentsListNotReceived"),
                    );
                });
        },
        async payoutRequest() {
            this.$v.$touch();
            if (this.$v.$invalid) return false;

            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${payoutRequestMutation}
                    `,
                    variables: {
                        amount: +this.amount,
                    },
                })
                .then((r) => {
                    let response = r.data.payoutRequest;
                    if (response) {
                        this.success = true;
                        this.$store.dispatch(
                            "user/setNewBalance",
                            this.wallet.coins - this.amount,
                        );
                    }
                })
                .catch((e) => {
                    this.$msgBox(
                        this.localeError("error"),
                        e.graphQLErrors[0].message,
                    );
                });
        },
        changeTab(tab) {
            this.currentTab = tab;
            this.hasMore = true;
            this.page = 1;
            this.payments = [];
            this.infiniteId += 1;
        },
        localizedDate(timestamp) {
            const date = new Date(timestamp);
            return this.$d(date, "dialog", "ru-RU");
        },
        timestampHoursMinutes(timestamp) {
            const date = new Date(timestamp);
            const minutes =
                (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
            return date.getHours() + ":" + minutes;
        },
        timestampYear(timestamp) {
            const date = new Date(timestamp);
            return date.getFullYear();
        },
        getPaymentServiceIconModifier(icon) {
            return {
                [`icon-${icon}_autowidth`]: this.pageSize <= 991,
                [`icon-${icon}`]: true,
            };
        },
    },
};
</script>

<style lang="scss" scope>
@import "payout-modal";
</style>
