<template lang="pug">
    .landing-mission.section
        ._wrap.wrap
            h2._title(v-html="locale('title')")
            p._description(v-html="locale('description')")
            ._stats <b>{{ memberCount }}</b> {{ locale("memberCount") }}
            ui-button(:text="locale('btn')" size="big" effect="flash" @click.native="redirectToAuth()")._btn
        ._decor(:class="{active: showPhotos}")
            img(src="./img/triangles.png")._triangles
</template>
<script>
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "LandingMission",
    components: {UiButton},
    data() {
        return {
            showPhotos: false,
            memberCount: 384983,
            analyzeFile: require("./lottie-files/analyze"),
        };
    },
    async mounted() {
        let date = new Date().getTime() + "";
        this.memberCount += +date.substring(7, date.length - 1);
        this.memberCount = this.thousandSeparator(this.memberCount);

        const bg = document.querySelector(".landing-mission__decor");
        window.addEventListener("mousemove", function (e) {
            let x = e.clientX / window.innerWidth;
            let y = e.clientY / window.innerHeight;
            bg.style.transform =
                "translate(-" + x * 10 + "px, -" + y * 10 + "px)";
        });

        window.addEventListener("scroll", this.showPhotosHandler);
    },
    destroyed() {
        window.removeEventListener("scroll", this.showPhotosHandler);
    },
    methods: {
        showPhotosHandler() {
            this.showPhotos = this.isVisible(
                document.querySelector(".landing-mission__decor"),
            );
        },
        isVisible(elem) {
            let coords = elem.getBoundingClientRect();
            let windowHeight = document.documentElement.clientHeight;
            let topVisible = coords.top > 0 && coords.top < windowHeight;
            let bottomVisible =
                coords.bottom < windowHeight && coords.bottom > 0;
            return topVisible || bottomVisible;
        },
        async redirectToAuth() {
            await this.temporaryAuth(true);
            this.yandexGoal("landing_btn_3");
        },
        thousandSeparator(str) {
            const parts = (str + "").split(".");
            const main = parts[0];
            const len = main.length;
            let output = "";
            let i = len - 1;
            while (i >= 0) {
                output = main.charAt(i) + output;
                if ((len - i) % 3 === 0 && i > 0) {
                    output = " " + output;
                }
                --i;
            }
            if (parts.length > 1) {
                output += "." + parts[1];
            }
            return output;
        },
    },
};
</script>

<style lang="scss" src="./landing-mission.scss"></style>
