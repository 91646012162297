<template lang="pug">
    .search(v-click-outside="outsideClick", data-tip="search")
        ._icon-box
            svg-icon(icon-name="search", addedClass="search__icon")._search-icon
        input._input(type="text", v-model="term", :placeholder="locale('findFriends')")
        transition(name="show-result")
            ._result(v-if="term.length > 0")
                ._result-wrap
                    ._result-list(v-if="!loading && searchResults.length > 0")
                        ._result-item-bar-count {{ locale("found") }} {{ total }}
                        router-link._result-item(
                            :to="{ name: 'person', params: { uuid: item.personId } }",
                            v-for="(item, index) in searchResults.slice(0, 3)",
                            :key="item.personId + index"
                        )
                            ._result-num {{ index + 1 }}
                            ._result-item-photo
                                user-photo(:photo="item.personAvatar" :micro="true")
                            ._result-item-info
                                ._result-name {{ item.personName }}
                                    svg-icon._is-celebrity(icon-name="star",v-if="item.celebrity")

                    ._error-text(v-else-if="term.length < 3") {{ locale("limit") }}
                    ._error-text(
                        v-else-if="!loading && term.length > 0 && searchTimerId === null"
                    ) {{ locale("not-found") }}
                    Loading.not-fixed(v-else-if="term.length >= searchTermMinLength")
                    router-link(
                        @click.native="term = ''",
                        :to="{ name: 'search', params: { name: term } }",
                        v-if="total > 3"
                    )
                        ui-button.search-show-all_btn(
                            type="bordered",
                            size="full",
                            color="gray",
                            :text="locale('show-all')"
                        )
</template>

<script>
import SvgIcon from "../icon-base/icon-base";
import gql from "graphql-tag";
import personsSearchQuery from "@/graphql/queries/personsSearch.query.graphql";
import UiButton from "@/components/ui/ui-button/ui-button";
import UserPhoto from "@/components/ui/user-photo/user-photo";

export default {
    name: "Search",
    components: {
        UserPhoto,
        SvgIcon,
        UiButton,
    },
    data: () => {
        return {
            searchTermMinLength: 3,
            searchTimerId: null,
            searchResults: [],
            noImage: require("../../../assets/images/no-avatar.jpg"),
            loading: false,
            term: "",
            total: 0,
        };
    },
    computed: {
        authStatus: (state) => state.$store.getters["auth/token"],
        page: (state) => state.$store.getters["page"],
    },
    watch: {
        $route() {
            this.term = "";
        },
        term: function (term) {
            term = term.trim();

            if (term.length >= this.searchTermMinLength) {
                if (this.searchTimerId) {
                    clearTimeout(this.searchTimerId);
                }

                this.searchTimerId = setTimeout(() => {
                    this.searchResults = [];
                    this.searchTimerId = null;
                    this.personsSearch(term);
                }, 1000);
            } else {
                this.searchTimerId = setTimeout(() => {
                    this.searchResults = [];
                    this.total = 0;
                }, 1000);
            }
        },
    },
    mounted() {
        if (this.page === "search") {
            this.term = "";
        }
    },
    methods: {
        async personsSearch() {
            this.loading = true;
            this.$apollo
                .query({
                    query: gql`
                        ${personsSearchQuery}
                    `,
                    variables: {
                        term: this.term,
                    },
                })
                .then((r) => {
                    let response = r.data.personsSearch.list;
                    let total = r.data.personsSearch.total;

                    if (response.length) {
                        this.searchResults = response;
                        this.total = total;
                    }
                    this.loading = false;
                })
                .catch(() => {
                    this.loading = false;
                });
        },
        outsideClick: function () {
            if (this.term.length > 0) {
                this.term = "";
            }
        },
    },
};
</script>

<style lang="scss">
@import "search";
</style>
