export default {
    install(Vue) {
        let timeout = "";

        Vue.prototype.printText = function (el, text, ms) {
            let strLen = 0,
                block = document.querySelector(el);
            this.cancelPrint();
            block.innerHTML = "";
            locking();

            function locking() {
                timeout = setTimeout(locking, ms);
                if (text[strLen] !== undefined) block.innerHTML += text[strLen];
                else clearTimeout(timeout);
                strLen++;
            }
        };

        Vue.prototype.cancelPrint = function () {
            clearTimeout(timeout);
        };
    },
};
