import {
    subscriptionsPlans,
    subscriptionsPlansList,
} from "@/components/subscriptions/settings/subscriptions-plans";
const lang = localStorage.getItem("lang") || "ru";

import ru from "../../../localization/ru";
import en from "../../../localization/en";
import uz from "../../../localization/uz";
import tj from "../../../localization/tj";
import kz from "../../../localization/kz";
import az from "../../../localization/az";
import kg from "../../../localization/kg";

const localization = {
    ru,
    en,
    uz,
    tj,
    kz,
    az,
    kg
};

export const subscriptionsCategoriesIds = {
    PROFESSION: 1,
    SELF_ESTEEM: 2,
    MOTIVATION: 3,
    RELATIONS: 4,
};

export const subscriptionsData = [
    {
        id: subscriptionsCategoriesIds.PROFESSION,
        icon: "sub-cat-prof",
        items: localization[lang].SubscriptionsData.PROFESSION.TITLES,
        plans: [
            {
                level: subscriptionsPlans.FREE.id,
                items: localization[lang].SubscriptionsData.PROFESSION.FREE,
            },
            {
                level: subscriptionsPlans.GOLD.id,
                items: localization[lang].SubscriptionsData.PROFESSION.GOLD,
            },
            {
                level: subscriptionsPlans.PLATINUM.id,
                items: localization[lang].SubscriptionsData.PROFESSION.PLATINUM,
            },
        ],
    },
    {
        id: subscriptionsCategoriesIds.SELF_ESTEEM,
        icon: "sub-cat-self-esteem",
        items: localization[lang].SubscriptionsData.SELF_ESTEEM.TITLES,
        plans: [
            {
                level: subscriptionsPlans.FREE.id,
                items: localization[lang].SubscriptionsData.SELF_ESTEEM.FREE,
            },
            {
                level: subscriptionsPlans.GOLD.id,
                items: localization[lang].SubscriptionsData.SELF_ESTEEM.GOLD,
            },
            {
                level: subscriptionsPlans.PLATINUM.id,
                items: localization[lang].SubscriptionsData.SELF_ESTEEM.PLATINUM,
            },
        ],
    },
    {
        id: subscriptionsCategoriesIds.MOTIVATION,
        icon: "sub-cat-motivation",
        items: localization[lang].SubscriptionsData.MOTIVATION.TITLES,
        plans: [
            {
                level: subscriptionsPlans.FREE.id,
                items: localization[lang].SubscriptionsData.MOTIVATION.FREE
            },
            {
                level: subscriptionsPlans.GOLD.id,
                items: localization[lang].SubscriptionsData.MOTIVATION.GOLD,
            },
            {
                level: subscriptionsPlans.PLATINUM.id,
                items: localization[lang].SubscriptionsData.MOTIVATION.PLATINUM,
            },
        ],
    },
    {
        id: subscriptionsCategoriesIds.RELATIONS,
        icon: "sub-cat-relations",
        items: localization[lang].SubscriptionsData.RELATIONS.TITLES,
        plans: [
            {
                level: subscriptionsPlans.FREE.id,
                items: localization[lang].SubscriptionsData.RELATIONS.FREE,
            },
            {
                level: subscriptionsPlans.GOLD.id,
                items: localization[lang].SubscriptionsData.RELATIONS.GOLD,
            },
            {
                level: subscriptionsPlans.PLATINUM.id,
                items: localization[lang].SubscriptionsData.RELATIONS.PLATINUM,
            },
        ],
    },
];

export const getMergedCategories = (fetchedCategories, withPlans) =>
    subscriptionsData.map((category) => {
        if (!fetchedCategories || !fetchedCategories.length) {
            return category;
        }

        const fetchedItem = fetchedCategories.find(
            (item) => item.category.id === category.id,
        );
        const { name, codeName } = fetchedItem.category;

        if (withPlans) {
            const plans = category.plans.map((plan) => {
                let id = null;
                let price = null;
                let discount = null;
                let accent = "#FFF";
                let accentBg = "#303030";

                const fetchedPlan = fetchedItem.plans.find(
                    (fetchedPlanItem) => fetchedPlanItem.level === plan.level,
                );
                const planUiSettings = subscriptionsPlansList.find(
                    (planUiItem) => planUiItem.id === plan.level,
                );

                if (planUiSettings) {
                    accent = planUiSettings.accent;
                    accentBg = planUiSettings.accentBg;
                }

                if (fetchedPlan) {
                    id = fetchedPlan.id;
                    price = fetchedPlan.price;
                    discount = fetchedPlan.discount;
                }

                return {
                    ...plan,
                    id,
                    price,
                    discount,
                    accent,
                    accentBg,
                };
            });

            return {
                ...category,
                name,
                codeName,
                plans,
            };
        }

        return { ...category, name };
    });
