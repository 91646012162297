<template lang="pug">
    section.ai-faq.section
        ._wrap.wrap
            h2._title {{ title }}
            ._list
                ._question(v-for="(question, index) in list" :key="index")
                    ._question-head(@click="question.isOpen = !question.isOpen")
                        ._question-title {{ question.title }}
                        svg-icon(icon-name="chevron-down" :class="{'ai-faq__question-chevron_active': question.isOpen}")._question-chevron
                    transition-expand(v-if="question.isOpen")
                        ._answer-box
                            ._answer(v-html="question.answer")
</template>

<script>
import TransitionExpand from "@/components/ui/transition-expand/transition-expand.vue";

export default {
    name: "AiFaq",
    components: { TransitionExpand },
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false
        }
    },
    computed: {
        title: state => state.$t(`AILanding.faq.titles.${state.ai}`),
        list: state => state.$t(`AILanding.faq.list.${state.ai}`),
    },
};
</script>

<style lang="scss" src="./ai-faq.scss"></style>
