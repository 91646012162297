<template lang="pug">
    .retake-onboarding
        ui-button(:text="item.name" v-for="item in list" :key="item.id" v-if="item.active && !item.passed" size="aside" @click.native="confirmOnBoardingSwitch(item.type)")._button
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "RetakeOnboarding",
    components: { UiButton },
    data() {
        return {
            list: [
                {
                    type: "DEFAULT",
                    name: this.locale("DEFAULT"),
                    id: 0,
                    passed: false,
                    active: true,
                },
                {
                    type: "PROFESSION_ORIENTATION",
                    name: this.locale("PROFESSION_ORIENTATION"),
                    id: 0,
                    passed: false,
                    active: true,
                },
            ],
        };
    },
    created() {
        this.generateOnBoardingList();
    },
    methods: {
        async generateOnBoardingList() {
            this.userOnBoardingTypesFromMixin.forEach((type) => {
                const activeItem = this.list.find((t) => t.type === type.type);

                if (activeItem) {
                    activeItem.passed = type.passed;
                    activeItem.id = type.id;
                    if (type.type === "DEFAULT") {
                        activeItem.type = "PSYCHOTIC_DETECTION";
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss" src="./retake-onboarding.scss"></style>
