<template lang="pug">
    header.ai-header.section
        ._wrap.wrap
            router-link(to="/")._logo-link
                svg-icon(icon-name="rounded-logo" added-catalog="ai-header")._logo
            ._title(v-html="title")
            router-link(to="/welcome/login")
                ui-button(:text="$t('AILanding.header.enterBtn')" variant="rounded" color="gray" type="bordered")._btn
</template>

<style lang="scss" src="./ai-header.scss"></style>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "AiHeader",
    components: { UiButton },    
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false
        }
    },
    computed: {
        title: state => state.$t(`AILanding.header.titles.${state.ai}`),
    }
};
</script>
