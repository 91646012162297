export const descriptionMixins = {
    methods: {
        showDescriptionModal(title, desc) {
            this.$bus.$emit("showPersonDescriptionModal", title, desc);
        },
        async confirmBuyDescriptionCategory(
            name,
            price,
            category,
            subCategory,
        ) {
            if (!this.$store.getters["auth/authStatus"]) {
                localStorage.skipAuth = true;
                localStorage.redirect = this.$route.fullPath;
                await this.$router.push({ name: "Login" });
                this.$msgBox(
                    "Ошибка",
                    "Для совершения данного действия требуется авторизация",
                );
            }
            this.categoryInfo.name = name;
            this.categoryInfo.price = price;
            this.categoryInfo.category = category;
            this.categoryInfo.subCategory = subCategory;

            this.$bus.$emit(
                "confirmBuyDescriptionCategory",
                name,
                price,
                category,
            );
        },
        async buyDescriptionCategory() {
            await this.$store
                .dispatch("person/buyCategory", {
                    apolloClient: this.$apollo,
                    personId: this.$store.getters["person/uuid"],
                    category: this.categoryInfo.category,
                    subCategory: this.categoryInfo.subCategory,
                })
                .then((result) => {
                    console.log("buyDescriptionCategory:success", result);
                    const taskId =
                        result.data.personUnlockDescriptionSubcategory.taskId;
                    clearInterval(this.taskInterval);
                    this.checkTask(taskId);
                })
                .catch((e) => {
                    this.$bus.$emit("closeConfirm");
                    if (e.graphQLErrors[0].extensions.code === 1008) {
                        this.$msgBox(
                            this.localeError("notEnoughMoneyTitle"),
                            this.localeError("notEnoughMoney"),
                        );
                    } else {
                        this.$msgBox(
                            this.localeError("error"),
                            this.localeError("retry"),
                        );
                    }
                });
        },
        async checkTask(taskId, dontCheckBalance) {
            this.taskInterval = setInterval(async () => {
                await this.$store
                    .dispatch("getTaskStatus", {
                        apolloClient: this.$apollo,
                        taskId: taskId,
                    })
                    .then((r) => {
                        if (r.status === "PENDING") {
                            return false;
                        } else if (r.status === "COMPLETED") {
                            this.$store.dispatch(
                                "person/setExternalPresent",
                                true,
                            );
                            this.$store.dispatch(
                                "user/setExternalPresent",
                                true,
                            );
                            this.personGetDescription();
                            this.$store.dispatch("person/getLeaderType", {
                                apolloClient: this.$apollo,
                                uuid: this.$store.getters["person/uuid"],
                            });
                            this.$store.dispatch("person/getInfo", {
                                apolloClient: this.$apollo,
                                uuid: this.$store.getters["person/uuid"],
                            });
                            if (typeof dontCheckBalance === "undefined") {
                                this.$store.dispatch(
                                    "user/getMyBalance",
                                    this.$apollo,
                                );
                            }
                        } else if (r.status === "ERROR") {
                            this.$bus.$emit("closeConfirm");
                            console.log("Create description error", r.error);
                            if (r.error === 1008) {
                                this.$msgBox(
                                    this.localeError("notEnoughMoneyTitle"),
                                    this.localeError("notEnoughMoney"),
                                );
                            } else if (r.error === 1200) {
                                if (this.isMyProfile) {
                                    this.$msgBox(
                                        this.localeError("wrongPhotoTitle"),
                                        this.localeError("wrongAnfasPhoto"),
                                    );
                                    this.$router.push("/settings/change-photo");
                                } else {
                                    this.$bus.$emit(
                                        "changeDescErrorStatus",
                                        true,
                                    );
                                }
                            } else if (r.error === 1201) {
                                if (this.isMyProfile) {
                                    this.$msgBox(
                                        this.localeError("wrongPhotoTitle"),
                                        this.localeError("wrongProfilePhoto"),
                                    );
                                    this.$router.push("/settings/change-photo");
                                } else {
                                    this.$bus.$emit(
                                        "changeDescErrorStatus",
                                        true,
                                    );
                                }
                            } else {
                                this.$msgBox(
                                    this.localeError("error"),
                                    this.localeError("retry"),
                                );
                            }
                        }

                        if (r.extensions && r.extensions.code) {
                            // task is not created yet
                            if (r.extensions.code === 1002) {
                                return false;
                            }
                        }

                        clearInterval(this.taskInterval);
                        this.taskInterval = "";
                    })
                    .catch((e) => {
                        console.log("checkTaskError:", e);
                    });
            }, 1500);
        },
        async personGetDescription(uuid = false) {
            await this.$store
                .dispatch("person/personGetDescription", {
                    apolloClient: this.$apollo,
                    uuid: uuid ? uuid : await this.$store.getters["person/uuid"],
                })
                .then((r) => {
                    if (r === 1002) {
                        this.createPersonDescription();
                    } else {
                        this.$bus.$emit("closeConfirm");
                    }
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("error"),
                        this.localeError("retry"),
                    );
                });
        },
        async createPersonDescription() {
            const photos = await this.$store.getters["person/photos"];
            if (photos["anfas"] && photos["profile"]) {
                await this.$store
                    .dispatch("person/createPersonDesc", {
                        apolloClient: this.$apollo,
                        uuid: await this.$store.getters["person/uuid"],
                    })
                    .then((r) => {
                        setTimeout(() => {
                            this.checkTask(r, true);
                        }, 1500);
                    })
                    .catch(() => {
                        this.$msgBox(
                            this.localeError("error"),
                            this.localeError("retry"),
                        );
                    });
            }
        },
        async prependShowModal(category, subCategory, descId) {
            console.log(this.categories[category].subcategories[subCategory]);
            let modalTitle =
                    this.categories[category].subcategories[subCategory].items[
                        descId
                    ].name,
                prepareBody =
                    this.categories[category].subcategories[subCategory].items[
                        descId
                    ].description,
                modalBody = prepareBody;

            if (modalTitle === "_text_") {
                modalTitle =
                    this.categories[category].subcategories[subCategory].name;
            }

            /*if (category > 3) {
                      modalTitle = this.categories[category].subcategories[subCategory].name;
                      prepareBody = this.categories[category].subcategories[subCategory].items;
                      modalBody = '';
                      prepareBody.forEach(item => {
                          modalBody += '<b>' + item.name + '</b>' + '\n' + item.description + '\n\n';
                      });
                  }*/

            this.showDescriptionModal(modalTitle, modalBody);
        },
    },
};
