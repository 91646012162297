<template lang="pug">
    .service-menu
        ._wrap(v-if="showCompares || !myProfile")
            ._title(v-html="compareTitle")
            ._list
                ._item.-compare(v-for="compare in serviceTypes" v-if="compare.type === 'compare' && !isMyProfile &&  (!myProfile || compare.categories.indexOf(descriptionSectionId) >= 0)" @click="getCompare(compare)")
                    ._item-icon-box
                        svg-icon(:icon-name="compare.icon")._item-icon
                    ._item-name {{ $t(`serviceTypes.${compare.id}`) }}

        ._wrap(v-if="showIndicators || !myProfile")
            service-collapse(:title="indicatorTitle" :open="false" :serviceIndex="descriptionSectionId")
                ._list(ref="indicator-list" slot="content")
                    ._item.-indicator(v-for="indicator in serviceTypes" v-if="indicator.type === 'indicator' && (!myProfile || indicator.categories.indexOf(descriptionSectionId) >= 0)" @click="getIndicator(indicator)")
                        ._item-icon-box
                            svg-icon(:icon-name="indicator.icon")._item-icon
                        ._item-name {{ isMyProfile ? $t(`serviceTypes.${indicator.id}_MY`) : $t(`serviceTypes.${indicator.id}_OTHER`) }}

        ._wrap(v-if="showAggressions || !myProfile")
            service-collapse(:title="aggressionTitle" :open="false" design="gray" :serviceIndex="descriptionSectionId")
                ._list(ref="indicator-list" slot="content")
                    ._item.-aggression(v-for="aggression in serviceTypes" v-if="aggression.type === 'aggression' && (!myProfile || aggression.categories.indexOf(descriptionSectionId) >= 0 && aggression.id !== 'INTELLIGENCE_LEVEL')" @click="getIndicator(aggression)")
                        ._item-icon-box
                            svg-icon(:icon-name="aggression.icon" :class="aggression.icon")._item-icon
                        ._item-name {{ $t(`serviceTypes.${aggression.id}`) }}
</template>

<script>
import { compareMixins } from "@/mixins/compare.mixins";

export default {
    name: "ServiceMenu",
    mixins: [compareMixins],
    data: () => ({
        showCompares: false,
        showIndicators: false,
        showAggressions: false,
        activeType: {},
        serviceTypesTemporary: [],
    }),
    computed: {
        myProfile: (state) =>
            state.$store.getters["user/uuid"] ===
            state.$store.getters["person/uuid"],
        personUuid: (state) => state.$store.getters["person/uuid"],
        personName: (state) => state.$store.getters["person/name"],
        personAvatar: (state) => state.$store.getters["person/avatar"],
        externalId: (state) =>
            state.manualExternalId !== ""
                ? state.manualExternalId
                : state.$store.getters["person/externalIdPresent"],
        compareTitle: (state) =>
            state.myProfile
                ? state.locale("with-friends")
                : `Сравните себя с <span>${
                    state.personName !== "Newbie"
                        ? state.personName
                        : state.locale("newbie", {}, true)
                }</span>`,
        indicatorTitle: (state) =>
            state.myProfile
                ? state.locale("about-me")
                : `${state.locale("about-another")}<br/> <span>${
                    state.personName !== "Newbie"
                        ? state.personName
                        : state.locale("newbie", {}, true)
                }</span>`,
        aggressionTitle: (state) =>
            state.myProfile
                ? state.locale("aggression")
                : `${state.locale("aggression-another")}:<br/> <span>${
                    state.personName !== "Newbie"
                        ? state.personName
                        : state.locale("newbie", {}, true)}
                }</span>`,
        descriptionSectionId: (state) =>
            state.$store.getters["descriptionSectionId"],
        serviceTypes: (state) => state.$store.getters["serviceTypesMobile"],
        personType: (state) =>
            state.$store.getters["person/isCelebrity"] ? "celebrity" : "person",
    },
    watch: {
        descriptionSectionId() {
            this.rebuildCategories();
        },
    },
    created() {},
    mounted() {
        // Перестраиваем категории, чтобы отобразить только те сравнения и индикаторы, которые доступны для выбранного раздела
        this.rebuildCategories();
    },
    methods: {
        async getCompare(compare) {
            // Если нет авторизации - говорим об этом
            if (!this.isAuth()) return;

            // Если нет хотя бы одной фотографии - говорим об этом
            if (!this.myPhotos.profile || !this.myPhotos.anfas) {
                this.showUploadPhotosAttempt();
                return;
            }

            if (this.isMyProfile || this.pageName === "merlin-analyze") {
                // Событие слушается в components/person/person.vue
                this.$bus.$emit("showPhotoCompare", compare);
                return;
            }

            this.activeType = compare;

            await this.getCompareResult(this.personUuid, compare.id)
                .then((r) => {
                    const result = r.data.compareWithPersonGet,
                        percent = result.resultValue,
                        title = result.resultTitle,
                        desc = result.resultDescription;

                    if (!percent) {
                        this.offerToBuyCompare(compare.id);
                        return;
                    }

                    this.$store.dispatch("setCompareResultDialog", {
                        show: true,
                        data: {
                            category: compare,
                            photo: this.personAvatar,
                            personUuid: this.isMyProfile ? "" : this.personUuid,
                            result: {
                                desc: desc,
                                title: title,
                                percent: percent,
                            },
                        },
                    });
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("retry"),
                    );
                });
        },
        async getIndicator(indicator) {
            // Если нет авторизации - говорим об этом
            if (!this.isAuth()) return;

            // Если нет хотя бы одной фотографии - говорим об этом
            if (!this.myPhotos.profile || !this.myPhotos.anfas) {
                this.showUploadPhotosAttempt();
                return;
            }

            this.activeType = indicator;

            await this.getIndicatorResult(this.personUuid, indicator.id)
                .then((r) => {
                    const result = r.data.personIndicatorGet,
                        percent = result.resultValue,
                        title = result.resultTitle,
                        desc = result.resultDescription;

                    if (!percent) {
                        this.offerToBuyIndicator(indicator.id);
                        return;
                    }

                    this.$store.dispatch("setCompareResultDialog", {
                        show: true,
                        data: {
                            category: indicator,
                            photo: this.personAvatar,
                            personUuid: this.isMyProfile ? "" : this.personUuid,
                            result: {
                                desc: desc,
                                title: title,
                                percent: percent,
                            },
                        },
                    });
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("retry"),
                    );
                });
        },
        rebuildCategories() {
            const compareStatus = [];
            const indicatorStatus = [];
            const aggressionStatus = [];

            Object.values(this.serviceTypes).find((r) => {
                if (r.type === "compare") {
                    if (r.categories.indexOf(this.descriptionSectionId) >= 0)
                        compareStatus.push(r);
                }
                if (r.type === "indicator") {
                    if (r.categories.indexOf(this.descriptionSectionId) >= 0)
                        indicatorStatus.push(r);
                }

                if (r.type === "aggression") {
                    if (r.categories.indexOf(this.descriptionSectionId) >= 0)
                        aggressionStatus.push(r);
                }
            });

            this.showCompares = !!compareStatus.length;
            this.showIndicators = !!indicatorStatus.length;
            this.showAggressions = !!aggressionStatus.length;
        },
    },
};
</script>

<style lang="scss">
@import "service-menu";
</style>
