import Vue from "vue";
import Router from "vue-router";
import store from "../store";
import routes from "./routes";

Vue.use(Router);

let router = new Router({
    mode: "history",
    //base: process.env.BASE_URL
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.path.includes("/ai/") >= 0) {
        window.scrollTo(0, 0);
    }
    if (to.name === "Static") {
        store.dispatch("setPage", "static");
    }
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (store.getters["auth/authStatus"]) {
            next();
            return;
        }
        next("/welcome/login");
    } else {
        next();
    }
});

export default router;
