<template lang="pug">
    footer.ai-footer.section
        ._wrap.wrap
            router-link(to="/")._logo-link
                svg-icon(icon-name="rounded-logo" added-catalog="ai-header")._logo
            ._title(v-html="title" v-if="ai !== 'main'")
            ._copyright {{ $t('AILanding.footer.copyright') }}
</template>

<style lang="scss" src="./ai-footer.scss"></style>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "AiFooter",
    components: { UiButton },
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false
        }
    },
    computed: {
        title: state => state.$t(`AILanding.header.titles.${state.ai}`),
    }
};
</script>
