<template lang="pug">
    .gift-timer(v-if="timer > 0")
        ._timer {{ formattedTime }}
        ._desc Cпециальное предложение
            
</template>

<script>
export default {
    name: "GiftTimer",
    props: {
        time: {
            required: false,
            default: 5,
            type: Number,
        },
    },
    data() {
        return {
            timer: null,
            interval: null,
        };
    },
    computed: {
        formattedTime: function () {
            let minutes, seconds, hours;
            hours = Math.floor(Math.floor((this.timer / 1000 / 60) << 0) / 60);
            minutes = Math.floor(this.timer / 60 / 1000 - hours * 60);
            seconds = Math.floor((this.timer / 1000) % 60);
            
            hours = String(hours).padStart(2, "0");
            seconds = String(seconds).padStart(2, "0");
            minutes = String(minutes).padStart(2, "0");

            return hours + ":" + minutes + ":" + seconds;
        },
    },
    created() {
        this.timer = this.time * 60 * 1000;
    },
    mounted() {
        this.interval = setInterval(() => {
            this.timer -= 1000;
            localStorage.giftTimer = this.timer / 60 / 1000;
            if (this.timer <= 0) {
                clearInterval(this.interval);
                this.interval = null;
            }
        }, 1000);
    },
};
</script>

<style src="./gift-timer.scss" lang="scss"></style>
