<template lang="pug">
    .promocode
        ui-button(:text="locale('btn')" v-if="status === 'start'" @click.native="status = 'enter'")._btn
        template(v-if="status === 'enter'")
            input(type="text" v-model="promocode" ref="promocode" :placeholder="locale('enter')" @input="$emit('changePromocode', promocode)")._input
            ._status(:class="{valid: isValid, invalid: !isValid}" v-if="promocodeInfo") {{ promocodeInfo }}
</template>
<script>
import UiButton from "../ui/ui-button/ui-button";
export default {
    name: "Promocode",
    components: { UiButton },
    data() {
        return {
            status: "start",
            promocode: "",
            promocodeInfo: "",
            isValid: false,
        };
    },
    computed: {
        promo: (state) => state.$route.query.promo
    },
    watch: {
        status(s) {
            if (s === "enter") {
                this.$nextTick(() => {
                    this.$refs.promocode.focus();
                });
            }
        },
    },
    beforeMount() {
        this.promocode = this.promo;
        this.$emit("changePromocode", this.promocode);
        if(this.promo) {
            this.$router.replace(this.$route.path);
        }
    },
};
</script>

<style src="./promocode.scss" lang="scss"></style>
