<template lang="pug">
    transition(name="fadeInTop")
        .description-popup(v-if="show" :class="{'description-popup_light': theme === 'light'}")
            ._head
                ._title-group
                    ._title {{ title }}
                    ._user-name(v-if="myPerson.uuid !== currentPerson.uuid && !addedInfo.id") {{ locale("learn-about") }} {{ personName }}
                ._head-group
                    right-aside-social(v-if="!addedInfo.id")
                button._change-theme(@click="changeTheme")
                    ._change-theme-text
                        svg-icon._change-theme-icon(:icon-name="isDarkThemeActive ? 'sunny-day' : 'moon'")
                        ._change-theme-help {{ isDarkThemeActive ? locale("light-background"): locale("dark-background") }}
                ._hide(@click="show = false")
                    svg-icon(icon-name="cross")._cross-icon
            perfect-scrollbar._content
                span(v-html="formattedDesc")
                template(v-if="addedInfo.url")
                    span._referral-title {{ locale("start-learn-profession") }}
                    a(:href="addedInfo.url" target="_blank")._referral-btn
                        ui-button(:text="`${locale('start-learn-profession-btn-text')}`")
</template>

<script>
import RightAsideSocial from "../right-aside/social/social";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "DescriptionPopup",
    components: { UiButton, RightAsideSocial },
    data: () => ({
        show: false,
        title: "",
        desc: "",
        theme: "dark",
        addedInfo: {
            id: 0
        },
    }),
    computed: {
        formattedDesc: function (state) {
            return state.desc.replace(/\n/g, "<br/>");
        },
        isDarkThemeActive: (state) => {
            return state.theme === "dark";
        },
        pageSize: (state) => state.$store.getters["windowWidth"],
        personName: (state) =>
            state.$store.getters["person/name"] === "Newbie"
                ? state.locale("newbie", {}, true)
                : state.$store.getters["person/name"],
    },
    watch: {
        theme(value) {
            localStorage.setItem("description-theme", value);
        },
        show(status) {
            if (status) {
                this.disableBodyScroll();
                history.pushState(null, null, location.href + "#modal");
                window.onpopstate = () => {
                    this.show = false;
                };
            } else {
                history.pushState(null, null, location.href.split("#")[0]);
                this.enableBodyScroll();
            }
        },
    },
    created() {
        if (localStorage.getItem("description-theme")) {
            this.theme = localStorage.getItem("description-theme");
        }
        this.$bus.$on(
            "showPersonDescriptionModal",
            (title, desc, addedInfo = { id: 0 }) => {
                this.disableBodyScroll();
                this.show = true;
                this.title = title;
                this.desc = desc;
                this.addedInfo = addedInfo;
            },
        );
    },
    destroyed() {
        this.$bus.$off("showPersonDescriptionModal");
    },
    methods: {
        changeTheme() {
            if (this.theme === "dark") {
                this.theme = "light";
            } else {
                this.theme = "dark";
            }
        },
    },
};
</script>

<style lang="scss">
.description-popup {
    $root: &;
    $w: 15vw;
    $h: 15vh;
    position: absolute;
    top: $h;
    right: $w;
    bottom: $h;
    left: $w;
    z-index: 2000;
    background: #1c1c1c;
    padding: 30px 40px;
    border: 1px solid $gold;
    transition: 0.4s;
    @media (max-width: 991px) {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px 30px;
    }
    @media (max-width: 620px) {
        left: 0;
        right: 0;
    }
    @media (max-width: 380px) {
        padding: 15px;
    }

    &_light {
        background: #f5f5f5;

        #{$root} {
            &__title {
                color: #444;
            }

            &__content {
                color: #444;

                b {
                    color: #8e751d;
                }
            }

            &__hide {
                color: #444;

                &:hover {
                    cursor: pointer;
                    color: #222;
                }
            }

            &__change-theme-icon {
                fill: #444;
            }

            &__change-theme-help {
                color: #444;
            }

            &__user-name {
                color: #444;
            }
        }

        .social__title {
            color: #444;
        }

        .social__item svg {
            fill: #444;
        }
    }

    &__head {
        position: relative;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-right: 220px;
        @media (max-width: 1199px) {
            padding-right: 80px;
        }
        @media (max-width: 767px) {
            position: relative;
            flex-wrap: wrap;
            padding-right: 75px;
        }
    }

    &__change-theme {
        position: absolute;
        top: 5px;
        right: 60px;
        display: flex;
        align-items: center;
        flex-shrink: 0;
        transition: opacity $t-time;
        background: none;
        border: none;

        &:hover {
            cursor: pointer;
            opacity: 0.75;
        }

        @media (max-width: 1199px) {
            top: 0;
            right: 50px;
            transform: none;
        }
    }

    &__change-theme-text {
        display: flex;
        align-items: center;
        width: auto;
        height: 20px;
        padding: 0 5px;
        color: #fff;
        @media (max-width: 991px) {
            height: 20px;
            width: 20px;
            padding: 0;
        }
    }

    &__change-theme-help {
        padding: 0 6px;
        font-size: 12px;
        color: currentColor;
        @media (max-width: 1199px) {
            display: none;
        }
    }

    &__change-theme-icon {
        width: 15px;
        height: 15px;
        fill: currentColor;
        margin: 0;
        @media (max-width: 991px) {
            height: 20px;
            width: 20px;
        }
    }

    &__title {
        font-size: 18px;
        color: #fff;
        font-weight: bold;
        text-transform: uppercase;
        transition: 0.4s;

        @media (max-width: 1199px) {
            width: 100%;
        }
        @media (max-width: 767px) {
            width: 100%;
            font-size: 16px;
        }
    }

    &__user-name {
        color: $gold;
        margin-top: 5px;
    }

    &__head-group {
        display: flex;
        align-items: center;
        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    &__hide {
        position: absolute;
        top: 5px;
        right: 0;
        font-size: 14px;
        color: $gold;
        transition: 0.4s;
        height: 20px;
        width: 20px;
        @media (max-width: 1199px) {
            position: absolute;
            top: 0;
            right: 0;
            transform: none;
        }

        &:hover {
            cursor: pointer;
            color: $gold-alt;
            opacity: 0.75;
        }

        svg {
            fill: currentColor;
        }
    }

    &__right-icon {
        position: relative;
        top: 1px;
        margin-left: 12px;
    }

    &__cross-icon {
        width: 20px;
        height: 20px;
        fill: $gold;
    }

    .social__title {
        margin-right: 15px;
        text-align: left;
        transition: 0.4s;


    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-top: 40px;
        font-size: 16px;
        color: #fff;
        line-height: 25px;
        overflow: scroll;
        height: calc(100% - 68px);
        transition: 0.4s;
        @media (max-width: 991px) {
            margin-top: 20px;
        }
        @media (max-width: 767px) {
            height: calc(100% - 90px);
            font-size: 14px;
            line-height: 1.5;
        }

        b {
            display: inline-block;
            color: $gold;
        }
    }

    &__referral-title {
        display: inline-block;
        font-size: 14px;
        color: #9a9a9a;
        margin-top: 15px;


    }

    &__referral-btn {
        position: relative;
        margin-top: 15px;

        &:after {
            content: '';
            position: absolute;
            bottom: 20px;
            right: -70px;
            display: inline-block;
            background: url(../../../assets/images/helper-arrow.png)
            center/cover no-repeat;
            width: 56px;
            height: 19px;
            transform: rotateZ(145deg);
        }
        &:hover {
            text-decoration: none;
        }
    }
}

.fadeInTop-enter-active {
    transition: 0.4s;
}

.fadeInTop-leave-active {
    transition: 0.4s;
}

.fadeInTop-enter {
    opacity: 0;
    transform: translateY(-30px);
}

.fadeInTop-leave-to {
    opacity: 0;
    transform: translateY(30px);
}
</style>
