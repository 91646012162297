<template lang="pug">
    .layout-decor
        ._wrap#tilt
            transition(name="show-big-circle")
                svg(viewBox="0 0 880 880" v-if="circle.big")._circle.-big
                    circle(r="430" cx="440" cy="440").rotate

            transition(name="show-medium-circle")
                svg(viewBox="0 0 880 880" v-if="circle.medium")._circle.-medium
                    circle(r="430" cx="440" cy="440").rotate

            transition(name="show-small-circle")
                svg(viewBox="0 0 880 880" v-if="circle.small")._circle.-small
                    circle(r="400" cx="440" cy="440").rotate

            transition(name="show-outer-wave")
                svg(viewBox="0 0 855 855" v-if="outerWave")._outer-wave
                    path.rotate#outerWave(d="M124.67,237.35c43.12-40.73,23.5-88.57,60.13-108.63c17.63-9.66,22.81,1.09,54.57-10.11c32.16-11.33,32.06-24.19,58.11-35.37C337.83,65.92,351.94,90.84,408,78c38.22-8.76,42.27-22.77,66.82-19.01c25.69,3.94,27.6,20.26,62.65,34.36c35.63,14.33,42.44,0.98,68.72,14.15c29.83,14.94,24,33.6,63.16,70.74c35.79,33.95,48.43,25.72,61.64,50.02c17.08,31.41-1.42,49.99,17.68,86.4c14.76,28.13,28.88,22.93,36.38,45.47c10.47,31.49-14.09,50.69-17.18,99.03c-2.11,33.11,14.48,38.29,9.09,63.66c-5.14,24.24-19.4,29.63-38.4,56.08c-36.76,51.19-14.38,74.2-45.47,108.13c-24.6,26.85-36.23,9.84-91.96,47.49c-36.86,24.91-45.38,41.54-73.77,45.47c-26.35,3.65-28.47-9.37-59.12-9.09c-50.27,0.46-64.59,35.68-100.04,27.28c-21.57-5.11-18.54-18.69-48.51-29.31c-29.82-10.57-38.47,0.88-62.65-8.59c-26.03-10.19-25.23-27.07-56.34-54.06c-35.79-31.05-47.17-17.68-69.73-40.93c-30.59-31.53-9.22-55.7-40.93-128.84c-18.08-41.71-31.45-48.67-30.32-75.79c1.1-26.19,14.32-37.31,16.67-68.97c2-26.82-10.15-29.84-9.85-59.87c0.11-10.66,0.6-34.26,13.64-56.08C93.06,254.25,105.62,255.35,124.67,237.35z")

            transition(name="show-inner-line-wave")
                svg(viewBox="0 0 855 855" v-if="innerLineWave")._inner-line-wave
                    circle(r="260" cx="427" cy="427")._inner-line-wave-lines

            transition(name="show-inner-wave")
                svg(viewBox="0 0 855 855" v-if="innerWave")._inner-wave.first
                    path(d="M240 270l4-18 16-7 7-18 18 2 7-13c11 2 8 2 16-6h7l5-9 16 3 6-7 10 3 5-9 10 4 10-11 14 5 13-11 14 13 11-8 16 6 16-11 10 14 15-5 5 7 10-1 5 6 11-3 9 13 10-2 2 9 13-4 4 13 15-2 6 16 18 1 4 17 18 11 4 19 15 7-3 11 8 5v8l9 5-2 15 9 7-6 8 12 7-5 11 12 9-8 13 14 14-13 11 7 17-5 13 11 16-13 12 3 15-8 5 1 9-5 6 3 8-11 9v12l-8 3 2 12-12 3 1 14-14 9v18l-17 5-7 20-23-2-7 17h-14c-6 6-4 6-12 8l-6 8-15-4-4 7-11-7-2 14-11-5-10 11-15-6-13 11-13-13-12 11-17-5-14 8-13-11-13 4-6-7c-8-1-8-1-15-5l-9 2-9-11-11 1-3-7-13 2-3-10h-17l-5-14-18-3-2-16-18-12-5-21-13-6-1-10-6-7-1-6-9-7v-15l-2-7 1-9-9-4 2-13-8-8 5-17-6-10 6-15-7-15 6-14-9-13 15-15-6-10 8-13-2-2 7-9-4-8 9-5 1-12 9-5-1-11 8-5v-20l16-8z")

            transition(name="show-inner-wave")
                svg(viewBox="0 0 855 855" v-if="innerWave")._inner-wave.second
                    path(d="M240 270l4-18 16-7 7-18 18 2 7-13c11 2 8 2 16-6h7l5-9 16 3 6-7 10 3 5-9 10 4 10-11 14 5 13-11 14 13 11-8 16 6 16-11 10 14 15-5 5 7 10-1 5 6 11-3 9 13 10-2 2 9 13-4 4 13 15-2 6 16 18 1 4 17 18 11 4 19 15 7-3 11 8 5v8l9 5-2 15 9 7-6 8 12 7-5 11 12 9-8 13 14 14-13 11 7 17-5 13 11 16-13 12 3 15-8 5 1 9-5 6 3 8-11 9v12l-8 3 2 12-12 3 1 14-14 9v18l-17 5-7 20-23-2-7 17h-14c-6 6-4 6-12 8l-6 8-15-4-4 7-11-7-2 14-11-5-10 11-15-6-13 11-13-13-12 11-17-5-14 8-13-11-13 4-6-7c-8-1-8-1-15-5l-9 2-9-11-11 1-3-7-13 2-3-10h-17l-5-14-18-3-2-16-18-12-5-21-13-6-1-10-6-7-1-6-9-7v-15l-2-7 1-9-9-4 2-13-8-8 5-17-6-10 6-15-7-15 6-14-9-13 15-15-6-10 8-13-2-2 7-9-4-8 9-5 1-12 9-5-1-11 8-5v-20l16-8z")

            transition(name="show-inner-wave")
                svg(viewBox="0 0 855 855" v-if="innerWave")._inner-wave.third
                    path(d="M240 270l4-18 16-7 7-18 18 2 7-13c11 2 8 2 16-6h7l5-9 16 3 6-7 10 3 5-9 10 4 10-11 14 5 13-11 14 13 11-8 16 6 16-11 10 14 15-5 5 7 10-1 5 6 11-3 9 13 10-2 2 9 13-4 4 13 15-2 6 16 18 1 4 17 18 11 4 19 15 7-3 11 8 5v8l9 5-2 15 9 7-6 8 12 7-5 11 12 9-8 13 14 14-13 11 7 17-5 13 11 16-13 12 3 15-8 5 1 9-5 6 3 8-11 9v12l-8 3 2 12-12 3 1 14-14 9v18l-17 5-7 20-23-2-7 17h-14c-6 6-4 6-12 8l-6 8-15-4-4 7-11-7-2 14-11-5-10 11-15-6-13 11-13-13-12 11-17-5-14 8-13-11-13 4-6-7c-8-1-8-1-15-5l-9 2-9-11-11 1-3-7-13 2-3-10h-17l-5-14-18-3-2-16-18-12-5-21-13-6-1-10-6-7-1-6-9-7v-15l-2-7 1-9-9-4 2-13-8-8 5-17-6-10 6-15-7-15 6-14-9-13 15-15-6-10 8-13-2-2 7-9-4-8 9-5 1-12 9-5-1-11 8-5v-20l16-8z")

            transition(name="scale")
                vue-lottie-player(v-if="showAnalyzeLocal" autoplay controls loop mode="normal" :animationData="analyzeFile" :width="pageWidth > 991 ? 360 : 240" :height="pageWidth > 991 ? 360 : 240")._player
</template>
<script>
//import VanillaTilt from "vanilla-tilt";
import VueLottiePlayer from "vue-lottie-player";

export default {
    name: "LayoutDecor",
    components: {VueLottiePlayer},
    props: {
        "showAnalyze": {
            type: Boolean,
            required: false,
            default: false
        },
        "showBigCircle": {
            type: Boolean,
            required: false,
            default: false
        },
        "showMediumCircle": {
            type: Boolean,
            required: false,
            default: false
        },
        "showSmallCircle": {
            type: Boolean,
            required: false,
            default: false
        },
        "showOuterWave": {
            type: Boolean,
            required: false,
            default: false
        },
        "showInnerLineWave": {
            type: Boolean,
            required: false,
            default: false
        },
        "showInnerWave": {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        tilt: "",
        analyzeFile: require("./lottie-files/analyze"),
        circle: {
            big: false,
            bigAnimation: false,
            medium: false,
            small: false,
        },
        outerWave: false,
        innerLineWave: false,
        innerWave: false,
        showAnalyzeLocal: false,
    }),
    computed: {},
    watch: {
        showOuterWave(status) {
            this.outerWave = status;
        },
        showInnerLineWave(status) {
            this.innerLineWave = status;
        },
        showInnerWave(status) {
            this.innerWave = status;
        },
        showAnalyze(status) {
            this.showAnalyzeLocal = status;
        },
    },
    mounted() {
        setTimeout(() => {
            this.circle.big = this.showBigCircle;
            this.circle.medium = this.showMediumCircle;
            this.circle.small = this.showSmallCircle;
            this.outerWave = this.showOuterWave;
            this.innerLineWave = this.showInnerLineWave;
            this.innerWave = this.showInnerWave;
            this.showAnalyzeLocal = this.showAnalyze;
        }, 100);

        this.tilt = document.getElementById("tilt");
        //VanillaTilt.init(this.tilt, {
        //  "full-page-listening": true,
        //  reverse: true,
        //  reset: true,
        //  max: 10
        //});
    },
    destroyed() {
        //this.tilt.vanillaTilt.destroy();
        this.loading = true;
    },
};
</script>

<style lang="scss">
@import "layout-decor";
</style>
