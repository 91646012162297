const lang = localStorage.getItem("lang") || "ru";
import ru from "../../localization/ru";
import en from "../../localization/en";
import uz from "../../localization/uz";
import tj from "../../localization/tj";
import kz from "../../localization/kz";
import az from "../../localization/az";
import kg from "../../localization/kg";

const localization = {
    ru,
    en,
    uz,
    tj,
    kz,
    az,
    kg
};

export const tips = {
    namespaced: true,
    state: {
        show: false,
        type: "",
        positions: null,
        list: localization[lang].Tips.list,
    },
    actions: {
        setPositions({ commit }, positions) {
            commit("setPositions", positions);
        },
        show({ commit }, type) {
            commit("show", type);
        },
        hide({ commit }) {
            commit("hide");
        },
    },
    getters: {
        show: (state) => state.show,
        type: (state) => state.type,
        list: (state) => state.list,
        positions: (state) => state.positions,
    },
    mutations: {
        show(state, type) {
            state.show = true;
            state.type = type;
        },
        setPositions(state, positions) {
            state.positions = positions;
        },
        hide(state) {
            state.show = false;
            state.type = "";
        },
    },
};
