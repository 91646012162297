<template lang="pug">
    .cookie
        ._text {{ locale("prefix-text") }}
            router-link(:to="{name: 'Static', params: { page: 'cookie'}}" tag="a") {{ locale("text") }}
        ui-button(type="text" :text="`${$t('cookies.btn-text')}`" @click.native="$emit('acceptCookies')")
</template>
<script>
import UiButton from "../../ui/ui-button/ui-button";

export default {
    name: "AppCookie",
    components: { UiButton },
};
</script>

<style lang="scss">
@import "cookie";
</style>
