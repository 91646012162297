export default {
    install(Vue) {
        let timer = "";
        Vue.prototype.$msgBox = function (title, message, type) {
            if (!message) {
                return;
            }
            clearTimeout(timer);

            this.$store
                .dispatch("setMessage", { title, message, type })
                .then(() => {
                    this.$store.dispatch("setMsgBoxStatus", true);
                });
            timer = setTimeout(() => {
                this.$store.dispatch("setMsgBoxStatus", false);
            }, 5000);
        };

        Vue.prototype.$inArray = function (needle, haystack) {
            let length = haystack.length;
            let i;
            let j;

            if (Array.isArray(needle) === false) {
                length = haystack.length;
                for (i = 0; i < length; i++) {
                    if (haystack[i] === needle) return true;
                }
            } else {
                let needleLength = needle.length;
                for (i = 0; i < length; i++) {
                    for (j = 0; j < needleLength; j++) {
                        if (haystack[i] === needle[j]) return true;
                    }
                }
            }
        };

        Vue.prototype.$arrayUnique = function (array) {
            return array.filter(onlyUnique);
            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index;
            }
        };

        Vue.prototype.$asThumb = function (imageUri, micro, thumbs = true) {
            if (!imageUri) {
                return "";
            }
            let dir = "";

            if(thumbs) dir = "thumbs";
            if (micro) dir = "micro";
            
            if(!micro && !thumbs) {
                return imageUri;
            }

            let pieces = imageUri.split(/\//),
                piecesLen = pieces.length,
                thumbUrl = "",
                i;

            for (i = 0; i < piecesLen; i++) {
                if (i > 0) thumbUrl += "/";
                if (i === piecesLen - 1) {
                    thumbUrl += dir + "/";
                }
                thumbUrl += pieces[i];
            }

            return thumbUrl;
        };
    },
};
