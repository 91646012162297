<template lang="pug">
    .blocked-ui
        loading(v-if="loading")
        ._title {{ locale("title") }} 
        ._description {{ locale("description") }}
        change-photos(:show-gift="false" photoType="anfas and profile")._uploader
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";
import gql from "graphql-tag";
import personCreateDescriptionMutation from "@/graphql/mutations/personCreateDescription.mutation.graphql";
import ChangePhotos from "@/components/person/change-photos/change-photos.vue";

export default {
    name: "BlockedUI",
    components: { ChangePhotos, UiButton },
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        anfas: function () {
            const photo = this.$store.getters["user/photos"]["anfas"];
            return photo?.includes("dummy") ? null : photo;
        },
        profile: function () {
            const photo = this.$store.getters["user/photos"]["profile"];
            return photo?.includes("dummy") ? null : photo;
        },
        photosReady: (state) =>
            !!(state.anfasChange || state.anfas) &&
            !!(state.profileChange || state.profile),
    },
    watch: {
        anfas() {
            this.anfasChange = true;
        },
        profile() {
            this.profileChange = true;
        },
        photosReady(status) {
            if (status) {
                this.generateDescription();
            }
        },
    },
    mounted() {
        if (this.anfas && this.profile) {
            this.generateDescription();
        }
    },
    methods: {
        async generateDescription() {
            this.loading = true;
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${personCreateDescriptionMutation}
                    `,
                    variables: {
                        personId: this.myPerson.uuid,
                        mode: "RECREATE_BY_PHOTOS",
                    },
                })
                .then((r) => {
                    const taskId = r.data.personCreateDescription.taskId;
                    if (taskId) {
                        this.taskResponseHandler(taskId);
                    }
                })
                .catch((e) => {
                    console.log(e.graphQLErrors);
                });
        },
        async taskResponseHandler(taskId) {
            const task = await this.checkTask(taskId);
            if (task.status === "PENDING" || task.status === "RUNNING") {
                setTimeout(() => {
                    this.taskResponseHandler(taskId);
                }, 1000);
            }
            if (task.status === "COMPLETED") {
                window.location.href = "/";
            }
            if (task.status === "ERROR") {
                this.loading = false;
                this.$msgBox(
                    this.localeError("error"),
                    this.localeError("createDescriptionError"),
                );
            }
        },
    },
};
</script>

<style lang="scss" src="./blocked-ui.scss"></style>
