import gql from "graphql-tag";

export const querySubscriptionPlansCategoriesOnly = gql`
    query subscriptionPlans($periodicity: PERIODICITY!) {
        subscriptionPlans(periodicity: $periodicity) {
            category {
                id
                name
                codeName
            }
        }
    }
`;

export const querySubscriptionPlans = gql`
    query subscriptionPlans($periodicity: PERIODICITY!) {
        subscriptionPlans(periodicity: $periodicity) {
            category {
                id
            }
            plans {
                id
                level
                price
                discount
                freeAnalogPlanId
            }
        }
    }
`;

export const querySubscriptionRecommendations = gql`
    query {
        myPersonRecommendations {
            recommendations {
                category {
                    id
                    name
                }
                items {
                    id
                    text
                    unlocked
                    unlockAvailable
                    unlockAvailableAt
                }
            }
        }
    }
`;
