<template lang="pug">
    .reviews.section
        ._wrap.wrap
            ._head
                h2._title(v-html="locale('title')")
                ._ctrl
                    ._ctrl-item.-prev(@click="slider.prev()" :class="{disabled: activeIndex === 0}")
                        svg-icon(icon-name="arrow-left")._ctrl-icon
                    ._ctrl-item.-next(@click="slider.next()" :class="{disabled: activeIndex === 1}")
                        svg-icon(icon-name="arrow-right")._ctrl-icon
            VueSlickCarousel(:arrows="false" :dots="false" :rows="1" :slidesPerRow="slidesPerRow" ref="slider" @afterChange="changeActiveIndex" :draggable="true")._list
                ._item(v-for="(video, index) in videos" :key="index")
                    video(muted loop playsinline controls)._video
                        source(:src="video" type="video/mp4")
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";

export default {
    name: "LandingReviews",
    components: { VueSlickCarousel },
    data() {
        return {
            activeIndex: 0,
        };
    },
    computed: {
        slider: (state) => state.$refs.slider,
        slidesPerRow: function () {
            if (this.pageWidth >= 992) return 4;
            if (this.pageWidth >= 480) return 3;
            return 2;
        },
        videos: function () {
            return {
                0: require("./video/review-1.mp4"),
                1: require("./video/review-2.mp4"),
                2: require("./video/review-3.mp4"),
                3: require("./video/review-4.mp4"),
            };
        },
    },
    methods: {
        changeActiveIndex(index) {
            this.activeIndex = index;
        },
    },
};
</script>

<style lang="scss" src="./reviews.scss"></style>
