<template lang="pug">
  .subscriptions-table-option
    template(v-if="!isPlanOptionSingle")
      .__label {{ planOption[0] }}
      .__additional(v-for="planOptionItem in additionalPlanOptions") {{ planOptionItem }}
    template(v-else)
      .__label {{ planOption }}

</template>

<script>
export default {
    name: "SubscriptionsTableOption",
    props: {
        plans: {
            type: Array,
            required: true,
        },
        optionId: {
            type: String,
            required: true,
        },
        planId: {
            type: String || null,
            required: false,
            default: null,
        },
        title: {
            type: String || null,
            required: false,
            default: null,
        },
    },
    computed: {
        planOption() {
            return this.plans
                .find((plan) => plan.id === this.planId)
                .items.find((option) => option.id === this.optionId).value;
        },
        isPlanOptionSingle() {
            return typeof this.planOption === "string";
        },
        additionalPlanOptions() {
            return this.planOption.slice(1);
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-table-option.scss" />
