import Vue from "vue";

export default Vue.mixin({
    methods: {
        VKGoal(id) {
            /* eslint-disable no-undef */
            if (_tmr) {
                _tmr.push({
                    type: "reachGoal",
                    id: 3524920,
                    value: 15,
                    goal: id,
                });
            }
        },
        yandexGoal(id, skip = true) {
            const localTarget = localStorage.getItem("target-completed");
            const targetCompleted = localTarget ? JSON.parse(localTarget) : [];

            if (this.$metrika?.reachGoal && !targetCompleted.includes(id)) {
                this.$metrika.reachGoal(id);
                if (skip) {
                    targetCompleted.push(id);
                    localStorage.setItem("target-completed", JSON.stringify(targetCompleted));
                }
            }
        }
    },
});
