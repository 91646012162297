<template lang="pug">
    div
        div Пользователей всего: {{ users }}<br>
        div Пользователей, которые хотя бы 1 раз за что либо заплатили: {{ payingUsers }}<br>
        div Всего пополнений: {{ deposit }} руб.<br>
        div Всего известный личностей: {{ persons }}<br>
</template>

<script>
import gql from "graphql-tag";

export default {
    name: "Stats",
    data() {
        return {
            users: 0,
            payments: 0,
            persons: 0,
        };
    },
    apollo: {
        getStats1: {
            query: gql`
                query {
                    getStats1 {
                        totalUsers
                        totalPayingUsers
                        totalPersons
                        totalDeposit {
                            totalDeposit
                        }
                    }
                }
            `,
            result(response) {
                if (response.data.getStats1) {
                    this.users = response.data.getStats1.totalUsers;
                    this.payingUsers = response.data.getStats1.totalPayingUsers;
                    this.persons = response.data.getStats1.totalPersons;
                    this.deposit =
                        response.data.getStats1.totalDeposit.totalDeposit;
                }
            },
            fetchPolicy: "network-only",
        },
    },
};
</script>
