import gql from "graphql-tag";

export const menuMixins = {
    queries: {
        getPersonCategories: gql`
            query {
                getPersonCategories(load10LatestPersons: true) {
                    id
                    name
                    persons {
                        uuid
                        name
                    }
                }
            }
        `,
        taskGet: gql`
            query taskGet($taskId: String!) {
                taskGet(taskId: $taskId) {
                    status
                    error
                }
            }
        `,
        getUserSubscriptions: gql`
            query getUserSubscriptions($uuid: String!) {
                getUserSubscriptions(uuid: $uuid)
            }
        `,
    },

    mutations: {
        createPersonCategory: gql`
            mutation createPersonCategory($name: String!) {
                createPersonCategory(name: $name)
            }
        `,
        deletePersonCategory: gql`
            mutation deletePersonCategory($id: Int!) {
                deletePersonCategory(id: $id)
            }
        `,
        personCreate: gql`
            mutation personCreate($name: String!, $categoryId: Int!) {
                personCreate(name: $name, categoryId: $categoryId)
            }
        `,
        personDelete: gql`
            mutation personDelete($uuid: String!) {
                personDelete(uuid: $uuid)
            }
        `,
        personCreateDescription: gql`
            mutation personCreateDescription($personId: String!) {
                personCreateDescription(
                    personId: $personId
                    mode: RECREATE_BY_ID
                ) {
                    taskId
                }
            }
        `,
        personSwitchPublicStatus: gql`
            mutation personSwitchPublicStatus($uuid: ID!) {
                personSwitchPublicStatus(uuid: $uuid)
            }
        `,
        personSwitchCelebrityStatus: gql`
            mutation personSwitchCelebrityStatus($uuid: ID!) {
                personSwitchCelebrityStatus(uuid: $uuid)
            }
        `,
        personChangeType: gql`
            mutation personChangeType($uuid: ID!, $newType: String!) {
                personChangeType(person_id: $uuid, type: $newType)
            }
        `,
        setUserSubscriptionPlan: gql`
            mutation setUserSubscriptionPlan(
                $uuid: String!
                $planId: Int!
                $force: Boolean
            ) {
                setUserSubscriptionPlan(
                    uuid: $uuid
                    planId: $planId
                    force: $force
                )
            }
        `,
        deleteUserSubscriptionPlan: gql`
            mutation deleteUserSubscriptionPlan($uuid: String!, $planId: Int!) {
                deleteUserSubscriptionPlan(uuid: $uuid, planId: $planId)
            }
        `,
        userDelete: gql`
            mutation userDelete($uuid: String!, $full: Boolean) {
                userDelete(uuid: $uuid, full: $full)
            }
        `,
    },
};
