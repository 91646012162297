<template lang="pug">
  modal(name="referral" class="referral-modal-wrap" :adaptive="true" width="100%" height="100%")
    perfect-scrollbar.referral-modal-box
      ._decor-1
      ._decor-2
      ._close-btn(@click="$modal.hide('referral')")
        svg-icon(icon-name="cross" added-class="help-modal__cross")
      .referral-modal
        ._group
          ._group-title Ваши рефералы {{ myPerson.public_url }}
          ._invite
            ._invite-group
              ._invite-group-title Куда вести пользователей по реферальной ссылке?
              ._invite-tabs
                ._invite-tab(:class="{active: refLocation === 'profile'}")
                  ui-button(size="small" type="bordered" :color="refLocation === 'home' ? 'gray' : ''" @click.native="refLocation = 'profile'" text="На мой профиль")
                ._invite-tab(:class="{active: refLocation === 'home'}")
                  ui-button(size="small" type="bordered" :color="refLocation === 'profile' ? 'gray' : ''" @click.native="refLocation = 'home'" text="На главную merlinface.com")

            ._invite-group
              ._invite-group-title Реферальная ссылка на вашу личность:
              ._invite-link-box(@click.stop.prevent="copyLink")
                input._invite-link-input(:value="resultLink" autocomplete="new-password" readonly)
                ._invite-link-copy(:class="{active: justCopied}")
                  svg-icon(icon-name="copy")._invite-link-copy-icon
                  ._invite-link-copy-text
                    template(v-if="!justCopied") скопировать
                    template(v-else) скопировано

            ._invite-group
              p._invite-desc Вы будете получать <span>{{ rewardPercent(1) }}%</span> с каждой покупки пользователя, которого Вы приведете по ссылке, а также <span>{{ rewardPercent(2) }}%</span> - с покупок пользователей, которых приведет Ваш реферал.

          ._list-box
            ._list-ctrl
              ._list-ctrl-item(@click="refType = 'referral'" :class="{active: refType === 'referral'}") Рефералы
              ._list-ctrl-item(v-if="isCoach" @click="refType = 'partner'" :class="{active: refType === 'partner'}") Партнёры

            perfect-scrollbar(v-if="refType === 'referral'" infinite-wrapper)._list
              referral-user(v-for="(referral, index) in referrals" :key="index" :referral="referral" userType="referralUser")
              infinite-loading(@infinite="getReferrals" identifier="partners")
                ._infinite-msg(slot="spinner") Загрузка...
                ._infinite-msg(slot="no-more")
                  template(v-if="referrals.length > 5") Больше нет записей
                ._infinite-msg(slot="no-results") У вас пока нет рефералов        

            perfect-scrollbar(v-if="refType === 'partner'" infinite-wrapper)._list
              referral-user(v-for="(partner, index) in partners" :key="index" :referral="partner")
              infinite-loading(@infinite="getPartners" identifier="partners")
                ._infinite-msg(slot="spinner") Загрузка...
                ._infinite-msg(slot="no-more") Больше нет записей
                ._infinite-msg(slot="no-results") У вас пока нет партнеров


        ._group.-stats(v-if="referralReport" @click="isStatsActive = !isStatsActive" :class="{active: isStatsActive}")
          ._group-title Статистика
            svg-icon(icon-name="chevron-down")._group-title-icon
          ._stats
            ._stats-item Перешли по вашей реферальной ссылке: <span>{{ referralReport.totalReferralLinkVisits }} человек</span>
            ._stats-item Зарегистрировались: <span>{{ referralReport.totalReferrals }} человек</span>
            ._stats-item Загрузили фотографии: <span>{{ referralReport.totalReferralsWithPhoto }} человек</span>
            ._stats-group
              ._stats-item Оформили подписку:
              ._stats-item
                span.gold Gold: 
                | <span>{{ referralReport.totalReferralsWithGold.monthly }} на месяц, {{ referralReport.totalReferralsWithGold.yearly }} на год</span>
              ._stats-item
                span.platinum Platinum: 
                | <span>{{ referralReport.totalReferralsWithPlatinum.monthly }} на месяц, {{ referralReport.totalReferralsWithPlatinum.yearly }} на год</span>
            ._stats-group
              ._stats-item Прогноз заработка в текущем месяце: <span>{{ referralReport.potentialIncome }} руб.</span>

</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import UiButton from "@/components/ui/ui-button/ui-button";
import { referralMixins } from "../../../mixins/referralMixins";
import ReferralUser from "./referral-user/referral-user";
import gql from "graphql-tag";
import referralReportQuery from "@/graphql/queries/referralReport.query.graphql";
import referralListQuery from "@/graphql/queries/referralList.query.graphql";
import coachPartnersListQuery from "@/graphql/queries/coachPartnersList.query.graphql";

export default {
    name: "ReferralModal",
    components: {
        ReferralUser,
        InfiniteLoading,
        UiButton,
    },
    mixins: [referralMixins],
    data: () => ({
        allowComponent: false,
        isLoadingList: 0,
        isLoadingPartner: 0,
        page: 1,
        partnersPage: 1,
        limit: 5,
        referrals: [],
        partners: [],
        justCopied: false,
        refType: "referral",
        noImage: require("../../../assets/images/no-avatar.jpg"),
        refLocation: "profile",
        isStatsActive: false,
        refLetters: {
            ROLE_USER: {
                home: "s",
                profile: "u",
            },
            ROLE_CELEBRITY: {
                home: "e",
                profile: "c",
            },
            ROLE_PARTNER: {
                home: "a",
                profile: "p",
            },
        },
        resultLink: "Загрука...",
        referralReport: null,
    }),
    computed: {
        isCelebrity: (state) => state.$store.getters["user/celebrity"],
        myRole: function () {
            let userRoles = this.myRoles;
            let dominantRole = "ROLE_USER";

            if (this.$inArray(userRoles, ["ROLE_CELEBRITY"]) === true) {
                dominantRole = "ROLE_CELEBRITY";
            } else if (this.$inArray(userRoles, ["ROLE_PARTNER"]) === true) {
                dominantRole = "ROLE_PARTNER";
            }

            return dominantRole;
        },
        myProfileLink: (state) =>
            state.myPerson.public_url
                ? state.myPerson.public_url
                : state.myPerson.uuid,
    },
    watch: {
        refLocation() {
            this.createLink();
        },
        refType(val) {
            if (val === "partner" && !this.partners.length) {
                this.getPartners();
            }
        },
    },
    async mounted() {
        await this.getReferralReport();
        await this.createLink();
    },
    methods: {
        async getReferrals($state) {
            await this.$apollo
                .query({
                    query: gql`
                        ${referralListQuery}
                    `,
                    variables: {
                        page: this.page,
                        limit: this.limit,
                    },
                })
                .then((r) => {
                    const referrals = r.data.referralList.referrals;
                    if (referrals.length) {
                        this.page++;
                        referrals.forEach((referral) =>
                            this.referrals.push(referral),
                        );
                        $state.loaded();
                    }
                    if (!r.data.referralList.hasMore) {
                        $state.complete();
                    }
                })
                .catch((e) => {
                    console.log(e);
                    $state.error();
                });
        },
        async getPartners($state) {
            await this.$apollo
                .query({
                    query: gql`
                        ${coachPartnersListQuery}
                    `,
                    variables: {
                        page: this.partnersPage,
                        limit: this.limit,
                    },
                })
                .then((r) => {
                    const partners = r.data.coachPartnersList.list;
                    console.log(r);
                    if (partners.length) {
                        this.page++;
                        partners.forEach((partner) =>
                            this.partners.push(partner),
                        );
                        $state.loaded();
                    }
                    if (!r.data.referralList.hasMore) {
                        $state.complete();
                    }
                })
                .catch(() => {
                    $state.error();
                });
        },
        async createLink() {
            this.allowComponent = true;
            this.resultLink =
                document.location.origin +
                "/" +
                this.refLetters[this.myRole][this.refLocation] +
                "/" +
                this.myProfileLink;
        },
        rewardPercent(level) {
            let reward = 0;
            switch (this.myRole) {
                case "ROLE_USER":
                    if (level === 1) reward = 50;
                    else reward = 10;
                    break;

                case "ROLE_CELEBRITY":
                case "ROLE_PARTNER":
                    if (level === 1) reward = 25;
                    else reward = 10;
                    break;
            }

            return reward;
        },
        copyLink() {
            let input = document.querySelector(
                ".referral-modal__invite-link-input",
            );
            input.select();
            try {
                document.execCommand("copy");
                this.justCopied = true;
                input.blur();
                setTimeout(function () {
                    this.justCopied = false;
                }, 2000);
            } catch (e) {
                this.$showError(
                    "Не удалось скопировать ссылку! Пожалуйста, выделите ее вручную и скопируйте.",
                );
            }
        },
        async getReferralReport() {
            await this.$apollo
                .query({
                    query: gql`
                        ${referralReportQuery}
                    `,
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.referralReport = r.data.referralReport;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "referral-modal";
</style>
