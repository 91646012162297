import gql from "graphql-tag";
import VueCookies from "vue-cookies";

export const auth = {
    namespaced: true,
    state: {
        auth: false,
        user_id: localStorage.getItem("user_id") || "",
        token: localStorage.getItem("token") || "",
        refresh_token: localStorage.getItem("refresh_token") || "",
    },
    actions: {
        successAuth({ commit }, tokens) {
            const domain = process.env.NODE_ENV === "development" ? "lvh.me" : ".merlinface.com";
            localStorage.setItem("user_id", tokens.user_id);
            localStorage.setItem("token", tokens.jwt);
            localStorage.setItem("refresh_token", tokens.refresh_token);
            VueCookies.set("token", tokens.jwt, "72h", "/", domain);
            VueCookies.set("refresh_token", tokens.refresh_token, "600h", "/", domain);
            VueCookies.set("user_id", tokens.user_id);

            console.log("store refresh token:", tokens.refresh_token);
            console.log(
                "now refresh token is :",
                localStorage.getItem("refresh_token"),
            );
            commit("successAuth", tokens.jwt);
        },

        obtainNewToken({ state }, { gatewayClient }) {
            const refreshToken =
                localStorage.getItem("refresh_token") ||
                VueCookies.get("token") ||
                "";
            console.log(state);
            const userUuid = localStorage.getItem("user_id");

            if (!refreshToken) {
                return new Promise((resolve, reject) => {
                    reject(new Error("Refresh token is not found locally"));
                });
            } else if (!userUuid) {
                return new Promise((resolve, reject) => {
                    reject(new Error("User id is not found locally"));
                });
            }

            const userAgent = navigator.userAgent;

            const mutation = gql`
                mutation refreshToken(
                    $userId: String!
                    $refreshToken: String!
                    $userAgent: String
                ) {
                    refreshToken(
                        user_id: $userId
                        refresh_token: $refreshToken
                        user_agent: $userAgent
                    ) {
                        jwt
                        refresh_token
                        user_uuid
                    }
                }
            `;
            return gatewayClient.mutate({
                mutation: mutation,
                variables: {
                    userId: userUuid,
                    refreshToken: refreshToken,
                    userAgent,
                },
                fetchPolicy: "no-cache",
            });
        },
        signout({ commit }) {
            localStorage.removeItem("token");
            localStorage.removeItem("refresh_token");
            localStorage.removeItem("user_id");
            localStorage.removeItem("tips");
            localStorage.removeItem("skip-steps");
            localStorage.removeItem("photo-skipped");
            localStorage.removeItem("landing");
            localStorage.removeItem("defaultOnBoardingType");
            VueCookies.remove("token");
            VueCookies.remove("refresh_token");
            VueCookies.remove("user_id");
            commit("signout");
        },
    },
    getters: {
        authStatus: (state) => !!state.token,
        token: (state) => state.token,
        user_id: (state) => state.user_id,
    },
    mutations: {
        successAuth(state, token) {
            state.token = token;
            state.auth = true;
        },
        signout(state) {
            state.auth = false;
            state.token = "";
            state.user_id = 0;
            state.refresh_token = "";
        },
    },
};
