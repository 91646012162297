<template lang="pug">
    perfect-scrollbar(tag="aside").right-aside
        ._wrap
            friends-filter(v-if="page === 'friends' || page === 'celebrity' || page === 'requests' || page === 'compare-with-me' || page === 'search'")
            multi-compare(v-if="page === 'friends' && isMultiCompareActive" type="aside")
            ._multi-compare-success(v-if="isMultiCompareSuccess")
                b Мультисравнение завершено!
                | Результаты сравнения вы можете увидеть в списке друзей
            //._multi-compare(:class="{'right-aside__multi-compare_active': multiCompareStatus}" @click="changeMultiCompareStatus" v-if="page === 'friends'") {{ multiCompareText }}
            //    svg-icon(icon-name="multi-compare")._multi-compare-icon
            service-menu(v-if="page === 'analyze' && personExternalIdPresent && isOnline")
</template>

<script>
import RightAsideSocial from "./social/social";
import ServiceMenu from "../service-menu/service-menu";
import FriendsFilter from "../friends-filter/friends-filter";
import { mapGetters } from "vuex";
import MultiCompare from "../../person/multi-compare/multi-compare";
import { onlineMixin } from "@/mixins/online.mixin";

export default {
    name: "RightAside",
    components: { MultiCompare, FriendsFilter, ServiceMenu, RightAsideSocial },
    mixins: [onlineMixin],
    data() {
        return {
            multiCompareStatus: false,
        };
    },
    computed: {
        ...mapGetters({
            page: "page",
            auth: "auth/authStatus",
        }),
        anfas: (state) => state.$store.getters["user/photos"].anfas,
        profile: (state) => state.$store.getters["user/photos"].profile,
        supportAccent: (state) =>
            (!state.anfas || !state.profile) && state.auth,
        externalIdPresent: (state) =>
            state.$store.getters["user/externalIdPresent"],
        personExternalIdPresent: (state) =>
            state.$store.getters["person/externalIdPresent"],
        isMultiCompareActive: (state) =>
            state.$store.getters["multiCompare/isActive"],
        isMultiCompareSuccess: (state) =>
            state.$store.getters["multiCompare/isSuccess"],
        multiCompareText: (state) =>
            state.multiCompareStatus ? "Отменить" : "Массовое сравнение",
    },
    watch: {
        isMultiCompareActive(val) {
            this.multiCompareStatus = val;
        },
    },
    methods: {
        changeMultiCompareStatus() {
            this.multiCompareStatus = !this.multiCompareStatus;
            this.$store.dispatch(
                "multiCompare/setStatus",
                this.multiCompareStatus,
            );
        },
        openModal(id) {
            this.$bus.$emit("openModal", id);
        },
        showModal(name) {
            if (!this.isTokenPresent()) {
                this.$router.push({ name: "Login" });
            }
            this.$modal.show(name, { props: { allowComponent: true } });
        },
        isTokenPresent() {
            return this.$store.getters["auth/token"] !== "";
        },
    },
};
</script>

<style lang="scss" scoped>
@import "right-aside.scss";
</style>
