import Vue from "vue";
import VueCookies from "vue-cookies";
import VueI18n from "vue-i18n";
import ru from "./localization/ru.js";
import en from "./localization/en.js";
import uz from "./localization/uz.js";
import az from "./localization/az.js";
import kz from "./localization/kz.js";
import tj from "./localization/tj.js";
import kg from "./localization/kg.js";
import VueMeta from "vue-meta";
import PortalVue from "portal-vue";
import AsyncComputed from "vue-async-computed";
import { ApolloClient } from "apollo-client";
import { getMainDefinition } from "apollo-utilities";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "apollo-link-ws";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { ApolloLink, from, fromPromise, split } from "apollo-link";
import VueApollo from "vue-apollo";
import router from "./router";
import App from "./App.vue";
import VueYandexMetrika from "vue-yandex-metrika";
/*import firebaseMessaging from "./firebase";

Vue.prototype.$messaging = firebaseMessaging;*/

import "./components/ui/ui-elements";

import "@/mixins/common.mixin";
import "@/mixins/tips.mixin";
import "@/mixins/checkTask.mixin";
import "@/mixins/checkAuth.mixin";
import "@/mixins/onBoarding.mixin.js";
import "@/mixins/analytics.mixin.js";

// Plugins
import PerfectScrollbar from "vue2-perfect-scrollbar";
import vOutsideEvents from "v-click-outside";
import printText from "./utils/print-text.plugin";
import Vuelidate from "vuelidate";
import VModal from "vue-js-modal";
import Common from "./utils/common";
import VueNumber from "vue-number-animation";
// Global components
import Loading from "./components/app/loading/loading";
import Select from "./components/app/select/select";
import SvgIcon from "./components/app/icon-base/icon-base";

import store from "./store";
import vSelect from "vue-select";
import "./registerServiceWorker";
// import "./utils/firebase";

Vue.config.productionTip = false;
Vue.use(VueApollo);
Vue.use(VueCookies);
Vue.use(AsyncComputed);
Vue.use(VueI18n);
Vue.use(VueMeta);
Vue.use(PerfectScrollbar);
Vue.use(vOutsideEvents);
Vue.use(Common);
Vue.use(printText);
Vue.use(Vuelidate);
Vue.use(VModal);
Vue.use(PortalVue);
Vue.use(VueNumber);
Vue.use(VueYandexMetrika, {
    id: 61563391,
    router: router,
    debug: true,
    env: process.env.NODE_ENV,
    options: {
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: true,
    },
});
Vue.component("Loading", Loading);
Vue.component("Select", Select);
Vue.component("svg-icon", SvgIcon);
Vue.component("v-select", vSelect);

// event bus
export const bus = new Vue();
Vue.prototype.$bus = new Vue();

// Retrieve jwt token from local storage and use it as default header item for Axios & Apollo Client
/*let jwt = localStorage.getItem('token');
if (jwt) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = jwt
}*/

const i18n = new VueI18n({
    locale:
        localStorage.getItem("lang") || window.navigator.language.slice(0, 2),
    fallbackLocale: "ru",
    messages: { ru, en, uz, az, kz, tj, kg },
    dateTimeFormats,
});

const gatewayLink = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_API_GATEWAY,
    headers: {
        language:
            localStorage.getItem("lang") ||
            window.navigator.language.slice(0, 2),
    },
});

const authMiddleware = new ApolloLink((operation, forward) => {
    // add the authorization to the headers
    operation.setContext(({ headers = {} }) => ({
        headers: {
            ...headers,
            authorization:
                localStorage.getItem("token") ||
                Vue.$cookies.get("token") ||
                null,
        },
    }));

    return forward(operation);
});

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
    pendingRequests.map((callback) => callback());
    pendingRequests = [];
};

const gatewayClient = new ApolloClient({
    link: from([
        // eslint-disable-next-line no-unused-vars
        onError(({ graphQLErrors, networkError, operation, forward }) => {
            console.log(operation);
            if (graphQLErrors) {
                for (let err of graphQLErrors) {
                    store.dispatch("telemetry", {
                        gatewayClient,
                        type: `${err.extensions?.category || "no-category"}|${
                            err.extensions.code
                        }`,
                        message: err.message || "no-message",
                    });

                    switch (err.extensions.code) {
                        case 1001:
                            // error code is set to UNAUTHENTICATED
                            // when AuthenticationError thrown in resolver
                            // eslint-disable-next-line no-case-declarations
                            let forward$;

                            if (!isRefreshing) {
                                isRefreshing = true;
                                forward$ = fromPromise(
                                    store
                                        .dispatch("auth/obtainNewToken", {
                                            gatewayClient,
                                        })
                                        .then((response) => {
                                            const responseData =
                                                response.data.refreshToken;
                                            // Store the new tokens for your auth link
                                            resolvePendingRequests();
                                            console.log(
                                                "need do save " +
                                                    responseData.jwt,
                                            );
                                            store.dispatch("auth/successAuth", {
                                                jwt: responseData.jwt,
                                                refresh_token:
                                                    responseData.refresh_token,
                                                user_id: responseData.user_uuid,
                                            });
                                            store.dispatch("telemetry", {
                                                gatewayClient,
                                                type: "Refresh token",
                                                message: "success",
                                            });
                                            return responseData.jwt;
                                        })
                                        .catch((error) => {
                                            console.log("Thrown error:", error);
                                            pendingRequests = [];
                                            store.dispatch("telemetry", {
                                                gatewayClient,
                                                type: "Refresh token",
                                                message: `failed: ${error.toString()}`,
                                            });
                                            if (window.navigator.onLine) {
                                                store
                                                    .dispatch("auth/signout")
                                                    .then(() => {
                                                        router.push({
                                                            name: "Login",
                                                        });
                                                        router.go();
                                                    });
                                            }
                                        })
                                        .finally(() => {
                                            isRefreshing = false;
                                        }),
                                ).filter((value) => Boolean(value));
                            } else {
                                // Will only emit once the Promise is resolved
                                forward$ = fromPromise(
                                    new Promise((resolve) => {
                                        pendingRequests.push(() => resolve());
                                    }),
                                );
                            }

                            return forward$.flatMap(() => forward(operation));
                    }
                }
            }
            if (networkError) console.log(`[Network error]: ${networkError}`);
        }),
        authMiddleware,
        gatewayLink,
    ]),
    cache: new InMemoryCache(),
});

let chatLink = new HttpLink({
    uri: process.env.VUE_APP_GRAPHQL_CHAT_SERVER,
    headers: {
        language:
            localStorage.getItem("lang") ||
            window.navigator.language.slice(0, 2),
    },
});

const chatWsLink = new WebSocketLink({
    uri: process.env.VUE_APP_GRAPHQL_CHAT_SERVER_WS,
    options: {
        reconnect: true,
    },
    headers: {
        language:
            localStorage.getItem("lang") ||
            window.navigator.language.slice(0, 2),
    },
});

const subscriptionMiddleware = {
    applyMiddleware: async (options, next) => {
        options.Authorization =
            (await localStorage.getItem("token")) ||
            (await Vue.$cookies.get("token")) ||
            null;
        next();
    },
};

chatWsLink.subscriptionClient.use([subscriptionMiddleware]);

chatLink = split(
    ({ query }) => {
        const definition = getMainDefinition(query);
        return (
            definition.kind === "OperationDefinition" &&
            definition.operation === "subscription"
        );
    },
    chatWsLink,
    chatLink,
);

const chatAuthLink = setContext((_, { headers }) => {
    const token =
        localStorage.getItem("token") || Vue.$cookies.get("token") || null;
    return {
        headers: {
            ...headers,
            Authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const chatClient = new ApolloClient({
    link: chatAuthLink.concat(chatLink),
    cache: new InMemoryCache(),
});

const apolloProvider = new VueApollo({
    clients: {
        gatewayClient,
        chatClient,
    },
    defaultClient: gatewayClient,
});

const dateTimeFormats = {
    "ru-RU": {
        dialog: {
            month: "short",
            day: "numeric",
        },
        dialogFull: {
            year: "numeric",
            month: "long",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
        },
        paymentsShort: {
            month: "short",
            day: "numeric",
        },
        short: {
            year: "numeric",
            month: "short",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "short",
            day: "numeric",
            weekday: "short",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
        },
    },
};

new Vue({
    router,
    store,
    apolloProvider,
    i18n,
    render: (h) => h(App),
}).$mount("#app");
