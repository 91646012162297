<template lang="pug">
    button(:type="type" :class="modifiers" :disabled="disabled").ui-button {{ text }}
        svg-icon(icon-name="arrow-right" v-if="arrow")._arrow
        svg-icon(:icon-name="rightIcon" v-if="rightIcon")._right-icon
        slot(name="icon")
        slot(name="content")
</template>

<script>
export default {
    name: "UiButton",
    props: {
        type: {
            type: String,
            require: false,
            default: "button",
        },
        color: {
            type: String,
            require: false,
            default: "",
        },
        effect: {
            type: String,
            require: false,
            default: "",
        },
        text: {
            type: String,
            require: false,
            default: ""
        },
        size: {
            type: String,
            require: false,
            default: "",
        },
        case: {
            type: String,
            require: false,
            default: "upper",
        },
        weight: {
            type: String,
            require: false,
            default: "medium",
        },
        disabled: {
            type: Boolean,
            require: false,
            default: false,
        },
        arrow: {
            type: Boolean,
            require: false,
            default: false,
        },
        rightIcon: {
            type: String,
            require: false,
            default: "",
        },
        variant: {
            type: String,
            require: false,
            default: ""
        }
    },
    computed: {
        modifiers: (state) => ({
            "ui-button_with-icon": state.rightIcon,
            "ui-button_bordered": state.type === "bordered",
            "ui-button_text": state.type === "text",
            "ui-button_merlin-app": state.type === "merlin-app",
            "ui-button_small": state.size === "small",
            "ui-button_big": state.size === "big",
            "ui-button_weight_bold": state.weight === "bold",
            "ui-button_case_capitalize": state.case === "capitalize",
            "ui-button_case_upper": state.case === "upper",
            "ui-button_case_lower": state.case === "lower",
            "ui-button_case_unset": state.case === "unset",
            "ui-button_full": state.size === "full",
            "ui-button_aside": state.size === "aside",
            "ui-button_social-connect": state.size === "social-connect",
            "ui-button_pink": state.color === "pink",
            "ui-button_orange": state.color === "orange",
            "ui-button_green": state.color === "green",
            "ui-button_gray": state.color === "gray",
            "ui-button_platinum": state.color.toLowerCase() === "platinum",
            "ui-button_gold": state.color === "gold",
            "ui-button_flash": state.effect === "flash",
            "ui-button_report": state.color === "report",
            "ui-button_rounded": state.variant === "rounded" 
        }),
    },
};
</script>

<style lang="scss">
@import "ui-button";
</style>
