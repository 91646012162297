<template lang="pug">
    modal(name="report" class="report-modal-wrap" :adaptive="true" width="100%" height="100%")
        .report-modal(@click.self="$modal.hide('report')")
            ._wrap
                ._content
                    ._close-btn(@click="$modal.hide('report');")
                        svg-icon(icon-name="cross")._close-icon
                    tempalte(v-if="success")
                        ._title Спасибо!
                            br/
                            span._event-number ID: {{ eventId }}
                        ._description Разработчики рассмотрят проблему в ближайшее время
                    template(v-else)
                        ._title Сообщение об ошибке
                            br/
                            span._event-number ID: {{ eventId }}
                        ._description Опишите что случилось, это нам поможет разобраться быстрее в проблеме
                        ._form
                            ui-input(
                                label="Ваше имя"
                                type="text"
                                placeholder="Введите имя"
                                v-bind:value.sync="name"
                                error="Вы не ввели имя"
                                :validator="$v.name.$error && $v.name.$dirty"
                            )._input
                            ui-input(
                                label="Ваш email"
                                type="email"
                                placeholder="Введите email"
                                v-bind:value.sync="email"
                                error="Введён не корректный email"
                                :validator="$v.email.$error && $v.email.$dirty"
                            )._input
                            ui-input(
                                label="Сообщение об ошибке"
                                type="textarea"
                                placeholder="Введите описание ошибки"
                                v-bind:value.sync="text"
                                error="Введите описание ошибки"
                                :validator="$v.text.$error && $v.text.$dirty"
                            )._input
                        ui-button(text="Отправить" @click.native="sendReport")._close-ui-btn
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";
import {email, minLength, required} from "vuelidate/lib/validators";

export default {
    name: "ReportModal",
    components: {UiButton},
    props: {
        eventId: {
            type: String,
            default: "",
            required: false
        }
    },
    data() {
        return {
            success: false,
            name: "",
            email: "",
            text: "",
        };
    },
    validations: {
        name: {
            required,
        },
        email: {
            required,
            email
        },
        text: {
            required,
            minLength: minLength(10)
        }
    },
    methods: {
        sendReport() {
            if (this.$v.$invalid) {
                this.$v.$touch();
            }
        }
    }
};
</script>

<style lang="scss" src="./report-modal.scss"></style>
