<template lang="pug">
    .tips(
        v-if="activeTips",
        v-click-outside="closeTips",
        :class="`tips_${activeTips.name}`",
        :style="boxPosition",
        ref="tipsBox"
    )
        ._close(@click="closeTips")
            svg-icon._close-icon(icon-name="cross")
            ._close-title {{ locale("close") }}
        ._content
            ._title(v-if="activeTips.title") {{ activeTips.title }}
            ._desc {{ activeTips.subtitle }}
            ._types-list(
                v-if="['people-recommendations', 'subscriptions'].indexOf(activeTips.name) !== -1"
            )
                ._type-item(v-for="(category, name) in subscriptionCategories")
                    ._type-icon-box
                        svg-icon._type-icon(:icon-name="categoriesIcons[name]")
                    ._type-title {{ category }}
        ._footer
            ._ctrl(v-if="activeTips.next || activeTips.prev")
                ui-button._ctrl-btn._ctrl-btn.-prev(
                    type="text",
                    :disabled="!activeTips.prev",
                    @click.native="prevTip"
                )
                    template(v-slot:content)
                        svg-icon._ctrl-btn-icon(icon-name="chevron-left")
                        ._ctrl-btn-text {{ locale("prev") }}
                ui-button._ctrl-btn._ctrl-btn.-next(
                    type="text",
                    :disabled="!activeTips.next",
                    @click.native="nextTip"
                )
                    template(v-slot:content)
                        ._ctrl-btn-text {{ locale("next") }}
                        svg-icon._ctrl-btn-icon(icon-name="chevron-right")
            ._skip(@click="disableTips()") {{ locale("disable") }}
</template>

<script>
import UiButton from "../ui/ui-button/ui-button";
import gql from "graphql-tag";
import ToggleMyTipsDisplayMutation from "@/graphql/mutations/toggleMyTipsDisplay.mutation.graphql";

export default {
    name: "Tips",
    components: {UiButton},
    props: {},
    data() {
        return {
            x: 0,
            y: 0,
            categoriesIcons: {
                PROFESSIONAL: "sub-cat-prof",
                RELATIONS: "sub-cat-relations",
                MOTIVATION: "sub-cat-motivation",
                SELFASSESSMENT: "sub-cat-self-esteem",
            },
        };
    },
    computed: {
        positions: (state) => state.$store.getters["tips/positions"],
        boxPosition: function () {
            const styles = {};
            if (this.x !== "initial") {
                styles.left = `${this.x}px`;
            }
            if (this.y !== "initial") {
                styles.top = `${this.y}px`;
            }
            return styles;
        },
        tips: (state) => state.$store.getters["tips/list"],
        subscriptionCategories: state => state.locale("categories")
    },
    watch: {
        positions() {
            this.changePositions();
        },
    },
    created() {
        window.addEventListener("resize", this.changePositions);
    },
    mounted() {
        this.$nextTick(() => {
            this.changePositions();
        });
    },
    destroyed() {
        window.removeEventListener("resize", this.changePositions);
    },
    methods: {
        changePositions() {
            if (this.activeTips) {
                const el = document
                    .querySelector(this.activeTips.element)
                    .getBoundingClientRect();
                const tipsBox = this.$refs.tipsBox.getBoundingClientRect();
                //const windowWidth = document.body.clientWidth;
                const windowHeight = document.body.clientHeight;
                const scrollTop = window.pageYOffset;
                let scrollInto = "start";
                if (this.activeTips.name === "chat-1") {
                    if (this.pageWidth > 991) {
                        this.x = el.x + tipsBox.width + 20;
                        this.y = el.y;
                    } else {
                        scrollInto = "center";
                        setTimeout(() => {
                            this.x = 30;
                            this.y = el.top + 80;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "chat-2") {
                    if (this.pageWidth > 991) {
                        this.x = el.x + tipsBox.width + 20;
                        this.y = el.y - 20;
                    } else {
                        scrollInto = "center";
                        setTimeout(() => {
                            this.x = 20;
                            this.y = el.top + 60;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "chat-3") {
                    if (this.pageWidth > 991) {
                        this.x = el.x + tipsBox.width + 20;
                        this.y = el.y - 20;
                    } else {
                        scrollInto = "center";
                        setTimeout(() => {
                            this.x = 20;
                            this.y = el.top + 110;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "chat-4") {
                    if (this.pageWidth > 991) {
                        this.x = el.x + tipsBox.width + 20;
                        this.y = el.y - 20;
                    } else {
                        scrollInto = "center";
                        setTimeout(() => {
                            this.x = 15;
                            this.y = el.top + 70;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "chat-5") {
                    if (this.pageWidth > 991) {
                        this.x = el.x + tipsBox.width / 2 + 50;
                        this.y = el.y - 20;
                    } else {
                        scrollInto = "center";
                        setTimeout(() => {
                            this.x = 15;
                            this.y = el.top - tipsBox.height - 20;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "auth") {
                    if (this.pageWidth > 991) {
                        this.x = el.x - tipsBox.width - 40;
                        this.y = windowHeight / 2 - tipsBox.height / 2;
                    } else {
                        this.x = el.x;
                        this.y = 20;
                    }
                }
                if (this.activeTips.name === "avatar") {
                    if (this.pageWidth > 991) {
                        this.x = el.x + tipsBox.width - 20;
                        this.y = el.y;
                    } else {
                        scrollInto = "center";
                        setTimeout(() => {
                            this.x = 15;
                            this.y = el.top + scrollTop + tipsBox.height;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "choose-avatar") {
                    if (this.pageWidth > 991) {
                        this.x = el.x - tipsBox.width - 40;
                        this.y = el.y - el.height + 40;
                    } else {
                        scrollInto = "center";
                        setTimeout(() => {
                            this.x = el.x;
                            this.y =
                                el.top -
                                50 +
                                scrollTop -
                                this.$refs.tipsBox.clientHeight;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "recommendations") {
                    if (this.pageWidth > 991) {
                        this.x = el.x + el.width + 90;
                        setTimeout(() => {
                            this.y =
                                el.y - this.$refs.tipsBox.clientHeight + 50;
                        }, 50);
                    } else {
                        this.x = 15;
                        setTimeout(() => {
                            this.y =
                                el.top +
                                scrollTop -
                                this.$refs.tipsBox.clientHeight -
                                20;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "people-recommendations") {
                    if (this.pageWidth > 991) {
                        this.x = el.x + el.width + 50;
                        setTimeout(() => {
                            this.y =
                                el.top - this.$refs.tipsBox.clientHeight / 2;
                        }, 100);
                    } else {
                        scrollInto = false;
                        this.x = 15;
                        setTimeout(() => {
                            this.y =
                                el.top +
                                scrollTop -
                                this.$refs.tipsBox.clientHeight -
                                20;
                        }, 100);
                    }
                }
                if (
                    this.activeTips.name === "sphere" ||
                    this.activeTips.name === "inside-sphere"
                ) {
                    if (this.pageWidth > 1270) {
                        this.x = el.x - tipsBox.width - 40;
                        this.y = windowHeight / 2 - tipsBox.height / 2 + 20;
                    } else {
                        scrollInto = "end";
                        setTimeout(() => {
                            this.x = 15;
                            this.y =
                                el.top +
                                scrollTop -
                                this.$refs.tipsBox.clientHeight +
                                60;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "search") {
                    if (this.pageWidth > 991) {
                        this.x = el.x + el.width + 20;
                        this.y = el.y;
                    } else {
                        scrollInto = false;
                        this.x = 15;
                        setTimeout(() => {
                            this.y =
                                el.top +
                                scrollTop -
                                this.$refs.tipsBox.clientHeight -
                                20;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "notifications") {
                    if (this.pageWidth > 991) {
                        this.x = el.x + 120;
                        this.y = el.y + 20;
                    } else {
                        scrollInto = false;
                        this.x = 15;
                        setTimeout(() => {
                            this.y =
                                el.top +
                                scrollTop -
                                this.$refs.tipsBox.clientHeight -
                                20;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "subscriptions") {
                    if (this.pageWidth > 991) {
                        this.x = el.x - tipsBox.width - 60;
                        this.y = el.y;
                    } else if (this.pageWidth > 480) {
                        scrollInto = "center";
                        setTimeout(() => {
                            this.x = el.left;
                            this.y = el.top + scrollTop + el.height + 15;
                        }, 100);
                    } else {
                        scrollInto = "center";
                        setTimeout(() => {
                            this.x = 15;
                            this.y = el.top + scrollTop + el.height + 15;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "multi-compare") {
                    if (this.pageWidth > 991) {
                        this.x = el.x - tipsBox.width - 40;
                        this.y = el.y;
                    } else if (this.pageWidth > 480) {
                        scrollInto = "end";
                        setTimeout(() => {
                            this.x = el.x - tipsBox.width + el.width;
                            this.y = el.top + el.height + scrollTop + 15;
                        }, 100);
                    } else {
                        scrollInto = "end";
                        setTimeout(() => {
                            this.x = 15;
                            this.y = el.top + el.height + 15;
                        }, 100);
                    }
                }
                if (this.activeTips.name === "recommendations-modal") {
                    scrollInto = false;
                    this.x = el.x;
                    this.y = el.y + 58 + scrollTop;
                }
                if (this.pageWidth <= 991) {
                    setTimeout(() => {
                        if (!scrollInto) return;
                        this.$refs.tipsBox.scrollIntoView({
                            behavior: "smooth",
                            block: scrollInto,
                        });
                    }, 400);
                }
            }
        },
        disableTips() {
            localStorage.tips = "all";
            this.closeTips();
            if (!this.isUserAuth) return;
            this.$apollo.mutate({
                mutation: gql`
                    ${ToggleMyTipsDisplayMutation}
                `,
            });

        },
        closeTips() {
            this.$store.dispatch("tips/hide");
        },
        prevTip() {
            if (this.activeTips.prev) {
                const skip = localStorage.getItem("tips")
                    ? JSON.parse(localStorage.getItem("tips"))
                    : [];
                const skipIndex = skip.indexOf(this.activeTips.prev);
                if (skipIndex !== -1) {
                    skip.splice(skipIndex, 1);
                    localStorage.tips = JSON.stringify(skip);
                }
                if(this.activeTips.prev === "chat-1") {
                    this.$router.push("/messages");
                    setTimeout(() => {
                        this.showTips(this.activeTips.prev, "next");
                    }, 100);
                    return;
                }
                this.showTips(this.activeTips.prev, "prev");
            }
        },
        nextTip() {
            if (this.activeTips.next) {
                const skip = localStorage.getItem("tips")
                    ? JSON.parse(localStorage.getItem("tips"))
                    : [];
                const skipIndex = skip.indexOf(this.activeTips.next);
                if (skipIndex !== -1) {
                    skip.splice(skipIndex, 1);
                    localStorage.tips = JSON.stringify(skip);
                }
                if(this.activeTips.next === "chat-2") {
                    this.$router.push("/messages/18d68acc-b1cd-11ed-b5e6-02420a000cf6");
                    setTimeout(() => {
                        this.showTips(this.activeTips.next, "next");
                    }, 100);
                    return;
                }
                this.showTips(this.activeTips.next, "next");
            }
        },
    },
};
</script>

<style lang="scss">
@import "tips";
</style>
