<template lang="pug">
  .landing-banner.section
    ._wrap.landing-wrap
      ._caption-box
        ._caption
          h1._title(v-html="locale('title')")
          ul._list
            li._list-item(v-for="item in locale('list')") {{ item }}
          ._illustration.-grayscale
            img(:src="require('./img/particles-grayscale.svg')")._particles-img.-grayscale#particles-2
            img(:src="require('./img/illustration-grayscale.svg')")._illustration-img.-grayscale#illustration-2
        ._illustration
          img(:src="require('./img/particles.svg')")._particles-img#particles-1
          img(:src="require('./img/illustration.svg')")._illustration-img#illustration-1
      ._description {{ locale("description") }}
      ._btn-box
        ui-button(text="Попробовать бесплатно" size="big" @click.native="redirectToAuth" variant="rounded")._btn
        ui-button(text="Что умеет Merlin AI" type="bordered" size="big" @click.native="$emit('update-tab', 'gpt')" variant="rounded")._btn

</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "LandingBanner",
    components: {UiButton},
    data() {
        return {};
    },
    mounted() {
        this.$nextTick(() => {
            this.animateIllustration();
        });
    },
    methods: {
        animateIllustration() {
            if(this.pageWidth <= 767) return;
            const img = document.querySelector("#illustration-1");
            const particles = document.querySelector("#particles-1");
            const imgGrayscale = document.querySelector("#illustration-2");
            const particlesGrayscale = document.querySelector("#particles-2");

            window.addEventListener("mousemove", function (e) {
                let x = e.clientX / window.innerWidth;
                let y = e.clientY / window.innerHeight;
                img.style.transform =
            "translate(-" + x * 10 + "px, -" + y * 10 + "px)";
                particles.style.transform =
            "translate(-" + x * 15 + "px, -" + y * 15 + "px)";
                imgGrayscale.style.transform =
            "translate(-" + x * 10 + "px, -" + y * 10 + "px)";
                particlesGrayscale.style.transform =
            "translate(-" + x * 15 + "px, -" + y * 15 + "px)";
            });
        },
        async redirectToAuth() {
            await this.temporaryAuth(true);
            this.yandexGoal("landing_btn_1");
        },
    },
};
</script>

<style lang="scss" src="./landing-banner.scss"></style>
