import Vue from "vue";
import taskGetQuery from "@/graphql/queries/taskGet.query.graphql";
import gql from "graphql-tag";

// Глобальный миксин для проверки статуса задачи
Vue.mixin({
    methods: {
        async checkTask(taskId) {
            return await this.$apollo
                .query({
                    query: gql`
                        ${taskGetQuery}
                    `,
                    variables: {
                        taskId,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    return r.data.taskGet;
                });
        },
    },
});
