export const onlineMixin = {
    data() {
        return {
            isOnline: true,
            navigatorOnline: navigator.onLine,
            hasRead: false, //flag to prevent Navigator.Online work a lot of times
        };
    },
    computed: {
        profileData: (state) => state.$store.getters["person/info"],
        userData: (state) => state.$store.getters["user/userData"],
        // Auth data
        authStatus: (state) => state.$store.getters["auth/authStatus"],
        token: (state) => state.$store.getters["auth/token"],
        personCategories: (state) =>
            state.$store.getters["person/personCategories"],
    },
    created() {
        this.cacheData();
        this.redirectToProfile();
    },
    watch: {
        navigatorOnline() {
            this.cacheData();
        },
        "$route.name"() {
            this.redirectToProfile();
        },
        profileData() {
            this.cacheData();
        },
        userData() {
            this.cacheData();
        },
        token() {
            this.cacheData();
        },
        personCategories() {
            this.cacheData();
        },
    },
    methods: {
        redirectToProfile() {
            if (this.$route.name === "home" && !navigator.onLine) {
                let userUrl = this.profileData.public_url
                    ? this.profileData.public_url
                    : `/person/${this.profileData.assigned_user}`;
                this.$router.push(userUrl);
                this.$router.go();
            }
        },
        cacheData() {
            if (navigator.onLine) {
                this.isOnline = true;

                if (
                    this.profileData &&
                    this.userData &&
                    this.personCategories
                ) {
                    let profileData = JSON.stringify(this.profileData);
                    let userData = JSON.stringify(this.userData);
                    let personCategories = JSON.stringify(
                        this.personCategories,
                    );

                    localStorage.setItem(
                        "profileData",
                        JSON.stringify(profileData),
                    );
                    localStorage.setItem("userData", JSON.stringify(userData));
                    localStorage.setItem(
                        "personCategories",
                        JSON.stringify(personCategories),
                    );
                }
            }
            if (!navigator.onLine) {
                this.isOnline = false;
                if (!this.hasRead) {
                    let profileData = JSON.parse(
                        localStorage.getItem("profileData"),
                    );
                    let userData = JSON.parse(localStorage.getItem("userData"));
                    let skipAuth = localStorage.getItem("skipAuth");
                    let personCategories = JSON.parse(
                        localStorage.getItem("personCategories"),
                    );
                    let token = localStorage.getItem("token");

                    if (
                        profileData &&
                        userData &&
                        skipAuth &&
                        token &&
                        personCategories
                    ) {
                        this.$store.commit(
                            "person/setPersonInfo",
                            JSON.parse(profileData),
                        );
                        this.$store.commit(
                            "user/setUserInfo",
                            JSON.parse(userData),
                        );
                        this.$store.commit(
                            "person/setPersonCategories",
                            JSON.parse(personCategories),
                        );
                        this.$store.commit("user/setExternalPresent", true);
                        this.$store.commit("auth/successAuth", token);

                        this.hasRead = true; //turn flag OFF
                    }
                }
            }
        },
    },
};
