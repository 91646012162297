<template lang="pug">
    .ui-input(:class="{error: validator}")
        label._label(v-if="label") {{ validator ? error : label }}
            input(:type="type" :placeholder="placeholder" @input="$emit('update:value', $event.target.value)")._field
</template>

<script>
export default {
    name: "UiInput",
    props: {
        "label": {
            required: false,
            default: "",
            type: String,
        },
        "type": {
            required: false,
            default: "",
            type: String,
        },
        "placeholder": {
            required: false,
            default: "",
            type: String,
        },
        "error": {
            required: false,
            default: "",
            type: String,
        },
        "validator": {
            required: false,
            default: "",
            type: String,
        },
    },
};
</script>

<style lang="scss">
.ui-input {
    $root: &;
    width: 100%;
    min-width: 300px;
    max-width: 300px;

    &.error {
        #{$root} {
            &__label {
                color: #b54848;
            }

            &__field {
                border-color: #b54848;
            }
        }
    }

    &__label {
        display: inline-block;
        width: 100%;
        text-align: left;
        font-size: 12px;
        color: #9a9a9a;
    }

    &__field {
        color: #bcbcbc;
        background: transparent;
        padding: 9px 11px;
        border: 1px solid #434343;
        width: 100%;
        margin-top: 8px;
        transition: 0.4s;

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
            display: none;
            -webkit-appearance: none;
            margin: 0;
        }
    }
}
</style>
