<template lang="pug">
    .subscriptions-period
        .subscriptions-period__list
            button.subscriptions-period__item(
                v-for="option in options"
                :key="option.id"
                :class="{ 'subscriptions-period__item_active': option.id === value }"
                @click="emitChoose(option.id)"
            )
                .subscriptions-period__item-text {{ option.label }}
</template>

<script>
export default {
    name: "SubscriptionsPeriod",
    model: {
        prop: "value",
        event: "choose",
    },
    props: {
        value: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
    },
    methods: {
        emitChoose(id) {
            this.$emit("choose", id);
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-period.scss" />
