<template lang="pug">
    modal(name="AISubscriptions" class="ai-subscriptions-modal-wrap" @before-open="beforeOpen" :adaptive="true" width="100%" height="100%")
        perfect-scrollbar.ai-subscriptions-modal(@click.self="$modal.hide('report')")
            ._wrap
                ._content
                    ._close-btn(@click="$modal.hide('AISubscriptions');")
                        svg-icon(icon-name="cross")._close-icon
                    ._content-inner
                        ._head
                            ._title Тариф «Оператор нейросетей»
                            ui-button(type="bordered" size="small" text="Ввести промокод" color="gray" @click.native="$modal.hide('AISubscriptions'); $modal.show('Promocode')")._promocode-btn
                            //._period-ctrl
                            //    ._period-item(:class="{active: period === 'monthly'}") В месяц
                            //    ._period-item(:class="{active: period === 'annually'}") В год
                        loading._loading(v-if="!isLoaded")
                        ._plans-list(v-else)
                            label._checkbox-inner(for="policy" :class="{error: policyError && !policy}")
                                ui-checkbox(type="checkbox" name="policy" v-bind:model.sync="policy" :checked="policy" label="Нажимая кнопку «Перейти» я соглашаюсь с условиями <a href='/docs/term-of-use' target='_blank'>пользовательского соглашения</a>" for="promocodes")._checkbox
                            ._policy-error(v-if="policyError && !policy") Примите условия пользовательского соглашения
                            VueSlickCarousel(v-bind="carouselSettings")._plans-slider
                                ._plan(v-for="plan in mainPlans" :key="plan.id" :class="plan.level")
                                    ._plan-name {{ plan.level }}
                                        svg-icon(icon-name="dotted-arrow-right")._plan-arrow
                                        ._plan-mobile-name {{ plan.level }}
                                    ._plan-price
                                        span(v-if="plan.price === 0 && plan.level !== 'FREE'") Активный тариф
                                        span(v-else)
                                            span._old-price(v-if="(+plan.id === 27 || +plan.id === 26) && myUser.is_discount_active") ${{ plan.price * 2 }}
                                            span ${{ plan.price }} / месяц
                                    ._plan-count {{ plan.level === "FREE" ? 5 : plan.chatMessagesLimit }} кредитов
                                        span(v-if="plan.level === 'FREE'")  в день
                                        span(v-else)  в месяц
                                    ._plan-params 
                                        template(v-if="plan.level === 'FREE'")
                                            ._plan-param
                                                svg-icon(icon-name="chat-gpt" added-catalog="ai-logos")._plan-icon.-gpt
                                                ._plan-param-text 
                                                    b Chat GPT 4o-mini
                                                    span._plan-param-helper Контекст: 4 000 символов
                                        template(v-else)
                                            ._plan-param(v-if="plan.level === 'GOLD'")
                                                svg-icon(icon-name="chat-gpt" added-catalog="ai-logos")._plan-icon.-gpt
                                                ._plan-param-text
                                                    b Chat GPT 4o
                                                    span._plan-param-helper Контекст: 8 500 символов
                                            ._plan-param(v-if="plan.level === 'PLATINUM'")
                                                svg-icon(icon-name="chat-gpt" added-catalog="ai-logos")._plan-icon.-gpt
                                                ._plan-param-text
                                                    b Chat GPT 4o
                                                    span._plan-param-helper Контекст: 16 000 символов
                                            ._plan-param
                                                svg-icon(icon-name="face-swap" added-catalog="ai-logos")._plan-icon.-face-swap
                                                ._plan-param-text
                                                    b Доступ к Face Swap
                                                    span._plan-param-helper Вставка своего лица на любые фотографии
                                            ._plan-param
                                                svg-icon(icon-name="mj" added-catalog="ai-logos")._plan-icon.-midjourney
                                                ._plan-param-text
                                                    b Midjourney
                                                    span._plan-param-helper Генерация в 2 раза быстрее
                                            ._plan-param(v-if="plan.level === 'GOLD'")
                                                svg-icon(icon-name="runway" added-catalog="ai-logos")._plan-icon.-midjourney
                                                ._plan-param-text
                                                    b Runway
                                                    span._plan-param-helper Доступ к модели Gen-2
                                            ._plan-param(v-if="plan.level === 'PLATINUM'")
                                                svg-icon(icon-name="runway" added-catalog="ai-logos")._plan-icon.-midjourney
                                                ._plan-param-text
                                                    b Runway
                                                    span._plan-param-helper Доступ к модели Gen-3
                                    ._plan-sale(v-if="(+plan.id === 27 || +plan.id === 26) && myUser.is_discount_active && +activeSubscription.id !== +plan.id && timeDifferent > 0")
                                        gift-timer(:time="timeDifferent")
                                    template(v-if="+activeSubscription.id !== +plan.id && plan.level !== 'FREE'")
                                        ui-button(:text="`Перейти на ${plan.level}`" :color="plan.level.toLowerCase()" @click.native="subscribe(plan.id, plan.level)")._plan-pay

                                    ._footer(v-if="+activeSubscription.id === +plan.id")
                                        ._subscribed(v-if="activeSubscription.auto_renew")
                                            ui-button(text="Отписаться" type="bordered" color="gray" @click.native="$modal.show('Unsubscribe', {mySubscriptions: mySubscriptions, planId: +plan.id, price: plan.price})")._unsubscribe-btn
                                            ._next-payment Дата следующего списания: {{ formatDate(activeSubscription.subscribed_due.date, "dd.mm.yyyy") }}
                                        span._success-unsubscribe(v-else-if="+activeSubscription.id !== 31")
                                            svg-icon(icon-name="check")._success-unsubscribe-icon
                                            | Вы успешно отписались

                            ._requests(v-if="requestsPlans.length && +activeSubscription.id !== 31")
                                ._requests-title Дополнительные запросы
                                ._requests-list
                                    ._requests-plan(v-for="(plan, index) in requestsPlans" :key="plan.id")
                                        label(:for="`plan-${plan.chatMessagesLimit}`")._request-label
                                            input(type="radio" v-model="req" :value="plan.id" :checked="index === 0" :id="`plan-${plan.chatMessagesLimit}`" name="req")._request-checkbox
                                            ._request-fake-checkbox
                                            ._request-checkbox-title {{ plan.chatMessagesLimit }} кредитов — ${{ plan.price }}
                                    ui-button(text="Перейти к оплате" @click.native="subscribe(req)")._request-btn
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";
import gql from "graphql-tag";
import subscriptionPlansQuery from "@/graphql/queries/subscriptionPlans.query.graphql";
import getMySubscriptionsQuery from "@/graphql/queries/getMySubscriptions.query.graphql";
import { mutationSubscribeToPlan } from "@/components/subscriptions/graphql/mutations";
import VueSlickCarousel from "vue-slick-carousel";
import setDiscountEndsAtResolverMutation from "@/graphql/mutations/setDiscountEndsAtResolver.mutation.graphql";
import GiftTimer from "../../person/change-photos/gift-timer/gift-timer.vue";
import UiCheckbox from "../../ui/ui-checkbox/ui-checkbox.vue";

export default {
    name: "AISubscriptionsModal",
    components: {
        UiCheckbox,
        GiftTimer,
        UiButton,
        VueSlickCarousel,
    },
    data() {
        return {
            period: "monthly",
            plans: [],
            mySubscriptions: [],
            isUnsubscribe: false,
            isLoaded: false,
            req: 28,
            policy: false,
            policyError: false,
        };
    },
    computed: {
        timeDifferent: function () {
            const now = new Date();
            const endAt = new Date(this.myUser.discount_ends_at);

            return (endAt - now) / (1000 * 60);
        },
        activeSubscription: (state) =>
            state.mySubscriptions.find(
                (plan) =>
                    plan.id === 26 ||
                    plan.id === 27 ||
                    plan.id === 32 ||
                    plan.id === 33,
            ) || { id: 31 },
        carouselSettings: (state) => ({
            dots: false,
            slidesToShow: state.slidesPerRow,
            slidesToScroll: 1,
            centerMode: true,
            centerPadding: "25px",
            arrows: false,
            autoplay: false,
            initialSlide: 0,
            
        }),
        slidesPerRow: function () {
            if (this.pageWidth >= 992) return 3;
            return 1;
        },
        mainPlans: function () {
            const plans = [];
            if (!this.plans.length) return plans;

            this.plans[0].plans.forEach((plan) => {
                if (
                    plan.level === "PLATINUM" ||
                    plan.level === "GOLD" ||
                    plan.level === "FREE"
                ) {
                    plans.push(plan);
                }
            });

            return plans.sort((a, b) => a.chatMessagesLimit - b.chatMessagesLimit);
        },
        requestsPlans: function () {
            const plans = [];
            if (!this.plans.length) return plans;

            this.plans[0].plans.forEach((plan) => {
                if (plan.level === "REQ") {
                    plans.push(plan);
                }
            });

            return plans.sort((a, b) => a.id - b.id);
        },
    },
    methods: {
        async beforeOpen() {
            if (
                !this.myUser.is_discount_active &&
                this.myUser.discount_ends_at === null
            ) {
                await this.$apollo
                    .mutate({
                        mutation: gql(setDiscountEndsAtResolverMutation),
                    })
                    .then(() => {
                        this.$store.dispatch("user/setDiscountStatus");
                    });
            }
            setTimeout(() => {
                this.getPlans();
                this.getMySubscriptions();
            }, 2000);
        },
        subscribe(planId, planName = "") {
            if(!this.policy) {
                this.policyError = true;
                return;
            }
            this.$apollo
                .mutate({
                    mutation: mutationSubscribeToPlan,
                    variables: {
                        plan: Number(planId),
                    },
                })
                .then((r) => {
                    if(planName) {
                        this.VKGoal(`ai_${planName.toLowerCase()}`);
                    }
                    window.location.href = r.data.subscribeToPlan;
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1002: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Выбранный план не найден",
                                );
                                break;
                            }
                            case 1005: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Не выбран ни один план",
                                );
                                break;
                            }
                            default: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Неизвестная ошибка",
                                );
                            }
                        }
                    }
                })
                .finally(() => {
                    this.$emit("close");
                });
        },
        async getPlans() {
            await this.$apollo
                .query({
                    query: gql(subscriptionPlansQuery),
                    variables: {
                        periodicity: "MONTHLY",
                        onlyChat: true,
                    },
                    fetchPolicy: "no-cache"
                })
                .then((r) => {
                    console.log("R", r);
                    this.plans = r.data.subscriptionPlans;
                    this.isLoaded = true;
                });
        },
        async getMySubscriptions() {
            await this.$apollo
                .query({
                    query: gql(getMySubscriptionsQuery),
                })
                .then((r) => {
                    this.mySubscriptions = r.data.getMySubscriptions;
                });
        },
    },
};
</script>

<style lang="scss" src="./ai-subscriptions-modal.scss"></style>
