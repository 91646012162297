<template lang="pug">
    .subscriptions-widget(v-click-outside="hideWidget", data-tip="subscriptions")
        transition(name="fadeInTop")
            template
                ._wrap(@mouseover="isHovered = false", @mouseleave="isHovered = false")
                    ._categories
                        ui-button(type="bordered" size="small" @click.native="$modal.show('subscription')")._btn
                            template(v-slot:content)
                                svg-icon(icon-name="merlin-head-subscription")._btn-icon
                                | Купить подписку


                    //subscriptions-widget-categories(
                    //	:categories="categories",
                    //	:active-category-id="null",
                    //	@itemHovered="hoverHandler"
                    //)
</template>

<script>
import gql from "graphql-tag";
import SubscriptionsWidgetCategories from "@/components/subscriptions/components/subscriptions-widget/components/subscriptions-widget-categories/subscriptions-widget-categories";
import { mapGetters } from "vuex";
import { subscriptionsData } from "@/components/subscriptions/settings/subscriptions-data";
import { subscriptionsPlans } from "@/components/subscriptions/settings/subscriptions-plans";
import subscriptionPlansQuery from "@/graphql/queries/subscriptionPlans.query.graphql";
import SubscriptionsWidgetContent from "@/components/subscriptions/components/subscriptions-widget/components/subscriptions-widget-content/subscriptions-widget-content";
import getMySubscriptionsQuery from "@/graphql/queries/getMySubscriptions.query.graphql";
import SubscriptionsWidgetFullInfo from "../../subscriptions-widget-full-info/subscriptions-widget-full-info.vue";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand.vue";
import UiButton from "../../../ui/ui-button/ui-button.vue";

export default {
    name: "SubscriptionsWidget",
    components: {
        UiButton,
        SubscriptionsWidgetContent,
        SubscriptionsWidgetCategories,
        SubscriptionsWidgetFullInfo,
        TransitionExpand,
    },
    data() {
        return {
            isWidgetOpen: false,
            subscriptionPlans: {
                MONTHLY: [],
                ANNUALLY: [],
            },
            isHovered: false,
            // prop values for subscriptions-widget-full-ino
            subscriptionsData: null,
            categoryData: null,
            //data emited from the subscriptions-widget-categories
            title: null,
            icon: null,
            id: null,
            // activeCategoryId: null
        };
    },
    computed: {
        ...mapGetters("user", ["subscriptionCategories"]),
        plans: function () {
            let plans = [];
            Object.values(this.subscriptionPlans).map((period) => {
                period.forEach((item) => plans.push(item));
            });
            return plans;
        },
        categories() {
            return this.subscriptionCategories.map((category) => {
                const categoryFetchedData = this.plans?.find(
                    (item) => item.category.id === category.id,
                );
                const categoryData = subscriptionsData.find(
                    (item) => item.id === category.id,
                );
                const planData = subscriptionsPlans[category.level];
                return {
                    ...category,
                    name: categoryFetchedData?.category?.name || "1",
                    icon: categoryData.icon,
                    accent: planData.accent,
                    accentBg: planData.accentBg,
                };
            });
        },
        // activeCategory() {
        //   return this.categories.find(category => category.id === this.activeCategoryId)
        // }
    },
    watch: {
        "$route.name"() {
            this.hideWidget();
        },
    },
    async created() {
        await this.getSubscriptionPlans("MONTHLY");
        await this.getSubscriptionPlans("ANNUALLY");
        await this.getActiveSubscriptions();
        this.$bus.$on("refreshActiveSubscriptions", async () => {
            await this.getSubscriptionPlans("MONTHLY");
            await this.getSubscriptionPlans("ANNUALLY");
            await this.getActiveSubscriptions();
        });
    },
    beforeDestroy() {
        this.$bus.$off("refreshActiveSubscriptions");
    },
    methods: {
        hoverHandler(icon, title, id) {
            this.title = title;
            this.icon = icon;
            this.id = id;
            this.categoryData = this.subscriptionsData.find((el) =>
                el.name.includes(title.slice(0, 5)),
            );
        },
        async getSubscriptionPlans(periodicity) {
            await this.$apollo
                .query({
                    query: gql`
                        ${subscriptionPlansQuery}
                    `,
                    variables: {
                        periodicity: periodicity,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.subscriptionPlans[periodicity] =
                        r.data.subscriptionPlans;
                    r.data.subscriptionPlans.forEach((category) => {
                        const junior = category.plans.find(
                            (plan) => plan.id === "21",
                        );
                        if (
                            junior &&
                            junior.price &&
                            periodicity === "MONTHLY"
                        ) {
                            this.$store.dispatch("set", {
                                name: "isJunior",
                                value: true,
                            });
                            this.$store.dispatch("set", {
                                name: "tariffJunior",
                                value: junior,
                            });
                        }
                    });
                });
        },
        async getActiveSubscriptions() {
            let userSubscriptions = [],
                savedSubscriptions = [];
            await this.$apollo
                .query({
                    query: gql`
                        ${getMySubscriptionsQuery}
                    `,
                })
                .then((r) => {
                    r.data.getMySubscriptions.forEach((plan) => {
                        userSubscriptions.push({
                            categoryId: 0,
                            planId: plan.id,
                            planLevel: plan.level,
                            period: "",
                        });
                    });
                    this.subscriptionsData = r.data.getMySubscriptions;
                });
            userSubscriptions.forEach((activePlan) => {
                this.subscriptionPlans["MONTHLY"].forEach((plan) => {
                    let planId = activePlan.planId;
                    if (planId === 22) {
                        planId = 1;
                    }
                    if (planId === 23) {
                        planId = 3;
                    }
                    if (planId === 24) {
                        planId = 5;
                    }
                    if (planId === 25) {
                        planId = 7;
                    }
                    const plansFound = plan.plans.find(
                        (p) => Number(p.id) === planId,
                    );
                    if (plansFound) {
                        savedSubscriptions.push({
                            categoryId: plan.category.id,
                            planId: activePlan.planId,
                            planLevel:
                                activePlan.planId > 21
                                    ? "JUNIOR"
                                    : activePlan.planLevel,
                            period: "MONTHLY",
                        });
                    }
                });
            });
            userSubscriptions.forEach((activePlan) => {
                this.subscriptionPlans["ANNUALLY"].forEach((plan) => {
                    const plansFound = plan.plans.find(
                        (p) => Number(p.id) === activePlan.planId,
                    );
                    if (plansFound) {
                        savedSubscriptions.push({
                            categoryId: plan.category.id,
                            planId: activePlan.planId,
                            planLevel: activePlan.planLevel,
                            period: "ANNUALLY",
                        });
                    }
                });
            });
            await this.$store.dispatch(
                "user/setSubscriptionsCategory",
                savedSubscriptions,
            );
        },
        openModal() {
            this.$modal.show("subscription");
        },
        showWidget() {
            this.isWidgetOpen = true;
        },
        hideWidget() {
            this.isWidgetOpen = false;
        },
        toggleWidget() {
            this.isWidgetOpen = !this.isWidgetOpen;
        },
        // setActiveCategoryId(categoryId) {
        //   this.activeCategoryId = categoryId
        // },
        // closeContent() {
        //   if (this.activeCategoryId !== null) {
        //     this.setActiveCategoryId(null)
        //   }
        // }
    },
    // created() {
    //   this.setupActiveCategory()
    // },
};
</script>
<style lang="scss"></style>
<style lang="scss" src="./subscriptions-widget.scss" />
