import gql from "graphql-tag";
import compareWithPersonGetQuery from "@/graphql/queries/compareWithPersonGet.query.graphql";
import compareWithPersonCreateMutation from "@/graphql/mutations/compareWithPersonCreate.mutation.graphql";
import compareWithPhotosCreateMutation from "@/graphql/mutations/compareWithPhotosCreate.mutation.graphql";
import comparePriceQuery from "@/graphql/queries/comparePrice.query.graphql";
import personIndicatorGetQuery from "@/graphql/queries/personIndicatorGet.query.graphql";
import personIndicatorPriceQuery from "@/graphql/queries/personIndicatorPrice.query.graphql";
import personIndicatorCreateMutation from "@/graphql/mutations/personIndicatorCreate.mutation.graphql";
import compareWithPhotosGetQuery from "@/graphql/queries/compareWithPhotosGet.query.graphql";

export const compareMixins = {
    data() {
        return {
            taskInterval: null,
            confirmDialogLocal: {
                show: false,
                loading: false,
                title: "",
                text: "",
                price: 0,
                comparisonCount: "",
                buttons: false,
                confirmText: "Подтвердить",
                type: "",
            },
            customCompare: false,
        };
    },
    computed: {
        userBalance: (state) => state.$store.getters["user/balance"],
        myPhotos: (state) => state.$store.getters["user/photos"],
        myPlans: (state) => state.$store.getters["user/subscriptionCategories"],
        plans: (state) => state.$store.getters["planIds"],
    },
    methods: {
        subscribeToActions() {
            const $this = this;
            this.$bus.$on("confirmDialogAction", function (type) {
                console.log("this bus action");
                $this.confirmDialogAction(type);
            });
        },
        unSubscribeToActions() {
            this.$bus.$off("confirmDialogAction");
        },
        // Получаем результат сравнения
        async getCompareResult(uuid, type) {
            return await this.$apollo.query({
                query: gql`
                    ${compareWithPersonGetQuery}
                `,
                variables: {
                    comparedPersonId: uuid,
                    compareType: type,
                },
                fetchPolicy: "no-cache",
            });
        },
        // Получаем результат сравнения по фото
        async getCompareWithPhotosResult(
            anfas,
            anfasMd5,
            profile,
            profileMd5,
            compareType,
        ) {
            return await this.$apollo.query({
                query: gql`
                    ${compareWithPhotosGetQuery}
                `,
                variables: {
                    anfas,
                    anfasMd5,
                    profile,
                    profileMd5,
                    compareType,
                },
                fetchPolicy: "no-cache",
            });
        },
        // Получаем результат индикатор
        async getIndicatorResult(uuid, type) {
            return await this.$apollo.query({
                query: gql`
                    ${personIndicatorGetQuery}
                `,
                variables: {
                    personId: uuid,
                    indicatorType: type,
                },
                fetchPolicy: "no-cache",
            });
        },
        // Предлагаем покупку сравнения или подписку, если нет попыток/денег
        async offerToBuyCompare(type, custom = false) {
            let isCelebrity = this.personType === "celebrity";
            this.subscribeToActions();

            this.customCompare = custom;
            await this.$apollo
                .query({
                    query: gql`
                        ${comparePriceQuery}
                    `,
                    variables: {
                        compareType: type,
                        custom,
                        isCelebrity,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    const compare = r.data.comparePrice;

                    if (
                        compare.subscriptionChecks > 0 ||
                        compare.subscriptionChecks === -1
                    ) {
                        let comparisonCount =
                            compare.subscriptionChecks === -1
                                ? this.$t("buyActions.compareUnlimited")
                                : this.$t("buyActions.compareLimit", {limit: compare.subscriptionChecks});

                        this.showConfirmBuyCompare({
                            price: compare.price,
                            comparisonCount: comparisonCount,
                        });
                        return;
                    }

                    if (
                        typeof compare.price === "number" &&
                        compare.price > 0
                    ) {
                        if (this.userBalance >= compare.price) {
                            this.showConfirmBuyCompare({
                                price: compare.price,
                                renewalDate:
                                    compare.subscriptionChecksRenewalDate,
                            });
                            return;
                        }
                    }

                    this.offerToUpgradePlan(
                        compare.subscriptionChecksRenewalDate,
                    );
                });
        },
        // Предлагаем покупку индикатора или подписку, если нет попыток/денег
        async offerToBuyIndicator(type, custom = false) {
            let isCelebrity = this.personType === "celebrity";
            this.subscribeToActions();

            await this.$apollo
                .query({
                    query: gql`
                        ${personIndicatorPriceQuery}
                    `,
                    variables: {
                        indicatorType: type,
                        custom,
                        isCelebrity,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    const indicator = r.data.personIndicatorPrice;

                    if (
                        indicator.subscriptionChecks > 0 ||
                        indicator.subscriptionChecks === -1
                    ) {
                        let comparisonCount =
                            indicator.subscriptionChecks === -1
                                ? this.$t("buyActions.indicatorUnlimited")
                                : this.$t("buyActions.indicatorLimit", {limit: indicator.subscriptionChecks});

                        this.showConfirmBuyIndicator({
                            comparisonCount: comparisonCount,
                        });
                        return;
                    }

                    if (
                        typeof indicator.price === "number" &&
                        indicator.price > 0
                    ) {
                        if (this.userBalance >= indicator.price) {
                            this.showConfirmBuyIndicator({
                                price: indicator.price,
                                renewalDate:
                                    indicator.subscriptionChecksRenewalDate,
                            });
                            return;
                        }
                    }

                    this.offerToUpgradePlan(
                        indicator.subscriptionChecksRenewalDate,
                    );
                });
        },

        // Запрос на покупку сравнения
        async buyCompare() {
            let compareCreateQuery = {
                mutation: gql`
                    ${compareWithPersonCreateMutation}
                `,
                variables: {
                    comparedPersonId: this.personUuid,
                    compareType: this.activeType.id,
                },
            };

            this.confirmDialogLocal.loading = true;

            if (this.customCompare) {
                compareCreateQuery = {
                    mutation: gql`
                        ${compareWithPhotosCreateMutation}
                    `,
                    variables: {
                        anfas: this.friendAnfas.photo,
                        anfasMd5: this.friendAnfas.md5,
                        profile: this.friendProfile.photo,
                        profileMd5: this.friendProfile.md5,
                        compareType: this.activeType.id,
                    },
                };
            }

            await this.$apollo
                .mutate(compareCreateQuery)
                .then((response) => {
                    let taskId = this.customCompare
                        ? "compareWithPhotosCreate"
                        : "compareWithPersonCreate";
                    let i = 0;
                    this.taskInterval = setInterval(() => {
                        this.checkTask(response.data[taskId]).then(
                            async (r) => {
                                const task = r;
                                i++;
                                if (i > 120) {
                                    this.disableCheckTask();
                                    this.$msgBox(
                                        this.localeError(
                                            "unknownError",
                                        ),
                                        this.localeError(
                                            "retry",
                                        ),
                                    );
                                    this.confirmDialogLocal.show = false;
                                    return;
                                }

                                if (task.status === "PENDING") {
                                    return;
                                }

                                if (task.status === "ERROR") {
                                    this.confirmDialogLocal.show = false;
                                    if (task.error === 1008) {
                                        this.$msgBox(
                                            this.localeError(
                                                "notEnoughMoneyTitle",
                                            ),
                                            this.localeError(
                                                "notEnoughMoney",
                                            ),
                                        );
                                    } else if (
                                        task.error === 1200 ||
                                        task.error === 1201
                                    ) {
                                        this.$msgBox(
                                            this.localeError(
                                                "wrongPhotoTitle",
                                            ),
                                            this.localeError(
                                                "wrongCelebrityPhoto",
                                            ),
                                        );
                                    }
                                }

                                if (task.status === "COMPLETED") {
                                    this.getCompare(this.activeType);
                                    this.confirmDialogLocal.show = false;
                                    await this.$store.dispatch(
                                        "user/getMyBalance",
                                        this.$apollo,
                                    );
                                }

                                this.disableCheckTask();
                            },
                        );
                    }, 2000);
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("retry"),
                    );
                    this.confirmDialogLocal.show = false;
                });
        },

        // Запрос на покупку индикатора
        async buyIndicator() {
            this.confirmDialogLocal.loading = true;
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${personIndicatorCreateMutation}
                    `,
                    variables: {
                        personId: this.personUuid,
                        analyse: this.activeType.id,
                    },
                })
                .then((response) => {
                    //let i = 0;
                    this.taskInterval = setInterval(() => {
                        this.checkTask(
                            response.data.personIndicatorCreate,
                        ).then(async (r) => {
                            const task = r;
                            console.log("interval", task);
                            /*i++;
                        if (i > 7) {
                            this.disableCheckTask();
                            this.$msgBox(
                                this.localeError("unknownError"),
                                this.localeError("retry")
                            );
                            this.confirmDialogLocal.show = false;
                            return;
                        }*/

                            if (task.status === "PENDING") {
                                return;
                            }

                            if (task.status === "ERROR") {
                                this.confirmDialogLocal.show = false;
                                if (task.error === 1008) {
                                    this.$msgBox(
                                        this.localeError(
                                            "notEnoughMoneyTitle",
                                        ),
                                        this.localeError(
                                            "notEnoughMoney",
                                        ),
                                    );
                                } else if (
                                    task.error === 1200 ||
                                    task.error === 1201
                                ) {
                                    this.$msgBox(
                                        this.localeError(
                                            "wrongPhotoTitle",
                                        ),
                                        this.localeError(
                                            "wrongCelebrityPhoto",
                                        ),
                                    );
                                }
                            }

                            if (task.status === "COMPLETED") {
                                this.getIndicator(this.activeType);
                                this.confirmDialogLocal.show = false;
                                await this.$store.dispatch(
                                    "user/getMyBalance",
                                    this.$apollo,
                                );
                            }

                            this.disableCheckTask();
                        });
                    }, 2000);
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError("retry"),
                    );
                    this.confirmDialogLocal.show = false;
                });
        },

        disableCheckTask() {
            clearInterval(this.taskInterval);
            this.taskInterval = null;
        },

        confirmDialogAction(type) {
            if (type === "uploadPhotoAttempt") {
                this.$router.push({ name: "change-photo" });
            }
            if (
                type === "buyCompareWithBalance" ||
                type === "buyCompareWithSubscription"
            ) {
                this.buyCompare();
            }
            if (
                type === "buyIndicatorWithBalance" ||
                type === "buyIndicatorWithSubscription"
            ) {
                this.buyIndicator();
            }

            if (type === "needSubscriptions") {
                this.confirmDialogLocal.show = false;
                this.$modal.show("subscription", {
                    categoryId: this.activeType.planId,
                });
            }

            if (type === "offerToUpgradePlan") {
                this.confirmDialogLocal.show = false;
                this.$modal.show("subscription", {
                    categoryId: this.activeType.planId,
                });
            }
        },
        showUploadPhotosAttempt() {
            this.confirmDialogLocal.show = true;
            this.confirmDialogLocal.type = "uploadPhotoAttempt";
            this.confirmDialogLocal.title = "Действие невозможно";
            this.confirmDialogLocal.text =
                "Анализ не может быть совершен, пока Вы не загрузите свои фотографии в анфас/профиль";
            this.confirmDialogLocal.confirmText = "Загрузить фотографии";
            this.confirmDialogLocal.buttons = true;
        },
        showConfirmBuyCompare({ price, renewalDate, comparisonCount }) {
            let name =
                this.personName !== "Newbie" ? this.personName : this.locale("newbie", {}, true);
            let type =
                this.personType === "celebrity"
                    ? this.$t("buyActions.compareCelebrity")
                    : this.$t("buyActions.compareUser");

            if (renewalDate) {
                this.confirmDialogLocal.comparisonTimer = renewalDate;
            }
            if (price) {
                this.confirmDialogLocal.price = price;
            }
            if (comparisonCount) {
                this.confirmDialogLocal.comparisonCount = comparisonCount;
            }

            this.confirmDialogLocal.show = true;
            this.confirmDialogLocal.type = "buyCompareWithBalance";
            this.confirmDialogLocal.title = this.$t("buyActions.compareConfirmTitle");
            this.confirmDialogLocal.text = this.$t("buyActions.compareConfirmText", {userType: type, name, activeType: this.$t(`serviceTypes.${this.activeType.id}`)});
            this.confirmDialogLocal.confirmText = this.$t("buyActions.compareConfirmBtn");
            this.confirmDialogLocal.buttons = true;
        },
        showConfirmBuyIndicator({ price, renewalDate, comparisonCount }) {
            let name =
                this.personName !== "Newbie" ? this.personName : "Без имени";
            let type =
                this.personType === "celebrity"
                    ? this.$t("buyActions.indicatorCelebrity")
                    : this.$t("buyActions.indicatorUser");

            if (renewalDate) {
                this.confirmDialogLocal.comparisonTimer = renewalDate;
            }
            if (price) {
                this.confirmDialogLocal.price = price;
            }
            if (comparisonCount) {
                this.confirmDialogLocal.comparisonCount = comparisonCount;
            }

            this.confirmDialogLocal.show = true;
            this.confirmDialogLocal.type = "buyIndicatorWithBalance";
            this.confirmDialogLocal.title = this.$t("buyActions.indicatorConfirmTitle");
            this.confirmDialogLocal.text = this.$t("buyActions.indicatorConfirmText", {userType: type, name, activeType: this.$t(`serviceTypes.${this.activeType.id}`)});
            this.confirmDialogLocal.confirmText = this.$t("buyActions.indicatorConfirmBtn");
            this.confirmDialogLocal.buttons = true;
        },
        showSubscriptionsAttempt() {
            this.confirmDialogLocal.show = true;
            this.confirmDialogLocal.comparisonTimer = null;
            this.confirmDialogLocal.type = "needSubscriptions";
            this.confirmDialogLocal.title = "Оформите подписку";
            this.confirmDialogLocal.text = `Для того чтобы совершить это действие, необходимо оформить подписку на категорию "${this.activeType.planName}"`;
            this.confirmDialogLocal.confirmText = "Оформить подписку";
            this.confirmDialogLocal.buttons = true;
        },
        offerToUpgradePlan(subscriptionRenewalDate) {
            const plan = this.myPlans.find(
                (plan) => plan.id == this.activeType.planId,
            ).level;
            let nextLevelPlan = plan === "FREE" ? "GOLD" : "PLATINUM",
                text = "";

            if (nextLevelPlan === "GOLD") {
                nextLevelPlan = "PLATINUM";
            }

            if (nextLevelPlan === "GOLD") {
                text = `${nextLevelPlan} и получать попытки чаще.`;
            }
            if (nextLevelPlan === "PLATINUM") {
                text = `${nextLevelPlan} и получить неограниченное число попыток.`;
            }

            if (subscriptionRenewalDate) {
                this.confirmDialogLocal.comparisonTimer =
                    subscriptionRenewalDate;
            }

            this.confirmDialogLocal.show = true;
            this.confirmDialogLocal.type = "offerToUpgradePlan";
            this.confirmDialogLocal.title = "У вас не осталось попыток…";
            this.confirmDialogLocal.buttons = true;

            if (plan === nextLevelPlan) {
                this.confirmDialogLocal.text = `В рамках тарифа ${plan} у вас закончились сравнения.`;
                this.confirmDialogLocal.confirmText = "";
            } else {
                this.confirmDialogLocal.text = `В рамках тарифа ${plan} у вас закончились сравнения. Вы можете перейти на ${text}`;
                this.confirmDialogLocal.confirmText = "Улучшить подписку";
            }
        },
    },
    watch: {
        confirmDialogLocal: {
            deep: true,
            handler() {
                this.$store.dispatch(
                    "changeConfirmDialog",
                    this.confirmDialogLocal,
                );
            },
        },
        "confirmDialogLocal.show"(status) {
            if (!status) {
                this.unSubscribeToActions();
                this.confirmDialogLocal.show = false;
                this.confirmDialogLocal.loading = false;
                this.confirmDialogLocal.title = "";
                this.confirmDialogLocal.text = "";
                this.confirmDialogLocal.price = 0;
                this.confirmDialogLocal.comparisonCount = "";
                this.confirmDialogLocal.comparisonTimer = null;
                this.confirmDialogLocal.buttons = false;
                this.confirmDialogLocal.confirmText = "Подтвердить";
                this.confirmDialogLocal.type = "";
            }
        },
    },
};
