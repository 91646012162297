<template lang="pug">
    .subscriptions
        ._wrap
            tariff-junior(v-if="isJunior")
            ._header
                ._title
                    h1._title-text {{ locale("title") }}
                ._filter
                    subscriptions-period(
                        :options="getPeriodOptions"
                        v-model="period"
                        v-if="activeCategoryId"
                    )
            loading(v-if="$apollo.queries.subscriptionPlans.loading")
            template(v-else)
                ._success(v-if="showUnsubscribe")
                    ._success-group
                        ._success-text {{ locale("canceled") }}
                    ui-button(type="bordered" size="small" text="Закрыть окно" @click.native="$modal.hide('subscription')")._success-btn

                ._success(v-if="showSuccess")
                    ._success-group
                        svg-icon(icon-name="check")._success-icon
                        ._success-text {{ locale("succeeded") }}
                    ui-button(type="bordered" size="small" text="Закрыть окно" @click.native="$modal.hide('subscription')")._success-btn

                template(v-if="!showSuccess && !showUnsubscribe")
                    transition(name="fade")
                        ._categories
                            subscriptions-categories(
                                :categories="getCategories"
                                :active-categories="getActiveCategories"
                                :current-category-id="activeCategoryId"
                                @select-category="setActiveCategoryId"
                            )
                    ._content(v-if="activeCategoryId")
                        ._content-title {{ locale("choosePlan") }}
                        subscriptions-content(
                            :category-id="activeCategoryId"
                            :period="period"
                        )

</template>

<script>
import { mapGetters } from "vuex";
import { querySubscriptionPlansCategoriesOnly } from "@/components/subscriptions/graphql/queries";
import { subscriptionsPlans } from "@/components/subscriptions/settings/subscriptions-plans";
import { getMergedCategories } from "@/components/subscriptions/settings/subscriptions-data";
import {
    getSubscriptionPeriodOptions,
    subscriptionsPeriods,
} from "@/components/subscriptions/settings/subscriptions-periods";

import SubscriptionsTable from "@/components/subscriptions/components/subscriptions-table/subscriptions-table";
import SubscriptionsCategories from "@/components/subscriptions/components/subscriptions-categories/subscriptions-categories";
import SubscriptionsPeriod from "@/components/subscriptions/components/subscriptions-period/subscriptions-period";
import UiButton from "@/components/ui/ui-button/ui-button";
import SubscriptionsContent from "@/components/subscriptions/components/subscriptions-content/subscriptions-content";
import TariffJunior from "./tariff-junior/tariff-junior";

export default {
    name: "Subscriptions",
    components: {
        TariffJunior,
        SubscriptionsContent,
        UiButton,
        SubscriptionsPeriod,
        SubscriptionsCategories,
        SubscriptionsTable,
    },
    props: {
        initialCategoryId: {
            type: Number,
            required: false,
            default: 0,
        },
    },
    data() {
        return {
            activeCategoryId: null,
            period: subscriptionsPeriods.ANNUAL,
            showSuccess: false,
            showUnsubscribe: false,
        };
    },
    apollo: {
        subscriptionPlans: {
            query: querySubscriptionPlansCategoriesOnly,
            variables() {
                return {
                    periodicity: this.period,
                };
            },
        },
    },
    computed: {
        ...mapGetters("user", ["subscriptionCategories"]),
        isJunior: (state) => state.myUser.isUsePromo,
        getPeriodOptions() {
            return getSubscriptionPeriodOptions();
        },
        getCategories() {
            return getMergedCategories(this.subscriptionPlans);
        },
        getActiveCategories() {
            return this.subscriptionCategories
                .filter(
                    (category) => category.level !== subscriptionsPlans.FREE.id,
                )
                .filter((category) => category.period === this.period)
                .map((category) => ({
                    id: category.id,
                    level: category.level,
                }));
        },
        allSubscriptions() {
            return this.subscriptionCategories.filter(
                (category) => category.level !== null,
            );
        },
    },
    watch: {
        allSubscriptions: {
            handler() {
                this.setDefaultPeriod();
            },
            deep: true,
            immediate: true,
        },
    },
    created() {
        this.setDefaultCategory();
        this.$bus.$on("refreshSubscriptionsPlans", () => {
            this.$apollo.queries.subscriptionPlans.refetch({
                periodicity: this.period,
            });
        });
        this.$bus.$on("showSuccessSubscription", () => {
            this.showSuccess = true;
        });
        this.$bus.$on("showSuccessUnsubscribe", () => {
            this.showUnsubscribe = true;
        });
    },
    beforeDestroy() {
        this.$bus.$off("refreshSubscriptionsPlans");
        this.$bus.$off("showSuccessSubscription");
    },
    methods: {
        setDefaultCategory() {
            if (this.initialCategoryId) {
                this.activeCategoryId = this.initialCategoryId;
            }
        },
        setDefaultPeriod() {
            const hasAnyMonth = this.allSubscriptions.some(
                (subscription) =>
                    subscription.period === subscriptionsPeriods.MONTH,
            );
            const hasAnyAnnual = this.allSubscriptions.some(
                (subscription) =>
                    subscription.period === subscriptionsPeriods.ANNUAL,
            );

            if (!hasAnyMonth && hasAnyAnnual) {
                this.period = subscriptionsPeriods.ANNUAL;
            }
        },
        setActiveCategoryId(categoryId) {
            this.activeCategoryId = categoryId;
        },
    },
};
</script>

<style lang="scss" src="./subscriptions.scss" />
