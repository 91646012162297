<template lang="pug">
.referral-user    
    ._date
        ._date-line {{ localizedDate(referral.createdAt) }}
        ._date-line {{ timestampYear(referral.createdAt) }}
    router-link(:to="{name: 'person', params: {uuid: referral[userType].person.uuid}}")._link
        ._avatar
            user-photo(:photo="referral[userType].person.photos.avatar")
        ._name {{ formatUserName(referral[userType].display_name) }}
    ._item-coins +{{ referral.reward }} Руб.
</template>

<script>
import UserPhoto from "../../../ui/user-photo/user-photo";
export default {
    name: "ReferralUser",
    components: { UserPhoto },
    props: {
        referral: {
            type: Object,
            default: () => ({}),
        },
        userType: {
            type: String,
            default: "partnerUser",
        },
    },
};
</script>

<style lang="scss">
@import "referral-user";
</style>
