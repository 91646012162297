<template lang="pug">
    .subscriptions-content
        template(v-if="$apollo.queries.subscriptionPlans.loading")
            loading
        template(v-else)
            transition(name="fade" appear)
                ._wrap
                    ._table
                        subscriptions-table(
                            :options="getActiveCategoryOptions"
                            :plans="getActiveCategoryPlans"
                            :period-type="getPeriod"
                            :active-plan-id="getActivePlanId"
                            :active-plan-level="getActivePlanLevel"
                            :active-plan-period="getActivePlanPeriod"
                        )
                    ._footer(v-if="showUnsubscribeBtn")
                        ui-button(
                            v-if="planAutoRenew || myPlanFreeData"
                            :text="locale('cancelBtn')"
                            color="gray"
                            type="bordered"
                            size="small"
                            @click.native="unsubscribe"
                        )
                        ._end-period(v-else) {{ locale("active", {date: formatDate(myPlanData.subscribed_due.date, "dd.mm.yyyy")}) }}


</template>

<script>
import gql from "graphql-tag";
import UiButton from "@/components/ui/ui-button/ui-button";
import SubscriptionsTable from "@/components/subscriptions/components/subscriptions-table/subscriptions-table";
import { getMergedCategories } from "@/components/subscriptions/settings/subscriptions-data";
import { querySubscriptionPlans } from "@/components/subscriptions/graphql/queries";
import unsubscribeFromPlanMutation from "@/graphql/mutations/unsubscribeFromPlan.mutation.graphql";
import { mapGetters } from "vuex";
import getMySubscriptionsQuery from "@/graphql/queries/getMySubscriptions.query.graphql";

export default {
    name: "SubscriptionsContent",
    components: {
        UiButton,
        SubscriptionsTable,
    },
    props: {
        categoryId: {
            type: Number,
            required: true,
        },
        period: {
            type: String,
            required: true,
        },
    },
    apollo: {
        subscriptionPlans: {
            query: querySubscriptionPlans,
            variables() {
                return {
                    periodicity: this.getPeriod,
                };
            },
        },
    },
    data() {
        return {
            myPlanData: {
                auto_renew: false,
                subscribed_due: false,
            },
            myPlanFreeData: {
                invoice_will_be_generated: false,
            },
        };
    },
    computed: {
        ...mapGetters("user", ["subscriptionCategories"]),
        getPeriod() {
            return this.period;
        },
        getCategories() {
            return getMergedCategories(this.subscriptionPlans, true);
        },
        getActiveCategory() {
            return this.getCategories.find(
                (category) => category.id === this.categoryId,
            );
        },
        getActiveCategoryOptions() {
            return this.getActiveCategory?.items || [];
        },
        getActiveCategoryPlans() {
            return this.getActiveCategory?.plans || [];
        },
        getActivePlan() {
            return this.subscriptionCategories.find(
                (category) => category.id === this.categoryId,
            );
        },
        getActivePlanId() {
            return this.getActivePlan?.planId || null;
        },
        getActivePlanLevel() {
            return this.getActivePlan?.level || null;
        },
        getActivePlanPeriod() {
            return this.getActivePlan?.period || null;
        },
        planAutoRenew: (state) => state.myPlanData?.auto_renew,
        showUnsubscribeBtn() {
            return (
                (this.getActivePlan.planId &&
                    this.getActivePlan.period === this.period) ||
                this.myPlanFreeData
            );
        },
        planIds: (state) => state.$store.getters["planIds"],
    },
    watch: {
        getActiveCategory: {
            deep: true,
            handler() {
                this.getPlanInfo();
            },
        },
    },
    created() {
        this.$bus.$on("confirmDialogAction", (type) => {
            if (type === "cancelSubscription") {
                this.$apollo
                    .mutate({
                        mutation: gql`
                            ${unsubscribeFromPlanMutation}
                        `,
                        variables: {
                            planId: Number(
                                this.getActivePlan.planId ||
                                    this.myPlanFreeData.id,
                            ),
                        },
                    })
                    .then(() => {
                        this.$bus.$emit("showSuccessUnsubscribe");
                        this.$store.dispatch("changeConfirmDialog", {
                            show: false,
                        });
                    });
            }
        });
    },
    mounted() {
        this.getPlanInfo();
    },
    methods: {
        async getPlanInfo() {
            await this.$apollo
                .query({
                    query: gql`
                        ${getMySubscriptionsQuery}
                    `,
                    variables: {
                        plan: Number(this.getActivePlan.planId),
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    console.log("content", r.data.getMySubscriptions);

                    let categoryName = this.planIds[this.getActiveCategory.id];

                    if (categoryName === "Профессия и предназначение") {
                        categoryName = this.locale("category_1");
                    }

                    if (categoryName === "Самооценка и уверенность") {
                        categoryName = this.locale("category_2");
                    }

                    if (categoryName === "Мотивация и эффективность") {
                        categoryName = this.locale("category_3");
                    }

                    if (categoryName === "Отношения и семья") {
                        categoryName = this.locale("category_4");
                    }

                    this.myPlanFreeData = r.data.getMySubscriptions.find(
                        (plan) =>
                            plan.invoice_will_be_generated &&
                            plan.name === categoryName,
                    );
                    this.myPlanData = r.data.getMySubscriptions.find(
                        (plan) => plan.id === Number(this.getActivePlan.planId),
                    );
                });
        },
        unsubscribe() {
            const category = this.planIds[this.getActivePlan.id];
            const period =
                this.period === "MONTHLY" ? this.locale("mounthly") : this.locale("annually");
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: this.locale("cancelTitle"),
                text: this.locale("cancelText", {period, category}),
                price: 0,
                comparisonCount: "",
                comparisonTimer: null,
                buttons: true,
                confirmText: this.locale("cancelBtn"),
                type: "cancelSubscription",
            });
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-content.scss" />
