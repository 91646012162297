<template lang="pug">
    .textarea-example
        ._title Пример:
        ._content
            svg-icon(icon-name="picture")._icon
            ._field(v-html="text")
        ._result(v-if="result")
            img(:src="require(`../img/${result}`)")._result-image
</template>

<script>
export default {
    name: "TextareaExample",
    props: {
        text: {
            type: String,
            default: "",
            required: false,
        },
        result: {
            type: String,
            default: "",
            required: false,
        },
    },
};
</script>

<style lang="scss" src="./textarea-example.scss"></style>
