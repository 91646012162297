import gql from "graphql-tag";

export const phoneConfirmMixins = {
    data: () => ({
        countryCodes: [
            {
                code: "RU",
                phone_code: "+7",
                short: "RU +7",
                full: "Россия +7",
            },
            {
                code: "BY",
                phone_code: "+375",
                short: "BY +375",
                full: "Беларусь +375",
            },
            {
                code: "UA",
                phone_code: "+380",
                short: "UA +380",
                full: "Украина +380",
            },
            {
                code: "KZ",
                phone_code: "+7",
                short: "KZ +7",
                full: "Казахстан +7",
            },
            {
                code: "AF",
                phone_code: "+93",
                short: "AF +93",
                full: "Afghanistan +93",
            },
            {
                code: "AL",
                phone_code: "+355",
                short: "AL +355",
                full: "Albania +355",
            },
            {
                code: "DZ",
                phone_code: "+213",
                short: "DZ +213",
                full: "Algeria +213",
            },
            {
                code: "AS",
                phone_code: "+1",
                short: "AS +1",
                full: "American Samoa +1",
            },
            {
                code: "AD",
                phone_code: "+376",
                short: "AD +376",
                full: "Andorra +376",
            },
            {
                code: "AO",
                phone_code: "+244",
                short: "AO +244",
                full: "Angola +244",
            },
            {
                code: "AG",
                phone_code: "+1",
                short: "AG +1",
                full: "Antigua & Barbuda +1",
            },
            {
                code: "AR",
                phone_code: "+54",
                short: "AR +54",
                full: "Argentina +54",
            },
            {
                code: "AM",
                phone_code: "+374",
                short: "AM +374",
                full: "Armenia +374",
            },
            {
                code: "AW",
                phone_code: "+297",
                short: "AW +297",
                full: "Aruba +297",
            },
            {
                code: "AU",
                phone_code: "+61",
                short: "AU +61",
                full: "Australia +61",
            },
            {
                code: "AT",
                phone_code: "+43",
                short: "AT +43",
                full: "Austria +43",
            },
            {
                code: "AZ",
                phone_code: "+994",
                short: "AZ +994",
                full: "Azerbaijan +994",
            },
            {
                code: "BS",
                phone_code: "+1",
                short: "BS +1",
                full: "Bahamas +1",
            },
            {
                code: "BH",
                phone_code: "+973",
                short: "BH +973",
                full: "Bahrain +973",
            },
            {
                code: "BD",
                phone_code: "+880",
                short: "BD +880",
                full: "Bangladesh +880",
            },
            {
                code: "BB",
                phone_code: "+1",
                short: "BB +1",
                full: "Barbados +1",
            },
            {
                code: "BE",
                phone_code: "+32",
                short: "BE +32",
                full: "Belgium +32",
            },
            {
                code: "BZ",
                phone_code: "+501",
                short: "BZ +501",
                full: "Belize +501",
            },
            {
                code: "BJ",
                phone_code: "+229",
                short: "BJ +229",
                full: "Benin +229",
            },
            {
                code: "BM",
                phone_code: "+1",
                short: "BM +1",
                full: "Bermuda +1",
            },
            {
                code: "BT",
                phone_code: "+975",
                short: "BT +975",
                full: "Bhutan +975",
            },
            {
                code: "BO",
                phone_code: "+591",
                short: "BO +591",
                full: "Bolivia +591",
            },
            {
                code: "BA",
                phone_code: "+387",
                short: "BA +387",
                full: "Bosnia & Herzegovina +387",
            },
            {
                code: "BW",
                phone_code: "+267",
                short: "BW +267",
                full: "Botswana +267",
            },
            {
                code: "BR",
                phone_code: "+55",
                short: "BR +55",
                full: "Brazil +55",
            },
            {
                code: "VG",
                phone_code: "+1",
                short: "VG +1",
                full: "British Virgin Islands +1",
            },
            {
                code: "BN",
                phone_code: "+673",
                short: "BN +673",
                full: "Brunei +673",
            },
            {
                code: "BG",
                phone_code: "+359",
                short: "BG +359",
                full: "Bulgaria +359",
            },
            {
                code: "BF",
                phone_code: "+226",
                short: "BF +226",
                full: "Burkina Faso +226",
            },
            {
                code: "BI",
                phone_code: "+257",
                short: "BI +257",
                full: "Burundi +257",
            },
            {
                code: "KH",
                phone_code: "+855",
                short: "KH +855",
                full: "Cambodia +855",
            },
            {
                code: "CM",
                phone_code: "+237",
                short: "CM +237",
                full: "Cameroon +237",
            },
            {
                code: "CA",
                phone_code: "+1",
                short: "CA +1",
                full: "Canada +1",
            },
            {
                code: "CV",
                phone_code: "+238",
                short: "CV +238",
                full: "Cape Verde +238",
            },
            {
                code: "KY",
                phone_code: "+1",
                short: "KY +1",
                full: "Cayman Islands +1",
            },
            {
                code: "CF",
                phone_code: "+236",
                short: "CF +236",
                full: "Central African Republic +236",
            },
            {
                code: "TD",
                phone_code: "+235",
                short: "TD +235",
                full: "Chad +235",
            },
            {
                code: "CL",
                phone_code: "+56",
                short: "CL +56",
                full: "Chile +56",
            },
            {
                code: "CN",
                phone_code: "+86",
                short: "CN +86",
                full: "China +86",
            },
            {
                code: "CO",
                phone_code: "+57",
                short: "CO +57",
                full: "Colombia +57",
            },
            {
                code: "KM",
                phone_code: "+269",
                short: "KM +269",
                full: "Comoros +269",
            },
            {
                code: "CG",
                phone_code: "+242",
                short: "CG +242",
                full: "Congo - Brazzaville +242",
            },
            {
                code: "CD",
                phone_code: "+243",
                short: "CD +243",
                full: "Congo - Kinshasa +243",
            },
            {
                code: "CK",
                phone_code: "+682",
                short: "CK +682",
                full: "Cook Islands +682",
            },
            {
                code: "CR",
                phone_code: "+506",
                short: "CR +506",
                full: "Costa Rica +506",
            },
            {
                code: "HR",
                phone_code: "+385",
                short: "HR +385",
                full: "Croatia +385",
            },
            {
                code: "CU",
                phone_code: "+53",
                short: "CU +53",
                full: "Cuba +53",
            },
            {
                code: "CY",
                phone_code: "+357",
                short: "CY +357",
                full: "Cyprus +357",
            },
            {
                code: "CZ",
                phone_code: "+420",
                short: "CZ +420",
                full: "Czech Republic +420",
            },
            {
                code: "CI",
                phone_code: "+225",
                short: "CI +225",
                full: "Côte d’Ivoire +225",
            },
            {
                code: "DK",
                phone_code: "+45",
                short: "DK +45",
                full: "Denmark +45",
            },
            {
                code: "DJ",
                phone_code: "+253",
                short: "DJ +253",
                full: "Djibouti +253",
            },
            {
                code: "DM",
                phone_code: "+1",
                short: "DM +1",
                full: "Dominica +1",
            },
            {
                code: "DO",
                phone_code: "+1",
                short: "DO +1",
                full: "Dominican Republic +1",
            },
            {
                code: "EC",
                phone_code: "+593",
                short: "EC +593",
                full: "Ecuador +593",
            },
            {
                code: "EG",
                phone_code: "+20",
                short: "EG +20",
                full: "Egypt +20",
            },
            {
                code: "SV",
                phone_code: "+503",
                short: "SV +503",
                full: "El Salvador +503",
            },
            {
                code: "GQ",
                phone_code: "+240",
                short: "GQ +240",
                full: "Equatorial Guinea +240",
            },
            {
                code: "ER",
                phone_code: "+291",
                short: "ER +291",
                full: "Eritrea +291",
            },
            {
                code: "EE",
                phone_code: "+372",
                short: "EE +372",
                full: "Estonia +372",
            },
            {
                code: "ET",
                phone_code: "+251",
                short: "ET +251",
                full: "Ethiopia +251",
            },
            {
                code: "FK",
                phone_code: "+500",
                short: "FK +500",
                full: "Falkland Islands +500",
            },
            {
                code: "FO",
                phone_code: "+298",
                short: "FO +298",
                full: "Faroe Islands +298",
            },
            {
                code: "FJ",
                phone_code: "+679",
                short: "FJ +679",
                full: "Fiji +679",
            },
            {
                code: "FI",
                phone_code: "+358",
                short: "FI +358",
                full: "Finland +358",
            },
            {
                code: "FR",
                phone_code: "+33",
                short: "FR +33",
                full: "France +33",
            },
            {
                code: "GF",
                phone_code: "+594",
                short: "GF +594",
                full: "French Guiana +594",
            },
            {
                code: "PF",
                phone_code: "+689",
                short: "PF +689",
                full: "French Polynesia +689",
            },
            {
                code: "GA",
                phone_code: "+241",
                short: "GA +241",
                full: "Gabon +241",
            },
            {
                code: "GM",
                phone_code: "+220",
                short: "GM +220",
                full: "Gambia +220",
            },
            {
                code: "GE",
                phone_code: "+995",
                short: "GE +995",
                full: "Georgia +995",
            },
            {
                code: "DE",
                phone_code: "+49",
                short: "DE +49",
                full: "Germany +49",
            },
            {
                code: "GH",
                phone_code: "+233",
                short: "GH +233",
                full: "Ghana +233",
            },
            {
                code: "GI",
                phone_code: "+350",
                short: "GI +350",
                full: "Gibraltar +350",
            },
            {
                code: "GR",
                phone_code: "+30",
                short: "GR +30",
                full: "Greece +30",
            },
            {
                code: "GL",
                phone_code: "+299",
                short: "GL +299",
                full: "Greenland +299",
            },
            {
                code: "GD",
                phone_code: "+1",
                short: "GD +1",
                full: "Grenada +1",
            },
            {
                code: "GP",
                phone_code: "+590",
                short: "GP +590",
                full: "Guadeloupe +590",
            },
            {
                code: "GU",
                phone_code: "+1",
                short: "GU +1",
                full: "Guam +1",
            },
            {
                code: "GT",
                phone_code: "+502",
                short: "GT +502",
                full: "Guatemala +502",
            },
            {
                code: "GN",
                phone_code: "+224",
                short: "GN +224",
                full: "Guinea +224",
            },
            {
                code: "GW",
                phone_code: "+245",
                short: "GW +245",
                full: "Guinea-Bissau +245",
            },
            {
                code: "GY",
                phone_code: "+592",
                short: "GY +592",
                full: "Guyana +592",
            },
            {
                code: "HT",
                phone_code: "+509",
                short: "HT +509",
                full: "Haiti +509",
            },
            {
                code: "HN",
                phone_code: "+504",
                short: "HN +504",
                full: "Honduras +504",
            },
            {
                code: "HK",
                phone_code: "+852",
                short: "HK +852",
                full: "Hong Kong +852",
            },
            {
                code: "HU",
                phone_code: "+36",
                short: "HU +36",
                full: "Hungary +36",
            },
            {
                code: "IS",
                phone_code: "+354",
                short: "IS +354",
                full: "Iceland +354",
            },
            {
                code: "IN",
                phone_code: "+91",
                short: "IN +91",
                full: "India +91",
            },
            {
                code: "ID",
                phone_code: "+62",
                short: "ID +62",
                full: "Indonesia +62",
            },
            {
                code: "IR",
                phone_code: "+98",
                short: "IR +98",
                full: "Iran +98",
            },
            {
                code: "IQ",
                phone_code: "+964",
                short: "IQ +964",
                full: "Iraq +964",
            },
            {
                code: "IE",
                phone_code: "+353",
                short: "IE +353",
                full: "Ireland +353",
            },
            {
                code: "IL",
                phone_code: "+972",
                short: "IL +972",
                full: "Israel +972",
            },
            {
                code: "IT",
                phone_code: "+39",
                short: "IT +39",
                full: "Italy +39",
            },
            {
                code: "JM",
                phone_code: "+1",
                short: "JM +1",
                full: "Jamaica +1",
            },
            {
                code: "JP",
                phone_code: "+81",
                short: "JP +81",
                full: "Japan +81",
            },
            {
                code: "JO",
                phone_code: "+962",
                short: "JO +962",
                full: "Jordan +962",
            },
            {
                code: "KE",
                phone_code: "+254",
                short: "KE +254",
                full: "Kenya +254",
            },
            {
                code: "KI",
                phone_code: "+686",
                short: "KI +686",
                full: "Kiribati +686",
            },
            {
                code: "KW",
                phone_code: "+965",
                short: "KW +965",
                full: "Kuwait +965",
            },
            {
                code: "KG",
                phone_code: "+996",
                short: "KG +996",
                full: "Kyrgyzstan +996",
            },
            {
                code: "LA",
                phone_code: "+856",
                short: "LA +856",
                full: "Laos +856",
            },
            {
                code: "LV",
                phone_code: "+371",
                short: "LV +371",
                full: "Latvia +371",
            },
            {
                code: "LB",
                phone_code: "+961",
                short: "LB +961",
                full: "Lebanon +961",
            },
            {
                code: "LS",
                phone_code: "+266",
                short: "LS +266",
                full: "Lesotho +266",
            },
            {
                code: "LR",
                phone_code: "+231",
                short: "LR +231",
                full: "Liberia +231",
            },
            {
                code: "LY",
                phone_code: "+218",
                short: "LY +218",
                full: "Libya +218",
            },
            {
                code: "LI",
                phone_code: "+423",
                short: "LI +423",
                full: "Liechtenstein +423",
            },
            {
                code: "LT",
                phone_code: "+370",
                short: "LT +370",
                full: "Lithuania +370",
            },
            {
                code: "LU",
                phone_code: "+352",
                short: "LU +352",
                full: "Luxembourg +352",
            },
            {
                code: "MO",
                phone_code: "+853",
                short: "MO +853",
                full: "Macau +853",
            },
            {
                code: "MK",
                phone_code: "+389",
                short: "MK +389",
                full: "Macedonia +389",
            },
            {
                code: "MG",
                phone_code: "+261",
                short: "MG +261",
                full: "Madagascar +261",
            },
            {
                code: "MW",
                phone_code: "+265",
                short: "MW +265",
                full: "Malawi +265",
            },
            {
                code: "MY",
                phone_code: "+60",
                short: "MY +60",
                full: "Malaysia +60",
            },
            {
                code: "MV",
                phone_code: "+960",
                short: "MV +960",
                full: "Maldives +960",
            },
            {
                code: "ML",
                phone_code: "+223",
                short: "ML +223",
                full: "Mali +223",
            },
            {
                code: "MT",
                phone_code: "+356",
                short: "MT +356",
                full: "Malta +356",
            },
            {
                code: "MH",
                phone_code: "+692",
                short: "MH +692",
                full: "Marshall Islands +692",
            },
            {
                code: "MQ",
                phone_code: "+596",
                short: "MQ +596",
                full: "Martinique +596",
            },
            {
                code: "MR",
                phone_code: "+222",
                short: "MR +222",
                full: "Mauritania +222",
            },
            {
                code: "MU",
                phone_code: "+230",
                short: "MU +230",
                full: "Mauritius +230",
            },
            {
                code: "MX",
                phone_code: "+52",
                short: "MX +52",
                full: "Mexico +52",
            },
            {
                code: "FM",
                phone_code: "+691",
                short: "FM +691",
                full: "Micronesia +691",
            },
            {
                code: "MD",
                phone_code: "+373",
                short: "MD +373",
                full: "Moldova +373",
            },
            {
                code: "MC",
                phone_code: "+377",
                short: "MC +377",
                full: "Monaco +377",
            },
            {
                code: "MN",
                phone_code: "+976",
                short: "MN +976",
                full: "Mongolia +976",
            },
            {
                code: "ME",
                phone_code: "+382",
                short: "ME +382",
                full: "Montenegro +382",
            },
            {
                code: "MS",
                phone_code: "+1",
                short: "MS +1",
                full: "Montserrat +1",
            },
            {
                code: "MA",
                phone_code: "+212",
                short: "MA +212",
                full: "Morocco +212",
            },
            {
                code: "MZ",
                phone_code: "+258",
                short: "MZ +258",
                full: "Mozambique +258",
            },
            {
                code: "MM",
                phone_code: "+95",
                short: "MM +95",
                full: "Myanmar (Burma) +95",
            },
            {
                code: "NA",
                phone_code: "+264",
                short: "NA +264",
                full: "Namibia +264",
            },
            {
                code: "NR",
                phone_code: "+674",
                short: "NR +674",
                full: "Nauru +674",
            },
            {
                code: "NP",
                phone_code: "+977",
                short: "NP +977",
                full: "Nepal +977",
            },
            {
                code: "NL",
                phone_code: "+31",
                short: "NL +31",
                full: "Netherlands +31",
            },
            {
                code: "NC",
                phone_code: "+687",
                short: "NC +687",
                full: "New Caledonia +687",
            },
            {
                code: "NZ",
                phone_code: "+64",
                short: "NZ +64",
                full: "New Zealand +64",
            },
            {
                code: "NI",
                phone_code: "+505",
                short: "NI +505",
                full: "Nicaragua +505",
            },
            {
                code: "NE",
                phone_code: "+227",
                short: "NE +227",
                full: "Niger +227",
            },
            {
                code: "NG",
                phone_code: "+234",
                short: "NG +234",
                full: "Nigeria +234",
            },
            {
                code: "NU",
                phone_code: "+683",
                short: "NU +683",
                full: "Niue +683",
            },
            {
                code: "NF",
                phone_code: "+672",
                short: "NF +672",
                full: "Norfolk Island +672",
            },
            {
                code: "KP",
                phone_code: "+850",
                short: "KP +850",
                full: "North Korea +850",
            },
            {
                code: "NO",
                phone_code: "+47",
                short: "NO +47",
                full: "Norway +47",
            },
            {
                code: "OM",
                phone_code: "+968",
                short: "OM +968",
                full: "Oman +968",
            },
            {
                code: "PK",
                phone_code: "+92",
                short: "PK +92",
                full: "Pakistan +92",
            },
            {
                code: "PW",
                phone_code: "+680",
                short: "PW +680",
                full: "Palau +680",
            },
            {
                code: "PS",
                phone_code: "+970",
                short: "PS +970",
                full: "Palestinian Territories +970",
            },
            {
                code: "PA",
                phone_code: "+507",
                short: "PA +507",
                full: "Panama +507",
            },
            {
                code: "PG",
                phone_code: "+675",
                short: "PG +675",
                full: "Papua New Guinea +675",
            },
            {
                code: "PY",
                phone_code: "+595",
                short: "PY +595",
                full: "Paraguay +595",
            },
            {
                code: "PE",
                phone_code: "+51",
                short: "PE +51",
                full: "Peru +51",
            },
            {
                code: "PH",
                phone_code: "+63",
                short: "PH +63",
                full: "Philippines +63",
            },
            {
                code: "PL",
                phone_code: "+48",
                short: "PL +48",
                full: "Poland +48",
            },
            {
                code: "PT",
                phone_code: "+351",
                short: "PT +351",
                full: "Portugal +351",
            },
            {
                code: "PR",
                phone_code: "+1",
                short: "PR +1",
                full: "Puerto Rico +1",
            },
            {
                code: "QA",
                phone_code: "+974",
                short: "QA +974",
                full: "Qatar +974",
            },
            {
                code: "RO",
                phone_code: "+40",
                short: "RO +40",
                full: "Romania +40",
            },
            {
                code: "RW",
                phone_code: "+250",
                short: "RW +250",
                full: "Rwanda +250",
            },
            {
                code: "RE",
                phone_code: "+262",
                short: "RE +262",
                full: "Réunion +262",
            },
            {
                code: "WS",
                phone_code: "+685",
                short: "WS +685",
                full: "Samoa +685",
            },
            {
                code: "SM",
                phone_code: "+378",
                short: "SM +378",
                full: "San Marino +378",
            },
            {
                code: "SA",
                phone_code: "+966",
                short: "SA +966",
                full: "Saudi Arabia +966",
            },
            {
                code: "SN",
                phone_code: "+221",
                short: "SN +221",
                full: "Senegal +221",
            },
            {
                code: "RS",
                phone_code: "+381",
                short: "RS +381",
                full: "Serbia +381",
            },
            {
                code: "SC",
                phone_code: "+248",
                short: "SC +248",
                full: "Seychelles +248",
            },
            {
                code: "SL",
                phone_code: "+232",
                short: "SL +232",
                full: "Sierra Leone +232",
            },
            {
                code: "SG",
                phone_code: "+65",
                short: "SG +65",
                full: "Singapore +65",
            },
            {
                code: "SK",
                phone_code: "+421",
                short: "SK +421",
                full: "Slovakia +421",
            },
            {
                code: "SI",
                phone_code: "+386",
                short: "SI +386",
                full: "Slovenia +386",
            },
            {
                code: "SB",
                phone_code: "+677",
                short: "SB +677",
                full: "Solomon Islands +677",
            },
            {
                code: "SO",
                phone_code: "+252",
                short: "SO +252",
                full: "Somalia +252",
            },
            {
                code: "ZA",
                phone_code: "+27",
                short: "ZA +27",
                full: "South Africa +27",
            },
            {
                code: "KR",
                phone_code: "+82",
                short: "KR +82",
                full: "South Korea +82",
            },
            {
                code: "SS",
                phone_code: "+211",
                short: "SS +211",
                full: "South Sudan +211",
            },
            {
                code: "ES",
                phone_code: "+34",
                short: "ES +34",
                full: "Spain +34",
            },
            {
                code: "LK",
                phone_code: "+94",
                short: "LK +94",
                full: "Sri Lanka +94",
            },
            {
                code: "KN",
                phone_code: "+1",
                short: "KN +1",
                full: "St. Kitts & Nevis +1",
            },
            {
                code: "LC",
                phone_code: "+1",
                short: "LC +1",
                full: "St. Lucia +1",
            },
            {
                code: "PM",
                phone_code: "+508",
                short: "PM +508",
                full: "St. Pierre & Miquelon +508",
            },
            {
                code: "VC",
                phone_code: "+1",
                short: "VC +1",
                full: "St. Vincent & Grenadines +1",
            },
            {
                code: "SD",
                phone_code: "+249",
                short: "SD +249",
                full: "Sudan +249",
            },
            {
                code: "SR",
                phone_code: "+597",
                short: "SR +597",
                full: "Suriname +597",
            },
            {
                code: "SZ",
                phone_code: "+268",
                short: "SZ +268",
                full: "Swaziland +268",
            },
            {
                code: "SE",
                phone_code: "+46",
                short: "SE +46",
                full: "Sweden +46",
            },
            {
                code: "CH",
                phone_code: "+41",
                short: "CH +41",
                full: "Switzerland +41",
            },
            {
                code: "SY",
                phone_code: "+963",
                short: "SY +963",
                full: "Syria +963",
            },
            {
                code: "ST",
                phone_code: "+239",
                short: "ST +239",
                full: "São Tomé & Príncipe +239",
            },
            {
                code: "TW",
                phone_code: "+886",
                short: "TW +886",
                full: "Taiwan +886",
            },
            {
                code: "TJ",
                phone_code: "+992",
                short: "TJ +992",
                full: "Tajikistan +992",
            },
            {
                code: "TZ",
                phone_code: "+255",
                short: "TZ +255",
                full: "Tanzania +255",
            },
            {
                code: "TH",
                phone_code: "+66",
                short: "TH +66",
                full: "Thailand +66",
            },
            {
                code: "TL",
                phone_code: "+670",
                short: "TL +670",
                full: "Timor-Leste +670",
            },
            {
                code: "TG",
                phone_code: "+228",
                short: "TG +228",
                full: "Togo +228",
            },
            {
                code: "TO",
                phone_code: "+676",
                short: "TO +676",
                full: "Tonga +676",
            },
            {
                code: "TT",
                phone_code: "+1",
                short: "TT +1",
                full: "Trinidad & Tobago +1",
            },
            {
                code: "TN",
                phone_code: "+216",
                short: "TN +216",
                full: "Tunisia +216",
            },
            {
                code: "TR",
                phone_code: "+90",
                short: "TR +90",
                full: "Turkey +90",
            },
            {
                code: "TM",
                phone_code: "+993",
                short: "TM +993",
                full: "Turkmenistan +993",
            },
            {
                code: "TC",
                phone_code: "+1",
                short: "TC +1",
                full: "Turks & Caicos Islands +1",
            },
            {
                code: "TV",
                phone_code: "+688",
                short: "TV +688",
                full: "Tuvalu +688",
            },
            {
                code: "VI",
                phone_code: "+1",
                short: "VI +1",
                full: "U.S. Virgin Islands +1",
            },
            {
                code: "UG",
                phone_code: "+256",
                short: "UG +256",
                full: "Uganda +256",
            },
            {
                code: "AE",
                phone_code: "+971",
                short: "AE +971",
                full: "United Arab Emirates +971",
            },
            {
                code: "GB",
                phone_code: "+44",
                short: "GB +44",
                full: "United Kingdom +44",
            },
            {
                code: "US",
                phone_code: "+1",
                short: "US +1",
                full: "United States +1",
            },
            {
                code: "UY",
                phone_code: "+598",
                short: "UY +598",
                full: "Uruguay +598",
            },
            {
                code: "UZ",
                phone_code: "+998",
                short: "UZ +998",
                full: "Uzbekistan +998",
            },
            {
                code: "VU",
                phone_code: "+678",
                short: "VU +678",
                full: "Vanuatu +678",
            },
            {
                code: "VE",
                phone_code: "+58",
                short: "VE +58",
                full: "Venezuela +58",
            },
            {
                code: "VN",
                phone_code: "+84",
                short: "VN +84",
                full: "Vietnam +84",
            },
            {
                code: "YE",
                phone_code: "+967",
                short: "YE +967",
                full: "Yemen +967",
            },
            {
                code: "ZM",
                phone_code: "+260",
                short: "ZM +260",
                full: "Zambia +260",
            },
            {
                code: "ZW",
                phone_code: "+263",
                short: "ZW +263",
                full: "Zimbabwe +263",
            },
            {
                code: "AX",
                phone_code: "+358",
                short: "AX +358",
                full: "Åland Islands +358",
            },
        ],
    }),
    methods: {
        newUserPhone(phoneNumber, token) {
            const query = gql`
                query newUserPhone($phoneNumber: String!, $authType: String) {
                    newUserPhone(phoneNumber: $phoneNumber, authType: $authType) {
                        status
                        wait
                        confirmationType
                    }
                }
            `;

            return this.$apollo.query({
                query: query,
                variables: {
                    phoneNumber,
                    authType: token
                },
                fetchPolicy: "no-cache",
            });
        },

        newUserPhoneVerify(phoneNumber, code, referralCode, promoCode) {
            const mutation = gql`
                mutation newUserPhoneVerify(
                    $phoneNumber: String!
                    $code: Int!
                    $referralCode: String
                    $userAgent: String
                    $promoCode: String
                ) {
                    newUserPhoneVerify(
                        phoneNumber: $phoneNumber
                        confirmationCode: $code
                        referralCode: $referralCode
                        userAgent: $userAgent
                        promoCode: $promoCode
                    ) {
                        status
                        wait
                        attempts
                        jwt
                        refresh_token
                        user_uuid
                    }
                }
            `;

            const userAgent = navigator.userAgent;
            return this.$apollo.mutate({
                mutation: mutation,
                variables: {
                    phoneNumber,
                    code: parseInt(code),
                    referralCode:
                        typeof referralCode !== "undefined" ? referralCode : "",
                    userAgent,
                    promoCode,
                },
                fetchPolicy: "no-cache",
            });
        },
        phoneConfirmationRequest(phoneNumber) {
            const query = gql`
                query phoneConfirmationRequest($phoneNumber: String!) {
                    phoneConfirmationRequest(phoneNumber: $phoneNumber) {
                        status
                        wait
                    }
                }
            `;

            return this.$apollo.query({
                query: query,
                variables: {
                    phoneNumber,
                },
                fetchPolicy: "no-cache",
            });
        },

        phoneConfirmationVerify(phoneNumber, code) {
            const mutation = gql`
                mutation phoneConfirmationVerify(
                    $phoneNumber: String!
                    $code: Int!
                ) {
                    phoneConfirmationVerify(
                        phone: $phoneNumber
                        confirmationCode: $code
                    ) {
                        status
                        attempts
                        wait
                    }
                }
            `;

            return this.$apollo.mutate({
                mutation: mutation,
                variables: {
                    phoneNumber,
                    code: parseInt(code),
                },
                fetchPolicy: "no-cache",
            });
        },
    },
};
