<template lang="pug">
    .landing-about.section
        ._wrap.landing-wrap
            ._head
                h2._title(v-html="locale(\"title\")")
            ._content
                ._list(v-for="(list, l) in locale('list')" :key="l")
                    ._item(v-for="(item, i) in list" :key="i" v-show="item") {{ item }}
                        svg-icon(:icon-name="i" added-catalog="landing/lines" :sizes="true" :data-line="i" v-if="isDesktopView")._item-line
                vue-lottie-player(autoplay controls loop mode="normal" :animationData="analyzeFile" width="300px" height="300px" v-if="isDesktopView")._player
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
import VueLottiePlayer from "vue-lottie-player";

export default {
    name: "LandingAbout",
    components: {UiButton, VueLottiePlayer},
    data() {
        return {
            analyzeFile: require("../../../assets/lottie-files/analyze"),
        };
        
    },
    mounted() {
    },
};
</script>

<style lang="scss" src="./landing-about.scss"></style>
