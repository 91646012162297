<template lang="pug">
    router-link(:to="{name: 'recommended-people'}" data-tip="people-recommendations").recommended-people-ctrl
        ._title {{ locale("title") }}
</template>

<script>
export default {
    name: "RecommendedPeopleCtrl",
};
</script>

<style src="./recommended-people-ctrl.scss" lang="scss"></style>
