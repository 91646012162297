<template lang="pug">
    .subscriptions-categories
        ._list
            button._list-item(
                v-for="item in categories"
                :key="item.id"
                :to="{ name: item.routeName }"
                @click="emitSelectCategory(item.id)"
            )
                subscriptions-categories-item(
                    :icon="item.icon"
                    :label="item.name"
                    :is-active="isCategoryActive(item.id)"
                    :is-current="isCategoryCurrent(item.id)"
                    :accent="getCategoryAccent(item.id)"
                )
</template>

<script>
import SubscriptionsCategoriesItem from "@/components/subscriptions/components/subscriptions-categories-item/subscriptions-categories-item";
import { subscriptionsPlans } from "@/components/subscriptions/settings/subscriptions-plans";

export default {
    name: "SubscriptionsCategories",
    components: { SubscriptionsCategoriesItem },
    props: {
        categories: {
            type: Array,
            required: true,
        },
        activeCategories: {
            type: Array,
            required: true,
        },
        currentCategoryId: {
            required: true,
            type: Number,
        },
    },
    methods: {
        isCategoryActive(categoryId) {
            return this.activeCategories
                .map((category) => category.id)
                .includes(categoryId);
        },
        isCategoryCurrent(categoryId) {
            return categoryId === this.currentCategoryId;
        },
        getCategoryAccent(categoryId) {
            const category = this.activeCategories
                .filter((category) => category.id !== null)
                .find((category) => categoryId === category.id);
            switch (category?.level) {
                case subscriptionsPlans.GOLD.id: {
                    return subscriptionsPlans.GOLD.accent;
                }
                case subscriptionsPlans.PLATINUM.id: {
                    return subscriptionsPlans.PLATINUM.accent;
                }
                case subscriptionsPlans.JUNIOR.id: {
                    return subscriptionsPlans.JUNIOR.accent;
                }
                default: {
                    return null;
                }
            }
        },
        emitSelectCategory(categoryId) {
            this.$emit("select-category", categoryId);
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-categories.scss" />
