import gql from "graphql-tag";

export const notificationsMixins = {
    queries: {
        notificationsCount: gql`
            query {
                notificationsCount
            }
        `,
    },
    methods: {
        notificationsList(page, itemsPerPage, haveRead) {
            let query = gql`
                query notificationsList(
                    $page: Int!
                    $itemsPerPage: Int!
                    $haveRead: Boolean
                ) {
                    notificationsList(
                        page: $page
                        itemsPerPage: $itemsPerPage
                        haveRead: $haveRead
                    ) {
                        notifications {
                            id
                            message
                            category {
                                name
                            }
                            subcategory {
                                name
                            }
                            have_read
                            created_at
                            sender {
                                uuid
                                display_name
                                person {
                                    photos {
                                        avatar
                                    }
                                }
                            }
                            sender_opened
                        }
                        hasMore
                    }
                }
            `;
            return this.$apollo.query({
                query: query,
                variables: {
                    page,
                    itemsPerPage,
                    haveRead,
                },
                fetchPolicy: "network-only",
            });
        },

        notificationMarkAsRead(id) {
            let query = gql`
                mutation notificationMarkAsRead($id: Int!) {
                    notificationMarkAsRead(notificationId: $id)
                }
            `;
            return this.$apollo.query({
                query: query,
                variables: {
                    id: parseInt(id),
                },
                fetchPolicy: "no-cache",
            });
        },

        responsePartnerReq(type, requestId) {
            console.log(type, requestId);
            let mutation = gql`
                mutation coachPartnerResponse(
                    $requestId: Int!
                    $status: COACH_PARTNER_STATUS!
                ) {
                    coachPartnerResponse(requestId: $requestId, status: $status)
                }
            `;
            return this.$apollo.mutate({
                mutation,
                variables: {
                    requestId: parseInt(requestId),
                    status: type,
                },
            });
        },

        notificationDelete(id) {
            let query = gql`
                mutation notificationDelete($id: Int!) {
                    notificationDelete(notificationId: $id)
                }
            `;
            return this.$apollo.query({
                query: query,
                variables: {
                    id: parseInt(id),
                },
                fetchPolicy: "no-cache",
            });
        },
    },
};
