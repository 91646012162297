<template lang="pug">
.subscriptions-widget-categories
	._list
		._item(v-for="category in categories", :key="category.id")
			subscriptions-widget-categories-item(
				:accent="category.accent",
				:icon="category.icon",
				:title="category.name",
				:level="category.level",
				:is-active="category.id === activeCategoryId",
				@click.native="openModal(category.id)",
				@itemHovered="$emit('itemHovered', category.icon, category.name, category.id)",
			)
</template>

<script>
import SubscriptionsWidgetCategoriesItem from "@/components/subscriptions/components/subscriptions-widget/components/subscriptions-widget-categories-item/subscriptions-widget-categories-item";
export default {
    name: "SubscriptionsWidgetCategories",
    components: { SubscriptionsWidgetCategoriesItem },
    props: {
        categories: {
            type: Array,
            required: true,
        },
        activeCategoryId: {
            type: String || null,
            required: false,
            default: null,
        },
    },
    methods: {
        // emitSelectCategory(categoryId) {
        //   this.$emit('select-category', categoryId)
        // },
        openModal(c) {
            this.$modal.show("subscription", { categoryId: c });
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-widget-categories.scss" />
