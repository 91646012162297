<template lang="pug">
    .loading
        svg._logo(version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 29 29" style="enable-background:new 0 0 29 29;" xml:space="preserve")
            path(d="M0.5,0.5L0.5,0.5h28l0,0v28l0,0h-28l0,0L0.5,0.5z")._sqr
            polygon(points="18.5,18.2 16.5,18.2 16.5,9.7 15.2,14.2 13.7,14.2 12.4,9.7 12.4,18.2 10.4,18.2 10.4,5.7 12.8,5.7 14.4,11.3 16.1,5.7 18.5,5.7 ")._letter
            rect(x="10.4" y="21.5" width="2" height="2")._indicator
        span._offline(v-if="!isOnline") {{ locale("title") }}
</template>

<script>
import { onlineMixin } from "@/mixins/online.mixin";
export default {
    name: "AppLoading",
    mixins: [onlineMixin],
    props: {
        text: {
            require: false,
            default: "",
            type: String,
        },
    },
};
</script>

<style lang="scss">
.loading {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #222;
    z-index: 10000;

    &__logo {
        fill: none;
        width: 50px;
        height: 50px;
    }
    &__offline {
        font-size: 14px;
        margin-top: 15px;
        color: #868586;
        text-align: center;
    }
    &__sqr {
        stroke-width: 2;
        stroke: $gold;
        stroke-dashoffset: 0;
        stroke-dasharray: 60;
        animation: snake 1.5s infinite linear;
    }

    &__letter {
        fill: $gold;
    }

    &__indicator {
        fill: $gold;
        animation: indicatorPos 1s infinite step-start;
    }
}

@keyframes snake {
    from {
        stroke-dashoffset: 0;
    }
    to {
        stroke-dashoffset: 240;
    }
}

@keyframes indicatorPos {
    0% {
        transform: translateX(0);
    }
    25% {
        transform: translateX(3px);
    }
    50% {
        transform: translateX(6px);
    }
    75% {
        transform: translateX(3px);
    }
    100% {
        transform: translateX(0);
    }
}
</style>
