<template lang="pug">
  .subscriptions-table-title(:style="getTitleStyles")
    ._text {{ title }}
    svg-icon(icon-name="dotted-arrow-right" v-if="title !== 'PLATINUM'")._arrow
</template>

<script>
export default {
    name: "SubscriptionsTableTitle",
    props: {
        accent: {
            type: String || null,
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: true,
        },
    },
    computed: {
        getTitleStyles() {
            return {
                color: this.accent,
                borderColor: this.accent,
            };
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-table-title.scss" />
