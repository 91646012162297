<template lang="pug">
    .personal-recommendations__item-count-wrap
        .personal-recommendations__item-count(v-if="atLeastOneRecommendationAvailable") +1
        .personal-recommendations__item-count.-waiting(v-if="atLeastOneRecommendationHasAvailableTime") {{ timeLeft }}
</template>

<script>
export default {
    name: "PersonalRecommendationsItem",
    props: {
        recommendations: {
            type: Array,
            required: true,
            default: () => [],
        },
    },
    data() {
        return {
            timeLeft: null,
        };
    },
    computed: {
        atLeastOneRecommendationAvailable: (state) =>
            state.recommendations.some(
                (item) => item.unlockAvailable && item?.unlockAvailableAt,
            ),
        atLeastOneRecommendationHasAvailableTime: (state) =>
            state.recommendations.some(
                (item) => !item.unlockAvailable && item?.unlockAvailableAt,
            ),
        openingTime: (state) =>
            state.recommendations.find(
                (item) => !item.unlockAvailable && item.unlockAvailableAt,
            )?.unlockAvailableAt,
    },
    created() {
        this.timeLeft = this.getRemainingTime(this.openingTime);
        setInterval(() => {
            this.timeLeft = this.getRemainingTime(this.openingTime);
        }, 1000);
    },
    methods: {},
};
</script>
