<template lang="pug">
button.subscriptions-widget-categories-item(
	:style="{ color: accent }",
	:class="{ 'subscriptions-widget-categories-item_active': isActive }",
	@mouseover="$emit('itemHovered')",
)
	._icon
		svg-icon._icon-svg(:icon-name="icon")
	._text {{ levelLabel }}
</template>

<script>
export default {
    name: "SubscriptionsWidgetCategoriesItem",
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        level: {
            type: String,
            required: true,
        },
        accent: {
            type: String || null,
            required: false,
            default: null,
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        levelLabel() {
            return this.level.substr(0, 4);
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-widget-categories-item.scss" />
