<template lang="pug">
    .mobile-menu(:class="{'mobile-menu_hidden': hideMobileMenu}")
        a._item.-support(:href="SUPPORT_LINK", target="_blank", v-if="!externalId")
            ._item-group
                ._item-count-group
                    svg-icon._icon.-telegram(icon-name="telegram")
                    ._item-count(v-if="unreadNotifications > 0") {{ unreadNotifications }}
                ._item-name {{ locale("support") }}
        
        router-link._item(
            to="/recommended-people",
            active-class="mobile-menu__item_active",
            tag="button"
            data-tip="people-recommendations"
        )
            ._item-group
                ._item-count-group
                    svg-icon._icon.-recommended(icon-name="recommended" added-catalog="mobile-menu")
                    //._item-count(v-if="friendRequestsCounter > 0") {{ friendRequestsCounter }}
                // ._item-name Люди

        router-link._item(
            :to="{ name: 'notifications' }",
            active-class="mobile-menu__item_active",
            tag="button",
            data-tip="notifications"
        )
            ._item-group
                ._item-count-group
                    svg-icon._icon.-notifications(icon-name="notifications" added-catalog="mobile-menu")
                    ._item-count(v-if="unreadNotifications > 0") {{ unreadNotifications }}
                //._item-name Уведомления

        router-link._item(:to="`/messages/${MERLIN_CONVERSATION_ID}`" active-class="mobile-menu__item_active" tag="button")
            ._item-group
                ._item-count-group
                    svg-icon._icon.-messages(icon-name="messages" added-catalog="mobile-menu")
                    ._item-count(v-if="unreadMessages") {{ unreadMessages }}
                    
        router-link._item(
            :to="{ name: 'home' }",
            :class="{ 'mobile-menu__item_active': isMyProfile }",
            data-tip="my-profile"
            tag="button"
        )
            ._item-group
                ._item-count-group
                    //img(v-if="myPerson.photos.avatar" :src="myPerson.photos.avatar")._item-avatar
                    svg-icon._icon.-user(icon-name="user" added-catalog="mobile-menu")
                //._item-name Мой профиль

        //._item(@click="showModal('referral')")
        //    ._item-group
        //        ._item-count-group
        //            svg-icon(icon-name="share")._friends-icon
        //._item
        //    ._item-group
        //        .header__menu.header__menu_mobile(@click="true"  :class="{'header__menu_active': menu.active, 'header__menu_first-step': menu.first, 'header__menu_second-step': menu.second}")
        //            .header__hamburger
        //                .header__hamburger-line.-top
        //                .header__hamburger-line.-center
        //                .header__hamburger-line.-bottom
</template>

<script>
import {friendMixins} from "@/mixins/friendMixins";

export default {
    name: "MobileMenu",
    mixins: [friendMixins],
    data: () => ({
        menu: {
            active: false,
            animation: false,
            first: false,
            second: false,
        },
        friendRequestsCounter: 0,
        unreadNotifications: 0,
        prevScrollPosition: 0,
        isMenuHidden: false
    }),
    computed: {
        hideMobileMenu: (state) => state.$store.getters["hideMobileMenu"],
        externalId: (state) => state.$store.getters["user/externalIdPresent"],
        avatar: function () {
            return this.$store.getters["user/myAvatar"];
        },
        unreadMessages: function () {
            return this.$store.getters["getNewMessagesCounter"];
        },
    },
    async created() {
        await this.friendRequestsCount()
            .then((response) => {
                this.friendRequestsCounter = response.data.friendRequestsCount;
            })
            .catch((e) => {
                throw e;
            });
        this.$bus.$on("notificationsCountUpdated", (newCount) => {
            this.unreadNotifications = newCount;
        });
    },
    beforeMount() {
        window.addEventListener("scroll", this.scrollDirection);
    },
    destroyed() {
        window.removeEventListener("scroll", this.scrollDirection);
    },
    methods: {
        showModal(name) {
            this.$modal.show(name, {props: {allowComponent: true}});
        },
        scrollDirection(disabled = true) {
            if(disabled) return; 
            const currentScrollPos = window.pageYOffset;
            this.isMenuHidden = this.prevScrollPosition < currentScrollPos;
            this.prevScrollPosition = currentScrollPos;
        }
    },
};
</script>

<style lang="scss">
@import "mobile-menu";
</style>
