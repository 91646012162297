<template lang="pug">
    modal(name="Image" class="image-modal-wrap" :adaptive="true" width="100%" height="100%" @before-open="beforeOpen")
        .image-modal(@click.self="$modal.hide('Image')")
            ._wrap
                ._content(@click="$modal.hide('Image')")
                    ._close-btn(@click="$modal.hide('Image')")
                        svg-icon(icon-name="cross")._close-icon
                    ._content-inner
                        img(:src="url")._image
</template>

<script>
export default {
    name: "ImageModal",
    data() {
        return {
            url: "",
        };
    },
    computed: {},
    mounted() {},
    methods: {
        beforeOpen(event) {
            this.url = event.params?.image;
        },
    },
};
</script>

<style lang="scss" src="./image-modal.scss"></style>
