import Vue from "vue";

Vue.mixin({
    methods: {
        isAuth() {
            if (!this.$store.getters["auth/authStatus"]) {
                localStorage.redirect = this.$route.fullPath;
                localStorage.skipAuth = true;
                this.$msgBox(
                    "Ошибка",
                    "Для совершения данного действия требуется авторизация",
                );
                return false;
            }
            return true;
        },
    },
});
