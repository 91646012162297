<template lang="pug">
  .subscriptions-widget-content-item
    ._text {{ text }}
    ._prop
      template(v-if="isAvailable")
        template(v-if="availableCount > 0")
          ._prop-label Осталось
          ._prop-value {{ availableCount }}
        template(v-else-if="availableCount === -1")
          ._prop-label Осталось
          ._prop-value Безлимит
      template(v-else)
        ._prop-label Осталось
        ._prop-value {{ availableAt }}
</template>

<script>
export default {
    name: "SubscriptionsWidgetContentItem",
    props: {
        text: {
            type: String,
            required: true,
        },
        isAvailable: {
            type: Boolean,
            required: true,
        },
        availableCount: {
            type: Number,
            required: false,
            default: 0,
        },
        availableAt: {
            type: Date || null,
            required: false,
            default: null,
        },
    },
};
</script>

<style lang="scss" src="./  subscriptions-widget-content-item.scss" />
