<template lang="pug">
    .personal-recommendations(:class="`personal-recommendations_${type}`" data-tip="recommendations")
        ._aside-btn(v-if="type === 'aside'") {{ locale("title") }}
            ._item-count(v-if="allCount") +{{ allCount }}
        ._wrap
            ._item(v-for="(recommendation, index) in recommendations" :key="recommendation.category.id" @click="openRecommendationsDialog(recommendation.category.name, recommendation.recommendations[0].items, index)")
                ._item-name {{ recommendation.category.name }}
                personal-recommendations-item(:recommendations="recommendation.recommendations[0].items" :category="recommendation.category.name")
</template>

<script>
import gql from "graphql-tag";
import myPersonRecommendationsQuery from "../../../graphql/queries/myPersonRecommendations.query.graphql";
import PersonalRecommendationsItem from "./personal-recommendations-item/personal-recommendations-item";

export default {
    name: "PersonalRecommendations",
    components: { PersonalRecommendationsItem },
    props: {
        type: {
            required: false,
            default: "1",
            type: String,
        },
    },
    data() {
        return {
            recommendations: {},
        };
    },
    computed: {
        allCount: (state) => {
            let count = 0;
            Object.values(state.recommendations).forEach((item) => {
                item.recommendations[0].items.find((item) => {
                    if (item.unlockAvailable) {
                        count++;
                    }
                });
            });
            return count;
        },
        activeIndex: (state) =>
            state.$store.getters["personalRecommendations"].index,
    },
    watch: {
        recommendations() {
            this.researchRecommendations();
        },
    },
    created() {
        this.$bus.$on("getNewPersonalRecommendations", () => {
            this.getPersonalRecommendations();
        });
    },
    destroyed() {
        this.$bus.$off("getNewPersonalRecommendations");
    },
    mounted() {
        this.getPersonalRecommendations();
    },
    methods: {
        async getPersonalRecommendations() {
            await this.$apollo
                .query({
                    query: gql`
                        ${myPersonRecommendationsQuery}
                    `,
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.recommendations = r.data.myPersonRecommendations;
                });
        },
        openRecommendationsDialog(category, list, index) {
            this.$store.dispatch("setPersonalRecommendations", {
                status: true,
                category,
                list,
                index,
            });
        },
        researchRecommendations() {
            const category =
                this.recommendations[this.activeIndex]?.category.name;
            const list =
                this.recommendations[this.activeIndex]?.recommendations[0]
                    .items;
            if (category && list) {
                console.log("research");
                this.openRecommendationsDialog(
                    category,
                    list,
                    this.activeIndex,
                );
            }
        },
    },
};
</script>

<style lang="scss">
@import "personal-recommendations";
@import "personal-recommendations_aside";
</style>
