<template lang="pug">
    .footer-links
        ._link {{ locale("about") }}
        ._link(@click="showModal('feedback')") {{ locale("contacts") }}
</template>

<script>
export default {
    name: "FooterLinks",
    methods: {
        showModal(name) {
            this.$modal.show(name, { props: { allowComponent: true } });
        },
    },
};
</script>

<style lang="scss">
.footer-links {
    position: absolute;
    bottom: 15px;
    left: 255px;
    display: flex;
    align-items: center;
    z-index: 100;
    @media (max-width: 991px) {
        display: none;
    }

    &__link {
        color: #7a7a7a;
        font-size: 12px;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        &:not(:first-child) {
            margin-left: 25px;
        }
    }
}
</style>
