<template lang="pug">
.glitch-animation(
	v-if="pageWidth > 991",
	:class="[{ 'glitch-animation-active': active }, { 'glitch-animation-big': size === 'big' }, { 'glitch-animation-extra': size === 'extra' }]",
	@mouseenter="active = true",
	@mouseleave="active = false"
)
	svg-icon.glitch-animation-frame-1(
		addedCatalog="glitch-animation",
		:iconName="'frame' + 1"
	)
	svg-icon.glitch-animation-frame-2(
		addedCatalog="glitch-animation",
		:iconName="'frame' + 2"
	)
	svg-icon.glitch-animation-frame-3(
		addedCatalog="glitch-animation",
		:iconName="'frame' + 3"
	)
</template>

<script>
export default {
    props: {
        size: {
            //normal, big or extra (for parents with a shadow)
            type: String,
            default: "normal",
            required: false,
        },
    },
    data: () => ({
        active: false,
    }),
};
</script>

<style lang="scss" scoped>
@import "./glitch-animation.scss";
</style>
