<template lang="pug">
    .subscriptions-table-form(:style="{ 'color': accent }")
        template(v-if="$apollo.loading")
            loading
        template(v-else)
            form._form(@submit.prevent="subscribe")
                ._subscribe
                    ui-button(
                        weight="bold"
                        size="full"
                        case="unset"
                        :color="planLevel.toLowerCase()"
                        :text="locale('payBtn')"
                        @click.native="subscribe"
                    )
                    span._balance-attempt(v-if="balance >= price") {{ locale("withBalance") }}

</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
// eslint-disable-next-line no-unused-vars
import { mutationSubscribeToPlan } from "@/components/subscriptions/graphql/mutations";

export default {
    name: "SubscriptionsTableForm",
    components: { UiButton },
    props: {
        planId: {
            type: String,
            required: true,
        },
        planLevel: {
            type: String,
            required: true,
        },
        accent: {
            type: String,
            required: true,
        },
        price: {
            type: Number || String,
            required: true,
        },
    },
    data() {
        return {};
    },
    computed: {
        balance: (state) => state.$store.getters["user/balance"],
    },
    methods: {
        subscribe() {
            this.$apollo
                .mutate({
                    mutation: mutationSubscribeToPlan,
                    variables: {
                        plan: Number(this.planId),
                    },
                })
                .then((r) => {
                    console.log(this.balance, this.price);
                    if (this.balance < this.price) {
                        window.location.href = r.data.subscribeToPlan;
                    } else {
                        this.$bus.$emit("refreshSubscriptionsPlans");
                        this.$bus.$emit("refreshActiveSubscriptions");
                        this.$bus.$emit("showSuccessSubscription");
                    }
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1002: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Выбранный план не найден",
                                );
                                break;
                            }
                            case 1005: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Не выбран ни один план",
                                );
                                break;
                            }
                            default: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Неизвестная ошибка",
                                );
                            }
                        }
                    }
                })
                .finally(() => {
                    this.$emit("close");
                });
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-table-form.scss" />
