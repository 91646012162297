<template lang="pug">
  .landing-gift.section
    ._wrap.wrap
      ._caption
        h3._title Подарок для тебя
        ._description Сделай себе крутой аватар в разных стилях из своего фото бесплатно!
        portal-target(name="giftImage")._decor
        ._list
          a(href="https://vk.com/merlinface" target="_blank")._btn-box
            ui-button(variant="rounded")._btn
              template(#content)
                ._btn-inner
                  svg-icon(icon-name="vk")._btn-icon
                  span Сделать аватар ВКонтакте
          a(href="https://t.me/ava_mf_gen_bot" target="_blank")._btn-box
            ui-button(variant="rounded")._btn
              template(#content)
                ._btn-inner
                  svg-icon(icon-name="telegram-fill")._btn-icon
                  span Сделать аватар в Telegram
          a(href="https://www.instagram.com/merlin.face" target="_blank")._btn-box
            ui-button(variant="rounded")._btn
              template(#content)
                ._btn-inner
                  svg-icon(icon-name="instagram")._btn-icon
                  span Сделать аватар в Instagram
      portal(to="giftImage" :disabled="pageWidth >= 768")._decor
        img(src="./images/gift-decor.png")._decor
</template>
<script>
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "LandingGift",
    components: { UiButton },
};
</script>

<style lang="scss" src="./landing-gift.scss"></style>
