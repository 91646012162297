<template lang="pug">
    .subscriptions-table-info
        template(v-if="isPlanExists && canShowInfo")
            ._card(:class="{ 'subscriptions-table-info__card_active': isFormShown }")
                ._card-inner
                    ._card-front
                        ._content
                            ._info(v-if="!isPlanActive")
                                ._price(:style="{ 'color': accent }")
                                    ._price-value {{ getMonthPrice }}
                                        span._price-currency &nbsp;₽
                                    ._price-period {{ getMonthLabel }}
                                    ._price-annual(v-if="isAnnualPeriod")
                                        | ({{ price }}
                                        span._price-annual-currency &nbsp;₽
                                        | &nbsp; {{ periodLabel }})
                                ._discount(v-if="discount > 0 && discount < 100 && periodType !== 'MONTHLY'" v-html="locale('discount', {discount: yearDiscount})")
                            ._info(v-else) {{ locale("active") }}

                            ._action(v-if="!(activePlanLevel === planLevel && activePlanPeriod === periodType)")
                                subscriptions-table-form(
                                    :plan-id="planId"
                                    :plan-level="planLevel"
                                    :price="getMonthPrice"
                                    :accent="accent"
                                )

</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
import {
    getSubscriptionPeriodLabel,
    subscriptionsPeriods,
} from "@/components/subscriptions/settings/subscriptions-periods";
import SubscriptionsTableForm from "@/components/subscriptions/components/subscriptions-table-form/subscriptions-table-form";
import { subscriptionsPlans } from "@/components/subscriptions/settings/subscriptions-plans";

export default {
    name: "SubscriptionsTableInfo",
    components: { SubscriptionsTableForm, UiButton },
    props: {
        planId: {
            type: String || null,
            required: false,
            default: null,
        },
        planLevel: {
            type: String,
            required: true,
        },
        periodType: {
            type: String,
            required: true,
        },
        price: {
            type: Number || null,
            required: false,
            default: null,
        },
        discount: {
            type: Number,
            required: false,
            default: 0,
        },
        accent: {
            type: String || null,
            required: false,
            default: null,
        },
        activePlanId: {
            type: String || null,
            required: false,
            default: null,
        },
        activePlanLevel: {
            type: String || null,
            required: false,
            default: null,
        },
        activePlanPeriod: {
            type: String || null,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            isFormShown: false,
        };
    },
    computed: {
        period() {
            return subscriptionsPeriods[this.periodType];
        },
        periodLabel() {
            return getSubscriptionPeriodLabel(this.periodType);
        },
        getMonthPrice() {
            switch (this.periodType) {
                case subscriptionsPeriods.MONTH: {
                    return this.price;
                }
                case subscriptionsPeriods.ANNUAL: {
                    return Math.ceil(this.price / 12);
                }
                default: {
                    return this.price;
                }
            }
        },
        getMonthLabel() {
            return getSubscriptionPeriodLabel(subscriptionsPeriods.MONTH);
        },
        isAnnualPeriod() {
            return this.periodType === subscriptionsPeriods.ANNUAL;
        },
        isPlanExists() {
            return this.planId !== null;
        },
        canShowInfo() {
            const isRealPlan = this.isPlanExists;
            const isPlanNotActive = this.planId !== this.activePlanId;
            const isSamePeriod = this.periodType === this.activePlanPeriod;
            const isPeriodWeakerThanActive =
                this.periodType === subscriptionsPeriods.MONTH &&
                this.activePlanPeriod === subscriptionsPeriods.ANNUAL;
            const isPlanWeakerThanActive =
                this.planLevel === subscriptionsPlans.GOLD.id &&
                this.activePlanLevel === subscriptionsPlans.PLATINUM.id;

            return (
                isRealPlan &&
                isPlanNotActive &&
                !isPeriodWeakerThanActive &&
                !(isPlanWeakerThanActive && isSamePeriod)
            );
        },
        yearDiscount() {
            // т.к. нет доступа к цене за месяц, то сначала считаем, сколько стоит месяц, а только потом общую скидку(-5 сделано из-за погрешности)
            // @TODO: переделать на адекватные подсчёты
            return Math.ceil(
                ((this.getMonthPrice * 100) / (100 - this.discount)) *
                    (this.discount / 100) *
                    12 -
                    5,
            );
        },
        subscriptionCategories: (state) =>
            state.$store.getters["user/subscriptionCategories"],
        isPlanActive: (state) =>
            state.subscriptionCategories.find(
                (c) => Number(c.planId) === Number(state.planId),
            ),
    },
    watch: {
        planId() {
            if (this.isFormShown) this.toggleSubscriptionForm();
        },
    },
    methods: {
        toggleSubscriptionForm() {
            this.isFormShown = !this.isFormShown;
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-table-info.scss" />
