import Vue from "vue";

export default Vue.mixin({
    data() {
        return {};
    },
    computed: {
        tips: (state) => state.$store.getters["tips/list"],
        tipsViewType: (state) => (state.isMobileView ? "mobile" : "desktop"),
        tipsActive: (state) => state.$store.getters["tips/show"],
        tipsType: (state) => state.$store.getters["tips/type"],
        activeTips: (state) =>
            state.tips.find(
                (help) =>
                    help.name === state.tipsType &&
                    help.showOn.indexOf(state.tipsViewType) !== -1,
            ),
    },
    methods: {
        async showTips(type, direction) {
            if(!this.myUser.tips_enabled) {
                return;
            }
            if (!localStorage.tips) localStorage.tips = JSON.stringify([]);

            let tip = this.tips.find((tip) => tip.name === type);
            let position = document.querySelector(tip.element);
            let skip = localStorage.getItem("tips");

            // Выключаем показ, когда пользователь отключил подсказки
            if (skip === "all") {
                localStorage.setItem("tips", "all");
                return;
            }

            skip = JSON.parse(skip);

            if (skip.length) {
                if (skip.indexOf(type) !== -1) {
                    // Если следующей подсказки нет - вырубаем
                    if (!tip.next) return;
                    // Показываем следующую подсказку, если текущая была закрыта
                    await this.showTips(tip.next);
                    return;
                }
            }

            if (position === null) {
                skip.push(type);
                localStorage.tips = JSON.stringify(skip);

                const skipIndex = skip.indexOf(tip[direction]);
                if (skipIndex !== -1) {
                    skip.splice(skipIndex, 1);
                    localStorage.tips = JSON.stringify(skip);
                }

                await this.showTips(tip[direction]);
                return;
            }

            setTimeout(() => {
                this.$store.dispatch("tips/setPositions", position);
                this.$store.dispatch("tips/show", type);
                if (tip.hideAfterShow) {
                    skip.push(type);
                    localStorage.tips = JSON.stringify(skip);
                }
            }, direction ? 0 : tip.timeout);
        },
    },
});
