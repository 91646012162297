<template lang="pug">
    .user-dropdown
        ._inner(@click="dropdownActive = !dropdownActive" v-click-outside="outsideClick")
            ._avatar-box
                user-photo(:photo="myPerson.photos.avatar")._avatar
            ._name Я
            svg-icon(icon-name="chevron-down")._chevron-icon
        transition(name="fade")
            ._list(v-if="dropdownActive")
                router-link._item(v-for="item in linkList" :key="item.to" :to="item.to") {{ item.name }}
                ._item(v-if="isAdmin" @click="copy(myPerson.uuid)") Скопировать Person UUID
                ._item(v-if="isAdmin" @click="copy(myUser.uuid)") Скопировать User UUID
                ._item(v-if="isAdmin" @click="copyToken") Скопировать токен
                ._item(v-if="isAdmin" @click="resetToken") Сбросить токен
                ._item(@click="logout") Выход
</template>

<script>
import UserPhoto from "../../ui/user-photo/user-photo.vue";

export default {
    name: "UserDropdown",
    components: { UserPhoto },
    data() {
        return {
            dropdownActive: false,
        };
    },
    computed: {
        linkList: (state) => [
            {
                name: "Мой профиль",
                to: `/person/${state.myPerson.uuid}`,
            },
            {
                name: "Настройки",
                to: "/settings",
            },
        ],
    },
    watch: {
        "route.name"() {
            console.log("change");
            this.dropdownActive = false;
        },
    },
    methods: {
        resetToken() {
            this.$store
                .dispatch("auth/obtainNewToken", {
                    gatewayClient: this.$apollo,
                })
                .then((response) => {
                    const responseData = response.data.refreshToken;
                    this.$store.dispatch("auth/successAuth", {
                        jwt: responseData.jwt,
                        refresh_token: responseData.refresh_token,
                        user_id: responseData.user_uuid,
                    });
                    return responseData.jwt;
                });
        },
        copyToken() {
            const token = localStorage.getItem("token");
            if (window.isSecureContext) {
                navigator.clipboard.writeText(token);
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = token;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    document.execCommand("copy");
                } catch (err) {
                    console.error("Unable to copy to clipboard", err);
                }
                document.body.removeChild(textArea);
            }
        },
        copy(text) {
            if (window.isSecureContext) {
                navigator.clipboard.writeText(text);
            } else {
                const textArea = document.createElement("textarea");
                textArea.value = text;
                document.body.appendChild(textArea);
                textArea.focus();
                textArea.select();
                try {
                    document.execCommand("copy");
                } catch (err) {
                    console.error("Unable to copy to clipboard", err);
                }
                document.body.removeChild(textArea);
            }
        },
        outsideClick: function () {
            this.dropdownActive = false;
        },
        async logout() {
            await this.$store.dispatch("auth/signout");
            await this.$router.push("/");
            this.$router.go();
        },
    },
};
</script>

<style lang="scss" src="./user-dropdown.scss"></style>
