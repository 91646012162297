<template lang="pug">
    header.landing-header.section
        ._wrap.landing-wrap
            svg-icon(icon-name="landing-logo" @click.native="scrollTop()")._logo
            nav._nav
                span._nav-item(@click="$emit('update-tab', 'about')") Что такое Merlinface
                span._nav-item(@click="$emit('update-tab', 'gpt')") Что умеет MerlinGPT
                span._nav-item(@click="$emit('update-tab', 'plans')") Тарифы
                span._nav-item(@click="$emit('update-tab', 'get')") Что вы получите
                span._nav-item(@click="$emit('update-tab', 'steps')") Как начать
            router-link(to="/welcome/login")  
              ui-button(type="bordered" color="gray" variant="rounded" text="Войти")._btn
</template>
<script>
import UiButton from "@/components/ui/ui-button/ui-button";
import LangSwitcher from "@/components/app/lang-switcher/lang-switcher.vue";

export default {
    name: "LandingHeader",
    components: { LangSwitcher, UiButton },
    props: {
        fixed: {
            type: Number,
            default: 0,
            required: true,
        },
        activeTab: {
            type: String,
            default: "",
            required: true,
        },
    },
    methods: {
        scrollTop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth",
            });
        },
    },
};
</script>

<style lang="scss" src="./landing-header.scss"></style>
