<template lang="pug">
    section.ai-examples.section
        ._wrap.wrap
            h2._title {{ title }}
            ._list
                img(v-for="(image, index) in photos" :key="index" :src="image.small_image ? image.small_image : image.image" :alt="`${image.prompt ? `Сгенерировано по запросу: ${image.prompt}` : 'Фото сгенерировано нейросетью'}`")._image
            ._footer
                ._count Уже сгенерировано более 400 000 картинок
                router-link(to="/welcome/login")
                    ui-button(variant="rounded" text="Сгенерировать изображение")._btn
</template>

<script>
import gql from "graphql-tag";
import getAIImagesQuery from "@/graphql/queries/getAIImages.query.graphql";
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "AiExamples",
    components: { UiButton },
    props: {
        ai: {
            type: String,
            default: "chatGPT",
            required: false,
        },
    },
    data: () => {
        return {
            photos: [],
        };
    },
    computed: {
        title: state => state.$t(`AILanding.aiExamples.titles.${state.ai}`),  
    },
    mounted() {
        if (this.ai === "stableDiffusion" || this.ai === "main") {
            this.getStableDiffusionImages();
        }
        if (this.ai === "midjourney") {
            this.getMidjourneyImages();
        }
    },
    methods: {
        async getStableDiffusionImages() {
            await this.$apollo
                .query({
                    query: gql(getAIImagesQuery),
                    client: "chatClient",
                    variables: {
                        count: 8,
                        type: "generated-by-stable-diffusion",
                    },
                })
                .then((r) => {
                    this.photos = r.data.getAIImages;
                });
        },
        async getMidjourneyImages() {
            await this.$apollo
                .query({
                    query: gql(getAIImagesQuery),
                    client: "chatClient",
                    variables: {
                        count: 8,
                        type: "generated-by-midjorney",
                    },
                })
                .then((r) => {
                    this.photos = r.data.getAIImages;
                });
        },
    },
};
</script>

<style lang="scss" src="./ai-examples.scss"></style>
