<template lang="pug">
    .user-photo(:class="type")
        img(:src="$asThumb(photo, micro, thumbs)" v-if="photo" @load="$emit('loaded')" alt="Avatar")._image
        ._no-image(v-else)
            svg-icon(:icon-name="customNoAvatar" :class="{'with-border': avatarBorder}")._no-image-icon
</template>

<script>
export default {
    name: "UserPhoto",
    props: {
        photo: {
            type: [String, Object],
            required: false,
            default: "",
        },
        micro: {
            type: Boolean,
            required: false,
            default: true,
        },
        thumbs: {
            type: Boolean,
            required: false,
            default: true,
        },
        avatarBorder: {
            type: Boolean,
            required: false,
            default: true,
        },
        type: {
            type: String,
            required: false,
            default: "",
        },
        customNoAvatar: {
            type: String,
            required: false,
            default: "profile"
        }
    },
    mounted() {
        if (!this.photo) {
            this.$emit("loaded");
        }
    },
};
</script>

<style lang="scss" scoped>
@import "user-photo.scss";
</style>
