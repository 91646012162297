export const subscriptionsPeriods = {
    MONTH: "MONTHLY",
    ANNUAL: "ANNUALLY",
};

const lang = localStorage.getItem("lang") || "ru";

import ru from "../../../localization/ru";
import en from "../../../localization/en";
import uz from "../../../localization/uz";
import tj from "../../../localization/tj";
import kz from "../../../localization/kz";
import az from "../../../localization/az";
import kg from "../../../localization/kg";

const localization = {
    ru,
    en,
    uz,
    kz,
    tj,
    az,
    kg
};

export const getSubscriptionPeriodLabel = (period) => {
    switch (period) {
        case subscriptionsPeriods.MONTH: {
            return localization[lang].SubscriptionPeriodLabel.MONTH;
        }
        case subscriptionsPeriods.ANNUAL: {
            return localization[lang].SubscriptionPeriodLabel.ANNUAL;
        }
        default: {
            throw new Error("Wrong subscriptions period type");
        }
    }
};

export const getSubscriptionPeriodOptions = () => [
    {
        id: subscriptionsPeriods.MONTH,
        label: localization[lang].SubscriptionPeriodOptions.MONTH,
    },
    {
        id: subscriptionsPeriods.ANNUAL,
        label: localization[lang].SubscriptionPeriodOptions.ANNUAL,
    },
];
