export const subscriptionsPlansIds = {
    FREE: "FREE",
    GOLD: "GOLD",
    PLATINUM: "PLATINUM",
    JUNIOR: "JUNIOR",
};

export const subscriptionsPlans = {
    [subscriptionsPlansIds.FREE]: {
        id: subscriptionsPlansIds.FREE,
        label: "Free",
        accent: null,
        accentBg: "#222222",
    },
    [subscriptionsPlansIds.GOLD]: {
        id: subscriptionsPlansIds.GOLD,
        label: "Gold",
        accent: "#f8e5ad",
        accentBg: "#272622",
    },
    [subscriptionsPlansIds.PLATINUM]: {
        id: subscriptionsPlansIds.PLATINUM,
        label: "Platinum",
        accent: "#b5dbff",
        accentBg: "#1f2123",
    },
    [subscriptionsPlansIds.JUNIOR]: {
        id: subscriptionsPlansIds.JUNIOR,
        label: "Junior",
        accent: "#da73c6",
        accentBg: "red",
    },
};

export const subscriptionsPlansList = Object.values(subscriptionsPlans);
