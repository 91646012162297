<template lang="pug">
    perfect-scrollbar.welcome-layout
        ._wrap(:class="routeName")
            main-header._header
            ._content
                ._ctrl-box(v-if="ctrlStep < 6 && isUserAuth && +selectedOnboardingType === 60")
                    ._ctrl
                        ._ctrl-item(:class="[{active: ctrlStep === 1}, {completed: ctrlStep > 1}]") {{ locale("ctrl.step-1") }}
                            svg-icon(icon-name="check")._ctrl-success-icon
                        ._ctrl-item(:class="[{active: ctrlStep === 2}, {completed: ctrlStep > 2}]") {{ locale("ctrl.step-2") }}
                            svg-icon(icon-name="check")._ctrl-success-icon
                        ._ctrl-group
                            ._ctrl-item(:class="[{active: ctrlStep === 3}, {completed: ctrlStep > 3}]")
                                svg-icon(icon-name="check")._ctrl-success-icon
                            ._ctrl-item(:class="[{active: ctrlStep === 5}]")
                                svg-icon(icon-name="check")._ctrl-success-icon
                            ._ctrl-group-name(:class="[{active: (ctrlStep === 3 || ctrlStep === 5)}]") {{ dynamicCtrlItem }}
                router-view
                transition(name="fadeInUp" appear)
                    cookie(v-if="!cookie" @acceptCookies="acceptCookies")

            //._back(@click="confirmRedirectToLanding()" v-if="isUserAuth && $route.fullPath !== '/welcome/phone' && $route.fullPath !== '/welcome/name'")
            //    svg-icon(icon-name="chevron-left")._back-icon
            //    span._back-text {{ locale("back-to-main") }}
            confirm-dialog(
                v-if="confirmDialog.show",
                :loading="confirmDialog.loading",
                :title="confirmDialog.title",
                :text="confirmDialog.text",
                :price="confirmDialog.price",
                :buttons="confirmDialog.buttons",
                :cancelText="confirmDialog.cancelText",
                :confirmText="confirmDialog.confirmText",
                :type="confirmDialog.type",
                :comparisonCount="confirmDialog.comparisonCount",
                :comparisonTimer="confirmDialog.comparisonTimer",
                :callback="confirmDialog.callback",
                @cancel="confirmDialog.show = false",
                @confirm="emitConfirmAction(confirmDialog.type)"
            )
</template>

<script>
import Cookie from "@/components/app/cookie/cookie";
import MainHeader from "@/components/app/main-header/main-header";
import LayoutDecor from "@/components/ui/layout-decor/layout-decor";
import ConfirmDialog from "@/components/confirm-dialog/confirm-dialog";
import CompareResultDialog from "@/components/compare-result-dialog/compare-result-dialog";

export default {
    name: "WelcomeLayout",
    components: {
        CompareResultDialog,
        ConfirmDialog,
        LayoutDecor,
        MainHeader,
        Cookie,
    },
    data() {
        return {
            cookie: false,
            showBigCircle: true,
            showMediumCircle: true,
            showSmallCircle: true,
            selectedOnboardingType: 0,
        };
    },
    computed: {
        ctrlStep: state => state.$store.getters.onboardingCtrlStepNumber,
        dynamicCtrlItem: state => state.ctrlStep === 3 ? state.locale("ctrl.step-3") : state.locale("ctrl.step-4"),
        routeName: (state) => state.$route.name,
        nextOnboardingStepIndex: state => state.$store.getters.nextOnboardingStepIndex,
        showOuterWave: function () {
            return (
                [
                    "Login",
                    "Welcome-want",
                    "Welcome-profession-vote",
                    "Welcome-talents",
                    "Welcome-professions",
                ].indexOf(this.routeName) > -1
            );
        },
        showInnerLineWave: function () {
            return (
                [
                    "Welcome-want",
                    "Welcome-profession-vote",
                    "Welcome-talents",
                    "Welcome-professions",
                ].indexOf(this.routeName) > -1
            );
        },
        showInnerWave: function () {
            return (
                ["Welcome-profession-vote", "Welcome-talents"].indexOf(
                    this.routeName,
                ) > -1
            );
        },
        confirmDialog: (state) => state.$store.getters["confirmDialog"],
        compareResultDialog: (state) =>
            state.$store.getters["compareResultDialog"],
        page: (state) => state.$store.getters["page"],
    },
    async mounted() {
        let cookieStatus = localStorage.cookie
            ? JSON.parse(localStorage.cookie)
            : false;
        if (cookieStatus) {
            this.cookie = true;
        }
        if (!this.myPerson.uuid) return;
        if (this.myRoles.indexOf("ROLE_NO_PHONE") >= 0) return;
        if (this.myUser.display_name === "Newbie") {
            return;
        }
        if (this.myUser.on_boarding_completed) {
            await this.$router.push(this.myPerson.uuid);
        } else {
            await this.getSelectedOnboardingType();
        }
    },
    methods: {
        async getSelectedOnboardingType() {
            const selectedOnBoardingType = await this.getOnBoardingCompletedSteps();
            const firstOnBoardingStep = selectedOnBoardingType.find(
                (step) => step.step_number === 1,
            );

            this.selectedOnboardingType = firstOnBoardingStep.step_result_int;
        },
        acceptCookies() {
            this.cookie = true;
            localStorage.cookie = true;
        },
        emitConfirmAction(type) {
            this.$bus.$emit("confirmDialogAction", type);
        },
        async toLanding() {
            await this.$store.dispatch("auth/signout");
            await this.$router.push("/");
            this.$router.go();
        },
        confirmRedirectToLanding() {
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: this.locale("back-to-main-confirm-title"),
                text: this.locale("back-to-main-confirm-desc"),
                buttons: true,
                confirmText: this.locale("back-to-main-confirm-apply"),
                callback: this.toLanding,
            });
        }
    },
};
</script>

<style src="./welcome-layout.scss" lang="scss" scoped></style>
