import gql from "graphql-tag";

export const friendMixins = {
    methods: {
        friendsCount() {
            let query = gql`
                query {
                    friendsCount
                }
            `;
            return this.$apollo.query({
                query: query,
                fetchPolicy: "no-cache",
            });
        },
        friendRequestsCount() {
            if(!this.isUserAuth) return;
            let query = gql`
                query {
                    friendRequestsCount
                }
            `;
            return this.$apollo.query({
                query: query,
                fetchPolicy: "no-cache",
            });
        },
        async getPersons(type, page, itemsPerPage, filter) {
            let query;
            if (type === "friends") {
                query = gql`
                    query friendList(
                        $page: Int
                        $itemsPerPage: Int
                        $filter: PersonListFilterInput
                    ) {
                        friendList(
                            page: $page
                            itemsPerPage: $itemsPerPage
                            filter: $filter
                        ) {
                            requestId
                            userId
                            personId
                            personName
                            personAvatar
                            personLeaderType
                            personPublicUrl
                            personCompare {
                                compareType
                                value
                            }
                            personIndicator {
                                indicatorType
                                value
                            }
                            analysesAvailable
                        }
                    }
                `;
            } else if (type === "REQUESTS") {
                console.log(filter);
                query = gql`
                    query friendRequests(
                        $page: Int
                        $itemsPerPage: Int
                        $filter: PersonListFilterInput
                    ) {
                        friendRequests(
                            page: $page
                            itemsPerPage: $itemsPerPage
                            filter: $filter
                        ) {
                            requestId
                            userId
                            personId
                            personName
                            personAvatar
                            personPublicUrl
                        }
                    }
                `;
            } else if (type === "celebrity") {
                query = gql`
                    query celebritiesList(
                        $page: Int
                        $itemsPerPage: Int
                        $filter: PersonListFilterInput
                    ) {
                        celebritiesList(
                            page: $page
                            itemsPerPage: $itemsPerPage
                            filter: $filter
                        ) {
                            total
                            list {
                                personId
                                personName
                                personAvatar
                                personLeaderType
                                personPublicUrl
                                personCompare {
                                    compareType
                                    value
                                }
                                personIndicator {
                                    indicatorType
                                    value
                                }
                                analysesAvailable
                                allFeaturesFilled
                            }
                        }
                    }
                `;
            } else {
                query = gql`
                    query analyzedWithMePersonsList(
                        $page: Int
                        $itemsPerPage: Int
                        $filter: PersonListFilterInput
                    ) {
                        analyzedWithMePersonsList(
                            page: $page
                            itemsPerPage: $itemsPerPage
                            filter: $filter
                        ) {
                            total
                            list {
                                personId
                                personName
                                personAvatar
                                personLeaderType
                                personCompare {
                                    compareType
                                    value
                                }
                                personIndicator {
                                    indicatorType
                                    value
                                }
                                analysesAvailable
                            }
                        }
                    }
                `;
            }

            return this.$apollo.query({
                query: query,
                variables: {
                    page,
                    itemsPerPage,
                    filter,
                },
                fetchPolicy: "no-cache",
            });
        },
        //$conversationId: Int!, $message: String!, $image: String
        friendRequests(page, itemsPerPage, filter) {
            let query = gql`
                query friendRequests(
                    $page: Int
                    $itemsPerPage: Int
                    $filter: PersonListFilterInput
                ) {
                    friendRequests(
                        page: $page
                        itemsPerPage: $itemsPerPage
                        filter: $filter
                    ) {
                        requestId
                        userId
                        personId
                        personName
                        personAvatar
                    }
                }
            `;
            return this.$apollo.query({
                query: query,
                variables: {
                    page,
                    itemsPerPage,
                    filter,
                },
                fetchPolicy: "no-cache",
            });
        },
    },
};
