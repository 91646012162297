<template lang="pug">
    modal(name="Promocode" class="promocode-modal-wrap" :adaptive="true" width="100%" height="100%")
        .promocode-modal
            ._wrap
                ._content
                    ._content-inner
                        form._form(@submit.prevent="activate")
                            ._close-btn(@click="$modal.hide('Promocode')")
                                svg-icon(icon-name="cross")._close-icon
                            ._form-title Введите промокод
                            input(v-model="code" placeholder="Промокод")._input
                            ._error(v-if="isError") Недействительный промокод
                            ._success(v-if="isSuccess") Промокод успешно активирован. Вам добавлено {{ addCount }} кредитов
                            ui-button(color="gray" type="bordered" text="Активировать")._btn
</template>

<script>
import UiButton from "../../ui/ui-button/ui-button.vue";
import activateCuratorPromocodeMutation from "@/graphql/mutations/activateCuratorPromocode.mutation.graphql";
import gql from "graphql-tag";

export default {
    name: "PromocodeModal",
    components: { UiButton },
    data() {
        return {
            code: "",
            isError: false,
            isSuccess: false,
            addCount: 0,
        };
    },
    computed: {},
    mounted() {},
    methods: {
        activate() {
            this.isError = false;
            this.isSuccess = false;
            this.$apollo
                .mutate({
                    mutation: gql(activateCuratorPromocodeMutation),
                    variables: {
                        promocode: this.code,
                    },
                })
                .then((r) => {
                    this.addCount = +r.data.activateCuratorPromocode;
                    this.$store.dispatch("user/addMessageAttempts", +r.data.activateCuratorPromocode);
                    this.code = "";
                    this.isSuccess = true;
                })
                .catch(() => {
                    this.isError = true;
                });
        },
    },
};
</script>

<style lang="scss" src="./promocode-modal.scss"></style>
