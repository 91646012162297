<template lang="pug">
  modal(name="camera" class="camera-modal" classes="camera-modal-wrap" :adaptive="true" width="100%" height="100%")
    .camera-modal
      ._container
        ._cross-btn(@click="hideModal")
          svg-icon(icon-name="cross")._cross-icon
        ._content
          slot(name="default")

</template>

<script>
export default {
    name: "CameraModal",
    mounted() {
        this.$modal.show("camera");
    },
    beforeDestroy() {
        this.$modal.hide("camera");
    },
    methods: {
        hideModal() {
            this.$modal.hide("camera");
            this.emitClose();
        },
        emitClose() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" src="./camera-modal.scss" />
