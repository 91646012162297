<template lang="pug">
    modal(
        name="subscription"
        class="subscription-modal-wrap"
        :adaptive="true"
        width="100%"
        height="100%"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    )
        perfect-scrollbar.subscription-modal
            ._close-btn(@click="$modal.hide('subscription')")
                svg-icon(icon-name="cross")._cross
            ._content
                subscriptions(:initial-category-id="categoryId")

</template>

<script>
import Subscriptions from "@/components/subscriptions/subscriptions";

export default {
    name: "SubscriptionModal",
    components: { Subscriptions },
    data() {
        return {
            categoryId: null,
        };
    },
    methods: {
        beforeOpen(event) {
            this.disableBodyScroll();
            const categoryId = event.params?.categoryId;
            if (categoryId) {
                this.categoryId = event.params.categoryId;
            }
        },
        beforeClose() {
            this.enableBodyScroll();
        },
    },
};
</script>

<style lang="scss" src="./subscription-modal.scss" />
