<template lang="pug">
    transition(name="app-message")
        .app-message(:class="type")
            ._close-btn(@click="closeMsgBox")
                svg-icon(icon-name="cross")._cross-icon
            ._icon-box
                svg-icon(icon-name="msgBoxError")
            ._info
                ._title {{ title }}
                ._text {{ message || localeError("unknown-error") }}
</template>

<script>
//import Sentry from "@sentry/vue";
import UiButton from "@/components/ui/ui-button/ui-button.vue";

export default {
    name: "AppMessage",
    components: {UiButton},
    props: {
        title: {
            require: true,
            default: "",
            type: String
        },
        message: {
            require: false,
            default: () => this.localeError("unknown-error"),
            type: String
        },
        type: {
            default: "info",
            require: false,
            type: String
        },
        eventId: {
            default: "",
            require: false,
            type: [String, Number]
        },
    },
    methods: {
        closeMsgBox() {
            this.$store.dispatch("setMsgBoxStatus", false);
        },
        openReportModal() {
            this.$modal.show("report");
            this.closeMsgBox();
        }
    },
};
</script>

<style lang="scss" scoped>
@import "app-message";
</style>
