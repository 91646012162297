import { render, staticRenderFns } from "./friend-actions.vue?vue&type=template&id=6933ab77&scoped=true&lang=pug&"
import script from "./friend-actions.vue?vue&type=script&lang=js&"
export * from "./friend-actions.vue?vue&type=script&lang=js&"
import style0 from "./friend-actions.vue?vue&type=style&index=0&id=6933ab77&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6933ab77",
  null
  
)

export default component.exports