<template lang="pug">
    .notifications-module(v-click-outside="outsideClick" :class="{'notifications-module_full': type}" data-tip="notifications")
        ._group
            ._icon-box(@click="openPopup")
                svg-icon(icon-name="notifications" :class="{'notifications-module__icon_active': unreadNotifications, 'notifications-module__icon_popup-active': popupActive}")._icon
                span._count(v-if="unreadNotifications" v-show="!popupActive") {{ unreadNotifications }}
            ._name(:class="{'notifications-module__name_active': popupActive}") {{ locale ("all") }}
        transition(name="show-notifications")
            ._popup(v-if="popupActive")
                ._popup-top-left-decor
                ._popup-top-right-decor
                ._popup-bottom-left-decor
                ._popup-bottom-right-decor
                perfect-scrollbar(ref="scrollWrapper" infinite-wrapper)._popup-content
                    ._notification(v-for="(notification, index) in notifications" :key="index" :class="[{'notifications-module__notification_special': notification.have_read === false}, {'notifications-module__notification_bordered': notificationsDates[index] === notificationsDates[index - 1]}]")
                        span._notification-date(v-if="notificationsDates[index] !== notificationsDates[index-1]") {{notificationsDates[index]}}
                        ._notification-icon(v-if="notification.category.name !== 'merlinapp_referral_created'")
                            svg-icon(:icon-name="getIcon(notification.category.name)")._icon
                        ._notification-avatar(v-else-if="notification.sender")
                            img(v-if="!notification.sender.person.photos.avatar" :src="notification.sender.person.photos.avatar")
                            ._no-avatar(v-else)
                                svg-icon(icon-name="no-avatar")._no-avatar-icon
                        ._notification-info
                            ._notification-title
                                template(v-if="notification.category.name === 'coach_partner_request'") {{ locale ("partner") }}

                                template(v-if="notification.category.name === 'deposit'" )
                                    | {{ locale ("deposit") }}

                                template(v-else-if="notification.category.name === 'bonus'" )
                                    | {{ locale ("bonus") }}

                                template(v-else-if="notification.category.name === 'referral_reward'" )
                                    | {{ locale ("reward") }}

                                template(v-else-if="notification.category.name === 'merlinapp_referral_created'")
                                    span(v-html="locale('referral', {name: notification.sender ? notification.sender.display_name : 'Кто-то'})")

                                template(v-else-if="notification.category.name === 'merlinapp_referral_subscription_trial_period_activated'" )
                                    span(v-html="locale('free-period', {message: notification.message, name: nnotification.sender.display_name ? notification.sender.display_name : locale('newbiew', {}, true)})")

                                template(v-else-if="notification.category.name === 'merlinapp_referral_subscription_cancelled'" )
                                    span(v-html="locale('cancel-subscribe', {message: notification.message, name: nnotification.sender.display_name ? notification.sender.display_name : locale('newbiew', {}, true)})")

                                template(v-else-if="notification.category.name === 'merlinapp_referral_reward_received'" )
                                    span(v-html="locale('pay-subscribe', {message: notification.message, name: nnotification.sender.display_name ? notification.sender.display_name : locale('newbiew', {}, true)})")

                                template(v-else-if="notification.category.name === 'merlinapp_referral_subscription_failed_to_extend'" )
                                    span(v-html="locale('subscribe-error', {message: notification.message, name: nnotification.sender.display_name ? notification.sender.display_name : locale('newbiew', {}, true)})")

                                template(v-else-if="notification.category.name === 'mapp_celebrity_compare'" )
                                    | {{ locale ("mapp_celebrity_compare") }}

                                template(v-else-if="notification.category.name === 'bonus_coach_partner'" )
                                    | {{ locale ("bonus_coach_partner") }}

                                template(v-else-if="notification.category.name === 'person_compare' || notification.category.name === 'bonus_person_compare'" )
                                    | {{ locale ("person_compare") }} {{ locale (notification.subcategory.name) }}

                                template(v-else-if="notification.category.name === 'person_indicator'" )
                                    | {{ locale ("person_indicator") }} {{ locale (notification.subcategory.name) }}

                                template(v-else-if="notification.category.name === 'analyse_item_unlock'" )
                                    | {{ locale ("analyse_item_unlock") }} {{ locale (notification.subcategory.name) }}

                                template(v-else-if="notification.category.name === 'person_review_created'" )
                                    | {{ locale ("person_review_created") }}

                                template(v-else-if="notification.category.name === 'new_in_top'" )
                                    | {{ locale ("new_in_top") }} +{{ notification.message }}

                                template(v-else-if="notification.category.name === 'phone_confirm'" )
                                    | {{ locale ("phone_confirm", {count: notification.message}) }}

                                template(v-else-if="notification.category.name === 'upload_photos_request'" )
                                    | {{ locale ("upload_photos_request") }}

                                template(v-else-if="notification.category.name === 'upload_photos_completed'" )
                                    | {{ locale ("upload_photos_completed", {user: splitStr(notification.message, 1)}) }}

                            ._notification-value
                                template(v-if="notification.category.name === 'coach_partner_request'") {{ locale ("upload_photos_completed", {user: notification.sender.display_name ? notification.sender.display_name : locale("newbie", {}, true)}) }}
                                    ._notification-ctrl-group
                                        button(type="button" @click="responsePartnerRequest('ADDED', notification.message)")._notification-ctrl {{ locale ("coach_partner_request_accept") }}
                                        button(type="button" @click="responsePartnerRequest('DECLINED', notification.message)")._notification-ctrl {{ locale ("coach_partner_request_decline") }}

                                template(v-else-if="$inArray(notification.category.name, ['analyse_item_unlock', 'bonus_person_compare', 'person_compare', 'person_indicator', 'bonus_coach_partner'])")
                                    | {{ locale ("analyse_item_unlock_reward", {bonus: notification.message}) }}

                                template(v-else-if="notification.category.name === 'new_in_top'")
                                    | {{ locale ("new_in_top_show") }}

                                template(v-else-if="notification.category.name === 'phone_confirm'")
                                    | {{ locale ("phone_confirm_reward") }}

                                template(v-else-if="notification.category.name === 'referral_reward'")
                                    | +{{ notification.message }} Р

                                template(v-else-if="notification.category.name === 'merlinapp_referral_created'")
                                template(v-else-if="notification.category.name === 'merlinapp_referral_subscription_trial_period_activated'")
                                template(v-else-if="notification.category.name === 'merlinapp_referral_subscription_cancelled'")
                                template(v-else-if="notification.category.name === 'merlinapp_referral_reward_received'")
                                template(v-else-if="notification.category.name === 'merlinapp_referral_subscription_failed_to_extend'")

                                template(v-else-if="notification.category.name === 'deposit'" )
                                    | {{ notification.message }} Р

                                template(v-else)

                    infinite-loading(:identifier="infiniteId" v-if="!isDrawing &&  (popupActive || type === 'full')" @infinite="showMore")
                        div(slot="spinner")._infinite-msg {{ locale("loading") }}
                        div(slot="no-more")
                            template(v-if="notifications.length > itemsPerPage" )
                                ._infinite-msg {{ locale("no-more") }}
                        div(slot="no-results")._infinite-msg
                            | {{ locale("no-notifications") }}

                router-link(:to="{name: 'notifications'}" v-if="type !== 'full'")._show-all {{ locale("show-all") }}
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";
import { notificationsMixins } from "../../../mixins/notificationsMixins";
import { mapGetters } from "vuex";
import { DateTime } from "luxon";

export default {
    name: "NotificationsModule",
    components: {
        InfiniteLoading,
    },
    mixins: [notificationsMixins],
    props: {
        type: {
            default: "",
            type: String,
            required: false,
        },
    },
    data: () => ({
        popupActive: false,
        popupActivatedJustNow: true,
        unreadNotifications: 0,
        notifications: [],
        notificationsDates: [],
        infiniteId: +new Date(),
        page: 1,
        itemsPerPage: 5,
        hasMore: true,
        isDrawing: false,
        startPooling: false,
    }),
    computed: {
        ...mapGetters({
            isMyUserLoaded: "user/isMyUserLoaded",
        }),
    },
    watch: {
        $route: function () {
            this.popupActive = false;
        },
        isMyUserLoaded: function () {
            setTimeout(() => {
                this.startPooling = true;
            }, 1000);
        },
    },
    mounted() {
        if (this.type === "full") {
            this.popupActive = true;
            this.itemsPerPage = 10;
        }
    },
    created() {
        this.$bus.$emit("notificationsCountUpdated", this.unreadNotifications);

        this.$bus.$on("openModal", (id) => {
            this.showModal(id);
        });
    },
    apollo: {
        notificationsCount: {
            query: notificationsMixins.queries.notificationsCount,
            result(response) {
                let count = 0;
                if (response.data.notificationsCount) {
                    count = response.data.notificationsCount;
                }

                if (count !== this.unreadNotifications) {
                    this.unreadNotifications = count;
                    this.$bus.$emit("notificationsCountUpdated", count);
                }
            },
            fetchPolicy: "network-only",
            pollInterval: 10000,
            skip() {
                return !this.startPooling;
            },
        },
    },
    destroyed() {
        this.$bus.$off("openModal");
    },
    methods: {
        responsePartnerRequest(type, requestId) {
            this.responsePartnerReq(type, parseInt(requestId))
                .then(() => {})
                .catch((e) => {
                    const errorCode = e.graphQLErrors[0].extensions.code;
                    let title = "error",
                        text = "retry";

                    if (errorCode === 1002) {
                        text = "partnerResponseAlreadySend";
                    }
                    if (errorCode === 1003) {
                        text = "noAccessText";
                    }
                    if (errorCode === 1006) {
                        text = "partnerResponseNotSend";
                    }

                    this.$msgBox(
                        this.localeError(title),
                        this.localeError(text),
                    );
                });
        },
        showModal(name) {
            this.$modal.show(name, { props: { allowComponent: true } });
        },
        showMore($state) {
            if (this.hasMore === false) {
                $state.complete();
                this.isDrawing = false;
                return;
            }

            this.notificationsList(this.page, this.itemsPerPage, true)
                .then((response) => {
                    console.log(response.data.notificationsList.notifications);
                    if (response.data.notificationsList.notifications.length) {
                        if (
                            this.popupActive &&
                            this.type !== "full" &&
                            this.popupActivatedJustNow === true
                        ) {
                            this.isDrawing = true;
                            this.popupActivatedJustNow = false;
                        }

                        this.page += 1;
                        response.data.notificationsList.notifications.forEach(
                            (notification) => {
                                this.notifications.push(notification);

                                // Creating notificationDates object and push the data
                                let dateFormat = {
                                    month: "long",
                                    day: "numeric",
                                };
                                let date = DateTime.fromISO(
                                    notification.created_at,
                                )
                                    .setLocale("ru")
                                    .toLocaleString(dateFormat);
                                this.notificationsDates.push(date);

                                if (
                                    notification.have_read === false &&
                                    this.unreadNotifications > 0
                                ) {
                                    this.unreadNotifications--;
                                }
                            },
                        );
                        $state.loaded();

                        this.hasMore = response.data.notificationsList.hasMore;
                        if (this.hasMore === false) $state.complete();

                        if (this.popupActive && this.type !== "full") {
                            // Delay for the popup to become visible, else infinite scroll wont work
                            setTimeout(() => {
                                this.isDrawing = false;
                            }, 500);
                        }
                    } else {
                        $state.complete();
                        this.hasMore = false;
                        this.isDrawing = false;
                    }
                })
                .catch(() => {
                    this.$msgBox(
                        this.localeError("unknownError"),
                        this.localeError(
                            "notificationsNotReceived",
                        ),
                    );
                });
        },
        getIcon(type) {
            const icons = {
                bonus: "gift",
                deposit: "wallet",
                person_review_created: "personality-profiles",
                bonus_person_compare: "user-gift",
                person_compare: "user-gift",
                person_indicator: "user-gift",
                analyse_item_unlock: "user-gift",
                referral_reward: "user-gift",
                merlinapp_referral_created: "user-gift",
                new_in_top: "heart",
                phone_confirm: "confirm-phone",
                upload_photos_request: "personality-profiles",
                upload_photos_completed: "personality-profiles",
                coach_partner_request: "handshake",
                bonus_coach_partner: "user-gift",
                mapp_celebrity_compare: "logo-icon",
                merlinapp_referral_subscription_trial_period_activated:
                    "logo-icon",
                merlinapp_referral_subscription_cancelled: "logo-icon",
                merlinapp_referral_reward_received: "logo-icon",
                merlinapp_referral_subscription_failed_to_extend: "logo-icon",
            };
            return icons[type];
        },
        openPopup: function () {
            if (this.type !== "full") {
                this.popupActive = true;
                this.popupActivatedJustNow = true;
            }
        },
        outsideClick: function () {
            if (this.type !== "full") {
                // to load new notifications
                this.page = 1;
                this.infiniteId += 1;
                this.notifications = [];
                this.popupActivatedJustNow = true;
                this.hasMore = true;
                this.isDrawing = false;
                this.popupActive = false;
            }
        },
        splitStr: function (str, index) {
            let split = str.split("|");

            return split[index] ? split[index] : null;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "notifications-module";
</style>
