<template lang="pug">
    .personal-data
        transition(name="slide")
            ._edit-profile(v-show="editProfile || !editPermitted")
                ._item(v-if="editPermitted")
                    ._input-wrap
                        label._input-label(:class="{link: !editName}" @click="editName = !editName") {{ (editName) ? 'Введите новое имя' : 'Изменить имя' }}
                        ._input-group(v-if="editName")
                            ._input-group-save
                                input(type="text" maxlength="255" v-model="personNameMutable" :class="{'personal-data__input_invalid': $v.personNameMutable.$dirty && !$v.personNameMutable.required}" style="width:auto")._input.-full
                                ._save-btn(@click="updatePersonData('name')")
                                    svg-icon(icon-name="check")
                            ._input-error(v-if="$v.personNameMutable.$dirty && !$v.personNameMutable.required") Введите имя личности

                ._item(v-if="editPermitted")
                    ._input-wrap
                        label._input-label(:class="{link: !editAddress}" @click="editAddress = !editAddress") {{ (editAddress) ? 'Короткий адрес страницы merlinface.com/' : 'Изменить адрес страницы' }}
                        ._input-group(v-if="editAddress")
                            ._input-group-save
                                input(type="text" v-model.trim="temporaryPublicAddress" placeholder="Введите адрес" :class="{'personal-data__date-picker-error': $v.temporaryPublicAddress.$error}")._input.-full
                                ._save-btn(@click="savePublicAddress" :class="{disabled: temporaryPublicAddress === publicAddress}")
                                    svg-icon(icon-name="check")
                        ._input-error(v-if="addressError") {{ addressError }}
                        ._input-error(v-if="!$v.temporaryPublicAddress.cyrillicExclusion && $v.temporaryPublicAddress.$dirty") Адрес страницы может содержать только латинские буквы, цифры и знаки «_» и «-» и не может быть короче 3-х или более 30-ти символов
                        ._input-error(v-if="temporaryPublicAddress > $v.temporaryPublicAddress.$params.maxLength.max && $v.temporaryPublicAddress.$dirty") Адрес не должен быть длиннее {{$v.temporaryPublicAddress.$params.maxLength.min}} символов

                ._item
                    ._input-wrap
                        label._input-label Пол:
                        ._input-group(v-if="editPermitted")
                            Select(:placeholder="genderName" :value="genderId" field-id="genderId" field-name="genderName" :options="genderList" @changeModel="changeModel")
                        ._person-data-value(v-else) {{ genderName }}

                ._item
                    ._input-wrap
                        label._input-label(v-if="editPermitted") Дата рождения:
                        ._input-label(v-else) Возраст:
                        ._input-group(v-if="editPermitted")
                            date-picker(:class="{'personal-data__date-picker-error': $v.date_of_birth.$dirty && !$v.date_of_birth.mustBeDate}" v-model="date_of_birth" type="date" prefix-class="merlinface" valueType="YYYY-MM-DD" format="DD.MM.YYYY" :default-value="date_of_birth || new Date()" value-type="date" :clearable="false" :lang="lang" @change="updatePersonData")
                            ._input-error(v-if="$v.date_of_birth.$dirty && !$v.date_of_birth.mustBeDate") Выберите дату рождения
                        ._person-data-value(v-else) {{ age }}

                ._item(v-if="weight || editPermitted")
                    ._input-wrap
                        label._input-label Вес:
                        ._input-group(v-if="editPermitted")
                            input(type="text" maxlength="3" v-model.trim="weight" :class="{'personal-data__input_invalid': ($v.weight.$dirty && !$v.weight.numeric) || ($v.weight.$dirty && !$v.weight.required)}" @keyup="updatePersonData")._input
                            ._input-error(v-if="($v.weight.$dirty && !$v.weight.numeric) || ($v.weight.$dirty && !$v.weight.required)") Введите ваш вес
                            ._input-unit(v-else) кг
                        ._person-data-value(v-else) {{ weight }} кг

                ._item(v-if="height || editPermitted")
                    ._input-wrap
                        label._input-label Рост:
                        ._input-group(v-if="editPermitted")
                            input(type="text" maxlength="3" v-model.trim="height" :class="{'personal-data__input_invalid': ($v.height.$dirty && !$v.height.numeric) || ($v.height.$dirty && !$v.height.required)}" @keyup="updatePersonData")._input
                            ._input-error(v-if="($v.height.$dirty && !$v.height.numeric) || ($v.height.$dirty && !$v.height.required)") Введите ваш рост
                            ._input-unit(v-else) см
                        ._person-data-value(v-else) {{ height }} см

                ._item
                    ._input-wrap
                        label._input-label Цвет глаз:
                        ._input-group(v-if="editPermitted")
                            Select(:placeholder="eyeColorName" :value="eyeColorId" field-id="eyeColorId" field-name="eyeColorName" :options="eyeColors" @changeModel="changeModel")
                        ._person-data-value(v-else) {{ eyeColorName }}

                ._item(v-if="isCelebrity && editPermitted")
                    ._input-wrap
                        label._input-label Номер телефона:
                        ._input-group
                            input(type="text" v-model.trim="phone" @keyup="updatePersonData('phone')" placeholder="+79998887766")._input.-full

                ._item(v-if="vk || editPermitted")
                    ._input-wrap
                        label._input-label ВКонтакте:
                        ._input-group(v-if="editPermitted")
                            input(type="text" v-model.trim="vk" @keyup="updatePersonData('social')" placeholder="https://vk.com/merlinface")._input.-full
                        a(:href="vk" target="_blank")._person-data-value(v-else) {{ vk.split('vk.com/')[1] }}

                ._item(v-if="instagram || editPermitted")
                    ._input-wrap
                        label._input-label Instagram:
                        ._input-group(v-if="editPermitted")
                            input(type="text" v-model.trim="instagram" @keyup="updatePersonData('social')" placeholder="https://instagram.com/merlinface")._input.-full
                        a(:href="instagram" target="_blank")._person-data-value(v-else) @{{ instagram.split('instagram.com/')[1] }}

                ._item(v-if="facebook || editPermitted")
                    ._input-wrap
                        label._input-label Facebook:
                        ._input-group(v-if="editPermitted")
                            input(type="text" v-model.trim="facebook" @keyup="updatePersonData('social')" placeholder="https://facebook.com/merlinface")._input.-full
                        a(:href="facebook" target="_blank" v-else)._person-data-value {{ facebook.split('facebook.com/')[1] }}

                ._item(v-if="youtube || editPermitted")
                    ._input-wrap
                        label._input-label Youtube:
                        ._input-group(v-if="editPermitted")
                            input(type="text" v-model.trim="youtube" @keyup="updatePersonData('social')" placeholder="https://youtube.com/c/merlinface")._input.-full
                        a(:href="youtube" target="_blank")._person-data-value(v-else) {{ youtube.split('youtube.com/')[1] }}

                ._item(v-if="twitter || editPermitted")
                    ._input-wrap
                        label._input-label Twitter:
                        ._input-group(v-if="editPermitted")
                            input(type="text" v-model.trim="twitter" @keyup="updatePersonData('social')" placeholder="https://twitter.com/merlinface")._input.-full
                        a(:href="twitter" target="_blank")._person-data-value(v-else) {{ twitter.split('twitter.com/')[1] }}
        ._list-more(@click="editProfile = !editProfile" v-if="editPermitted")
            ._list-more-icon-box
                svg-icon(icon-name="more-dots")._list-more-icon
            ._list-more-text {{ settingsText }}
        ._line-decor
        ._item.-save(v-if="saveData") {{ saveData }}

</template>

<script>
import gql from "graphql-tag";
import { maxLength, numeric, required } from "vuelidate/lib/validators";
import { mapGetters } from "vuex";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/ru";
import connectSocialMediaQuery from "@/graphql/queries/connectSocialMedia.query.graphql";
import getMyConnectedSocialMediaQuery from "@/graphql/queries/getMyConnectedSocialMedia.query.graphql";

// Date validation function
const mustBeDate = function (value) {
    const regExp = new RegExp("[0-9]{4}-[0-9]{2}-[0-9]{2}"); // YYYY-MM-DD
    return regExp.test(value);
};

const cyrillicExclusion = function (value) {
    if (value) {
        const regExp = new RegExp(/^[a-z\-0-9_]{3,30}$/);
        return regExp.test(value);
    }
    return true;
};

export default {
    name: "PersonalData",
    components: {
        DatePicker,
    },
    props: {
        editPermitted: {
            required: true,
            default: false,
            type: Boolean
        },
    },
    data: () => ({
        lang: {
            formatLocale: {
                firstDayOfWeek: 1,
            },
            monthBeforeYear: false,
        },
        saveData: "",
        saveDataLoading: false,
        saveTime: "",
        saveTimeout: "",
        updateTimeout: "",

        editName: false,
        editAddress: false,
        editProfile: false,

        phone: "",
        eyeColors: [],
        eyeColorId: 0,
        eyeColorName: "Не указан",
        genderList: [
            {
                id: "null",
                name: "Не указан",
            },
            {
                id: "M",
                name: "Мужской",
            },
            {
                id: "F",
                name: "Женский",
            },
        ],
        personNameMutable: "",
        genderId: "",
        genderName: "Не указан",
        weight: 0,
        height: 0,
        date_of_birth: 0,
        instagram: "",
        twitter: "",
        youtube: "",
        facebook: "",
        vk: "",

        addressError: "",

        temporaryPublicAddress: "",
    }),
    validations: {
        personNameMutable: {
            required,
        },
        genderId: {
            required,
        },
        weight: {
            numeric,
            required,
        },
        height: {
            numeric,
            required,
        },
        date_of_birth: {
            mustBeDate,
            required,
        },
        temporaryPublicAddress: {
            maxLength: maxLength(30),
            cyrillicExclusion,
        },
    },
    computed: {
        ...mapGetters({
            personal_data: "person/personalData",
            social_networks: "person/social",
            celebrityPhone: "person/celebrityPhone",
            personName: "person/name",
            isCelebrity: "person/isCelebrity",
            connectedSocialMedia: "person/connectedSocialMedia",
        }),

        publicAddress: {
            get: function () {
                return this.$store.getters["person/url"];
            },
            set: (val) => (this.temporaryPublicAddress = val),
        },

        age: function () {
            let today = new Date(),
                birthDate = new Date(this.date_of_birth),
                age = today.getFullYear() - birthDate.getFullYear(),
                m = today.getMonth() - birthDate.getMonth();

            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age = age - 1;
            }

            return age;
        },
        settingsText: (state) =>
            state.editProfile ? "Скрыть" : "Настройки профиля",
    },
    watch: {
        personal_data: function (personal) {
            this.updateInWatch(personal, "personalData");
        },
        social_networks: function (social) {
            this.updateInWatch(social, "social");
        },
        celebrityPhone: function (phone) {
            this.updateInWatch(phone, "phone");
        },
        publicAddress: function (address) {
            this.temporaryPublicAddress = address;
        },
        personName: function (val) {
            this.personNameMutable = val;
        },
        $route: function () {
            this.saveDataLoading = "";
            this.saveTime = "";
            this.saveTimeout = "";
            this.updateTimeout = "";
            this.temporaryPublicAddress = "";
        },
        temporaryPublicAddress(v) {
            if (!v) return;
            this.temporaryPublicAddress = v.toLowerCase();
        },
        editProfile(status) {
            if (!status) {
                this.$emit("closeEditProfile");
            }
        },
    },
    async mounted() {
        this.temporaryPublicAddress = this.publicAddress;
        this.personNameMutable = this.personName;

        this.eyeColors = await this.$store
            .dispatch("getEyeColorList", {
                apolloClient: this.$apollo,
            })
            .catch(() => {
                this.$msgBox(
                    this.localeError("attention"),
                    this.$options.filters.localizeError(
                        "personDataNotReceived",
                    ),
                );
            });
        if (this.isMyProfile) {
            this.getMyConnectedSocialMedia();
        }
        this.updateInWatch(this.personal_data, "personalData");
        this.updateInWatch(this.social_networks, "social");
        this.updateInWatch(this.celebrityPhone, "");
    },
    methods: {
        async connectSocial(client) {
            await this.$apollo
                .query({
                    query: gql`
                        ${connectSocialMediaQuery}
                    `,
                    variables: {
                        client,
                        redirect_uri: `https://merlinface.com/connect/${client}/check`, // window.location.origin
                    },
                })
                .then((r) => {
                    const socialWindow = window.open(
                        r.data.connectSocialMedia.redirect_uri,
                        "connect-social",
                        {
                            left: 0,
                            top: 0,
                        },
                    );

                    let interval = null;

                    interval = setInterval(async () => {
                        console.log("interval");
                        await this.$apollo
                            .query({
                                query: gql`
                                    ${getMyConnectedSocialMediaQuery}
                                `,
                            })
                            .then((r) => {
                                if (r.data.getMyConnectedSocialMedia[client]) {
                                    clearInterval(interval);
                                    interval = null;
                                    this.$store.dispatch("person/set", {
                                        name: "connectedSocialMedia",
                                        value: r.data.getMyConnectedSocialMedia,
                                    });
                                    socialWindow.close();
                                }
                            });
                    }, 3000);
                })
                .catch((e) => {
                    console.log({ e });
                });
        },
        changeModel(selectValues) {
            this[selectValues.fieldId] = selectValues.fieldIdValue;
            this[selectValues.fieldName] = selectValues.fieldNameValue;
            this.updatePersonData();
        },
        async getMyConnectedSocialMedia() {
            await this.$apollo
                .query({
                    query: gql`
                        ${getMyConnectedSocialMediaQuery}
                    `,
                })
                .then((r) => {
                    this.$store.dispatch("person/set", {
                        name: "connectedSocialMedia",
                        value: r.data.getMyConnectedSocialMedia,
                    });
                });
        },
        updatePersonData(type) {
            clearTimeout(this.updateTimeout);
            this.saveDataLoading = "";
            this.saveTime = "";
            if (this.$v.$invalid) {
                this.$v.$touch();
                return false;
            } else {
                clearTimeout(this.saveTimeout);
                this.saveTime = "";
                this.saveData = "";
                let userData = {
                        name: this.personNameMutable,
                        gender: this.genderId,
                        height: this.height,
                        weight: this.weight,
                        date_of_birth: this.date_of_birth,
                        eye_color: this.eyeColorId,
                        uuid: this.$store.getters["person/uuid"],
                    },
                    socialData = {
                        uuid: this.$store.getters["person/uuid"],
                        twitter: this.twitter,
                        instagram: this.instagram,
                        youtube: this.youtube,
                        facebook: this.facebook,
                        vk: this.vk,
                    },
                    phoneData = {
                        uuid: this.$store.getters["person/uuid"],
                        phone: this.phone,
                    },
                    state = this,
                    dispatchType =
                        type === "social"
                            ? "updateSocialData"
                            : type === "phone"
                                ? "updateCelebrityPhone"
                                : "updatePersonData";
                let resultData =
                    type === "social"
                        ? socialData
                        : type === "phone"
                            ? phoneData
                            : userData;

                this.updateTimeout = setTimeout(async () => {
                    this.saveData = "Сохранение данных";
                    await state.$store
                        .dispatch(`person/${dispatchType}`, {
                            apolloClient: state.$apollo,
                            userData: resultData,
                        })
                        .then(() => {
                            const date = new Date();
                            this.saveData =
                                "Сохранено в " +
                                date.getHours() +
                                ":" +
                                (date.getMinutes() < 10 ? 0 : "") +
                                date.getMinutes();

                            if (type === "name" && this.editName) {
                                console.log("editName");
                                this.editName = false;
                                this.$msgBox(
                                    "Успешно!",
                                    "Ваше новое имя сохранено!",
                                    "success",
                                );
                            }

                            if (this.personName !== this.personNameMutable) {
                                this.$store.dispatch(
                                    "person/setPersonName",
                                    this.personNameMutable,
                                );
                            }
                        })
                        .catch((e) => {
                            let code = e.graphQLErrors[0].extensions.code,
                                msg = "";
                            switch (code) {
                                case 1003:
                                    msg =
                                        "У вас нет доступа для редактирования данных";
                                    break;
                                case 1013:
                                    msg =
                                        "Имя пользователя можно менять не чаще чем раз в сутки.";
                                    this.editName = false;
                                    this.personNameMutable = this.personName;
                                    break;
                                default:
                                    msg = `Код ошибки: ${code}. Пожалуйста, повторите попытку позже.`;
                            }
                            this.$msgBox("Ошибка", msg);

                            setTimeout(() => {
                                this.saveData = "";
                            }, 5000);
                        });
                }, 1000);
            }
        },
        updateInWatch(personal, type) {
            if (type === "personalData") {
                //TODO: if personal_data is not defined(manually created empty person - it is valid person)
                this.genderId = personal.gender ? personal.gender : "null";
                this.genderName = this.genderList.find(
                    (gender) => gender.id === this.genderId,
                ).name;
                this.height = personal.height || 0;
                this.weight = personal.weight || 0;

                this.date_of_birth = personal.date_of_birth
                    ? personal.date_of_birth.split("T")[0]
                    : "1990-01-01";

                const eye = this.$store.getters["person/eye"];
                if (eye) {
                    this.eyeColorId = eye.id;
                    this.eyeColorName = eye.name;
                }
            } else if (type === "social") {
                this.twitter =
                    personal.twitter === null ? "" : personal.twitter;
                this.instagram =
                    personal.instagram === null ? "" : personal.instagram;
                this.youtube =
                    personal.youtube === null ? "" : personal.youtube;
                this.facebook =
                    personal.facebook === null ? "" : personal.facebook;
                this.vk = personal.vk === null ? "" : personal.vk;
            } else {
                this.phone = personal ? personal : "";
            }
        },
        async savePublicAddress() {
            this.addressError = "";

            if (
                this.temporaryPublicAddress ===
                this.$store.getters["person/url"]
            ) {
                this.addressError = "Указанный адрес уже принадлежит Вам";
                return;
            }
            this.$v.$touch();

            if (this.$v.$invalid) return false;

            console.log("valid");

            await this.$apollo
                .mutate({
                    mutation: gql`
                        mutation personChangePublicUrl(
                            $personId: ID!
                            $url: String
                        ) {
                            personChangePublicUrl(
                                personId: $personId
                                url: $url
                            )
                        }
                    `,
                    variables: {
                        url: this.temporaryPublicAddress,
                        personId: this.$store.getters["person/uuid"],
                    },
                    fetchPolicy: "no-cache",
                })
                .then(() => {
                    this.$v.$reset();
                    this.editAddress = false;
                    this.$store.dispatch(
                        "person/setPersonUrl",
                        this.temporaryPublicAddress,
                    );
                    if (this.temporaryPublicAddress !== "") {
                        this.$msgBox(
                            "Успешно!",
                            "Ваш короткий адрес сохранён, теперь ваша страница доступна по короткому адресу",
                            "success",
                        );
                        this.saveData = "Новый адрес сохранён!";
                        setTimeout(() => {
                            this.$router.replace({
                                name: "public_person",
                                params: { uuid: this.temporaryPublicAddress },
                            });
                        }, 2000);
                    } else {
                        this.$msgBox(
                            "Успешно!",
                            "Ваш короткий адрес был удалён",
                            "success",
                        );
                        this.$router.replace({
                            name: "person",
                            params: {
                                uuid: this.$store.getters["person/uuid"],
                            },
                        });
                    }
                })
                .catch((e) => {
                    this.addressError = "Неизвестная ошибка.";
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1007:
                                this.addressError = "Адрес уже занят.";
                                break;
                            case 1006:
                                this.addressError =
                                    "Неправильный адрес, попробуйте другой.";
                                break;
                        }
                    }
                });
        },
    },
};
</script>

<style lang="scss">
@import "personal-data";

$namespace: "merlinface";
$calendar-hover-color: #222;
$calendar-hover-background-color: $gold;
$calendar-active-color: #222;
$default-color: #fff;
$primary-color: $gold;
$border-color: $gold;
$input-border-color: none;
@import "~vue2-datepicker/scss/index.scss";

.#{$namespace}-datepicker-main {
    background: #222;
}

.#{$namespace}-input {
    background: none;
    border: 0;
    border-bottom: 1px solid $gold;
    border-radius: 0;
    box-shadow: none;
    background: transparent;
    outline-color: $gold;
    color: #bcbcbc;
    padding: 0;
    width: 72px;
}

.#{$namespace}-icon-calendar {
    display: none;
}

.#{$namespace}-input-wrapper:hover .#{$namespace}-icon-clear {
    display: none;
}

.#{$namespace}-table-date .cell.not-current-month {
    color: #333;
}
</style>
