import gql from "graphql-tag";
<template lang="pug">
    .tariff-junior
        ._title {{ locale(juniorTitle) }}
        ._content
            ._desc(v-html="locale('desc')")
            ._footer
                ._price 100 р в {{ periodName }}
                ui-button(color="pink" text="Перейти на Junior" @click.native="subscribe" v-if="!isJuniorActive")
                ui-button(color="pink" type="bordered" :text="locale('cancelSubscription')" @click.native="confirmUnsubscribe" v-else-if="plan.auto_renew")
                ._wait(v-else v-html="endDate")
</template>
<script>
import UiButton from "../../ui/ui-button/ui-button";
import SubscriptionsPeriod from "../components/subscriptions-period/subscriptions-period";
import { subscriptionsPeriods } from "../settings/subscriptions-periods";
import { mutationSubscribeToPlan } from "@/components/subscriptions/graphql/mutations";
import gql from "graphql-tag";
import getMySubscriptionsQuery from "@/graphql/queries/getMySubscriptions.query.graphql";
import unsubscribeFromPlanMutation from "@/graphql/mutations/unsubscribeFromPlan.mutation.graphql";

export default {
    name: "TariffJunior",
    components: { SubscriptionsPeriod, UiButton },
    data() {
        return {
            period: "MONTHLY",
            periods: [
                {
                    id: subscriptionsPeriods.MONTH,
                    label: "на месяц",
                },
                {
                    id: subscriptionsPeriods.ANNUAL,
                    label: "на год",
                },
            ],
            activeSubscriptions: [],
        };
    },
    computed: {
        tariff: (state) => state.$store.getters["tariffJunior"],
        balance: (state) => state.$store.getters["user/balance"],
        periodName: (state) => (state.period === "MONTHLY" ? "месяц" : "год"),
        isJuniorActive: (state) =>
            state.activeSubscriptions.filter((plan) => plan.id > 21).length >=
            4,
        juniorTitle: (state) =>
            state.isJuniorActive ? "title-1" : "title-2",
        plan: (state) => state.activeSubscriptions[0],
        endDate: (state) =>
            `Подписка активна до<br/> ${state.formatDate(
                state.plan.subscribed_due.date,
                "DDD HH:mm",
            )}`,
    },
    mounted() {
        this.getActiveSubscriptions();
    },
    methods: {
        async getActiveSubscriptions() {
            await this.$apollo
                .query({
                    query: gql`
                        ${getMySubscriptionsQuery}
                    `,
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.activeSubscriptions = r.data.getMySubscriptions;
                });
        },
        subscribe() {
            this.$apollo
                .mutate({
                    mutation: mutationSubscribeToPlan,
                    variables: {
                        plan: 21,
                    },
                })
                .then((r) => {
                    if (this.balance < 100) {
                        window.location.href = r.data.subscribeToPlan;
                    } else {
                        this.$bus.$emit("refreshSubscriptionsPlans");
                        this.$bus.$emit("refreshActiveSubscriptions");
                        this.$bus.$emit("showSuccessSubscription");
                    }
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1002: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Выбранный план не найден",
                                );
                                break;
                            }
                            case 1005: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Не выбран ни один план",
                                );
                                break;
                            }
                            default: {
                                this.$msgBox(
                                    "Ошибка запроса на подписку",
                                    "Неизвестная ошибка",
                                );
                            }
                        }
                    }
                })
                .finally(() => {
                    this.$emit("close");
                });
        },
        confirmUnsubscribe() {
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: this.locale("confirm.title"),
                text: this.locale("confirm.desc"),
                price: 0,
                comparisonCount: "",
                comparisonTimer: null,
                buttons: true,
                confirmText: this.locale("confirm.btn"),
                callback: this.unsubscribe,
            });
        },
        async unsubscribe() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${unsubscribeFromPlanMutation}
                    `,
                    variables: {
                        planId: Number(this.plan.id),
                    },
                })
                .then(() => {
                    this.$bus.$emit("showSuccessUnsubscribe");
                    this.$store.dispatch("changeConfirmDialog", {
                        show: false,
                    });
                    this.getActiveSubscriptions();
                });
        },
    },
};
</script>

<style src="./tariff-junior.scss" lang="scss"></style>
