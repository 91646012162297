<template lang="pug">
  .another-person-photo {{text}}
    ._share(v-if="showShareIcons")
      right-aside-social
</template>

<style lang="scss" src="./invite-friends.scss"></style>
<script>
import RightAsideSocial from "../../../app/right-aside/social/social";

export default {
    components: { RightAsideSocial },
    props: {
        text: {
            required: false,
            type: String,
            default:
                "Кажется, вы пытаетесь загрузить фотографии другого человека. Поделитесь с ним ссылкой на наш сайт и вы получите бонусы",
        },
        showShareIcons: {
            required: false,
            type: Boolean,
            default: true,
        },
    },
};
</script>
