<template lang="pug">
  .landing-steps.section
    ._wrap.wrap
      h2._title Как начать:
      ._vertical-list
        ._vertical-item(v-for="(step, index) in steps" :key="index")
          ._vertical-item-title {{ step.title }}
          ._vertical-item-number {{ step.number }}
        svg-icon(icon-name="chevron-down")._vertical-chevron
          
        //._list
        //._item(v-for="step in steps")
        //  ._item-number {{ step.number }}
        //  ._item-title {{ step.title }}
        //  svg-icon(icon-name="chevron-right")._item-chevron

      ._footer
        ._footer-box
          ._footer-group
            img(src="./images/head.svg")._merlin-head
            ._footer-desc
              ._footer-title {{ locale("result.title") }}
              ul._footer-list
                li._footer-item(v-for="(resultItem, index) in locale('result.list')" :key="index") {{ resultItem }}
          ui-button(:text="locale('btn')" size="big" effect="flash" variant="rounded" @click.native="redirectToAuth()")._btn
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "LandingSteps",
    components: { UiButton },
    computed: {
        steps: (state) => state.locale("steps"),
    },
    methods: {
        async redirectToAuth() {
            this.yandexGoal("landing_btn_2");
        },
    },
};
</script>
<style lang="scss" src="./landing-steps.scss"></style>
