import gql from "graphql-tag";

const getDefaultState = () => ({
    info: {},
    name: "",
    gender: "",
    externalId: null,
    externalIdPresent: false,
    category: [],
    personal_data: [],
    photos: {},
    public_url: "",
    publicOpened: false,
    isCelebrity: false,
    celebrityPhone: "123",
    uuid: "",
    eye_color: "",
    loaded: false,
    predictedAge: null,
    social: [],
    personCategories: [],
    userUuid: "",
    leader_type: "",
    manualName: "",
    connectedSocialMedia: {
        facebook: false,
        telegram: false,
        vkontakte: false,
    },
});

export const person = {
    namespaced: true,
    state: getDefaultState(),
    actions: {
        set({ commit }, { name, value }) {
            commit("set", { name, value });
        },
        resetState({ commit }) {
            commit("resetState");
        },
        setPerson({ commit }, data) {
            commit("setPerson", data);
        },
        setAvatar({ commit }, data) {
            commit("setPersonPhoto", data);
        },
        async getInfo({ commit }, { apolloClient, uuid }) {
            if (uuid === undefined) {
                uuid = this.$store.getters["user/uuid"];
            }
            const query = gql`
                query {
                    getPerson(uuid: "${uuid}") {
                        uuid
                        name
                        leader_type
                        public_url
                        social_networks {
                            instagram
                            twitter
                            youtube
                            facebook
                            vk
                        }
                        category {
                            id
                            name
                        }
                        eye_color {
                            id
                            name
                        }
                        personal_data {
                            gender
                            weight
                            height
                            date_of_birth
                        }
                        photos {
                            avatar
                            anfas
                            profile
                            full_length
                        }
                        extras {
                            external_id
                            external_id_present
                            predicted_age
                        }
                        public_opened,
                        celebrity_status {
                            celebrity
                            celebrity_phone
                        },
                        created_by_user
                        assigned_user
                    }
                }
            `;
            return await apolloClient
                .query({
                    query,
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    commit("setPerson", response.data.getPerson);
                });
        },

        async getLeaderType({ commit }, { apolloClient, uuid }) {
            if (!uuid) {
                return;
            }
            console.log("GETFROMSTORE QUERY");
            let query = gql`
                query {
                    getPerson(uuid: "${uuid}") {
                        leader_type
                    }
                }
            `;

            return await apolloClient.query({ query }).then((response) => {
                console.log("leaderTypeResponse", response);
                commit("setLeaderType", response.data.getPerson.leader_type);
            });
        },

        async getExternalId(commit, { apolloClient, uuid }) {
            if (uuid === undefined) {
                uuid = this.$store.getters["user/uuid"];
            }
            const query = gql`
                query {
                    getPerson(uuid: "${uuid}") {
                        extras {
                            external_id_present
                        }
                    }
                }
            `;
            return await apolloClient
                .query({
                    query,
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    return response.data.getPerson;
                });
        },
        async getInfoForCompare(commit, { apolloClient, uuid }) {
            if (uuid === undefined) {
                uuid = this.$store.getters["user/uuid"];
            }
            const query = gql`
                query {
                    getPerson(uuid: "${uuid}") {
                        name
                        photos {
                            avatar
                        }
                    }
                }
            `;
            return await apolloClient.query({
                query,
                fetchPolicy: "no-cache",
            });
        },
        async updatePersonData({ commit }, { apolloClient, userData }) {
            console.log("updatePersonData action", userData);
            const mutation = gql`
                mutation {
                    personEditPersonalData(
                        person_id: "${userData.uuid}"
                        input: {
                            name: "${userData.name}"
                            gender: ${userData.gender}
                            date_of_birth: "${userData.date_of_birth}"
                            height: ${userData.height}
                            weight: ${userData.weight}
                            eye_color: ${userData.eye_color}
                        }
                    ) {
                        eye_color {
                            id
                            name
                        }
                        personal_data {
                            gender
                            weight
                            height
                            date_of_birth
                        }
                    }
                }
            `;
            return await apolloClient.mutate({ mutation }).then((response) => {
                commit(
                    "setNewPersonalData",
                    response.data.personEditPersonalData,
                );
                return response;
            });
        },
        async updateSocialData(commit, { apolloClient, userData }) {
            console.log(userData);
            const mutation = gql`
                mutation {
                    personSetSocialNetworks(
                        person_id: "${userData.uuid}"
                        input: {
                            twitter: "${userData.twitter}",
                            instagram: "${userData.instagram}",
                            youtube: "${userData.youtube}",
                            facebook: "${userData.facebook}",
                            vk: "${userData.vk}"
                        }
                    )
                }
            `;
            await apolloClient.mutate({ mutation });
        },
        async updateCelebrityPhone({ commit }, { apolloClient, userData }) {
            console.log(userData);
            const mutation = gql`
                mutation {
                    personSetCelebrityPhone(
                        personId: "${userData.uuid}"
                        phoneNumber: "${userData.phone}"
                    )
                }
            `;
            await apolloClient.mutate({ mutation }).then((response) => {
                commit(
                    "setNewPersonalData",
                    response.data.personEditPersonalData,
                );
            });
        },
        setPersonName({ commit }, name) {
            commit("setPersonName", name);
        },
        async getPhotoUploadUrl(commit, { apolloClient }) {
            const query = gql`
                query {
                    getPhotoUploadUrl
                }
            `;
            return await apolloClient.query({
                query,
                fetchPolicy: "no-cache",
            });
        },
        async setPersonPhoto(
            { commit },
            { apolloClient, category, uuid, photo },
        ) {
            const mutation = gql`
                mutation {
                    setPersonPhoto(input: {
                        person_id: "${uuid}",
                        photo_url: "${photo}",
                        photo_category: ${category}
                    }) {
                        taskId
                    }
                }
            `;
            if (category !== "AVATAR") {
                commit("setPersonPhoto", { photo, category });
            }
            return await apolloClient.mutate({ mutation });
        },
        async createPersonDesc(commit, { apolloClient, uuid, rc }) {
            if (typeof uuid === "undefined") {
                return;
            }

            let mode = "CREATE";

            if (rc) {
                mode = "RECREATE_BY_PHOTOS";
            }

            const mutation = gql`
                mutation {
                    personCreateDescription(
                        personId: "${uuid}"
                        mode: ${mode}
                    ) {
                        taskId
                    }
                }
            `;
            console.log(mutation);
            return await apolloClient
                .mutate({ mutation })
                .then((r) => {
                    return r.data.personCreateDescription.taskId;
                })
                .catch((e) => {
                    if (e.graphQLErrors[0]) {
                        return e.graphQLErrors[0].extensions.code;
                    }

                    return 1000;
                });
        },
        setPersonUrl({ commit }, url) {
            commit("setPersonUrl", url);
        },
        setTemporaryPhoto({ commit }, { photo, type }) {
            console.log("setTemporaryPhoto", photo, type);
            commit("setTemporaryPhoto", { photo, type });
        },
        async clearPerson({ commit }) {
            await commit("clearPerson");
        },
        setPersonPublicStatus({ commit }, status) {
            commit("setPersonPublicStatus", status);
        },
        setPersonCelebrityStatus({ commit }, status) {
            commit("setPersonCelebrityStatus", status);
        },
        async personGetDescription({ commit }, { apolloClient, uuid }) {
            if (!uuid) {
                return;
            }

            const query = gql`
                query {
                    personGetDescription(personId: "${uuid}") {
                        id
                        name
                        subcategories {
                            id
                            name
                            price
                            unlocked
                            tip
                            items {
                                id
                                name
                                description
                            }
                            itemsTotal
                        }
                    }
                }
            `;
            return apolloClient
                .query({
                    query,
                    variables: {
                        uuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    commit("setPersonCategories", r.data.personGetDescription);
                })
                .catch((e) => {
                    if (e.graphQLErrors[0]) {
                        return e.graphQLErrors[0].extensions.code;
                    }

                    return 1000;
                });
        },
        setPersonDescription({ commit }, data) {
            commit("setPersonCategories", data);
        },
        async buyCategory(
            commit,
            { apolloClient, personId, category, subCategory },
        ) {
            const mutation = gql`
                mutation {
                    personUnlockDescriptionSubcategory(
                        categoryId: ${category},
                        subcategoryId: ${subCategory},
                        personId: "${personId}"
                    ) {
                        taskId
                    }
                }
            `;
            return await apolloClient.mutate({ mutation });
        },
        async getComparePrice(context, { apolloClient, type }) {
            const query = gql`
                query {
                    comparePrice(
                        compareType: ${type},
                        custom: false,
                        isCelebrity: ${context.state.isCelebrity}
                    ) {
                        price
                        subscriptionChecks
                    }
                }
            `;
            return await apolloClient.query({ query });
        },
        async getIndicatorPrice(context, { apolloClient, type }) {
            const query = gql`
                query {
                    personIndicatorPrice(
                        indicatorType: ${type},
                        isCelebrity: ${context.state.isCelebrity}
                    )
                }
            `;
            return await apolloClient.query({ query });
        },
        async buyIndicator(commit, { apolloClient, personId, indicatorType }) {
            const query = gql`
                mutation personIndicatorCreate(
                    $personId: String!
                    $analyse: PersonIndicatorType!
                ) {
                    personIndicatorCreate(
                        input: { personId: $personId, indicatorType: $analyse }
                    )
                }
            `;
            return await apolloClient.mutate({
                mutation: query,
                variables: {
                    personId: personId,
                    analyse: indicatorType,
                },
                fetchPolicy: "no-cache",
            });
        },
        async buyCompare(
            commit,
            { apolloClient, comparedPersonId, compareType },
        ) {
            const mutation = gql`
                mutation {
                    compareWithPersonCreate(input: {
                        comparedPersonId: "${comparedPersonId}"
                        compareType: ${compareType}
                    })
                }
            `;
            return await apolloClient.mutate({ mutation });
        },
        async getIndicator(commit, { apolloClient, personId, indicatorType }) {
            const query = gql`
                query personIndicatorGet(
                    $personId: String!
                    $indicatorType: PersonIndicatorType!
                ) {
                    personIndicatorGet(
                        input: {
                            personId: $personId
                            indicatorType: $indicatorType
                        }
                    ) {
                        resultValue
                        resultTitle
                        resultDescription
                    }
                }
            `;
            return await apolloClient.query({
                query,
                variables: {
                    personId: personId,
                    indicatorType: indicatorType,
                },
                fetchPolicy: "no-cache",
            });
        },
        async getCompare(
            commit,
            { apolloClient, comparedPersonId, compareType },
        ) {
            const query = gql`
                query compareWithPersonGet(
                    $comparedPersonId: String!
                    $compareType: PersonCompareType!
                ) {
                    compareWithPersonGet(
                        input: {
                            comparedPersonId: $comparedPersonId
                            compareType: $compareType
                        }
                    ) {
                        resultValue
                        resultTitle
                        resultDescription
                    }
                }
            `;
            return await apolloClient.query({
                query,
                variables: {
                    comparedPersonId: comparedPersonId,
                    compareType: compareType,
                },
                fetchPolicy: "no-cache",
            });
        },
        async checkPhotoCompareStatus(
            commit,
            { apolloClient, anfas, anfasMd5, profile, profileMd5, type },
        ) {
            const query = gql`
                query {
                    compareWithPhotosGet(input: {
                        photoAnfas: "${anfas}"
                        photoAnfasMd5: "${anfasMd5}"
                        photoProfile: "${profile}"
                        photoProfileMd5: "${profileMd5}"
                        compareType: ${type}
                    })
                    {
                        resultValue
                        resultTitle
                        resultDescription
                    }
                }
            `;
            return await apolloClient
                .query({ query })
                .then((r) => {
                    return r.data.compareWithPhotosGet;
                })
                .catch((e) => {
                    if (e.graphQLErrors[0]) {
                        return e.graphQLErrors[0].extensions.code;
                    }

                    return 1000;
                });
        },
        async buyPhotoCompare(
            commit,
            { apolloClient, anfas, anfasMd5, profile, profileMd5, type },
        ) {
            const mutation = gql`
                mutation {
                    compareWithPhotosCreate(input: {
                        photoAnfas: "${anfas}"
                        photoAnfasMd5: "${anfasMd5}"
                        photoProfile: "${profile}"
                        photoProfileMd5: "${profileMd5}"
                        compareType: ${type}
                    })
                }
            `;
            return await apolloClient.mutate({ mutation });
        },
        async clearPersonInfo({ commit }) {
            commit("clearPersonInfo");
        },
        async clearUserUuid({ commit }) {
            commit("clearUserUuid");
        },
        async setExternalPresent({ commit }, status) {
            commit("setExternalPresent", status);
        },
    },
    getters: {
        info: (state) => state.info,
        name: (state) => state.name,
        gender: (state) => state.gender,
        externalId: (state) => state.externalId,
        externalIdPresent: (state) => state.externalIdPresent,
        predictedAge: (state) => state.predictedAge,
        type: (state) => state.category.name,
        categoryId: (state) => state.category.id,
        personalData: function (state) {
            return state.personal_data;
        },
        eye: (state) => state.eye_color,
        url: (state) => state.public_url,
        uuid: (state) => state.uuid,
        userUuid: (state) => state.userUuid,
        photos: (state) => state.photos,
        avatar: (state) => state.photos.avatar,
        leader_type: (state) => state.leader_type,
        isPersonLoaded: (state) => state.loaded,
        isPublicPerson: (state) => state.publicOpened,
        isCelebrity: (state) => state.isCelebrity,
        celebrityPhone: (state) => state.celebrityPhone,
        social: (state) => state.social,
        personCategories: (state) => state.personCategories,
        itemsTotal: (state) => {
            let total = {};
            state.personCategories.forEach((item) => {
                item.subcategories.map((item) => {
                    total[item.id] = item.itemsTotal;
                });
            });
            return total;
        },
        connectedSocialMedia: (state) => state.connectedSocialMedia,
    },
    mutations: {
        set(state, { name, value }) {
            state[name] = value;
        },
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        clearUserUuid(state) {
            state.userUuid = "";
        },
        setPersonName(state, name) {
            state.name = name;
        },
        clearPersonInfo(state) {
            state.name = "";
            state.gender = "";
            state.externalId = null;
            state.category = [];
            state.personal_data = [];
            state.photos = [];
            state.public_url = "";
            state.publicOpened = false;
            state.isCelebrity = false;
            state.uuid = "";
            state.eye_color = "";
            state.loaded = false;
            state.personCategories = [];
            state.leader_type = "";
            state.personCategories = [
                {
                    id: 1,
                    name: "",
                    subcategories: [
                        {
                            id: "",
                            name: "",
                            itemsTotal: 0,
                        },
                    ],
                },
                {
                    id: 2,
                    name: "",
                    subcategories: [
                        {
                            id: "",
                            name: "",
                            itemsTotal: 0,
                        },
                    ],
                },
                {
                    id: 3,
                    name: "",
                    subcategories: [
                        {
                            id: "",
                            name: "",
                            itemsTotal: 0,
                        },
                    ],
                },
                {
                    id: 4,
                    name: "",
                    subcategories: [
                        {
                            id: "",
                            name: "",
                            itemsTotal: 0,
                        },
                    ],
                },
            ];
            state.manualName = "";
        },
        setPerson(state, personData) {
            state.info = personData;
            state.name = personData.name;
            state.gender = personData.personal_data.gender;
            state.externalId = personData.extras.external_id;
            state.externalIdPresent = personData.extras.external_id_present;
            state.predictedAge = personData.extras.predicted_age;
            state.category = personData.category;
            state.public_url = personData.public_url;
            state.publicOpened = personData.public_opened;
            state.isCelebrity = personData.celebrity_status.celebrity;
            state.celebrityPhone = personData.celebrity_status.celebrity_phone;
            state.uuid = personData.uuid;
            state.userUuid = personData.assigned_user;
            state.personal_data = personData.personal_data;
            state.social = personData.social_networks;
            state.eye_color = personData.eye_color;
            state.photos = personData.photos;
            state.loaded = true;
            state.leader_type = personData.leader_type;
        },
        setNewPersonalData(state, userData) {
            state.eye_color = userData.eye_color;
            state.personal_data = userData.personal_data;
        },
        setTemporaryPhoto(state, data) {
            state.photos[data.type.toLowerCase()] = data.photo;
        },
        setPersonUrl(state, url) {
            state.public_url = url;
        },
        setPersonPublicStatus(state, status) {
            state.publicOpened = status;
        },
        setPersonCelebrityStatus(state, status) {
            state.isCelebrity = status;
        },
        clearPerson(state) {
            state.name = "";
            state.gender = "";
            state.externalId = null;
            state.externalIdPresent = false;
            state.category = [];
            state.personal_data = [];
            state.photos = [];
            state.public_url = "";
            state.publicOpened = false;
            state.isCelebrity = false;
            state.uuid = "";
            state.eye_color = "";
            state.loaded = false;
        },
        setPersonCategories(state, data) {
            state.personCategories = data;
        },
        setExternalPresent(state, status) {
            console.log("setExternalPresent:", status);
            state.externalIdPresent = status;
        },
        setPersonPhoto(state, data) {
            state.photos[data.category.toLowerCase()] = data.photo;
        },
        setLeaderType(state, type) {
            state.leader_type = type;
        },
    },
};
