import gql from "graphql-tag";

export const sharedMixins = {
    queries: {
        messageSentSubscription: gql`
            subscription messageSent($userId: String!) {
                messageSent(userId: $userId) {
                    ...Message
                }
            }

            fragment Message on Message {
                id
                message
                image
                senderId
                senderName
                senderPhoto
                senderPersonId
                createdAt
                conversationId
                reachTheGoal
                readByRecipient
                businessProposal
                stableDiffusion
                midjorney
                midjorneyData
                themeId
            }
        `,
        userOnline: gql`
            mutation userOnline($userId: String!) {
                userOnline(userId: $userId)
            }
        `,
    },
};
