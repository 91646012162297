<template lang="pug">
    modal(name="Unsubscribe" class="unsubscribe-modal-wrap" :adaptive="true" width="100%" height="100%" @before-open="beforeOpen")
        .unsubscribe-modal
            ._wrap
                ._content
                    ._content-inner
                        ._form
                            ._close-btn(@click="$modal.hide('Unsubscribe')")
                                svg-icon(icon-name="cross")._close-icon
                            ._form-title Вы уверены? 
                            ._desc(v-if="isUnsubscribe") Вы успешно отписались. Тариф будет действовать до конца оплаченного периода.
                            template(v-else)
                                ._desc(v-if="planId === 26 || planId === 27") Для Вас сейчас действует скидка и Вы платите всего
                                    ._price ${{ planId === 26 ? 6 : 3 }} / месяц
                                    span В случае отмены стоимость станет
                                    ._price.-new ${{ planId === 26 ? 12 : 6 }} / месяц
                                ui-button(color="gray" type="bordered" text="Отменить подписку" @click.native="unsubscribe")._btn
                                ui-button(type="bordered" text="Закрыть" @click.native="$modal.hide('Unsubscribe')")._btn
</template>

<script>
import UiButton from "../../ui/ui-button/ui-button.vue";
import gql from "graphql-tag";
import unsubscribeFromPlanMutation from "@/graphql/mutations/unsubscribeFromPlan.mutation.graphql";

export default {
    name: "UnsubscribeModal",
    components: { UiButton },
    data() {
        return {
            planId: 0,
            price: null,
            isUnsubscribe: false,
            mySubscriptions: []
        };
    },
    computed: {
        activeSubscription: (state) =>
            state.mySubscriptions.find(
                (plan) =>
                    plan.id === 26 ||
                    plan.id === 27 ||
                    plan.id === 32 ||
                    plan.id === 33,
            ) || { id: 31 },
    },
    mounted() {},
    methods: {
        beforeOpen(event) {
            this.planId = event.params?.planId;
            this.price = event.params?.price;
            this.mySubscriptions = event.params?.mySubscriptions;
        },

        unsubscribe() {
            this.$apollo
                .mutate({
                    mutation: gql(unsubscribeFromPlanMutation),
                    variables: {
                        planId: this.activeSubscription.id,
                    },
                })
                .then(() => {
                    this.isUnsubscribe = true;
                });
        },
    },
};
</script>

<style lang="scss" src="./unsubscribe-modal.scss"></style>
