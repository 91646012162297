<template lang="pug">
    .confirm-dialog(@click.self="cancel")
        ._wrap
            Loading(v-if="loading")
            template
                ._title(v-if="title") {{ title }}
                ._text(v-if="text") {{ text }}
                ._price(v-if="price") {{ locale("price") }} {{ price }} ₽
                ._price(v-if="comparisonTimer") {{ locale("timer") }} {{ timer }}
                ._price(v-if="comparisonCount") {{ comparisonCount }}
                ._footer(v-if="buttons")
                    button(type="button" @click="$emit('cancel')")._btn.-cancel {{ cancelText || locale("cancel") }}
                    button(type="button" @click="confirm" v-if="confirmText" :disabled="isBtnDisabled")._btn.-confirm {{ confirmText || locale("apply") }}
</template>

<script>
export default {
    name: "ConfirmDialog",
    props: {
        loading: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: false,
            default: "",
        },
        text: {
            type: String,
            required: false,
            default: "",
        },
        price: {
            type: Number,
            required: false,
            default: 0,
        },
        buttons: {
            type: Boolean,
            required: false,
            default: true,
        },
        cancelText: {
            type: String,
            required: false,
            default: "",
        },
        confirmText: {
            type: String,
            required: false,
            default: "'",
        },
        type: {
            type: String,
            required: false,
            default: "",
        },
        comparisonCount: {
            type: String,
            required: false,
            default: "",
        },
        comparisonTimer: {
            type: Object,
            required: false,
            default: () => {},
        },
        callback: {
            type: Function,
            required: false,
            default: () => {},
        },
    },
    data() {
        return {
            timer: null,
            interval: null,
            isBtnDisabled: false,
        };
    },
    mounted() {
        this.timer = this.getRemainingTime(this.comparisonTimer);
    },
    created() {
        if (this.comparisonTimer) {
            this.interval = setInterval(() => {
                this.timer = this.getRemainingTime(this.comparisonTimer);
            }, 1000);
        }
    },
    methods: {
        confirm() {
            if (this.isBtnDisabled) return;
            this.isBtnDisabled = true;

            if (this.type) {
                this.$emit("confirm", this.type);

                if (this.type === "uploadPhotoAttempt") {
                    this.$router.push({ name: "change-photo" });
                    this.callback();
                    this.close();
                }
            } else {
                this.callback();
                this.close();
            }

            setTimeout(() => {
                this.isBtnDisabled = false;
            }, 500);
        },
        close() {
            this.$store.dispatch("changeConfirmDialog", {
                show: false,
                loading: false,
                title: "",
                text: "",
                buttons: true,
                confirmText: "",
                type: "",
                callback: () => {},
            });
        },
        cancel() {
            if (this.loading) return;
            this.$emit("cancel");
        },
    },
};
</script>

<style src="./confirm-dialog.scss" lang="scss"></style>
