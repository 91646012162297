<template lang="pug">
    .element-decor(:class="[design, {active: active}, size]")
        ._item.-top-left
        ._item.-top-right
        ._item.-bottom-left
        ._item.-bottom-right
</template>

<script>
export default {
    name: "ElementDecor",
    props: {
        design: {
            type: String,
            default: "",
            required: false,
        },
        size: {
            type: String,
            default: "",
            required: false,
        },
        active: {
            type: Boolean,
            default: false,
            required: false,
        },
    },
};
</script>
<style lang="scss">
.element-decor {
    $root: &;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    &.gray {
        #{$root} {
            &__item {
                border-color: #626262;

                &:after {
                    border-color: #d0d0d0;
                }
            }
        }
    }

    &.small {
        #{$root} {
            &__item {
                width: 18px;
                height: 18px;
            }
        }
    }

    &.extra-small {
        #{$root} {
            &__item {
                width: 7px;
                height: 7px;
            }
        }
    }

    &.active {
        &.small {
            #{$root} {
                &__item {
                    width: 24px;
                    height: 24px;

                    &:after {
                        width: 8px;
                        height: 8px;
                    }
                }
            }
        }
    }

    &__item {
        position: absolute;
        width: 36px;
        height: 36px;
        border: 1px solid #635d4c;
        transition: 0.4s;

        &:after {
            content: "";
            position: absolute;
            width: 6px;
            height: 6px;
            border: 1px solid $gold;
            transition: 0.4s;
        }

        &_top-left {
            top: 0;
            left: 0;
            border-bottom: 0;
            border-right: 0;

            &:after {
                top: -1px;
                left: -1px;
                border-right: 0;
                border-bottom: 0;
            }
        }

        &_top-right {
            top: 0;
            right: 0;
            border-bottom: 0;
            border-left: 0;

            &:after {
                top: -1px;
                right: -1px;
                border-left: 0;
                border-bottom: 0;
            }
        }

        &_bottom-left {
            bottom: 0;
            left: 0;
            border-top: 0;
            border-right: 0;

            &:after {
                bottom: -1px;
                left: -1px;
                border-right: 0;
                border-top: 0;
            }
        }

        &_bottom-right {
            bottom: 0;
            right: 0;
            border-top: 0;
            border-left: 0;

            &:after {
                bottom: -1px;
                right: -1px;
                border-left: 0;
                border-top: 0;
            }
        }
    }
}
</style>
