<template lang="pug">
transition(
	name="expand",
	@enter="enter",
	@after-enter="afterEnter",
	@leave="leave"
  appear
)
	slot
</template>

<script>
export default {
    data: () => ({
        timeout: null,
    }),
    methods: {
        enter(el) {
            el.style.height = "auto";

            const height = getComputedStyle(el).height;
            el.style.height = 0;
            getComputedStyle(el);
            setTimeout(() => {
                el.style.height = height;
            });
        },
        afterEnter(el) {
            el.style.height = "auto";
        },
        leave(el) {
            el.style.height = getComputedStyle(el).height;
            el.style.opacity = 0;
            el.style.marginTop = 0;
            el.style.marginBottom = 0;

            getComputedStyle(el);
            this.timeout = setTimeout(() => {
                el.style.height = 0;
                clearTimeout(this.timeout);
            }, 100);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./transition-expand.scss";
</style>
