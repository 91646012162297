<template lang="pug">
    perfect-scrollbar.l-aside(:class="{'l-aside_full-info': fullInfo, hidden: leftAsideHidden}" tag="aside" ref="scroll")
        template(v-if="page !== 'static'")
            ._wrap(v-click-outside="closeFullInfo")
                admin-menu(v-if="isEditor && page === 'person'")
                ._person-private-photos(v-if="isEditor && page === 'person'")
                    template(v-if="anfasPhoto || profilePhoto")
                        img(:src="anfasPhoto")._person-private-photo
                        img(:src="profilePhoto")._person-private-photo
                    span._no-photos(v-else) {{ locale("user-not-uploaded-photo") }}
                ._full-info
                    personal-data(:editPermitted="editPermitted" @closeEditProfile="closeEditProfile" v-if="page === 'person' && editPermitted")._personal-data
                    ._toggle-menu(@click="changeAsideHidden()" v-if="isUserAuth")
                        svg-icon(icon-name="toggle-menu" v-if="!leftAsideHidden")._toggle-menu-icon
                        svg-icon(icon-name="untoggle-menu" v-else)._toggle-menu-icon.-big
                        span._toggle-menu-text Свернуть
                    user-nav._user-nav
                ._ctrls-btn(v-if="haveRealPhotos")
                    retake-on-boarding
                    personal-recommendations._ctrl-btn(type="aside")
                connect-social(v-if="isUserAuth && isMyProfile")._connect-social
                ._legal-links
                    a(:href="SUPPORT_LINK" target="_blank" :class="{active: supportAccent}")._legal-link.-support
                        svg-icon(icon-name="telegram")._legal-icon
                        | {{ locale("feedback") }}
                    ._legal-link(@click="showModal('referral')" v-if="isUserAuth") {{ locale("referral-program") }}
                    ._legal-link(@click="showModal('payout')" v-if="isUserAuth") {{ locale("bonus-program") }}
                    //._legal-link(@click="showMerlinDatingPay = true" v-if="isUserAuth") Подписка Merlin Dating
                    ._legal-link.-toggle(@click="legalToggleActive = !legalToggleActive" :class="{active: legalToggleActive}") {{ locale("legal-info-title") }}
                        svg-icon(icon-name="chevron-down")._legal-link-icon
                    ._legal-toggle(v-if="legalToggleActive")
                        router-link(:to="{name: 'Static', params: { page: 'requisites'}}")._legal-link {{ locale("contacts-and-requisites") }}
                        router-link(:to="{name: 'Static', params: { page: 'about'}}")._legal-link {{ locale("about") }}
                        router-link(:to="{name: 'Static', params: { page: 'payments'}}")._legal-link {{ locale("service-pay") }}
                        router-link(:to="{name: 'Static', params: { page: 'term-of-use'}}")._legal-link {{ locale("term-of-use") }}
                        a._legal-link(href="/docs/privacy-policy.html") {{ locale("privacy-policy") }}
                    ._legal-link(@click="logout" v-if="isUserAuth") {{ locale("logout", {}, true) }}
        //transition(name="fadeIn")
        //    merlin-dating-pay(v-if="showMerlinDatingPay" @close="showMerlinDatingPay = false")
</template>

<script>
import { mapGetters } from "vuex";
import PersonalData from "../../person/personal-data/personal-data";
import FriendActions from "./friend-actions/friend-actions";
import UserNav from "../user-nav/user-nav";
import AdminMenu from "./admin-menu/admin-menu";
import { descriptionMixins } from "../../../mixins/descriptionMixins";
import PersonalRecommendations from "../../person/personal-recommendations/personal-recommendations";
import RecommendedPeopleCtrl from "./recommended-people-ctrl/recommended-people-ctrl";
import ConnectSocial from "../../person/connect-social/connect-social";
import MerlinDatingPay from "@/components/merlin-dating-pay/merlin-dating-pay";
import RetakeOnBoarding from "@/components/app/retake-onboarding/retake-onboarding";
import LangSwitcher from "@/components/app/lang-switcher/lang-switcher.vue";

export default {
    name: "LeftAside",
    components: {
        LangSwitcher,
        RetakeOnBoarding,
        MerlinDatingPay,
        ConnectSocial,
        RecommendedPeopleCtrl,
        PersonalRecommendations,
        AdminMenu,
        FriendActions,
        PersonalData,
        UserNav,
    },
    mixins: [descriptionMixins],
    data: () => ({
        lang: "ru",
        fullInfo: true,
        taskInterval: "",
        legalToggleActive: false,
        //showMerlinDatingPay: false
    }),
    computed: {
        ...mapGetters({
            page: "page",
            name: "person/name",
            personExternalId: "person/externalId",
            typeIcons: "personTypeIcons",
        }),
        leftAsideHidden: (state) => state.$store.getters["leftAsideHidden"],
        supportAccent: (state) =>
            (!state.anfas || !state.profile) && state.auth,
        displayName: (state) => {
            if (state.name === "Newbie") {
                return state.locale("newbie", {}, true);
            }
            return state.name;
        },
        leaderType: (state) => state.$store.getters["person/leader_type"],
        leaderTypeIcon: (state) => state.typeIcons[state.leaderType],
        avatar: function () {
            return this.isMyProfile
                ? this.$store.getters["user/myAvatar"]
                : this.$store.getters["person/photos"]["avatar"];
        },
        anfasPhoto: (state) => state.$store.getters["person/photos"].anfas,
        profilePhoto: (state) => state.$store.getters["person/photos"].profile,
        editPermitted: function () {
            return this.isEditor && this.page === "person";
        },
        currentPersonUuid: (state) => state.$store.getters["person/uuid"],
        havePhotos: (state) =>
            state.$store.getters["user/photos"].profile &&
            state.$store.getters["user/photos"].anfas,
        externalIdPresent: (state) =>
            state.$store.getters["user/externalIdPresent"],
    },
    methods: {
        changeAsideHidden() {
            localStorage.setItem(
                "leftAsideHidden",
                JSON.stringify(!this.leftAsideHidden),
            );
            this.$store.dispatch("set", {
                name: "leftAsideHidden",
                value: !this.leftAsideHidden,
            });
        },
        closeFullInfo() {
            if (this.fullInfo) {
                this.fullInfo = false;
            }
        },
        closeEditProfile() {
            this.$refs.scroll.$el.scrollTop = 0;
        },
        showModal(name) {
            this.$modal.show(name, { props: { allowComponent: true } });
        },
        async logout() {
            await this.$store.dispatch("auth/signout");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "l-aside";
</style>
