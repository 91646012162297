<template lang="pug">
    .lang-switcher
        multiselect._input(@select="setLocale" v-model="lang" :searchable="false" :options="langs" label="name" selectLabel="" deselectLabel="" selectedLabel="" :placeholder="locale('selectCountry')")
            template(v-slot:option="{ option }")
                ._country
                    img(:src="require(`../../../assets/images/flags/${option.code}.jpg`)")._flag
                    ._name {{ option.name }}            
            template(v-slot:singleLabel="singleValue")
                ._country
                    img(:src="require(`../../../assets/images/flags/${singleValue.option.code}.jpg`)")._flag
                    ._name {{ singleValue.option.name }}
</template>

<script>
import multiselect from "vue-multiselect/src";

export default {
    name: "LangSwitcher",
    components: {multiselect},
    data: function () {
        return {
            isInit: false,
            lang: {
                code: "ru",
                name: "Русский"
            },
            locales: {
                "ru": "Русский",
                "en": "English",
                "uz": "O'zbek",
                "tj": "Tоҷикӣ",
                "az": "Azərbaycan",
                "kz": "Қазақ",
                "kg": "Кыргызча"
            }
        };
    },
    computed: {
        langs: function () {
            return this.$i18n.availableLocales.map(lang => {
                return {
                    code: lang,
                    name: this.locales[lang]
                };
            });
        }
    },
    mounted() {
        this.lang = this.langs.find(lang => lang.code === this.$i18n.locale);
    },
    methods: {
        setLocale(value) {
            localStorage.setItem("lang", value.code);
            this.$root.$i18n.locale = value.code;
            if(process.env.NODE_ENV !== "development") {
                window.location.reload();
            }
        }
    }
};
</script>

<style lang="scss" src="./lang-switcher.scss"></style>
