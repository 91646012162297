import gql from "graphql-tag";import gql from "graphql-tag";
<template lang="pug">
    .multi-compare(:class="type")
        ._title Режим мультисравнения активирован
        ._compare-name(v-if="type === 'aside'") {{ compareName }}
        ._list(v-if="listAvailable" data-tip="multi-compare")
            ._item(v-for="compare in compares" :key="compare.id" :class="{'multi-compare__item_active': compare.selected, 'multi-compare__item_disabled': !checkAvailableStatus(compare.planId)}" @click="selectCompare(compare)" @mouseenter="compareName = compare.name" @mouseleave="compareName = ''")
                ._item-icon-box.shine
                    svg-icon(:icon-name="compare.icon" :class="'multi-compare__item-icon_'+compare.icon")._item-icon
                ._item-name(v-html="compare.name")
        ._not-aviable(v-else) У вас нет ни одной подписки по тарифу PLATINUM. Для того чтобы пользовать массовым сравнением
            span._not-aviable-link(@click="$modal.show('subscription')") оформите
            |  хотя бы одну подписку на PLATINUM тариф.
        ._footer(v-if="listAvailable" ref="multiCompareFooter" :class="{fixed: fixedFooter}")
            ui-button(:text="loadingText" :disabled="!compares.find(compare => compare.selected) || !persons.length" @click.native="startMultiCompare" :class="{disabled: isStart}")
            ._error
                span(v-if="!compares.find(compare => compare.selected)") Для того, чтобы провести анализ, выберите хотябы одну категорию сравнения.
                span(v-else-if="!persons.length") Для того, чтобы провести анализ, выберите хотябы одного друга из списка.

</template>

<script>
import gql from "graphql-tag";

import compareWithPersonsMultipleCreateMutation from "@/graphql/mutations/compareWithPersonsMultipleCreate.mutation.graphql";
import personIndicatorsMultipleCreateMutation from "@/graphql/mutations/personIndicatorsMultipleCreate.mutation.graphql";
import taskMultipleGetQuery from "@/graphql/queries/taskMultipleGet.query.graphql";
import UiButton from "../../ui/ui-button/ui-button";

export default {
    name: "MultiCompare",
    components: { UiButton },
    props: {
        type: {
            default: "",
            required: false,
            type: String,
        },
    },
    data() {
        return {
            compareName: "",
            isLoading: false,
            runningTasks: [], // Все созданные задачи
            runningTasksErrors: [], // Ошибки, которые возникли в процессе
            comparesList: [], // Лист с запросами на сравнение
            indicatorsList: [], // Лист с запросами индикаторов
            readyTasks: 0, // Количество готовых сравнений
            errorTasks: 0, // Количество сравнений с ошибками
            loadingText: "Проверить совместимость",
            isStart: false,
            fixedFooter: false,
            planId: 0,
        };
    },
    computed: {
        persons: (state) => state.$store.getters["multiCompare/persons"],
        compares: (state) =>
            state.$store.getters["serviceTypes"]
                .filter((compare) => compare.availableToMultiCompare)
                .map((compare) => {
                    state.$set(compare, "selected", false);
                    return compare;
                }),
        subscriptions: (state) =>
            state.$store.getters["user/subscriptionCategories"],
        listAvailable: (state) =>
            state.subscriptions.find((plan) => plan.level === "PLATINUM"),
        activeCompares: (state) =>
            state.compares.filter((compare) => compare.selected),
        totalTasks: (state) => state.runningTasks.length,
    },
    mounted() {
        if (this.listAvailable) {
            this.showTips("multi-compare");
        }
    },
    methods: {
        selectCompare(compare) {
            if (this.checkAvailableStatus(compare.planId)) {
                compare.selected = !compare.selected;
            } else {
                this.planId = compare.planId;
                this.$store.dispatch("changeConfirmDialog", {
                    show: true,
                    loading: false,
                    title: "Требуется подписка",
                    text: `Необходимо оформить подписку на PLATINUM-тариф категории "${compare.planName}"."`,
                    price: 0,
                    comparisonCount: "",
                    buttons: true,
                    cancelText: "Отмена",
                    confirmText: "Оформить подписку",
                    callback: this.showSubscriptionModal,
                });
            }
        },
        showSubscriptionModal() {
            this.$modal.show("subscription", {
                categoryId: this.planId,
            });
        },
        checkAvailableStatus(planId) {
            return this.subscriptions.find(
                (plan) => plan.id === planId && plan.level === "PLATINUM",
            );
        },
        async startMultiCompare() {
            if (this.isStart) return;
            this.isStart = true;
            this.isLoading = true;
            this.loadingText = "Подготовка запросов...";
            await this.prepareQueries();

            if (this.comparesList.length) {
                await this.getCompares();
            }

            if (this.indicatorsList.length) {
                await this.getIndicators();
            }

            if (this.totalTasks > 0) {
                this.loadingText = "Отправка запроса...";
                await this.checkRunningTasks();
                return;
            }
            this.checkTasksStatus();
        },
        async getCompares() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${compareWithPersonsMultipleCreateMutation}
                    `,
                    variables: {
                        input: this.comparesList,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    response.data.compareWithPersonsMultipleCreate.tasks.forEach(
                        (item) => {
                            if (item.task) {
                                this.runningTasks.push(item.task);
                            } else if (item.error) {
                                this.processErrorsWhileExecuting(item.error);
                            }
                        },
                    );
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        this.$msgBox(
                            this.localeError("error"),
                            this.localeError("retry"),
                        );
                    }
                });
        },
        async getIndicators() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${personIndicatorsMultipleCreateMutation}
                    `,
                    variables: {
                        input: this.indicatorsList,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    response.data.personIndicatorsMultipleCreate.tasks.forEach(
                        (item) => {
                            if (item.task) {
                                this.runningTasks.push(item.task);
                            }
                        },
                    );
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        this.$msgBox(
                            this.localeError("error"),
                            this.localeError("retry"),
                        );
                    }
                });
        },
        async prepareQueries() {
            for (const person of this.persons) {
                for (const analyse of this.activeCompares) {
                    if (person.analysesAvailable) {
                        if (analyse.type === "compare") {
                            this.comparesList.push({
                                comparedPersonId: person.personId,
                                compareType: analyse.id,
                            });
                        } else if (analyse.type === "indicator") {
                            this.indicatorsList.push({
                                personId: person.personId,
                                indicatorType: analyse.id,
                            });
                        }
                    }
                }
            }
        },
        async checkRunningTasks() {
            await this.$apollo
                .query({
                    query: gql`
                        ${taskMultipleGetQuery}
                    `,
                    variables: {
                        tasks: this.runningTasks,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    response.data.taskMultipleGet.forEach((task) => {
                        switch (task.status) {
                            case "COMPLETED":
                                this.removeValueFromArray(
                                    this.runningTasks,
                                    task.uuid,
                                );
                                this.readyTasks++;
                                break;

                            case "ERROR":
                                this.removeValueFromArray(
                                    this.runningTasks,
                                    task.uuid,
                                );
                                this.readyTasks++;
                                this.errorTasks++;
                                this.processErrorsWhileExecuting(task.error);
                                break;
                        }
                    });

                    this.checkTasksStatus();
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1002:
                                // retry
                                setTimeout(() => {
                                    console.log("retry");
                                    this.checkRunningTasks();
                                }, 1000);
                                break;

                            default:
                                this.$msgBox(
                                    this.localeError(
                                        "error",
                                    ),
                                    this.localeError(
                                        "retry",
                                    ),
                                );
                        }
                    }
                });
        },
        checkTasksStatus() {
            if (this.readyTasks >= this.totalTasks) {
                this.$store.dispatch("filterResults", {
                    term: "",
                });
                this.isStart = false;
                this.$emit("saveComparedPersons", this.persons);
                this.$store.dispatch("multiCompare/setPersons", []);
                this.$store.dispatch("multiCompare/setSuccessStatus", true);
                this.$store.dispatch("multiCompare/setStatus", false);
            } else if (this.readyTasks < this.totalTasks) {
                this.loadingText = `Идёт анализ: ${this.readyTasks} из ${this.totalTasks}`;
                setTimeout(() => {
                    this.checkRunningTasks();
                }, 1000);
            }
        },
        processErrorsWhileExecuting(error) {
            switch (error) {
                case 1007:
                    // skip already exist analyse
                    break;

                case 1008:
                    this.runningTasksErrors.push(
                        "Не хватило средств для покупки услуги.",
                    );
                    break;

                case 1300:
                    this.runningTasksErrors.push(
                        "Вы не загрузили свои фотографии в анфас и профиль.",
                    );
                    break;

                case 1301:
                    this.sentUploadPhotoRequests++;
                    break;

                default:
                    this.runningTasksErrors.push("Неизвестная ошибка " + error);
            }
        },
        removeValueFromArray: function (array, value) {
            array.splice(array.indexOf(value), 1);
        },
    },
};
</script>

<style lang="scss" src="./multi-compare.scss"></style>
