<template lang="pug">
    div.select
        v-select(
            append-to-body
            autoscroll
            label="name"
            :disabled="disabled"
            :options="options"
            :value="value"
            :filterable="false"
            :clearable="false"
            :placeholder="placeholder ? placeholder : locale('choose')"
            @input="emitChange"
        )
            template(v-slot:no-options)
                | {{ locale("nothing") }}
            template(v-slot:open-indicator)
                svg-icon(icon-name="chevron-down" added-class="select__chevron-down")
            template(v-slot:selected-option)
                | {{ placeholder ? placeholder : locale("choose") }}

</template>

<script>
import vSelect from "vue-select";

export default {
    name: "Select",
    components: {
        "v-select": vSelect,
    },
    props: {
        placeholder: {
            require: true,
            type: String,
            default: ""
        },
        value: {
            require: true,
            type: [String, Number],
            default: ""
        },
        fieldId: {
            require: true,
            type: String,
            default: ""
        },
        fieldName: {
            require: true,
            type: String,
            default: ""
        },
        options: {
            require: true,
            type: Array,
            default: () => ([])
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        isOpen: false,
        timeout: "",
    }),
    watch: {
        placeholder(val) {
            console.log(val);
        },
    },
    methods: {
        emitChange(option) {
            this.$emit("changeModel", {
                fieldId: this.fieldId,
                fieldIdValue: option.id,
                fieldName: this.fieldName,
                fieldNameValue: option.name,
            });
        },
    },
};
</script>

<style lang="scss">
@import "select";
</style>
