<template lang="pug">
	.install-app
		._wrap
			._wrap-content
				._wrap-content-sub
					button._wrap-content-sub-close-btn(@click="$emit('close')")
						svg-icon(iconName="cross")
				svg-icon._wrap-content-install-logo(iconName="install-logo")
				._wrap-content-desc
					span._wrap-content-desc-title Merlinface
					span._wrap-content-desc-sub Merlin AI, LLC
					svg-icon._wrap-content-desc-stars(iconName="stars")
			ui-button._wrap-install-btn(
				@click.native="install",
				type="bordered",
				color="gray",
				text="Установить",
				size="small"
			)
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";

export default {
    components: { UiButton, TransitionExpand },
    props: {
        deferredPrompt: {
            type: Object,
            default: () => ({}),
            required: false
        }
    },
    methods: {
        install() {
            // Show the prompt
            this.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the A2HS prompt");
                } else {
                    console.log("User dismissed the A2HS prompt");
                }
                this.$emit("close");
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "./install-app.scss";
</style>
