import gql from "graphql-tag";
import onBoardingTypesQuery from "@/graphql/queries/onBoardingTypes.query.graphql";
import onBoardingSwitchTypeMutation from "@/graphql/mutations/onBoardingSwitchType.mutation.graphql";
import Vue from "vue";

Vue.mixin({
    data() {
        return {
            onBoardingSwitchTypeVarFromMixin: null
        };
    },
    computed: {
        userOnBoardingTypesFromMixin: (state) =>
            state.$store.getters["user/onBoardingTypes"],
        userPassedOnBoardingTypesFromMixin: (state) =>
            state.$store.getters["user/passedOnBoardingTypes"],
    },
    methods: {
        async getOnBoardingTypesFromMixin() {
            await this.$apollo
                .query({
                    query: gql(onBoardingTypesQuery),
                    fetchPolicy: "no-cache"
                })
                .then((r) => {
                    console.log("completedOnBoardingTypes", r.data.onBoardingTypes);
                    this.$store.dispatch(
                        "user/setOnBoardingTypes",
                        r.data.onBoardingTypes,
                    );
                });
        },
        async cancelSecondOnBoardingFromMixin() {
            const selectedOnBoardingType = await this.getOnBoardingCompletedSteps();
            const firstOnBoardingStep = selectedOnBoardingType.find(
                (step) => step.step_number === 1,
            );

            const resetId = this.userPassedOnBoardingTypesFromMixin.find(
                (i) =>
                    i.poll_value_id !== firstOnBoardingStep.step_result_int && i.passed
            );

            console.log(resetId);

            this.$apollo
                .mutate({
                    mutation: gql(onBoardingSwitchTypeMutation),
                    variables: {
                        type: resetId.type,
                    },
                })
                .then(() => {
                    window.location.reload();
                });
        },
        confirmOnBoardingSwitch(type) {
            let text = this.locale("onBoardingMixin.description-1", {}, true),
                title = this.locale("onBoardingMixin.title-1", {}, true);
            this.onBoardingSwitchTypeVarFromMixin = type;


            if (type === "PROFESSION_ORIENTATION") {
                title = this.locale("onBoardingMixin.title-1", {}, true);
                text = this.locale("onBoardingMixin.description-2", {}, true);
            }

            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: title,
                text: text,
                buttons: true,
                confirmText: "Начать",
                callback: this.onBoardingSwitchTypeFromMixin,
            });
        },
        startPsychotype() {
            this.onBoardingSwitchTypeFromMixin("PSYCHOTIC_DETECTION");
        },
        onBoardingSwitchTypeFromMixin() {
            this.$apollo
                .mutate({
                    mutation: gql(onBoardingSwitchTypeMutation),
                    variables: {
                        type: this.onBoardingSwitchTypeVarFromMixin,
                    },
                })
                .then(() => {
                    window.location.reload();
                });
        },
    },
});
