<template lang="pug">
  .merlin-dating
    ._group
      ._desc {{ locale("title", {name: personName === "newbie" ? locale("newbie", {}, true) : personName }) }}?
        br/
        b {{ locale("subtitle") }}
    ._footer
      a(href="https://apps.apple.com/ru/app/merlin/id1532219463" target="_blank" v-if="isApple || (!isApple && !isAndroid)")._button
        ui-button(type="merlin-app")._button-content
          template(v-slot:content)
            img(src="../../../assets/images/app-stores/app-store.png")
      a(href="https://play.google.com/store/apps/details?id=merlin.app.merlinface" target="_blank" v-if="isAndroid || (!isApple && !isAndroid)")._button
        ui-button(type="merlin-app")._button-content
          template(v-slot:content)
            img(src="../../../assets/images/app-stores/google-play.png")
</template>

<script>
import UiButton from "../../ui/ui-button/ui-button";

export default {
    name: "MerlinDating",
    components: { UiButton },
    props: {
        personName: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            isAndroid: false,
            isApple: false,
            device: null,
        };
    },
    created() {
        let userAgent = navigator.userAgent || navigator.vendor || window.opera;
        this.device = userAgent;
        if (/android/i.test(userAgent)) {
            this.isAndroid = true;
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            this.isApple = true;
        } else if (/(Mac)/i.test(navigator.platform)) {
            this.isApple = true;
        }
    },
};
</script>

<style src="./merlin-dating.scss" lang="scss"></style>
