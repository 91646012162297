<template lang="pug">
    .service-collapse(:class="[classes, size]")
        element-decor(:design="design" size="small" :active="isOpen", v-if="design !== 'gold'")
        ._head(@click="toggle")
            ._title(v-if="!icon")
                span(v-html="title")
                ui-button(type="text" :text="locale('openNow')" v-if="timer" @click.native="confirmSubscription")
            ._title-decor(v-else-if="icon")
                svg-icon._title-decor-icon(
                    :iconName="icon",
                    :class="'service-collapse__title-decor-icon-' + icon"
                )
                ._title-decor-text(v-html="title")
            slot(name="ctrl", v-if="!subscription")._ctrl
                svg-icon(icon-name="chevron-down")._chevron-down
        ._content-subscription(v-if="subscription")
            ._content-subscription-title Результат доступен по подписке:
            ._content-subscription-gold(v-if="subscription === 'gold'") <b>Merlin Gold</b> — Мотивация и энергия
            ._content-subscription-plat(v-if="subscription === 'plat'") <b>Merlin Platinum</b> — Мотивация и энергия
        ._wrap(ref="wrap")
            ._content(ref="content")
                slot(name="content", v-if="!subscription") Не указан контент
</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
export default {
    name: "ServiceCollapse",
    components: { UiButton },
    props: {
        serviceIndex: {
            required: false,
            default: null,
            type: Number,
        },
        categoryId: {
            required: false,
            default: null,
            type: Number,
        },
        title: {
            required: true,
            default: "",
            type: String,
        },
        design: {
            required: false,
            default: "",
            type: String,
        },
        open: {
            required: false,
            default: true,
            type: Boolean,
        },
        timer: {
            required: false,
            default: false,
            type: Boolean,
        },
        disabled: {
            required: false,
            default: false,
            type: Boolean,
        },
        size: {
            type: String,
            required: false,
            default: "",
        },
        subscription: {
            type: String,
            required: false,
            default: "",
        },
        icon: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            isOpen: false,
        };
    },
    computed: {
        wrap: (state) => state.$refs.wrap,
        content: (state) => state.$refs.content,
        classes: (state) => ({
            "service-collapse_active": state.isOpen,
            gray: state.design === "gray",
        }),
    },
    watch: {
        serviceIndex() {
            this.toggle();
            setTimeout(() => {
                this.toggle();
            });
        },
    },
    mounted() {
        if (this.open) {
            this.toggle();
        }
    },
    methods: {
        toggle() {
            if (this.disabled || this.subscription) return;
            const contentHeight = this.content.clientHeight;
            if (!this.isOpen) {
                this.wrap.style.maxHeight = `${contentHeight}px`;
                this.isOpen = true;
            } else {
                this.wrap.style.maxHeight = 0;
                this.isOpen = false;
            }
        },
        confirmSubscription() {
            this.$store.dispatch("changeConfirmDialog", {
                show: true,
                loading: false,
                title: this.locale("confirmTitle"),
                text: this.locale("confirmText"),
                buttons: true,
                confirmText: this.locale("confirmBtn"),
                callback: this.openSubscriptions,
            });
        },
        openSubscriptions() {
            this.$emit("close-recommendations");
            this.$modal.show("subscription", { categoryId: this.categoryId });
        },
    },
};
</script>

<style src="./service-collapse.scss" lang="scss"></style>
