<template lang="pug">
    label.ui-checkbox
        input(:type="type" :name="name" :checked="checked" :id="name" :value="id" @change="emitValue")._field
        ._box
            ._box-view
            ._box-label(v-html="label")
</template>

<script>
export default {
    name: "UiCheckbox",
    props: {
        type: {
            type: String,
            default: "",
        },
        label: {
            type: String,
            default: "",
        },
        name: {
            type: String,
            default: "",
        },
        id: {
            type: String,
            default: "",
        },
        checked: {
            type: Boolean,
            default: false,
        },
        for: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            check: false,
        };
    },
    watch: {
        checked(status) {
            console.log(status);
            if (status) {
                this.check = true;
            }
        },
    },
    created() {
        if (this.checked) {
            this.check = true;
        }
    },
    methods: {
        emitValue($event) {
            if(this.for === "promocodes") {
                return this.$emit("update:model", $event.target.checked);
            }
            this.$emit("update:model", $event.target.value);
        },
    },
};
</script>

<style lang="scss">
.ui-checkbox {
    $root: &;

    &__field {
        display: none;

        &:checked {
            + #{$root} {
                &__box {
                    #{$root} {
                        &__box-view {
                            &:after {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    &__box {
        display: flex;
        align-items: center;

        &:hover {
            cursor: pointer;
        }
    }

    &__box-view {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 20px;
        height: 20px;
        border-radius: 10px;
        border: 1px solid #434343;
        background: #31302c;
        flex: none;

        &:after {
            content: "";
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background: $gold;
            transition: 0.4s;
            opacity: 0;
        }
    }

    &__box-label {
        font-size: 14px;
        margin-left: 14px;
        color: rgba(#fff, 0.5);
        
        a {
            color: $gold;
        }
    }
}
</style>
