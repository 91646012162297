export const multiCompare = {
    namespaced: true,
    state: {
        isActive: false,
        isSuccess: false,
        persons: [],
    },
    actions: {
        setStatus({ commit }, status) {
            commit("setStatus", status);
        },
        setSuccessStatus({ commit }, status) {
            commit("setSuccessStatus", status);
        },
        setPersons({ commit }, persons) {
            commit("setPersons", persons);
        },
        reset({ commit }) {
            commit("reset");
        },
    },
    getters: {
        isActive: (state) => state.isActive,
        isSuccess: (state) => state.isSuccess,
        persons: (state) => state.persons,
    },
    mutations: {
        setPersons(state, persons) {
            state.persons = persons;
        },
        reset(state) {
            state.persons = [];
            state.isActive = false;
        },
        setStatus(state, status) {
            state.isActive = status;
            if (status) {
                state.isSuccess = false;
            }
        },
        setSuccessStatus(state, status) {
            state.isSuccess = status;
        },
    },
};
