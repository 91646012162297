<template lang="pug">
  .person-webcam(:class="typeClassModifier")
    ._container
      ._mask
      ._help
        span._help-text Встаньте перед камерой так, <br> чтобы ваше лицо совпадало с силуэтом
      ._camera
        vue-web-cam._camera-output(
          ref="webcam"
          select-first-device
          playsinline
          autoplay
          @error="onError"
          @notsupported="onNotSupported"
          )
      ._apply
        button._apply-btn(@click="makePhoto")
          svg-icon._apply-icon(icon-name="photo")
</template>

<script>
import { isMac, mirrorBase64Image } from "@/utils/navigator-utils";
import { WebCam } from "vue-web-cam";

const PHOTO_TYPE = {
    UNFACE: "unface",
    PROFILE: "profile",
};

const ERROR = {
    NOT_ALLOWED: "NotAllowedError",
};

export default {
    name: "PersonWebcam",
    components: {
        "vue-web-cam": WebCam,
    },
    props: {
        /**
         * unface | profile
         */
        type: {
            type: String,
            required: false,
            default: PHOTO_TYPE.UNFACE,
        },
        categoryId: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            img: null,
            camera: null,
            deviceId: null,
            devices: [],
        };
    },
    computed: {
        photoTypes() {
            return PHOTO_TYPE;
        },
        typeClassModifier() {
            return {
                "person-webcam_type_profile": this.type === PHOTO_TYPE.PROFILE,
                "person-webcam_type_unface": this.type === PHOTO_TYPE.UNFACE,
                "person-webcam_reversed": this.isReversed,
            };
        },
        isReversed() {
            return isMac();
        },
    },
    methods: {
        onError(error) {
            switch (error.name) {
                case ERROR.NOT_ALLOWED: {
                    this.$msgBox(
                        "Ошибка доступа",
                        "Проверьте возможность доступа к веб-камере",
                    );
                    break;
                }
                default: {
                    this.$msgBox(
                        "Ошибка",
                        "Неизвестная ошибка при работе с веб-камерой",
                    );
                }
            }
            this.emitError(error);
        },
        onNotSupported() {
            this.$msgBox(
                "Ошибка поддержки",
                "Ваш браузер не поддерживает работу веб-камеры",
            );
        },
        async makePhoto() {
            const { webcam } = this.$refs;

            const photo = webcam.capture();

            const imageToUpload = this.isMac
                ? await mirrorBase64Image(photo)
                : photo;

            this.emitPhoto(imageToUpload);
            this.emitClose();
        },

        emitPhoto(imageEncoded) {
            const id = this.categoryId;
            this.$emit("capture", { imageEncoded, id });
        },
        emitError(error) {
            this.$emit("error", error);
        },
        emitClose() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss" src="./person-webcam.scss" />
