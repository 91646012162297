<template lang="pug">
  .subscriptions-categories-item(:class="getClassModifiers" :style="{ 'color': accent }")
    ._icon
      svg-icon._icon-svg(:icon-name="icon")
    ._text {{ label }}
    ._sub(v-if="isActive")
      ._sub-icon
        svg-icon(icon-name="sub-success" :style="{ 'color': `${accent}!important` }")
      ._sub-text(:style="{ 'color': accent }")

</template>

<script>
export default {
    name: "SubscriptionsCategoriesItem",
    props: {
        icon: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            required: true,
        },
        isActive: {
            type: Boolean,
            required: false,
            default: false,
        },
        isCurrent: {
            type: Boolean,
            required: false,
            default: false,
        },
        accent: {
            type: String || null,
            required: false,
            default: null,
        },
    },
    computed: {
        getClassModifiers() {
            return {
                "subscriptions-categories-item_active": this.isActive,
                "subscriptions-categories-item_current": this.isCurrent,
            };
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-categories-item.scss" />
