export const DEVICE_TYPE = {
    VIDEO: "videoinput",
};

export async function detectDevice(deviceType) {
    let mediaDevices = navigator.mediaDevices;

    if (!mediaDevices || !mediaDevices.enumerateDevices) {
        return false;
    }

    const devices = await mediaDevices.enumerateDevices();
    const userHasDevice = devices.some((device) => deviceType === device.kind);

    return userHasDevice;
}

export function dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    const u8arr = new Uint8Array(n);
    let n = bstr.length;

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export function isMac() {
    return navigator.platform.toUpperCase().indexOf("MAC") >= 0;
}

export async function mirrorBase64Image(srcBase64) {
    return new Promise((resolve) => {
        const canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let image = new Image();

        image.onload = function () {
            ctx.scale(-1, 1);
            ctx.drawImage(image, 0, 0, image.width * -1, image.height);
            const dataURL = canvas.toDataURL("image/jpeg");
            resolve(dataURL);
        };

        image.src = srcBase64;
    });
}
