<template lang="pug">
    .landing-advantages.section(ref="advantages")
        ._wrap.wrap
            h2._title {{ locale("title") }}
            ._content
                ._list
                    ._item(v-for="advantage in advantages" :key="advantage.id" @click="setActiveAdvantage(advantage)" :class="{active: advantage.active, big: advantage.big}")
                        ._item-icon-box
                            svg-icon(:icon-name="advantage.code" added-catalog="landing/advantages")._item-icon
                        ._item-content
                            h3._item-title {{ advantage.title }}
                            ._item-play(v-if="!advantage.active && !advantage.big")
                                svg-icon(icon-name="play" added-catalog="landing")._item-play-icon
                                ._item-play-text {{ locale("showVideo") }}
                            transition-expand
                                p._item-description(v-if="advantage.big || advantage.active") {{ advantage.description }}
                        transition-expand
                            video(autoplay="autoplay" muted loop playsinline preload="none" v-if="advantage.active && pageWidth <= 991")._video.-m
                                source(:src="webm" type="video/webm")
                                source(:src="mp4" type="video/mp4")
                    transition(name="fade")
                transition(name="fade")
                    ._sticky(ref="sticky")
                      video(autoplay="autoplay" muted loop playsinline preload="none" v-if="isVideoReady")._video
                          source(:src="webm" type="video/webm")
                          source(:src="mp4" type="video/mp4")
</template>

<script>
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";

export default {
    name: "LandingAdvantages",
    components: { TransitionExpand },
    data() {
        return {
            videoInterval: null,
            isVideoReady: true,
        };
    },
    computed: {
        advantages: state => state.locale("list"),
        activeAdvantage: (state) => state.advantages.find((i) => i.active),
        webm: (state) =>
            state.activeAdvantage?.id
                ? require(`./video/${state.activeAdvantage.code}.webm`)
                : "",
        mp4: (state) =>
            state.activeAdvantage?.id
                ? require(`./video/${state.activeAdvantage.code}.mp4`)
                : "",
        videoEl: (state) => state.$refs.video,
        sticky: (state) => state.$refs.sticky,
    },
    watch: {
        "activeAdvantage.id"() {
            this.isVideoReady = false;
            setTimeout(() => {
                this.isVideoReady = true;
            }, 50);
        },
    },
    created() {
        //window.addEventListener("scroll", this.stickyVideo);
    },
    methods: {
        stickyVideo() {
            const scrollTop = window.pageYOffset;
            const videoOpts = this.videoEl.getBoundingClientRect();
            const stickyOpts = this.sticky.getBoundingClientRect();

            if (scrollTop > stickyOpts.top + scrollTop - 100) {
                if(scrollTop >= stickyOpts.top + scrollTop + videoOpts.height - 400) return;
                this.videoEl.style.top =
                    Math.abs(scrollTop + stickyOpts.top - scrollTop - 100) + "px";
            }
        },
        setActiveAdvantage(advantage) {
            this.advantages.forEach((item) => {
                item.active = false;
            });
            advantage.active = true;
        },
    },
};
</script>

<style lang="scss" src="./landing-advantages.scss"></style>
