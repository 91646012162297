<template lang="pug">
    .auth-form(ref="authForm")
        transition(name="fadeIn")
            loading(v-if="isUserLoading")
        router-link._back(to="/" v-if="step === 'phone'")
            svg-icon(icon-name="arrow-left")._back-icon
            ._back-text {{ locale("back") }}
        ._back.-other-phone(@click="step = 'phone'" v-if="step === 'confirm-phone'")
            svg-icon(icon-name="arrow-left")._back-icon
            ._back-text {{ locale("another-phone") }}
        ._back.-other-phone(@click="step = 'phone'" v-if="step === 'confirm-telegram'")
            svg-icon(icon-name="arrow-left")._back-icon
            ._back-text {{ locale("prev-step") }}
        #recaptcha
        ._input-group(v-if="step === 'phone'")
            label(for="number")._input-title {{ locale("auth") }}
            ._input-group-desc {{ locale("confirm-phone") }}
            ._resend.-error(v-if="errorMessage || smsTimerCountdown > 0")
                template(v-if="smsTimerCountdown > 0") {{ locale("retry-after", {timer: smsTimerCountdown}) }}
                template(v-else) {{ errorMessage }}
            ._inline-inputs
                ._input-helper
                    ._countru-code
                        input(v-model.number="countryCode" @keydown="codeDropdownStatus = true" @blur="changeCountryCode('temporary')" v-mask="['+#', '+##', '+###']")._input.-country-code
                        transition(name="show-code-dropdown" appear)
                            ._country-code-dropdown(v-show="codeDropdownStatus")
                                ._top-left-decor
                                ._top-right-decor
                                ._bottom-left-decor
                                ._bottom-right-decor
                                perfect-scrollbar._country-code-dropdown-inner
                                    ._country-code-item(v-for="code in countryCodesFiltered" @click.stop="changeCountryCode(code.phone_code)") {{ code.full }}
                                    ._country-code-empty(v-if="!countryCodesFiltered.length") {{ locale("not-found") }}
                    ._input-help {{ locale("country-code") }}
                ._input-helper.-phone
                    input(type="tel" inputmode="numeric" v-model="number" id="number" v-focus @keyup.enter="getCode()" v-mask="countryCode === '+7' ? ['(###) ### ## ##'] : ['### ### ### ###']")._input
                    ._input-help {{ locale("phone") }}
            ui-button(@click.native="getCode()" size="full" :arrow="true" :text="confirmBtnText" :disabled="!allowedSmsSend")._btn
            vue-telegram-login(v-if="process !== 'development'" mode="callback" telegram-login="Merlin_registration_bot" @callback="telegramHandler" requestAccess="write" :userpic="false" radius="1" ref="telegram-login")._telegram
            promocode._promocode(@changePromocode="changePromocode")
            ._agree-text {{ locale("agree_1") }}
                router-link(to="/docs/term-of-use")._agree-link {{ locale("agree_2") }}
                |  {{ locale("agree_and") }}&nbsp;
                router-link(to="/docs/privacy-policy.html" target="_blank")._agree-link {{ locale("agree_3") }}
                | .

        ._input-group(v-if="step === 'confirm-telegram' && process !== 'development'")
            ._input-title.-telegram
                span(v-html="telegramAuthText")
                span._input-title-dots
            ._phone
                ._telegram-loading-box(v-if="isFetchAccountSend")
                    loading._telegram-loading
                template(v-else)
                    ._telegram-message
                        img(src="../../../assets/images/auth-form/bot-avatar.jpg")._telegram-message-avatar
                        ._telegram-message-text 📱 {{ locale("telegram") }}
                    img._telegram-interface(src="../../../assets/images/auth-form/telegram-interface.png")


        ._input-group(v-if="step === 'confirm-phone'")
            ._phone
                ._phone-type {{ confirmationType === "CALL" ? locale("call") : locale("message") }}
                ._phone-indicator
                    svg-icon(:icon-name="confirmationType.toLowerCase()")._phone-icon
                div(:class="exampleClass" v-html="exampleText")
            ._code-group
                ._code-text(v-html="codeText")
                input(type="tel" inputmode="numeric" v-mask="[codeMask]" :placeholder="placeholder" v-model="smsCode"  ref="codeInput" v-focus @keyup.enter="confirmSmsCode" @keyup="confirmSmsCode")._input.-small

            ._resend.active(v-if="errorMessage")
                template(v-if="smsTimerCountdown > 0 && errorMessage === 'BLOCKED'") {{ locale("blocked", {timer: smsTimerCountdown}) }}
                template(v-else) {{errorMessage}}

            ._resend.active(v-else)
                template(v-if="smsTimerCountdown > 0") {{ locale("retry-error", {timer: smsTimerCountdown}) }}
                ui-button(v-else text="запросить код повторно" @click.native="getCode()" size="full")._btn

            ui-button(:text="enterBtnText" size="full" @click.native="confirmSmsCode()" :disabled="loading || $v.smsCode.$error" :class="{disabled: loading || $v.smsCode.$error}" :arrow="true")._btn

</template>

<script>
import { maxLength, minLength, required } from "vuelidate/lib/validators";
import { mask } from "vue-the-mask";
import { phoneConfirmMixins } from "../../../mixins/phoneConfirmMixins";
import UiButton from "../../ui/ui-button/ui-button";
import Promocode from "../../promocode/promocode";
import { vueTelegramLogin } from "vue-telegram-login";
import gql from "graphql-tag";
import exchangeTelegramPayloadToTokenMutation from "@/graphql/mutations/exchangeTelegramPayloadToToken.mutation.gql";
import { initializeApp } from "firebase/app";
import {
    getAuth,
    RecaptchaVerifier,
    signInWithPhoneNumber,
} from "firebase/auth";
import newUserPhoneQuery from "@/graphql/queries/newUserPhone.query.graphql";
import newUserPhoneVerifyMutation from "@/graphql/mutations/newUserPhoneVerify.mutation.graphql";

let firebase;
let firebaseAuth;

const firebaseApp = async function () {
    const firebaseConfig = {
        apiKey: "AIzaSyDJ28hGVgxX9M3OFJjD3-vxjPxlr52HrAQ",
        authDomain: "mface-bca5b.firebaseapp.com",
        projectId: "mface-bca5b",
        storageBucket: "mface-bca5b.appspot.com",
        messagingSenderId: "722288497743",
        appId: "1:722288497743:web:54de0d42aa4fd0a742463a",
        measurementId: "G-0BC16RJ9VZ",
    };
    firebase = await initializeApp(firebaseConfig);
    firebaseAuth = await getAuth(firebase);

    window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha",
        {
            size: "invisible",
            callback: (state) => {
                console.log(state);
            },
            error: (error) => {
                console.log(error);
            },
        },
        firebaseAuth,
    );

    window.recaptchaVerifier.render().then((widgetId) => {
        window.recaptchaWidgetId = widgetId;
    });
};

export default {
    name: "Auth",
    components: { Promocode, UiButton, vueTelegramLogin },
    directives: {
        mask,
        focus: {
            inserted: function (el) {
                el.focus();
            },
        },
    },
    mixins: [phoneConfirmMixins],
    data() {
        return {
            step: "phone",
            number: "",
            smsCode: "",
            smsCounter: 0,
            smsTimer: null,
            smsTimerCountdown: 0,
            referralCode: "",
            loading: false,
            errorMessage: "",
            confirmationType: "CALL",
            codeDropdownStatus: false,
            countryCode: "+7",
            temporaryCode: "+7",
            codeTimeout: null,
            promocode: "",
            isUserLoading: false,
            isFetchAccountSend: false,
            telegramTimeout: null,
            process: "development",
            authType: "TOKEN",
        };
    },
    validations() {
        if (this.isGoogle) {
            return {
                smsCode: {
                    required,
                    minLength: minLength(6),
                    maxLength: maxLength(6),
                },
            };
        }
        return {
            smsCode: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(4),
            },
        };
    },
    computed: {
        isGoogle: (state) => state.authType === "TOKEN",
        codeMask: function() {
            if(this.IS_DEVELOPMENT) {
                return "######";
            }
            
            return this.isGoogle ? "######" : "####";
        },
        placeholder: function() {
            if(this.IS_DEVELOPMENT) {
                return "X X X X";
            }
            
            return this.isGoogle ? "X X X X X X" : "X X X X";
        },
        telegramAuthText: (state) =>
            state.isFetchAccountSend
                ? state.locale("telegramTitle")
                : state.locale("telegramTitle2"),
        countryCodesFiltered: (state) =>
            state.countryCodes.filter((item) =>
                item.phone_code.includes(state.countryCode),
            ),
        codeText: (state) =>
            state.confirmationType === "CALL"
                ? state.locale("enter-call-code")
                : state.locale("enter-message-code"),
        exampleClass: (state) =>
            state.confirmationType === "CALL"
                ? "auth-form__phone-example"
                : "auth-form__message-example",
        exampleText: (state) =>
            state.confirmationType === "CALL"
                ? "+7 (XXX) XXX <span>XXXX</span>"
                : `${state.locale("code")} <span>${state.codeMask}</span>`,
        allowedSmsSend: function () {
            if (this.isValidPhone === false) {
                return false;
            }
            return this.smsTimerCountdown <= 0 && !this.loading;
        },
        phoneNumber() {
            let phone = this.countryCode + this.number;
            phone = phone.replace(/[^0-9+]/g, "");
            if (phone[0] !== "+") {
                phone = `+${phone}`;
            }

            return phone;
        },
        isValidPhone() {
            let phone = this.phoneNumber;
            if (this.number === "" || this.number.length < 5) {
                return false;
            }
            if (phone[0] !== "+") {
                phone = `+${phone}`;
            }
            const phoneNumber = phone.replace(/[^0-9+]/g, "");
            return /^\+(?:[0-9]?){6,14}[0-9]$/.test(phoneNumber);
        },
        isRussianPhone: function () {
            return /^\+7\d{10}$/.test(this.phoneNumber);
        },
        isCisNumber: function () {
            return /^\+(?:99[2468]\d{9}|37[34]\d{8}|375\d{9}|993\d{8}|380\d{9}|77\d{9})$/.test(this.phoneNumber);
        },
        confirmBtnText: (state) =>
            !state.loading ? state.locale("confirm") : state.locale("request"),
        enterBtnText: (state) =>
            !state.loading ? state.locale("enter") : state.locale("loading"),
    },
    watch: {
        smsCode: function () {
            this.$v.smsCode.$touch();
        },
        smsTimerCountdown: function () {
            if (this.smsTimerCountdown === 0) return;
            setTimeout(() => {
                if (this.smsTimerCountdown <= 0) {
                    this.smsTimerCountdown = 0;
                    return;
                }
                this.smsTimerCountdown--;
            }, 1000);
        },
        step() {
            if (this.step === "confirm-phone") {
                if (!this.isGoogle) return;
                setTimeout(async () => {
                    await signInWithPhoneNumber(
                        firebaseAuth,
                        this.phoneNumber,
                        /* eslint-disable no-undef */
                        window.recaptchaVerifier,
                    )
                        .then((confirmationResult) => {
                            window.confirmationResult = confirmationResult;
                        })
                        .catch((error) => {
                            console.log(error);
                        });
                }, 1000);
            }
            clearTimeout(this.telegramTimeout);
        },
    },
    mounted() {
        this.process = process.env.NODE_ENV;

        localStorage.setItem("photo-skipped", "false");

        this.showTips("auth", this.$refs["authForm"]);
        this.$v.$touch();

        if (localStorage.phone_code) {
            this.changeCountryCode(localStorage.phone_code);
        }

        const referralCookieKey = "referralCode";
        if (this.$cookies.isKey(referralCookieKey) === true) {
            this.referralCode = this.$cookies.get(referralCookieKey);
        }

        // Если дев версия - сразу запрашиваем СМС, а не гугл
        /*        if (process.env.NODE_ENV === "development" || localStorage.getItem("mode") === "development") {
                    this.smsCounter = 10;
                }*/

        this.$nextTick(() => {
            // Не рендерим файрбейс на dev, т.к. нет необходимости
            if (
                this.process === "development" ||
                localStorage.getItem("mode") === "development"
            )
                return;
            this.firebase();
        });
    },
    methods: {
        async firebase() {
            firebaseApp();
        },
        changePromocode(code) {
            this.promocode = code;
        },
        changeCountryCode(code) {
            if (code === "temporary") {
                this.codeTimeout = setTimeout(() => {
                    if (
                        this.countryCodes.find(
                            (i) =>
                                i.phone_code === `+${this.countryCode}` ||
                                i.phone_code === `${this.countryCode}`,
                        )
                    ) {
                        if (this.countryCode[0] !== "+") {
                            this.countryCode = `+${this.countryCode}`;
                        }
                        this.codeDropdownStatus = false;
                        localStorage.phone_code = this.countryCode;
                        this.temporaryCode = `+${this.countryCode}`;
                        return;
                    }
                    this.codeDropdownStatus = false;
                    this.countryCode = `+${this.temporaryCode}`;
                }, 200);
                return;
            }
            clearTimeout(this.codeTimeout);
            this.codeTimeout = null;
            this.codeDropdownStatus = false;
            localStorage.phone_code = code;
            this.countryCode = code;
            this.temporaryCode = code;
        },
        getCode() {
            this.loading = true;
            this.errorMessage = "";
            this.smsCounter++;
            if (this.isRussianPhone || this.isCisNumber && this.smsCounter <= 3) {
                this.authType = "PHONE";
            } else {
                this.authType = "TOKEN";
            }
            this.$apollo
                .query({
                    query: gql(newUserPhoneQuery),
                    variables: {
                        phoneNumber: this.phoneNumber,
                        authType: this.authType,
                        forceAuth: this.IS_DEVELOPMENT
                    },
                    fetchPolicy: "no-cache",
                })
                .then(async (response) => {
                    this.loading = false;
                    switch (response.data.newUserPhone.status) {
                        case "OK":
                            this.step = "confirm-phone";
                            this.smsTimerCountdown = 30;
                            break;
                        case "WAIT":
                            this.smsTimerCountdown =
                                response.data.newUserPhone.wait;
                            break;
                        case "BLOCKED":
                        case "ALREADY_HAS_CONFIRMED":
                            this.smsTimerCountdown =
                                response.data.newUserPhone.wait;
                            break;
                        case "SEND_ERROR":
                            this.errorMessage = this.locale("requestError");
                            break;
                        case "ERROR":
                            this.errorMessage = this.locale("phoneError");
                            break;
                    }

                    this.confirmationType =
                        response.data.newUserPhone.confirmationType;
                })
                .catch((e) => {
                    this.loading = false;

                    if (e.graphQLErrors) {
                        let errorCode = e.graphQLErrors[0].extensions.code;
                        switch (errorCode) {
                            case 1006:
                                this.errorMessage = this.locale("phoneError");
                                break;
                            default:
                                this.errorMessage =
                                    this.localeError("unknownError");
                        }
                    }
                });
        },
        async confirmSmsCode() {
            let count = this.isGoogle ? 6 : 4;

            // Если ввели меньше символов чем нужно - ждём.
            if (this.smsCode.length < count) return;
            this.loading = true;
            this.errorMessage = "";

            if (this.isGoogle) {
                await window.confirmationResult
                    .confirm(this.smsCode)
                    .then(async (result) => {
                        await this.verifyPhone(result._tokenResponse.idToken);
                    })
                    .catch((e) => {
                        console.log(e);
                        this.errorMessage = e.message;
                    });
            } else {
                await this.verifyPhone("", +this.smsCode);
            }
        },
        async verifyPhone(firToken = "", confirmationCode = "") {
            const variables = {
                phoneNumber: this.phoneNumber,
                userAgent: navigator.userAgent,
                referralCode: this.referralCode ? this.referralCode : "",
            };

            // Если пришёл фиртокен гугла - добавляем, если нет - то смс код.
            if (firToken) {
                variables.firToken = firToken;
            } else {
                variables.confirmationCode = confirmationCode;
            }

            console.log("PROMOCODE", this.promocode);
            if (this.promocode) {
                variables.promoCode = this.promocode;
            }

            await this.$apollo
                .mutate({
                    mutation: gql(newUserPhoneVerifyMutation),
                    variables: variables,
                })
                .then(async (response) => {
                    const responseData = response.data.newUserPhoneVerify;
                    switch (responseData.status) {
                        case "OK":
                            this.VKGoal("registration");
                            this.yandexGoal("phone", false);
                            await this.$store.dispatch("auth/successAuth", {
                                jwt: responseData.jwt,
                                refresh_token: responseData.refresh_token,
                                user_id: responseData.user_uuid,
                            });
                            await this.getMyUser();
                            await this.getOnBoardingTypesFromMixin();
                            break;
                        case "NOT_FOUND":
                            this.errorMessage = this.locale("codeExpired");
                            this.step = "phone";
                            break;
                        case "ALREADY_HAS_CONFIRMED":
                            this.errorMessage =
                                "Вы уже подтвердили номер телефона.";
                            break;
                        case "WRONG_CODE":
                            this.errorMessage = this.locale("codeError", {
                                attempts: responseData.attempts,
                            });
                            break;
                        case "BLOCKED":
                            this.errorMessage = "BLOCKED";
                            this.smsTimerCountdown = 0;
                            setTimeout(() => {
                                this.smsTimerCountdown = responseData.wait;
                            }, 100);
                            break;
                        case "SEND_ERROR":
                            this.errorMessage =
                                "Ошибка запроса звонка, попробуйте снова через несколько секунд.";
                            break;
                        default:
                            this.errorMessage = responseData.status;
                            break;
                    }
                    this.$msgBox(this.localeError("error"), this.errorMessage);
                    this.loading = false;
                })
                .catch((e) => {
                    this.loading = false;
                    if (e.graphQLErrors) {
                        let errorCode = e.graphQLErrors[0].extensions.code;
                        switch (errorCode) {
                            case 1006:
                                if (this.confirmationType === "CALL") {
                                    this.errorMessage =
                                        "Укажите последние 4 цифры телефона, который Вам звонит.";
                                } else {
                                    this.errorMessage =
                                        "Введите проверочный код, который отправлен Вам по смс.";
                                }
                                break;
                            default:
                                this.errorMessage =
                                    "Неизвестная ошибка, попробуйте снова через несколько минут.";
                        }
                    }
                });
        },
        async getMyUser() {
            this.isUserLoading = true;

            await this.loadMyUser(true);
        },
        telegramHandler(user) {
            let userAgent =
                navigator.userAgent || navigator.vendor || window.opera;

            if (user?.hash) {
                this.$apollo
                    .mutate({
                        mutation: gql(exchangeTelegramPayloadToTokenMutation),
                        variables: {
                            payload: user,
                            userAgent,
                            promoCode: this.promocode,
                        },
                    })
                    .then(async (r) => {
                        if (r.data.exchangeTelegramPayloadToToken?.jwt) {
                            const responseData =
                                r.data.exchangeTelegramPayloadToToken;
                            await this.$store.dispatch("auth/successAuth", {
                                jwt: responseData.jwt,
                                refresh_token: responseData.refresh_token,
                                user_id: responseData.user_uuid,
                            });
                            await this.getMyUser();
                            await this.getOnBoardingTypesFromMixin();
                        }
                    })
                    .catch((e) => {
                        if (e.graphQLErrors[0].extensions.code === 1002) {
                            if (this.step === "confirm-telegram") {
                                this.fetchAccount(user);
                                this.telegramTimeout = setTimeout(() => {
                                    this.telegramHandler(user);
                                }, 3000);
                            }
                        }
                    });
                this.step = "confirm-telegram";
            }
        },
        async fetchAccount(user) {
            if (this.isFetchAccountSend) return;
            let str = "";

            Object.keys(user).forEach((param) => {
                str += `${param}=${user[param]}&`;
            });

            await fetch(`https://merlinface.com/signin/telegram?${str}`, {
                headers: {
                    Referer: "https://merlinface.com",
                },
            });

            this.isFetchAccountSend = true;
        },
    },
};
</script>

<style lang="scss">
@import "auth-form";
</style>
