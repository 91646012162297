<template lang="pug">
    svg(:class="`icon-` + iconName + ` ` + addedClass" :viewBox="viewBox" :width="width" :height="height")
</template>

<script>
import {parse} from "postsvg";
import render from "posthtml-render";

const cache = new Map();

export default {
    name: "SvgIcon",
    props: {
        iconName: {
            type: String,
            required: true,
        },
        sizes: {
            type: Boolean,
            required: false,
            default: false,
        },
        addedClass: {
            type: String,
            default: "",
        },
        addedCatalog: {
            type: String,
            default: "",
            required: false,
        },
        generateReadyEvent: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        svgString: "",
    }),
    computed: {
        addedPath: (state) =>
            state.addedCatalog ? `${state.addedCatalog}/` : "",
        filepath() {
            return require(`@/assets/svg/${this.addedPath}${this.iconName}.svg`);
        },
        parsedSVG() {
            return this.svgString ? parse(this.svgString) : null;
        },
        viewBox() {
            return this.parsedSVG
                ? this.parsedSVG.root.attrs.viewBox
                : "0 0 20 20";
        },
        width() {
            if (this.sizes) {
                return this.parsedSVG
                    ? this.parsedSVG.root.attrs.width
                    : false;
            }
            return false;
        },
        height() {
            if (this.sizes) {
                return this.parsedSVG
                    ? this.parsedSVG.root.attrs.height
                    : false;
            }
            return false;
        },
    },
    watch: {
        filepath: {
            immediate: true,
            handler: "loadFile",
        },
        svgString: "refreshSvg",
        stroke: "refreshSvg",
        fill: "refreshSvg",
    },
    methods: {
        loadFile() {
            this.getSvgIconText()
                .then((responseText) => (this.svgString = responseText))
                .catch((error) => {
                    console.error("Ошибка загрузки SVG-файла", error);
                });
        },

        async getSvgIconText() {
            let url = this.filepath;
            if (!cache.has(url)) {
                try {
                    cache.set(
                        url,
                        fetch(url).then((r) => r.text()),
                    );
                } catch (e) {
                    cache.delete(url);
                }
            }
            return cache.has(url)
                ? await cache.get(url)
                : Promise.reject(new Error("Нет SVG-файла в локальном кэше"));
        },

        refreshSvg() {
            Promise.resolve(this.parsedSVG)
                .then((svgTree) => {
                    return svgTree;
                })
                .then((svgTree) => render(svgTree.root.content))
                .then((svgHtml) => (this.$el.innerHTML = svgHtml))
                .then(() => this.generateReadyEvent && this.$emit("ready"))
                .catch((error) => {
                    console.error("Ошибка при обновлении SVG", error);
                    this.$emit("error", error);
                });
        },
    },
};
</script>

<style>
svg {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -2px;
    transition: fill 0.3s, stroke 0.3s;
}
</style>
