<template lang="pug">
    .social
        ._title#social-title {{ locale("title") }}
        ._list
            a(:href="'https://vk.com/share.php?url=' + shareUrl" @mouseenter="printText('.social__title', locale(\"vk\"), 40)" @mouseleave="printText('.social__title', locale(\"title\"), 40)")._item
                svg-icon(icon-name="vk")
            a(:href="'https://twitter.com/intent/tweet?url=' + shareUrl" @mouseenter="printText('.social__title', 'Twitter', 40)" @mouseleave="printText('.social__title', locale(\"title\"), 40)")._item
                svg-icon(icon-name="twitter")
            a(:href="'https://connect.ok.ru/offer?url=' + shareUrl" @mouseenter="printText('.social__title', locale(\"ok\"), 40)" @mouseleave="printText('.social__title', locale(\"title\"), 40)")._item
                svg-icon(icon-name="ok")
            a(:href="'https://www.facebook.com/sharer/sharer.php?u=' + shareUrl" @mouseenter="printText('.social__title', 'Facebook', 40)" @mouseleave="printText('.social__title', locale(\"title\"), 40)")._item
                svg-icon(icon-name="facebook")
</template>

<script>
import { referralMixins } from "../../../../mixins/referralMixins";
import { mapGetters } from "vuex";

export default {
    name: "RightAsideSocial",
    mixins: [referralMixins],
    data: function () {
        return {
            shareUrl: encodeURI(document.location.origin + this.$route.path),
            referCode: null,
        };
    },
    computed: {
        ...mapGetters({
            isMyUserLoaded: "user/isMyUserLoaded",
        }),
    },
    watch: {
        referCode: function (val) {
            this.shareUrl += "?r=" + val;
        },
    },
    mounted() {
        this.getMyReferralCode();
    },
    methods: {
        isTokenPresent() {
            return this.$store.getters["auth/token"] !== "";
        },
        async getMyReferralCode() {
            if (!this.isMyUserLoaded) return;

            await this.referralCode().then((response) => {
                this.referCode = response.data.referralCode;
            });
        },
    },
};
</script>

<style lang="scss">
.social {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 210px;
    overflow: hidden;

    &__title {
        font-size: 12px;
        color: #9a9a9a;
    }

    &__list {
        display: flex;
        align-items: center;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        flex: none;
        border-radius: 14px;
        transition: 0.4s;

        &:last-child {
            margin-right: -4px;
        }

        svg {
            transition: 0.4s;
        }

        &:not(:first-child) {
            margin-left: 6px;
        }

        &:hover {
            cursor: pointer;

            svg {
                fill: $gold;
            }
        }
    }
}
</style>
