import Vuex from "vuex";
import Vue from "vue";
import { auth } from "./modules/auth";
import { user } from "./modules/user";
import { person } from "./modules/person";
import { tips } from "./modules/tips";
import { assistants } from "./modules/assistants";
import { multiCompare } from "./modules/multi-compare";
import saveTelemetryMutation from "@/graphql/mutations/saveTelemetry.mutation.graphql";

import gql from "graphql-tag";

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        auth,
        user,
        person,
        tips,
        multiCompare,
        assistants
    },
    state: {
        merlinChatBlocked: false,
        chatPikaThemeId: 0,
        chatMidjourneyThemeId: 0,
        chatFaceSwapThemeId: 0,
        chatStableDiffusionVideoThemeId: 0,
        chatRunwayThemeId: 0,
        chatThemes: [],
        msgBoxTitle: "",
        msgBoxText: "",
        msgBoxType: "",
        msgBoxEventId: "",
        msgBoxStatus: false,
        loading: true,
        newMessagesCounter: null,
        page: "",
        friendRequests: 0,
        personFilters: {},
        eyeColorList: [],
        windowWidth: window.innerWidth,
        personDescriptionDelay: 5000,
        phoneConfirmed: true,
        personCategories: [],
        descriptionSectionId: -1,
        nextOnboardingStepIndex: 0,
        courseBannerHidden: localStorage.getItem("courseBannerHidden") === "true",
        leftAsideHidden: localStorage.getItem("leftAsideHidden") === "true",
        chatAsideHidden: localStorage.getItem("chatAsideHidden") === "true",
        planIds: {
            "Профессия и предназначение": 1,
            "Самооценка и уверенность": 2,
            "Мотивация и эффективность": 3,
            "Отношения и семья": 4,
            1: "Профессия и предназначение",
            2: "Самооценка и уверенность",
            3: "Мотивация и эффективность",
            4: "Отношения и семья",
        },
        serviceTypes: [
            {
                id: "COMPATIBILITY",
                icon: "heart",
                type: "compare",
                categories: [1],
                active: false,
                showMerlinDatingAds: true,
                planId: 4,
                availableToMultiCompare: true,
            },
            {
                id: "SIMILARITY",
                icon: "likeness",
                type: "compare",
                categories: [3],
                active: false,
                showMerlinDatingAds: false,
                planId: 2,
                availableToMultiCompare: true,
            },
            {
                id: "BUSINESS",
                icon: "partner",
                type: "compare",
                categories: [0],
                active: false,
                showMerlinDatingAds: false,
                planId: 1,
                availableToMultiCompare: true,
            },
            {
                id: "INTERACTION_BUSINESS",
                icon: "greed",
                type: "indicator",
                categories: [0, 3],
                active: false,
                showMerlinDatingAds: false,
                planId: 1,
                availableToMultiCompare: true,
            },
            {
                id: "INTERACTION_INTERPERSONAL",
                icon: "emotional",
                type: "indicator",
                categories: [1, 3],
                active: false,
                showMerlinDatingAds: true,
                planId: 4,
                availableToMultiCompare: true,
            },
            {
                id: "AGGRESSION_COMMON",
                icon: "general-aggression",
                categories: [-1, 1],
                type: "aggression",
                active: false,
                showMerlinDatingAds: false,
                planId: 4,
            },
            {
                id: "AGGRESSION_PHYSICAL",
                icon: "physical-aggression",
                categories: [0, 1],
                type: "aggression",
                active: false,
                showMerlinDatingAds: true,
                planId: 1,
            },
            {
                id: "AGGRESSION_EMOTIONAL",
                icon: "emotional-aggression",
                categories: [0, 1],
                type: "aggression",
                active: false,
                showMerlinDatingAds: true,
                planId: 1,
            },
            {
                id: "AGGRESSION_SOCIAL",
                icon: "social-aggression",
                categories: [1],
                type: "aggression",
                active: false,
                showMerlinDatingAds: true,
                planId: 1,
            },
            {
                id: "AGGRESSION_INTELLECTUAL",
                icon: "intellectual-aggression",
                categories: [0, 1],
                type: "aggression",
                active: false,
                showMerlinDatingAds: true,
                planId: 4,
            },
            {
                id: "INTELLIGENCE_LEVEL",
                icon: "intellectual-aggression",
                categories: [10],
                type: "aggression",
                active: false,
                showMerlinDatingAds: false,
                planId: 40,
            },
        ],
        personTypeIcons: {
            "Администратор": "management-practitioner-1",
            "Активист": "jumble-4",
            "Алгоритмист": "innovator-entrepreneur-1",
            "Артист": "informal-leader-manipulator-3",
            "Вдохновитель": "preacher-mentor-1",
            "Гедонист": "mediator-diplomat-1",
            "Генератор": "innovator-enthusiast-1",
            "Дипломат захватчик": "capturer-diplomat-4",
            "Идейник": "idea-leader-1",
            "Идейный вдохновитель": "inspirer-of-ideas-1",
            "Идейный лидер": "idea-leader-2",
            "Имиджмейкер": "idea-leader-3",
            "Инженер": "inspector-perfectionist-1",
            "Инициатор": "idea-leader-4",
            "Инспектор-перфекционист": "inspector-perfectionist-2",
            "Интегратор": "innovator-entrepreneur-2",
            "Исследователь": "innovator-enthusiast-2",
            "Исследователь-аналитик": "research-analyst-1",
            "Коммуникатор": "mediator-diplomat-2",
            "Коннектор": "mediator-diplomat-3",
            "Контроллер": "inspector-perfectionist-3",
            "Критик": "grey-cardinal-1",
            "Легионер": "push-puller-1",
            "Лидер": "push-puller-2",
            "Лоббист": "capturer-diplomat-1",
            "Массовик затейник": "jumble-1",
            "Мастер-реализатор": "master-implementer-1",
            "Материалист": "capturer-diplomat-2",
            "Меценат": "inspirer-of-ideas-2",
            "Мечтатель": "inspirer-of-ideas-3",
            "Моралист": "preacher-mentor-2",
            "Напористый захватчик": "push-puller-3",
            "Педагог": "preacher-mentor-3",
            "Наставник": "informal-leader-manipulator-1",
            "Неформальный лидер манипулятор": "informal-leader-manipulator-2",
            "Новатор": "innovator-enthusiast-3",
            "Новатор энтузиаст": "innovator-enthusiast-4",
            "Оптимизатор": "master-implementer-2",
            "Оптимист": "jumble-2",
            "Организатор": "jumble-3",
            "Перфекционист": "research-analyst-2",
            "Политик": "capturer-diplomat-3",
            "Посредник дипломат": "mediator-diplomat-4",
            "Практик": "management-practitioner-2",
            "Практик консерватор": "conservative-practitioner-1",
            "Практик-управитель": "management-practitioner-3",
            "Предприниматель новатор": "innovator-entrepreneur-3",
            "Приверженец": "conservative-practitioner-2",
            "Прогнозист": "grey-cardinal-2",
            "Проповедник наставник": "preacher-mentor-4",
            "Рационализатор": "research-analyst-3",
            "Реализатор": "management-practitioner-4",
            "Романтик": "inspirer-of-ideas-4",
            "Серый кардинал": "grey-cardinal-3",
            "Систематизатор": "research-analyst-4",
            "Скептик": "grey-cardinal-4",
            "Социализатор": "conservative-practitioner-3",
            "Стабилизатор": "inspector-perfectionist-4",
            "Технарь": "master-implementer-3",
            "Умелец": "master-implementer-4",
            "Управленец": "push-puller-4",
            "Хранитель": "conservative-practitioner-4",
            "Экспериментатор": "innovator-entrepreneur-4",
            "Энтузиаст": "informal-leader-manipulator-4",

            "Administrator": "management-practitioner-1",
            "Activist": "jumble-4",
            "Algorithmist": "innovator-entrepreneur-1",
            "Artist": "informal-leader-manipulator-3",
            "Inspirationalist": "preacher-mentor-1",
            "Hedonist": "mediator-diplomat-1",
            "Socializer": "conservative-practitioner-3",
            _hidden_: "question",
            _locked_: "question",
        },
        confirmDialog: {
            show: false,
            loading: false,
            title: "",
            text: "",
            price: 0,
            comparisonCount: "",
            comparisonTimer: "",
            buttons: false,
            cancelText: "Отменить",
            confirmText: "Подтвердить",
            type: "",
            personUuid: null,
            serviceType: null,
            callback: () => {
                console.log("Empty callback function");
            },
        },
        compareResultDialog: {
            show: false,
            data: {
                category: {},
                photo: "",
                result: {
                    percent: 0,
                    title: "",
                    desc: "",
                },
                personUuid: "",
                personName: "",
                ads: false,
            },
        },
        personalRecommendations: {
            status: false,
            category: null,
            list: [],
            index: null,
        },
        customPersonPhoto: null,
        isJunior: false,
        tariffJunior: {},
        globalOverlay: false,
        onboardingCtrlStepNumber: 1,
        hideMobileMenu: false,
        activeThemeName: "",
        faceSwapVerification: false,
        faceSwapPhoto: null
    },
    actions: {
        set({ commit }, { name, value }) {
            commit("set", { name, value });
        },
        telemetry({ commit }, { gatewayClient, message, type }) {
            if(process.env.NODE_ENV === "development") return;
            return gatewayClient
                .mutate({
                    mutation: gql(saveTelemetryMutation),
                    variables: {
                        message,
                        type,
                    },
                })
                .then((r) => {
                    commit("");
                    console.log(r.data);
                });
        },
        setWindowWidth({ commit }, width) {
            commit("setWindowWidth", width);
        },
        filterResults({ commit }, filter) {
            commit("filterResults", filter);
        },
        async getTaskStatus(commit, { apolloClient, taskId }) {
            const query = gql`
                query {
                    taskGet(taskId: "${taskId}") {
                        status
                        error
                    }
                }
            `;
            return apolloClient
                .query({ query, fetchPolicy: "no-cache" })
                .then((r) => {
                    return r.data.taskGet;
                })
                .catch((e) => {
                    return e.graphQLErrors[0];
                });
        },
        setMessage({ commit }, message) {
            commit("setMessage", message);
        },
        setMsgBoxStatus({ commit }, status) {
            commit("setMsgBoxStatus", status);
        },
        setLoadingStatus({ commit }, status) {
            commit("setLoadingStatus", status);
        },
        async getEyeColorList({ commit }, { apolloClient }) {
            const query = gql`
                query {
                    eyeColorsList {
                        id
                        name
                    }
                }
            `;
            return await apolloClient.query({ query }).then((response) => {
                commit("setEyeListColor", response.data.eyeColorsList);
                return response.data.eyeColorsList;
            });
        },
        async getNewMessagesCount({ commit }, { apolloClient }) {
            const query = gql`
                query {
                    getUnreadMessagesCount
                }
            `;
            return await apolloClient
                .query({
                    query: query,
                    client: "chatClient",
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    commit(
                        "setNewMessagesCounter",
                        response.data.getUnreadMessagesCount,
                    );
                    return response.data.getUnreadMessagesCount;
                });
        },
        setNewMessagesCounter({ commit }, counter) {
            commit("setNewMessagesCounter", counter);
        },
        setPage({ commit }, page) {
            commit("setPage", page);
        },
        addTask({ commit }, taskId) {
            commit("addTask", taskId);
        },
        changePersonDescriptionDelay({ commit }, delay) {
            commit("changePersonDescriptionDelay", delay);
        },
        setPhoneConfirmed({ commit }, value) {
            commit("setPhoneConfirmed", value);
        },
        setDescriptionSectionId({ commit }, id) {
            commit("setDescriptionSectionId", id);
        },
        changeConfirmDialog({ commit }, confirmDialog) {
            commit("changeConfirmDialog", confirmDialog);
        },
        setPersonalRecommendations({ commit }, data) {
            commit("setPersonalRecommendations", data);
        },
        setCompareResultDialog({ commit }, data) {
            commit("setCompareResultDialog", data);
        },
        resetCompareResultDialog({ commit }) {
            commit("resetCompareResultDialog");
        },
        setSearchedPeople({ commit }, data) {
            commit("setSearchedPeople", data);
        },
    },
    getters: {
        faceSwapPhoto: state => state.faceSwapPhoto,
        faceSwapVerification: state => state.faceSwapVerification,
        activeThemeName: state => state.activeThemeName,
        hideMobileMenu: state => state.hideMobileMenu,
        merlinChatBlocked: state => state.merlinChatBlocked,
        chatThemes: (state) => state.chatThemes,
        chatPikaThemeId: (state) => state.chatPikaThemeId,
        chatMidjourneyThemeId: (state) => state.chatMidjourneyThemeId,
        chatFaceSwapThemeId: (state) => state.chatFaceSwapThemeId,
        chatStableDiffusionVideoThemeId: (state) => state.chatStableDiffusionVideoThemeId,
        chatRunwayThemeId: (state) => state.chatRunwayThemeId,
        courseBannerHidden: (state) => state.courseBannerHidden,
        leftAsideHidden: (state) => state.leftAsideHidden,
        chatAsideHidden: (state) => state.chatAsideHidden,
        onboardingCtrlStepNumber: (state) => state.onboardingCtrlStepNumber,
        msgBoxTitle: (state) => state.msgBoxTitle,
        msgBoxText: (state) => state.msgBoxText,
        msgBoxType: (state) => state.msgBoxType,
        msgBoxEventId: (state) => state.msgBoxEventId,
        msgBoxStatus: (state) => state.msgBoxStatus,
        loading: (state) => state.loading,
        eyeColorList: (state) => state.eyeColorList,
        getNewMessagesCounter: (state) => state.newMessagesCounter,
        page: (state) => state.page,
        nextOnboardingStepIndex: (state) => state.nextOnboardingStepIndex,
        windowWidth: (state) => state.windowWidth,
        personDescriptionDelay: (state) => state.personDescriptionDelay,
        phoneConfirmed: (state) => state.phoneConfirmed,
        descriptionSectionId: (state) => state.descriptionSectionId,
        serviceTypes: (state) => state.serviceTypes,
        serviceTypesMobile: (state) =>
            state.serviceTypes.map((item) => {
                item.categories.push(-1);
                return item;
            }),
        planIds: (state) => state.planIds,
        confirmDialog: (state) => state.confirmDialog,
        personalRecommendations: (state) => state.personalRecommendations,
        personTypeIcons: (state) => state.personTypeIcons,
        customPersonPhoto: (state) => state.customPersonPhoto,
        compareResultDialog: (state) => state.compareResultDialog,
        isJunior: (state) => state.isJunior,
        tariffJunior: (state) => state.tariffJunior,
        globalOverlay: (state) => state.globalOverlay,
        personFilters: (state) => state.personFilters,
        friendRequests: (state) => state.friendRequests,
    },
    mutations: {
        set(state, { name, value }) {
            state[name] = value;
        },
        setMessage(state, message) {
            state.msgBoxTitle = message.title;
            state.msgBoxText = message.message;
            state.msgBoxType = message.type;
            state.msgBoxEventId = message.eventId;
        },
        setMsgBoxStatus(state, status) {
            state.msgBoxStatus = status;
        },
        setLoadingStatus(state, status) {
            state.loading = status;
        },
        setEyeListColor(state, list) {
            state.eyeColorList = list;
        },
        setNewMessagesCounter(state, counter) {
            state.newMessagesCounter = counter;
        },
        setPage(state, page) {
            state.page = page;
        },
        setWindowWidth(state, width) {
            state.windowWidth = width;
        },
        filterResults(state, filter) {
            state.personFilters = filter;
        },
        changePersonDescriptionDelay(state, delay) {
            state.personDescriptionDelay = delay;
        },
        setPhoneConfirmed(state, value) {
            state.phoneConfirmed = value;
        },
        setDescriptionSectionId(state, id) {
            state.descriptionSectionId = id;
        },
        changeConfirmDialog(state, confirmDialog) {
            state.confirmDialog = confirmDialog;
        },
        setPersonalRecommendations(state, data) {
            state.personalRecommendations = data;
        },
        setCompareResultDialog(state, data) {
            console.log(data);
            state.compareResultDialog = {
                show: data.show,
                data: data.data,
            };
        },
        resetCompareResultDialog(state) {
            state.compareResultDialog = {
                show: false,
                data: {
                    category: {},
                    photo: "",
                    result: {
                        percent: 0,
                        title: "",
                        desc: "",
                    },
                    personUuid: "",
                    personName: "",
                    ads: false,
                },
            };
        },
    },
});
