<template lang="pug">
.connect-social
	._label {{ locale("title") }}
	._list
		._item
			ui-button._social-btn(
				v-if="!connectedSocialMedia.vkontakte",
				type="bordered",
				color="gray",
				size="social-connect",
				@click.native="connectSocial('vkontakte')"
			)
				template(v-slot:content)
					._social-btn-content.-gray
						svg-icon._social-btn-icon(icon-name="vk")
						._social-btn-text {{ locale("enable") }}
			ui-button._social-btn(v-else, type="text", size="social-connect")
				template(v-slot:content)
					._social-btn-content
						svg-icon._social-btn-icon(icon-name="vk")
						._social-btn-text {{ locale("enabled") }}
		._item
			ui-button._social-btn(
				v-if="!connectedSocialMedia.facebook",
				type="bordered",
				color="gray",
				size="social-connect",
				@click.native="connectSocial('facebook')"
			)
				template(v-slot:content)
					._social-btn-content.-gray
						svg-icon._social-btn-icon(icon-name="facebook")
						._social-btn-text {{ locale("enable") }}
			ui-button._social-btn(v-else, type="text", size="social-connect")
				template(v-slot:content)
					._social-btn-content
						svg-icon._social-btn-icon(icon-name="facebook")
						._social-btn-text {{ locale("enabled") }}
		._item
			ui-button._social-btn(
				v-if="!connectedSocialMedia.telegram",
				type="bordered",
				color="gray",
				size="social-connect",
				@click.native="connectSocial('telegram')"
			)
				template(v-slot:content)
					._social-btn-content.-gray
						svg-icon._social-btn-icon(icon-name="telegram")
						._social-btn-text {{ locale("enable") }}
			ui-button._social-btn(v-else, type="text", size="social-connect")
				template(v-slot:content)
					._social-btn-content
						svg-icon._social-btn-icon(icon-name="telegram")
						._social-btn-text {{ locale("enabled") }}
		transition-expand
			._item(v-if="!showEmailForm")
				ui-button._social-btn(
					v-if="!connectedSocialMedia.email",
					@click.native="showEmailForm = true",
					type="bordered",
					color="gray",
					size="social-connect"
				)
					template(v-slot:content)
						._social-btn-content.-gray
							svg-icon._social-btn-icon(icon-name="email")
							._social-btn-text {{ locale("enable") }}
				ui-button._social-btn(v-else, type="bordered", size="social-connect")
					template(v-slot:content)
						._social-btn-content
							svg-icon._social-btn-icon._email(icon-name="email")
							._social-btn-text {{ locale("enabled") }}
		transition-expand
			form._item-email(v-if="showEmailForm", @submit.prevent="connectEmail")
				._item-email-title(v-if="emailFormStep === 1") {{ locale("enterEmail") }}
				._item-email-title(v-if="emailFormStep === 2") {{ locale("codeSend") }}
				input._item-email-input(
					v-if="emailFormStep === 1",
					v-model="email",
					placeholder="Введите e-mail адрес",
					type="email"
				)
				input._item-email-input(
					v-if="emailFormStep === 2",
					v-model="emailCode",
					maxlength="4",
					placeholder="4-х значный код",
					type="text"
				)
				._item-email-sub(v-if="emailFormStep === 1") {{ locale("youGetCode") }}
				transition-expand
					._item-email-sub(
						v-if="emailFormStep === 2 && emailCodeCountDownTimer > 0 && !emailCodeError"
					) {{ locale("retryEmail", { timer: emailCodeCountDownTimer }) }}
				._item-email-error(v-if="emailFormStep === 2 && emailCodeError") {{ locale("codeError") }}
				transition(name="fadeIn")
					ui-button._item-email-btn(
						v-if="sendCodeAgain",
						@click.native="timerStart",
						type="text"
					)
						template(v-slot:content)
							element-decor._item-email-btn-decor(size="extra-small")
							span {{ locale("retrySend") }}
				ui-button._item-email-btn(
					v-if="emailFormStep === 1",
					:disabled="!$v.email.required || !$v.email.email",
					text="ОТПРАВИТЬ",
					@click.native="connectEmail"
				)
				ui-button._item-email-btn(
					v-if="emailFormStep === 2",
					:disabled="!$v.emailCode.numeric || !$v.emailCode.minLength || !$v.emailCode.required",
					text="ПРОВЕРИТЬ",
					@click.native="checkEmailCode"
				)
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import UiButton from "../../ui/ui-button/ui-button";
import ElementDecor from "@/components/ui/element-decor/element-decor";
import getMyConnectedSocialMediaQuery from "@/graphql/queries/getMyConnectedSocialMedia.query.graphql";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";
import { required, email, minLength, numeric } from "vuelidate/lib/validators";
import connectSocialMediaQuery from "@/graphql/queries/connectSocialMedia.query.graphql";

export default {
    name: "ConnectSocial",
    components: {
        UiButton,
        ElementDecor,
        TransitionExpand,
    },
    data() {
        return {
            email: "",
            emailCode: "",
            emailCodeError: null,
            emailCodeUri: null,
            emailCodeCountDownTimer: 30,
            emailCodeCountDownTimerInterval: null,
            sendCodeAgain: false,
            showEmailForm: false,
            emailFormStep: 1,
            checkSocialInterval: null,
            checkSocialCounter: 0,
        };
    },
    computed: {
        ...mapGetters({
            connectedSocialMedia: "person/connectedSocialMedia",
        }),
    },
    mounted() {
        this.getMyConnectedSocialMedia();
    },
    validations: {
        email: { required, email },
        emailCode: { required, minLength: minLength(4), numeric },
    },
    methods: {
        async connectEmail() {
            if (this.emailFormStep === 1) {
                this.emailFormStep = 2;

                await this.$apollo
                    .query({
                        query: gql`
                            ${connectSocialMediaQuery}
                        `,
                        variables: {
                            client: "email",
                            redirect_uri: this.email,
                        },
                    })
                    .then((r) => {
                        const URL = r.data.connectSocialMedia.redirect_uri;
                        if (URL) {
                            this.emailCodeUri = URL;
                            this.timerStart();
                        }
                    });
            }
            if (this.emailFormStep === 2 && this.emailCode.length) {
                await this.checkEmailCode();
            }
        },

        async checkEmailCode() {
            let uploadUrl = this.emailCodeUri;
            let data = JSON.stringify(this.emailCode);
            let xhr = new XMLHttpRequest();
            xhr.open("POST", uploadUrl, true);
            xhr.setRequestHeader(
                "Authorization",
                this.$store.getters["auth/token"],
            );
            xhr.onreadystatechange = function () {
                if (this.readyState === XMLHttpRequest.DONE) {
                    if (this.status === 200) {
                        let response = JSON.parse(this.responseText);
                        console.log(response);
                        this.showEmailForm = false;
                        this.connectedSocialMedia.email = true;
                    } else {
                        this.emailCodeError = this.locale("codeError");
                    }
                }
            };
            xhr.send(data);
        },
        timerStart() {
            this.sendCodeAgain = false;
            this.emailCodeError = null;
            this.emailCodeCountDownTimer = 30;
            this.emailCodeCountDownTimerInterval = setInterval(() => {
                if (this.emailCodeCountDownTimer === 1) {
                    clearInterval(this.emailCodeCountDownTimerInterval);
                    this.sendCodeAgain = true;
                }
                this.emailCodeCountDownTimer--;
            }, 1000);
        },
        async connectSocial(client) {
            window.open(
                `${window.location.origin}/connect/${client}/start`,
                "connect-social",
                {
                    left: 0,
                    top: 0,
                },
            );

            this.checkSocialInterval = setInterval(async () => {
                if (this.checkSocialCounter++ >= 30) {
                    clearInterval(this.checkSocialInterval);
                    this.checkSocialInterval = null;
                    this.checkSocialCounter = 0;
                    return;
                }
                await this.$apollo
                    .query({
                        query: gql`
                            ${getMyConnectedSocialMediaQuery}
                        `,
                        fetchPolicy: "no-cache",
                    })
                    .then((r) => {
                        if (r.data.getMyConnectedSocialMedia[client]) {
                            clearInterval(this.checkSocialInterval);
                            this.checkSocialInterval = null;
                            this.$store.dispatch("person/set", {
                                name: "connectedSocialMedia",
                                value: r.data.getMyConnectedSocialMedia,
                            });
                            console.log(r.data.getMyConnectedSocialMedia);
                        }
                    });
            }, 1000);
        },
        async getMyConnectedSocialMedia() {
            await this.$apollo
                .query({
                    query: gql`
                        ${getMyConnectedSocialMediaQuery}
                    `,
                })
                .then((r) => {
                    this.$store.dispatch("person/set", {
                        name: "connectedSocialMedia",
                        value: r.data.getMyConnectedSocialMedia,
                    });
                });
        },
    },
};
</script>

<style lang="scss" scoped>
@import "connect-social";
</style>
