<template lang="pug">
    .photo-examples
        ._content
            ._cross-btn(@click="closeExamples()")
                svg-icon(icon-name="cross")._cross-icon
            ._title {{ locale("title") }}
            ._desc(v-html="locale('text')")
            ._list
                ._item
                    img(src="../../../assets/images/anfas-example.jpg")._item-photo
                    ._item-name {{ locale("anfas") }}
                ._item
                    img(src="../../../assets/images/profile-example.jpg")._item-photo
                    ._item-name {{ locale("profile") }}
            button(type="button" @click="closeExamples()")._close-btn {{ locale("btn") }}
</template>

<script>
export default {
    name: "PhotoExamples",
    methods: {
        closeExamples() {
            this.$emit("closeExamples");
        },
    },
};
</script>

<style lang="scss" scoped>
@import "photo-examples";
</style>
