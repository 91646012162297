<template lang="pug">
  .auth
    auth-form
</template>
<script>
import UiButton from "../ui/ui-button/ui-button";
import AuthForm from "./auth-form/auth-form";

export default {
    components: { AuthForm, UiButton },
    mounted() {
        if (this.myUser.uuid) {
            this.$router.replace("/");
        }
        let cookieStatus = localStorage.cookie
            ? JSON.parse(localStorage.cookie)
            : false;
        if (cookieStatus) {
            this.cookie = true;
        }
    },
    methods: {
        acceptCookies() {
            localStorage.cookie = true;
            this.cookie = true;
        },
    },
};
</script>

<style lang="scss">
@import "auth";
</style>
