<template lang="pug">
  .subscriptions-widget-content(:style="getStyleModifiers")
    ._header
      ._icon
        svg-icon._icon-svg(:icon-name="icon")
      ._title {{ title }}
        ._subtitle(v-if="subtitle") {{ subtitle }}
    ._list
      //._item(v-for="recommendation in recommendations" :key="recommendation.id")
      //  subscriptions-widget-content-item(
      //    :is-available="recommendation.unlocked"
      //    :text="recommendation.text"
      //    :available-at="recommendation.availableAt"
      //    :available-count="recommendation.availableCount"
      //  )

</template>

<script>
import SubscriptionsWidgetContentItem from "@/components/subscriptions/components/subscriptions-widget/components/subscriptions-widget-content-item/  subscriptions-widget-content-item";
export default {
    name: "SubscriptionsWidgetContent",
    components: { SubscriptionsWidgetContentItem },
    props: {
        icon: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        subtitle: {
            type: String,
            required: false,
            default: "",
        },
        accent: {
            type: String || null,
            required: false,
            default: null,
        },
        accentBg: {
            type: String || null,
            required: false,
            default: null,
        },
    },
    computed: {
        getStyleModifiers() {
            return {
                backgroundColor: this.accentBg,
                color: this.accent,
            };
        },
    },
};
</script>

<style lang="scss" src="./subscriptions-widget-content.scss" />
