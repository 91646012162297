<template lang="pug">
  .subscriptions-table
    table._table(v-if="pageSize >= screenSizeLg")
      thead._table-head
        th._table-head-item
        th._table-head-item(
          v-for="plan in plans"
          :id="plan.id"
          :class="getActivePlanModifier(plan.id)"
          )
          ._table-title
            subscriptions-table-title(
              :accent="plan.accent"
              :title="plan.level"
              )
          ._table-info
            subscriptions-table-info(
              :plan-id="plan.id"
              :plan-level="plan.level"
              :active-plan-id="activePlanId"
              :active-plan-level="activePlanLevel"
              :active-plan-period="activePlanPeriod"
              :period-type="periodType"
              :price="plan.price"
              :discount="plan.discount"
              :accent="plan.accent"
              )
      tbody._table-body
        tr._table-row(v-for="option in options" :id="option.id")
          td._table-col.subscriptions-table__table-col-head
            ._table-col-content(v-if="typeof option.name === 'string'") {{ option.name }}
            ._table-col-content(v-else)
              ._table-col-content-additional(v-for="item in option.name" :key="item") {{ item }}
                
          td._table-col(
            v-for="plan in plans"
            :class="getActivePlanModifier(plan.id)"
          )
            ._table-col-content
              subscriptions-table-option(
                :option-id="option.id"
                :plan-id="plan.id"
                :plans="plans"
                ) 

    ._carousel(v-else)
      vue-slick-carousel(v-bind="carouselSettings")
        ._carousel-item(v-for="plan in plans" :style="getCarouselItemStyles(plan)")
          ._carousel-item-label.-left(:style="getCarouselItemStyles(plan)")
            ._carousel-item-label-text {{ plan.level }}
          ._carousel-item-label.-right(:style="getCarouselItemStyles(plan)")
            ._carousel-item-label-text {{ plan.level }}
          ._carousel-item-title
            subscriptions-table-title(
              :accent="plan.accent"
              :title="plan.level"
              )
          ._carousel-item-info
            subscriptions-table-info(
              :plan-id="plan.id"
              :plan-level="plan.level"
              :active-plan-id="activePlanId"
              :active-plan-level="activePlanLevel"
              :active-plan-period="activePlanPeriod"
              :period-type="periodType"
              :price="plan.price"
              :discount="plan.discount"
              :accent="plan.accent"
            )
          ._carousel-item-rows
            ._carousel-item-row(v-for="option in options" :id="option.id")
              ._carousel-item-head(v-if="typeof option.name === 'string'") {{ option.name }}
              ._carousel-item-head(v-else)
                  ._table-col-content-additional(v-for="item in option.name" :key="item") {{ item }}
              ._carousel-item-content
                subscriptions-table-option(
                  :option-id="option.id"
                  :plan-id="plan.id"
                  :plans="plans"
                )


</template>

<script>
import UiButton from "@/components/ui/ui-button/ui-button";
import VueSlickCarousel from "vue-slick-carousel";
import SubscriptionsTableTitle from "@/components/subscriptions/components/subscriptions-table-title/subscriptions-table-title";
import SubscriptionsTableInfo from "@/components/subscriptions/components/subscriptions-table-info/subscriptions-table-info";
import SubscriptionsTableOption from "@/components/subscriptions/components/subscriptions-table-option/subscriptions-table-option";
import { SCREEN_SIZE } from "@/settings/ui";

export default {
    name: "SubscriptionsTable",
    components: {
        SubscriptionsTableOption,
        SubscriptionsTableTitle,
        UiButton,
        SubscriptionsTableInfo,
        VueSlickCarousel,
    },
    props: {
        plans: {
            type: Array,
            required: true,
        },
        periodType: {
            type: String,
            required: true,
        },
        options: {
            type: Array,
            required: true,
        },
        activePlanId: {
            type: String || null,
            required: false,
            default: null,
        },
        activePlanLevel: {
            type: String || null,
            required: false,
            default: null,
        },
        activePlanPeriod: {
            type: String || null,
            required: false,
            default: null,
        },
    },
    computed: {
        pageSize: (state) => state.$store.getters["windowWidth"],
        screenSizeLg: () => SCREEN_SIZE.LG,
        carouselSettings() {
            return {
                dots: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                centerMode: true,
                centerPadding: "25px",
                infinite: false,
                arrows: false,
                autoplay: false,
                initialSlide: this.activePlanIndex,
            };
        },
        activePlanIndex() {
            const planIndex = this.plans.findIndex(
                (plan) => plan.id === this.activePlanId,
            );
            return planIndex !== -1 ? planIndex : 0;
        },
    },
    methods: {
        getPlanOptionByPlanIdAndOptionId(optionId, planId) {
            return this.plans
                .find((plan) => plan.id === planId)
                .items.find((option) => option.id === optionId);
        },
        getCarouselItemStyles(plan) {
            return {
                "background-color": plan.accentBg,
                color: plan.accent,
            };
        },
        getActivePlanModifier(planId) {
            return {
                "subscriptions-table__col_active": planId === this.activePlanId,
            };
        },
    },
};
</script>

<style src="vue-slick-carousel/dist/vue-slick-carousel.css" />
<style lang="scss" src="./subscriptions-table.scss" />
