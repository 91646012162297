import gql from "graphql-tag";

export const referralMixins = {
    methods: {
        referralCode() {
            const query = gql`
                query {
                    referralCode
                }
            `;
            return this.$apollo.query({
                query: query,
            });
        },
    },
};
