<template lang="pug">
  .friend-actions(v-if="personExternalIdPresent")
    template(v-if="userFriendingStatus")
      //- Отправленная заявка
      ._request-status(v-if="($inArray(userFriendingStatus, ['PENDING', 'AWAITING_YOUR_RESPONSE', 'DECLINED']) && isUserAuth) && !request && !requestProcesed" :class="{aside: !isMobileView}")
        template(v-if="$inArray(userFriendingStatus, ['PENDING'])")
            ._request-status-group
              svg-icon(icon-name="check")._check-icon
              | {{ locale("requestSend") }}
            ui-button(type="bordered" size="small" color="gray" @click.native="cancelFriendRequest")._cancel-btn
              template(v-slot:content)
                svg-icon(icon-name="cross")._cancel-btn-icon
                | {{ locale("cancel") }}

        template(v-if="$inArray(userFriendingStatus, ['AWAITING_YOUR_RESPONSE']) && userFriendingRequestId > 0")
            ._request-status-group
              svg-icon(icon-name="notifications")._check-icon
              | {{ locale("incomingRequest") }}
            ui-button(type="bordered" size="small" color="gray" @click.native="friendRequestResponse(userFriendingRequestId, 'ADDED')")._cancel-btn
              template(v-slot:content)
                svg-icon(icon-name="check")._cancel-btn-icon
                | {{ locale("accept") }}
            ui-button(type="bordered" size="small" color="gray" @click.native="friendRequestResponse(userFriendingRequestId, 'DECLINED')")._cancel-btn
              template(v-slot:content)
                svg-icon(icon-name="cross")._cancel-btn-icon
                | {{ locale("decline") }}
      //- Пришедшая заявка
      transition-expand
        ._request-status(v-if="request && !requestProcesed" :class="{aside: !isMobileView}")
          ui-button(type="bordered", @click.native="friendRequestResponse(request, 'ADDED')")._accept-btn
            template(v-slot:content)
              svg-icon(icon-name="accept")._cancel-btn-icon
              span {{ locale("add") }}
          ui-button(type="bordered" size="small" color="gray" @click.native="friendRequestResponse(request, 'DECLINED')")._cancel-btn
            template(v-slot:content)
              svg-icon(icon-name="cross")._cancel-btn-icon
      ui-button(:text="locale('sendMessage')" v-if="sendMessageAvailable && isMobileView" @click.native="sendMessage")._btn
      ui-button(:text="locale('addToFriends')" v-if="friendRequestAvailable" @click.native="sendFriendRequest")._btn

    template(v-if="!isMyProfile && $inArray(myUserRoles, ['ROLE_COACH'])")
      ._request-status.-partner(v-if="partnerStatus === 'PENDING'")
        svg-icon(icon-name="check")._check-icon
        | {{ locale("partnerRequestSend") }}
      ui-button(:text="locale('makePartner')" v-if="$inArray(partnerStatus, ['NOT_FOUND'])" @click.native="sendPartnerRequest")._btn
</template>

<script>
import gql from "graphql-tag";
import { mapGetters } from "vuex";
import friendChatAvailabilityCheckQuery from "@/graphql/queries/friendChatAvailabilityCheck.query.graphql";
import partnerRequestMutation from "@/graphql/mutations/partnerRequest.mutation.graphql";
import friendResponseMutation from "@/graphql/mutations/friendResponse.mutation.graphql";
import friendingStatusQuery from "@/graphql/queries/friendingStatus.query.graphql";
import friendRequestQuery from "@/graphql/queries/friendRequest.query.graphql";
import coachPartnerStatusMutation from "@/graphql/mutations/coachPartnerStatus.mutation.graphql";
import friendRequestCancelQuery from "@/graphql/queries/friendRequestCancel.query.graphql";
import UiButton from "../../../ui/ui-button/ui-button";
import TransitionExpand from "@/components/ui/transition-expand/transition-expand";

export default {
    name: "FriendActions",
    components: { UiButton, TransitionExpand },
    props: {
        refresh: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data: () => ({
        userFriendingStatus: null,
        userFriendingRequestId: null,
        partnerStatus: null,
        isChatAvailability: false,
        requestProcesed: false,
    }),
    computed: {
        ...mapGetters({
            uuid: "person/uuid",
            isPersonLoaded: "person/isPersonLoaded",
            userUuid: "person/userUuid",
            myUserUuid: "user/userUuid",
            myUserRoles: "user/myUserRoles",
        }),
        isPersonPage: function () {
            return (
                this.$route.name === "person" ||
                this.$route.name === "public_person"
            );
        },
        friendRequestAvailable: function () {
            return (
                this.$inArray(this.userFriendingStatus, ["NOT_FOUND"]) &&
                !this.isMyProfile &&
                this.isPersonPage &&
                this.myUserUuid &&
                this.userUuid
            );
        },
        sendMessageAvailable: function () {
            return (
                this.isUserAuth &&
                (this.userFriendingStatus === "ADDED" ||
                    this.isChatAvailability) &&
                !this.request
            );
        },
        request: function () {
            return this.$route.query.request;
        },
    },
    watch: {
        isPersonLoaded: function () {
            this.checkAllStatuses();
        },
        "$route.params.uuid"() {
            this.$store.dispatch("person/clearUserUuid");
            this.checkAllStatuses();
        },
        refresh(status) {
            if (status) {
                this.checkAllStatuses();
            }
        },
    },
    created() {
        setTimeout(() => {
            this.checkAllStatuses();
        }, 500);
    },
    methods: {
        async chatAvailableCheck() {
            await this.$apollo
                .query({
                    query: gql`
                        ${friendChatAvailabilityCheckQuery}
                    `,
                    variables: {
                        uuid: this.userUuid,
                    },
                })
                .then((r) => {
                    this.isChatAvailability =
                        r.data.friendChatAvailabilityCheck;
                });
        },
        async checkFriendStatus() {
            if (
                !this.userUuid ||
                !this.myUserUuid ||
                this.userUuid === this.myUserUuid
            ) {
                return;
            }
            await this.$apollo
                .query({
                    query: gql`
                        ${friendingStatusQuery}
                    `,
                    variables: {
                        id: this.userUuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    this.userFriendingStatus = response.data.friendingStatus;

                    if (response.data.friendingStatus.indexOf("AWAITING_YOUR_RESPONSE") !== -1) {
                        const matches = response.data.friendingStatus.match(/AWAITING_YOUR_RESPONSE_(\d+)/);

                        if (matches && matches[1]) {
                            this.userFriendingRequestId = parseInt(matches[1]);
                        }

                        this.userFriendingStatus = "AWAITING_YOUR_RESPONSE";
                    }
                })
                .catch(() => {
                    if (this.isMyProfile) return;
                    this.$msgBox(
                        this.localeError("error"),
                        this.localeError(
                            "friendStatusNotReceived",
                        ),
                    );
                });
        },
        async checkPartnerStatus() {
            if (!this.userUuid || this.userUuid === this.myUserUuid) {
                return;
            }
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${coachPartnerStatusMutation}
                    `,
                    variables: {
                        id: this.userUuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    this.partnerStatus = response.data.coachPartnerStatus;
                })
                .catch(() => {
                    if (this.isMyProfile) return;
                    this.$msgBox(
                        this.localeError("error"),
                        this.localeError(
                            "partnerStatusNotReceived",
                        ),
                    );
                });
        },
        async sendPartnerRequest() {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${partnerRequestMutation}
                    `,
                    variables: {
                        id: this.userUuid,
                    },
                })
                .then(() => {
                    this.checkPartnerStatus();
                })
                .catch((e) => {
                    const codeError = e.graphQLErrors[0].extensions.code;

                    let title = "error",
                        text = "retry";

                    if (codeError === 1003) {
                        text = "noAccessText";
                    }
                    if (codeError === 1006) {
                        text = "uuidError";
                    }
                    if (codeError === 1007) {
                        text = "alreadyPartner";
                    }
                    if (codeError === 1502) {
                        text = "waitingResponse";
                    }
                    if (codeError === 1503) {
                        text = "partnerRequestDeclined";
                    }
                    this.$msgBox(
                        this.localeError(title),
                        this.localeError(text),
                    );
                });
        },
        async sendFriendRequest() {
            await this.$apollo
                .query({
                    query: gql`
                        ${friendRequestQuery}
                    `,
                    variables: {
                        id: this.userUuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then(() => {
                    this.checkFriendStatus();
                })
                .catch((e) => {
                    let title = "unknownError",
                        text = "retry",
                        type = "";
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        switch (codeError) {
                            case 1001:
                                this.$store.dispatch("auth/signout");
                                break;

                            case 1002:
                                title = "error";
                                text = "pageError";
                                break;

                            case 1006:
                                title = "unknownError";
                                text = "friendRequestError";
                                break;

                            case 1007:
                                title = "alreadyFriendsTitle";
                                text = "alreadyFriends";
                                type = "info";
                                break;

                            case 1502:
                                title = "info";
                                text = "alreadySendFriendRequest";
                                type = "info";
                                break;

                            case 1503:
                                title = "info";
                                text = "friendRequestDecline";
                                break;

                            default:
                                title = "unknownError";
                                text = "retry";
                        }
                    }
                    this.$msgBox(
                        this.localeError(title),
                        this.localeError(text),
                        type,
                    );
                });
        },
        async checkAllStatuses() {
            await this.checkFriendStatus();
            await this.chatAvailableCheck();
            if (
                !this.isMyProfile &&
                this.$inArray(this.myUserRoles, ["ROLE_COACH"])
            ) {
                await this.checkPartnerStatus();
            }
        },
        async cancelFriendRequest() {
            await this.$apollo
                .query({
                    query: gql`
                        ${friendRequestCancelQuery}
                    `,
                    variables: {
                        uuid: this.userUuid,
                    },
                    fetchPolicy: "no-cache",
                })
                .then(() => {
                    this.checkFriendStatus();
                })
                .catch((e) => {
                    console.log(e);
                });
        },
        friendRequestResponse(requestId, response) {
            let action = response; // 'ADDED' or 'DECLINED'

            this.$apollo
                .query({
                    query: gql`
                        ${friendResponseMutation}
                    `,
                    variables: {
                        requestId: +requestId,
                        status: response,
                    },
                    fetchPolicy: "no-cache",
                })
                .then(() => {
                    // TODO скрыть accept-decline действия
                    this.requestProcesed = true;
                    this.$router.replace({ request: null });

                    if (action === "ADDED") {
                        this.$msgBox(
                            "Успешно!",
                            "Заявка в друзья принята",
                            "success",
                        );
                    }

                    if (action === "DECLINED") {
                        this.$msgBox(
                            "Успешно!",
                            "Заявка в друзья отклонена",
                            "success",
                        );
                    }
                })
                .catch(() => {
                    let title = "error",
                        text = "retry";
                    this.$msgBox(
                        this.localeError(title),
                        this.localeError(text),
                    );
                });
        },
        sendMessage() {
            this.$router.push(`/messages/${this.userUuid}`);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "friend-actions";
</style>
