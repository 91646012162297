<template lang="pug">
  perfect-scrollbar.compare-result-dialog(@click.self="close")
    ._wrap
      ._content
        ._close-btn(@click="close")
          svg-icon(icon-name="cross")._close-icon
        ._title {{ title }} 
        ._photos
          template(v-if="config.category.type === 'compare'")
            ._photo(data-tip="choose-avatar" @click="redirectToChooseAvatar" :class="{pointer: !myAvatar}")
              user-photo(:photo="myAvatar" :micro="false")
            ._photo
              user-photo(:photo="config.photo" :micro="false")

          template(v-if="config.category.type !== 'compare'")
            ._photo
              user-photo(:photo="config.photo" :micro="false")

          ._category-icon-box
            svg-icon(:icon-name="config.category.icon")._category-icon
        ._percent {{ normalizePercent }}
        ._description(v-html="formattedDesc")
        ui-button(text="Продолжить" v-if="type === 'onBoarding'" @click.native="close")._close-ui-btn
        ._social(v-else)
          right-aside-social
        router-link(:to="{name: 'person', params: { uuid: config.personUuid }}"  v-if="config.personUuid && false")._more-btn
          ui-button(text="Узнать больше о человеке")
      merlin-dating._merlin-dating(v-if="!isMyProfile && config.category.showMerlinDatingAds" :personName="config.personName")
</template>

<script>
import UserPhoto from "../ui/user-photo/user-photo";
import UiButton from "../ui/ui-button/ui-button";
import MerlinDating from "../app/merlin-dating/merlin-dating";
import RightAsideSocial from "../app/right-aside/social/social";
import { compareMixins } from "@/mixins/compare.mixins";

export default {
    name: "CompareResultDialog",
    components: { RightAsideSocial, MerlinDating, UiButton, UserPhoto },
    mixins: [compareMixins],
    props: {
        config: {
            type: Object,
            required: true,
            default: () => ({}),
        },
        type: {
            type: String,
            required: false,
            default: "",
        },
    },
    computed: {
        formattedDesc: function (state) {
            if (state.config.result.desc) {
                return state.config.result.desc.replace(/\\n/g, "<br/>");
            }
            return "";
        },
        myAvatar: (state) => state.$store.getters["user/myAvatar"],
        icon: (state) =>
            state.config.categoryId ? state.config.categoryId : "heart",
        title: (state) =>
            state.config.result.title
                ? state.config.result.title
                : state.config.category.name,
        normalizePercent: (state) =>
            state.config.result.percent > 100
                ? ">100"
                : state.config.result.percent,
    },
    mounted() {
        setTimeout(() => {
            if (!this.myAvatar && this.type !== "onBoarding") {
                this.showTips("choose-avatar");
            }
        }, 2000);
    },
    methods: {
        close() {
            this.$store.dispatch("resetCompareResultDialog");

            // сбрасываем интервал в миксине compare.mixins.js по закрытию
            this.disableCheckTask();

            if (this.type === "onBoarding") {
                this.$emit("close");
            }
        },
        redirectToChooseAvatar() {
            if (this.myAvatar || this.type === "onBoarding") return;
            this.$router.push("/settings/change-photo/avatar");
            this.close();
        },
    },
};
</script>

<style lang="scss" scoped>
@import "compare-result-dialog.scss";
</style>
