export const assistants = {
    namespaced: true,
    state: {
        assistantId: null,
    },
    actions: {
        setAssistantId({ commit }, uuid) {
            commit("setAssistantId", uuid);
        },
    },
    getters: {
        assistantId: (state) => state.assistantId,
    },
    mutations: {
        setAssistantId(state, uuid) {
            state.assistantId = uuid;
        },
    },
};
