<template lang="pug">
    .subscriptions-widget-full-info
        element-decor(design="design", :size="size")
        ._wrap
            .wrap-header(:class="`wrap-header-${level}`")
                svg-icon.wrap-header-icon(:icon-name="icon")
                span.wrap-header-text {{ title }}
            .wrap-item
                span.wrap-item-title {{ field1 }}
                .wrap-item-info(
                    v-if="!recomendationsCount && !recommendationsUnlockAtError"
                ) до начисления
                    span.wrap-item-info-indicator {{ recommendationsUnlockAt }}
                .wrap-item-info(v-else-if="recommendationsUnlockAtError") обратитесь в службу поддержки
                .wrap-item-info(v-else-if="recomendationsCount > 0") осталось
                    span.wrap-item-info-indicator {{ recomendationsCount }}
            .wrap-item
                span.wrap-item-title {{field2}}
                .wrap-item-info(v-if="!checksCount && !checkCountRenewalDateError") до начисления
                    span.wrap-item-info-indicator {{ checkCountRenewalDate }}
                .wrap-item-info(v-else-if="checkCountRenewalDateError") обратитесь в службу поддержки
                .wrap-item-info(v-else-if="checksCount > 0") осталось
                    span.wrap-item-info-indicator {{ checksCount }}
            .wrap-item
                span.wrap-item-title {{field3}}
                .wrap-item-info(
                    v-if="!checksWithCelebrity && !checksWithCelebritiesCountRenewalDateError"
                ) до начисления
                    span.wrap-item-info-indicator {{ checksWithCelebritiesCountRenewalDate }}
                .wrap-item-info(v-else-if="checksWithCelebritiesCountRenewalDateError") обратитесь в службу поддержки
                .wrap-item-info(v-else-if="checksWithCelebrity > 0") осталось
                    span.wrap-item-info-indicator {{ checksWithCelebrity }}
            .wrap-item
                span.wrap-item-title Статус подписки
                    .wrap-item-title-sub(v-if="invoiceWillBeGenerated") не удалось продлить подписку
                    .wrap-item-title-sub(v-else-if="autoRenew") автопродление активно
                .wrap-item-info(v-if="subscribedDue && !invoiceWillBeGenerated") активна до
                    span.wrap-item-info-indicator {{ subscribedDue }}
</template>

<script>
import ElementDecor from "@/components/ui/element-decor/element-decor";
import getRemainingTime from "@/mixins/common.mixin";
import localizedDate from "@/mixins/common.mixin";
import { DateTime } from "luxon";

export default {
    components: { ElementDecor },
    mixins: [getRemainingTime, localizedDate],
    // Category data is an Object with: time, dates, counters, id, level, name, etc...
    // Title and Icon copying is a bad way, but there is no/incorrect data, so at the moment I kept it here.
    props: {
        icon: {
            type: String,
            default: "",
            required: false,
        },
        title: {
            type: String,
            default: "",
            required: false,
        },
        id: {
            type: [Number, String],
            default: "",
            required: false,
        },
        categoryData: {
            type: Object,
            default: () => ({}),
            required: true,
        },
    },
    data: () => ({
        //For animate element-decor
        size: "small",
        animationTimeout: null,

        //timers and dates
        recommendationsUnlockAt: null,
        recommendationsUnlockAtError: false,
        recommendationsUnlockInterval: null,

        checkCountRenewalDate: null,
        checkCountRenewalDateError: false,
        checkCountRenewalInterval: null,

        checksWithCelebritiesCountRenewalDate: null,
        checksWithCelebritiesCountRenewalDateError: false,
        checksWithCelebritiesCountRenewalInterval: null,
    }),
    computed: {
        //Data depending on internal static id (1-4)
        field1() {
            const fields = [
                "Персональные рекомендации для самореализации",
                "Персональные рекомендации для повышения самооценки",
                "Персональные рекомендации для повышения мотивации",
                "Индивидуальные рекомендации для улучшения отношений",
            ];
            return fields[this.id - 1];
        },

        field2() {
            const fields = [
                "Подбор бизнес-партнеров с Merlinface",
                "Подбор людей для обмена опытом",
                "Подбор людей-мотиваторов",
                "Подбор партнеров для отношений",
            ];
            return fields[this.id - 1];
        },

        field3() {
            const fields = [
                "Проверка деловой совместимости со знаменитостью",
                "???",
                "???",
                "Сравнение на совместимость со знаменитостью",
            ];
            return fields[this.id - 1];
        },

        //Dynamic data from api
        recomendationsCount() {
            return this.categoryData.person_recommendations_unlock_available;
        },
        checksCount() {
            return this.categoryData.checks_count;
        },
        checksWithCelebrity() {
            return this.categoryData.checks_with_celebrities_count;
        },
        subscribedDue() {
            let date = DateTime.fromJSDate(
                new Date(this.categoryData.subscribed_due.date),
            )
                .setLocale("ru")
                .toFormat("dd.MM.yyyy");
            if (date.slice(6, 10) === "2171") {
                return "Бессрочно";
            } else {
                return date;
            }
        },
        autoRenew() {
            return this.categoryData.auto_renew;
        },
        invoiceWillBeGenerated() {
            return this.categoryData.invoice_will_be_generated;
        },
        level() {
            return this.categoryData.level;
        },
    },
    watch: {
        categoryData() {
            this.recommendationsUnlockAtError = false;
            this.checkCountRenewalDateError = false;
            this.checksWithCelebritiesCountRenewalDateError = false;

            this.clearTimers();
            this.startTimers();
        },
    },
    created() {
        //Animation for element-decor
        this.animationTimeout = setTimeout(() => {
            this.size = "big";
            clearTimeout(this.animationTimeout);
        }, 400);

        //Timers initializing
        this.startTimers();
    },
    destroyed() {
        this.clearTimers();
    },
    methods: {
        startTimers() {
            //Timer for recomendations
            if (!this.recomendationsCount || this.recm <= 0) {
                let date =
                    new Date(
                        this.categoryData.person_recommendations_unlock_available_at.date,
                    ).getTime() - new Date().getTime();

                if (date < 0) {
                    this.recommendationsUnlockAtError = true;
                } else {
                    this.recommendationsUnlockAt = this.getRemainingTime(
                        this.categoryData
                            .person_recommendations_unlock_available_at,
                    );

                    this.recommendationsUnlockInterval = setInterval(() => {
                        this.recommendationsUnlockAt = this.getRemainingTime(
                            this.categoryData
                                .person_recommendations_unlock_available_at,
                        );
                    }, 1000);
                }
            }

            //Timer for checks
            if (!this.checksCount || this.checksCount <= 0) {
                let date =
                    new Date(
                        this.categoryData.checks_count_renewal_date.date,
                    ).getTime() - new Date().getTime();

                if (date < 0) {
                    this.checkCountRenewalDateError = true;
                } else {
                    this.checkCountRenewalDate = this.getRemainingTime(
                        this.categoryData.checks_count_renewal_date,
                    );

                    this.checkCountRenewalInterval = setInterval(() => {
                        this.checkCountRenewalDate = this.getRemainingTime(
                            this.categoryData.checks_count_renewal_date,
                        );
                    }, 1000);
                }
            }

            //Timer for checks with celebrity
            if (!this.checksWithCelebrity || this.checksWithCelebrity <= 0) {
                let date =
                    new Date(
                        this.categoryData.checks_with_celebrities_count_renewal_date.date,
                    ).getTime() - new Date().getTime();

                if (date < 0) {
                    this.checksWithCelebritiesCountRenewalDateError = true;
                } else {
                    this.checksWithCelebritiesCountRenewalDate =
                        this.getRemainingTime(
                            this.categoryData
                                .checks_with_celebrities_count_renewal_date,
                        );

                    this.checksWithCelebritiesCountRenewalInterval =
                        setInterval(() => {
                            this.checksWithCelebritiesCountRenewalDate =
                                this.getRemainingTime(
                                    this.categoryData
                                        .checks_with_celebrities_count_renewal_date,
                                );
                        }, 1000);
                }
            }
        },
        clearTimers() {
            clearInterval(this.recommendationsUnlockInterval);
            clearInterval(this.checkCountRenewalInterval);
            clearInterval(this.checksWithCelebritiesCountRenewalInterval);

            this.recommendationsUnlockInterval = null;
            this.checkCountRenewalInterval = null;
            this.checksWithCelebritiesCountRenewalInterval = null;
        },
    },
};
</script>

<style lang="scss">
@import "./subscriptions-widget-full-info.scss";
</style>
