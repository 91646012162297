<template lang="pug">
    modal(name="start-assistant" class="start-assistant-modal-wrap" :adaptive="true" width="100%" height="100%" @before-open="beforeOpen" @before-close="beforeClose")
        .start-assistant-modal
            ._wrap
                ._content
                    ._content-inner
                        ._info
                            ._close-btn(@click="$modal.hide('start-assistant')")
                                svg-icon(icon-name="cross")._close-icon
                            ._status-box
                                ._avatar-box(v-if="isStart")
                                    user-photo(:photo="assistant.personAvatar")._avatar
                                loading._status-loading(v-else-if="isStarting")
                                svg-icon(icon-name="attempt" added-catalog="assistant" v-else)._attempt-icon
                            ._title(v-html="title")
                            ._description(v-html="description")
                            ._buttons
                                ui-button(v-if="!isStart && !isStarting" type="bordered" text="Запустить" @click.native="enableBot")
                                ui-button(type="bordered" color="gray" text="Закрыть" @click.native="$modal.hide('start-assistant')")

</template>

<script>
import UiButton from "../../ui/ui-button/ui-button.vue";
import UserPhoto from "@/components/ui/user-photo/user-photo.vue";
import botSwitchPublishedMutation from "@/graphql/mutations/botSwitchPublished.mutation.graphql";
import gql from "graphql-tag";

export default {
    name: "StartAssistantModal",
    components: { UserPhoto, UiButton },
    data() {
        return {
            assistant: {
                personName: "",
                personId: "",
                personAvatar: null,
            },
            isStarting: false,
            isStart: false,
        };
    },
    computed: {
        title: function () {
            if (this.isStart) {
                return `Ассистент <span>${this.assistant.personName}</span> успешно запущен`;
            }
            if (this.isStarting) {
                return "Запускаем ассистента";
            }
            return "Вы уверены?";
        },
        description: function () {
            if (this.isStart) {
                return "Чтобы получить к нему доступ, вы можете нажать на кнопку «Запущен» и поделиться им с другими.";
            }
            if (this.isStarting) {
                return "Пожалуйста, подождите...";
            }
            return `После запуска ассистента <span>${this.assistant.personName}</span>, доступ к нему может получить любой желающий, а удалить его можно только по нашему усмотрению.`;
        },
    },
    mounted() {},
    methods: {
        beforeOpen(event) {
            this.assistant = event.params?.assistant;
        },
        beforeClose() {
            console.log("before close");
            this.isStarting = false;
            this.isStart = false;
        },
        async enableBot() {
            this.isStarting = true;
            await this.$apollo
                .mutate({
                    mutation: gql(botSwitchPublishedMutation),
                    variables: {
                        uuid: this.assistant.personId,
                    },
                })
                .then(() => {
                    this.isStart = true;
                    this.$store.dispatch(
                        "assistants/setAssistantId",
                        this.assistant.personId,
                    );
                });
        },
    },
};
</script>

<style lang="scss" src="./start-assistant.scss"></style>
