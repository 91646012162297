import Vue from "vue";
import gql from "graphql-tag";
import { dataURLtoFile } from "@/utils/navigator-utils";

export const photoUploadMixins = {
    methods: {
        async uploadPhotoWebcamAdapter({ imageEncoded, id }) {
            const file = dataURLtoFile(imageEncoded, `$photo-${id}.png`);

            const target = {
                id: id,
                files: [file],
                isDecoded: true,
            };

            await this.uploadPhoto({ target });
        },
        async uploadPhoto({ target }) {
            this.anotherPerson = false;

            if (!target.files[0].size && !target.isDecoded) {
                return;
            }

            this[target.id].loading = true;
            await this.$store
                .dispatch("person/getPhotoUploadUrl", {
                    apolloClient: this.$apollo,
                })
                .then((response) => {
                    const uploadUrl = response.data.getPhotoUploadUrl,
                        $this = this,
                        xhr = new XMLHttpRequest(),
                        //TODO: test infinite loading, if it will work then change usage of:
                        // "this.$store.getters['auth/token']" everywhere
                        token =
                            localStorage.getItem("token") ||
                            Vue.$cookies.get("token");

                    xhr.open(
                        "POST",
                        uploadUrl + "&category=" + target.id,
                        true,
                    );
                    xhr.setRequestHeader("Authorization", token);

                    xhr.onreadystatechange = async function () {
                        if (this.readyState === XMLHttpRequest.DONE) {
                            if (this.status === 200) {
                                const response = JSON.parse(this.responseText);

                                if (response.error === null) {
                                    if (
                                        target.id === "friendAnfas" ||
                                        target.id === "friendProfile"
                                    ) {
                                        $this[target.id].md5 = response.md5;
                                        $this[target.id].photo =
                                            response.http_host +
                                            response.folder_name +
                                            response.file_name;
                                        $this[target.id].loading = false;
                                        return;
                                    }

                                    if (
                                        target.id === "adminAnfas" ||
                                        target.id === "adminProfile" ||
                                        target.id === "adminAvatar"
                                    ) {
                                        $this[target.id].photo =
                                            response.http_host +
                                            response.folder_name +
                                            response.file_name;
                                        await $this.setPhotoFromAdmin(
                                            target.id,
                                            target.dataset.uuid,
                                        );
                                        return;
                                    }

                                    $this[target.id].photo =
                                        response.http_host +
                                        response.folder_name +
                                        response.file_name;

                                    $this[target.id].preview =
                                        response.http_host +
                                        response.folder_name +
                                        response.file_name;

                                    await $this.setPhotoToPerson(target.id);
                                } else {
                                    $this.handlerError(response.error, false, "", target.id);

                                    switch (target.id) {
                                        case "anfas":
                                        case "profile":
                                        case "friendAnfas":
                                        case "adminAnfas":
                                        case "adminProfile":
                                        case "friendProfile":
                                            $this[target.id].loading = false;

                                            if (
                                                $this[target.id].valid !==
                                                undefined
                                            ) {
                                                $this[target.id].valid = false;
                                            }

                                            break;
                                    }

                                    return false;
                                }
                            } else {
                                $this[target.id].loading = false;
                                $this[target.id].valid = false;
                                $this.$msgBox(
                                    $this.localeError(
                                        "unknownError",
                                    ),
                                    $this.localeError(
                                        "retry",
                                    ),
                                );
                            }
                        }
                    };
                    xhr.send(target.files[0]);
                });
        },

        async setPhotoToPerson(id) {
            let $this = this;
            await this.$store
                .dispatch("person/setPersonPhoto", {
                    apolloClient: this.$apollo,
                    category: id.toUpperCase(),
                    uuid: this.userUuid,
                    photo: this[id].photo,
                })
                .then((response) => {
                    const taskId = response.data.setPersonPhoto.taskId;
                    let intervalId;

                    intervalId = setInterval(() => {
                        $this.$apollo
                            .query({
                                query: gql`
                                    query taskGet($taskId: String!) {
                                        taskGet(taskId: $taskId) {
                                            status
                                            error
                                            result
                                        }
                                    }
                                `,
                                variables: {
                                    taskId,
                                },
                                fetchPolicy: "no-cache",
                            })
                            .then((response) => {
                                switch (response.data.taskGet.status) {
                                    case "COMPLETED":
                                    case "ERROR":
                                        clearInterval(intervalId);
                                        $this[id].loading = false;
                                        $this.changed = true;

                                        if (
                                            response.data.taskGet.status ===
                                            "COMPLETED"
                                        ) {
                                            if (id === "avatar") {
                                                $this.$store.dispatch(
                                                    "person/setAvatar",
                                                    {
                                                        category: "AVATAR",
                                                        photo: $this[id].photo,
                                                    },
                                                );
                                            }
                                            $this[id].valid = true;
                                            $this.$emit("photo-uploaded");
                                            $this.$store.dispatch(
                                                "user/setTemporaryPhoto",
                                                {
                                                    photo: this[id].photo,
                                                    type: id,
                                                },
                                            );
                                            
                                            this.yandexGoal(`${id}_upload`);

                                            switch (id) {
                                                case "anfas":
                                                    $this.$store.dispatch(
                                                        "user/decreaseMyUserLimits",
                                                        "uploadAnfasPhoto",
                                                    );
                                                    break;

                                                case "profile":
                                                    $this.$store.dispatch(
                                                        "user/decreaseMyUserLimits",
                                                        "uploadProfilePhoto",
                                                    );
                                                    break;
                                            }

                                            setTimeout(() => {
                                                $this.$store.dispatch(
                                                    "user/updateMyUserLimits",
                                                    $this.$apollo,
                                                );
                                            }, 5000);
                                        } else {
                                            $this[id].valid = false;

                                            if (
                                                response.extensions &&
                                                response.extensions.code
                                            ) {
                                                // task is not created yet
                                                if (
                                                    response.extensions.code ===
                                                    1002
                                                ) {
                                                    return false;
                                                }
                                            }

                                            $this.handlerError(
                                                response.data.taskGet.error,
                                                false,
                                                response.data.taskGet.result,
                                                id
                                            );
                                        }

                                        $this.loadingText = "";
                                        break;
                                }
                            })
                            .catch((r) => {
                                this.yandexGoal(`${id}_error`);
                                if (r.graphQLErrors) {
                                    const error =
                                        r.graphQLErrors[0].extensions.code;

                                    if (error === 1002) {
                                        return;
                                    }
                                }
                                clearInterval(intervalId);
                                this.$msgBox(
                                    this.localeError(
                                        "unknownError",
                                    ),
                                    this.localeError(
                                        "retry",
                                    ),
                                );
                            });
                    }, 1200);
                })
                .catch((e) => {
                    this.handlerError(
                        e.graphQLErrors[0].extensions.code,
                        false,
                        "",
                        id,
                    );
                });
        },

        async getMyPhotos() {
            const query = gql`
                query {
                    getMyUser {
                        person {
                            photos {
                                anfas
                                profile
                            }
                        }
                    }
                }
            `;
            return this.$apollo.query({
                query: query,
                fetchPolicy: "no-cache",
            });
        },

        async setPhotoFromAdmin(id, uuid) {
            let photoType = id.replace("admin", "");
            console.log("setPhotoFromAdmin", id, photoType);
            await this.$store
                .dispatch("person/setPersonPhoto", {
                    apolloClient: this.$apollo,
                    category: photoType.toUpperCase(),
                    uuid: uuid,
                    photo: this[id].photo,
                })
                .then((response) => {
                    const taskId = response.data.setPersonPhoto.taskId;
                    let intervalId;
                    intervalId = setInterval(() => {
                        this.$apollo
                            .query({
                                query: gql`
                                    query taskGet($taskId: String!) {
                                        taskGet(taskId: $taskId) {
                                            status
                                            error
                                        }
                                    }
                                `,
                                variables: {
                                    taskId,
                                },
                                fetchPolicy: "no-cache",
                            })
                            .then((response) => {
                                switch (response.data.taskGet.status) {
                                    case "COMPLETED":
                                    case "ERROR":
                                        clearInterval(intervalId);

                                        if (
                                            response.data.taskGet.status ===
                                            "COMPLETED"
                                        ) {
                                            this.$store.dispatch(
                                                "person/setTemporaryPhoto",
                                                {
                                                    photo: this[id].photo,
                                                    type: photoType.toUpperCase(),
                                                },
                                            );
                                        } else {
                                            this.handlerError(
                                                response.data.taskGet.error,
                                                false,
                                                "",
                                                id
                                            );
                                        }

                                        this[id].loading = false;
                                        break;
                                }
                            })
                            .catch((r) => {
                                if (r.graphQLErrors) {
                                    const error =
                                        r.graphQLErrors[0].extensions.code;

                                    if (error === 1002) {
                                        return;
                                    }
                                }

                                clearInterval(intervalId);
                                this.$msgBox(
                                    this.localeError(
                                        "unknownError",
                                    ),
                                    this.localeError(
                                        "retry",
                                    ),
                                );
                            });
                    }, 1000);
                });
        },
        handlerError(errorCode, isReturn = false, result = "", id) {
            let errorTitle = "";
            let errorMsg = "";

            this.yandexGoal(`${id}_error`);

            switch (parseInt(errorCode)) {
                case 1200:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongAnfasPhoto";
                    break;

                case 1201:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongProfilePhoto";
                    break;

                case 1205:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongPhotoSame";
                    break;

                case 1206:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongPhotoFeatures";
                    break;

                case 1207:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongPhotoYouAreNot";
                    break;

                case 1208:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongPhotoFewPersons";
                    break;

                case 1209:
                    errorTitle = "attention";
                    errorMsg = "firstUploadAnfas";
                    this[id].loading = false;
                    this[id].photo = null;
                    this[id].preview = null;
                    this[id].valid = false;
                    break;

                case 1210:
                    errorTitle = "attention";
                    errorMsg = "descriptionNotReady";
                    break;

                case 12001:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongPhotoToTheRight";
                    break;

                case 12002:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongPhotoToTheRight";
                    break;

                case 12003:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongPhotoToTheBottom";
                    break;

                case 12004:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongPhotoToTheUp";
                    break;

                case 12005:
                    errorTitle = "wrongPhotoTitle";
                    errorMsg = "wrongPhotoFewPersons";
                    break;

                default:
                    errorTitle = "unknownError";
                    errorMsg = "photoNotUploaded";
            }

            if (isReturn) {
                return {
                    title: errorTitle,
                    message: errorMsg,
                };
            }

            this.$emit("photo-upload-error", Number(errorCode), result);

            if (Number(errorCode) === 1212) return;

            this.$msgBox(
                this.localeError(errorTitle),
                this.localeError(errorMsg),
            );
        },
    },
    watch: {},
};
