<template lang="pug">
    .personal-recommendations-dialog
        ._outer
            ._inner(@click.self="closeRecommendations")
                ._content-wrap
                    ._head
                        ._title {{ personalRecommendations.category }}
                            span._title-small {{ locale("title") }}
                        ._close(@click="closeRecommendations")
                            svg-icon(icon-name="cross")._close-icon
                    ._content(data-tip="recommendations-modal")
                        service-collapse(
                            v-for="(item, index) in personalRecommendations.list"
                            :title="`${locale('number')}${index+1}`"
                            :key="item.id"
                            :open="false"
                            :disabled="!item.unlockAvailable && !item.text"
                            design="!item.unlocked ? 'gray' : ''"
                            :class="{disabled: !item.unlockAvailable && !item.text}"
                            :serviceIndex="item.text"
                            :timer="item.unlockAvailableAt && !item.unlockAvailable"
                            :categoryId="categoryId"
                            @close-recommendations="closeRecommendations"
                            @click.native="getNewRecommendation(item)"
                        )._item
                            template(slot="ctrl")
                                ._item-timer(v-if="item.unlockAvailableAt && !item.unlockAvailable" :data-text="locale('openAfter')") {{ timeLeft }}
                                ._item-timer.-availabel(v-if="item.unlockAvailableAt && item.unlockAvailable") {{ locale("canOpen") }}
                                ._item-timer.-locked(v-if="!item.unlockAvailable && !item.text && !item.unlockAvailableAt")
                                    svg-icon(icon-name="lock")._item-lock-icon
                            ._item-content(slot="content" v-html="formatText(item.text)")

</template>

<script>
import gql from "graphql-tag";
import unlockRecommendationMutation from "@/graphql/mutations/unlockRecommendation.mutation.graphql";

export default {
    name: "PersonalRecommendationsDialog",
    data() {
        return {
            isOpen: false,
            timeLeft: null,
        };
    },
    computed: {
        personalRecommendations: (state) =>
            state.$store.getters["personalRecommendations"],
        openingTime: (state) =>
            state.personalRecommendations.list.find(
                (item) => item.unlockAvailableAt,
            )?.unlockAvailableAt,
        categoryId: state => state.$store.getters["planIds"][state.personalRecommendations.category]
    },
    created() {
        this.disableBodyScroll();
        this.timeLeft = this.getRemainingTime(this.openingTime);
        setInterval(() => {
            this.timeLeft = this.getRemainingTime(this.openingTime);
        }, 1000);
    },
    mounted() {
        this.showTips("recommendations-modal");
        history.pushState(null, null, location.href + "#modal");
        window.onpopstate = () => {
            this.closeRecommendations();
        };
    },
    destroyed() {
        history.pushState(null, null, location.href.split("#")[0]);
        window.onpopstate = () => {
            console.log("destroy onPopState");
        };
    },
    methods: {
        async getNewRecommendation(item) {
            await this.$apollo
                .mutate({
                    mutation: gql`
                        ${unlockRecommendationMutation}
                    `,
                    variables: {
                        id: parseInt(item.id),
                    },
                })
                .then((r) => {
                    const result = r.data.unlockRecommendation;
                    this.$bus.$emit("getNewPersonalRecommendations");
                    if (result.unlocked) {
                        this.isOpen = true;
                        //item.text = result.text;
                        //item.unlockAvailableAt = null;
                        //item.unlockAvailable = false;
                    }
                })
                .catch((e) => {
                    if (e.graphQLErrors[0].extensions.code === 1007) {
                        console.log(1007);
                        this.$bus.$emit("getNewPersonalRecommendations");
                        this.isOpen = true;
                    }
                });
        },
        closeRecommendations() {
            if (this.isOpen) {
                this.$bus.$emit("getNewPersonalRecommendation");
            }
            this.enableBodyScroll();
            this.$store.dispatch("setPersonalRecommendations", {
                status: false,
                category: null,
                list: [],
                index: null,
            });
        },

        formatText(text) {
            if (text) {
                return text.replace(/\n/g, "<br/>");
            }
            return "";
        },
    },
};
</script>

<style src="./personal-recommendations-dialog.scss" lang="scss" scoped></style>
