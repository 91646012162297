<template lang="pug">
    .menu(:class="{menu_active: menuStatus}")
        ._wrap
            ._services
                ._close(@click="menuStatus = false") Закрыть меню
                template(v-if="isEditor")
                    a(v-for="(category, index) in categories" v-bind:key="category.id")._service-link.-type.block
                        ._row
                            ._name(@click="selectCategory(category.id, index)")
                                | {{ category.name }}
                            ._del(v-if="isAdmin" @click="deleteCategory(category.id, index)") x

                        ._persons(:class="{expanded: selectedCategory === category.id}")
                            ._person(
                                v-if="category.persons.length > 0"
                                v-for="(person, personIndex) in category.persons" v-bind:key="person.uuid"
                            )
                                router-link(:to="{name: 'person', params: {uuid: person.uuid}}")._name
                                    | {{ person.name }}
                                .del(@click="deletePerson(person.uuid, personIndex, index)") x
                            ._add-btn
                                input(type="text" placeholder="Добавить личность" v-model="addPersonInput[index]" @keydown="createPersonHandler")

            ._group
                ._add-btn
                    input(type="text" placeholder="Добавить категорию" v-model="addCategoryInput" @keydown="createCategoryHandler")

            //._services
            //    router-link(to="/")._service-link
            //        ._service-icon-box
            //            svg-icon(icon-name="base-desc" added-class="menu__service-icon")
            //        ._service-name Ваше базовое описание
            //    router-link(to="/")._service-link
            //        ._service-icon-box
            //            svg-icon(icon-name="full-desc" added-class="menu__service-icon")
            //        ._service-name Ваше полное описание
            //    router-link(to="/")._service-link
            //        ._service-icon-box
            //            svg-icon(icon-name="your-leadership-skills" added-class="menu__service-icon")
            //        ._service-name Ваши лидерские качества
            //._balance
            //    ._balance-icon
            //        svg-icon(icon-name="f-logo")
            //    ._balance-text {{ balance }} Руб.
            //._links
            //    router-link(to="/")._link О проекте
            //    router-link(to="/")._link Контакты
</template>

<script>
import SvgIcon from "../icon-base/icon-base";
import { menuMixins } from "../../../mixins/menuMixins";

export default {
    name: "PersonMenu",
    components: { SvgIcon },
    mixins: [menuMixins],
    data: () => ({
        selectedCategory: 0,
        selectedCategoryIndex: 0,
        prevCategory: 0,
        addCategoryInput: "",
        addPersonInput: [],
        menuStatus: false,
        menuActivator: false,
    }),
    computed: {
        balance: function () {
            return this.$store.getters["user/balance"];
        },
        categories: function () {
            return this.getPersonCategories;
        },
    },
    created() {
        this.$bus.$on("openCategoryMenu", () => {
            this.menuActivator = true;
            this.menuStatus = true;
            setTimeout(() => {
                this.menuActivator = false;
            }, 1000);
        });
    },
    apollo: {
        getPersonCategories: menuMixins.queries.getPersonCategories,
    },
    methods: {
        closeMenu() {
            if (!this.menuActivator) {
                this.menuStatus = false;
            }
        },
        selectCategory(categoryId, index) {
            // collapse
            if (this.prevCategory === categoryId) {
                this.selectedCategory = 0;
                this.selectedCategoryIndex = -1;
                this.prevCategory = 0;
                return;
            }

            // expand
            this.selectedCategory = categoryId;
            this.selectedCategoryIndex = index;
            this.prevCategory = categoryId;
        },
        async createPersonHandler(event) {
            let keyCode;

            if (event.key !== undefined) {
                keyCode = event.key;
            } else if (event.keyIdentifier !== undefined) {
                keyCode = event.keyIdentifier;
            } else if (event.keyCode !== undefined) {
                keyCode = event.keyCode;
            }

            if (keyCode === 13 || keyCode === "Enter") {
                event.preventDefault();
                const name = event.target.value;
                await this.createPerson(name);
            }
        },
        async createPerson(name) {
            name = name.trim();
            if (name.length === 0) {
                return;
            }

            await this.$apollo
                .mutate({
                    mutation: menuMixins.mutations.personCreate,
                    variables: {
                        name,
                        categoryId: parseInt(this.selectedCategory),
                    },
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    const uuid = response.data.personCreate;
                    const person = {
                        __typename: "Person",
                        uuid,
                        name,
                    };

                    this.categories[this.selectedCategoryIndex].persons.push(
                        person,
                    );
                    this.addPersonInput[this.selectedCategoryIndex] = "";

                    this.$router.push(
                        this.$router.resolve({
                            name: "person",
                            params: { uuid },
                        }).href,
                    );
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        alert(codeError);
                    } else {
                        console.error(e);
                    }
                });
        },

        async deletePerson(uuid, personIndex, categoryIndex) {
            uuid = uuid.trim();
            if (uuid.length !== 36) {
                return;
            }

            let agree = confirm("Вы действительно хотите удалить личность?");
            if (agree) {
                await this.$apollo
                    .mutate({
                        mutation: menuMixins.mutations.personDelete,
                        variables: { uuid },
                        fetchPolicy: "no-cache",
                    })
                    .then((response) => {
                        const success = response.data.personDelete;
                        if (success === true) {
                            this.categories[categoryIndex].persons.splice(
                                personIndex,
                                1,
                            );
                        }
                    })
                    .catch((e) => {
                        if (e.graphQLErrors) {
                            const codeError =
                                e.graphQLErrors[0].extensions.code;
                            alert(codeError);
                        } else {
                            console.error(e);
                        }
                    });
            }
        },
        async createCategoryHandler(event) {
            let keyCode;

            if (event.key !== undefined) {
                keyCode = event.key;
            } else if (event.keyIdentifier !== undefined) {
                keyCode = event.keyIdentifier;
            } else if (event.keyCode !== undefined) {
                keyCode = event.keyCode;
            }

            if (keyCode === 13 || keyCode === "Enter") {
                event.preventDefault();
                const name = event.target.value;
                await this.createCategory(name);
            }
        },
        async createCategory(name) {
            name = name.trim();
            if (name.length === 0) {
                return;
            }

            await this.$apollo
                .mutate({
                    mutation: menuMixins.mutations.createPersonCategory,
                    variables: {
                        name: name,
                    },
                    fetchPolicy: "no-cache",
                })
                .then((response) => {
                    const category = {
                        __typename: "PersonCategory",
                        id: response.data.createPersonCategory,
                        name,
                        persons: [],
                    };

                    this.categories.push(category);
                    this.addCategoryInput = "";
                })
                .catch((e) => {
                    if (e.graphQLErrors) {
                        const codeError = e.graphQLErrors[0].extensions.code;
                        alert(codeError);
                    } else {
                        console.error(e);
                    }
                    //this.$appError(codeError, 'Личность не найдена');
                });
        },

        async deleteCategory(id, index) {
            id = parseInt(id);
            if (id <= 0) {
                return index;
            }

            let agree = confirm(
                "Вы действительно хотите удалить категорию? Все личности, входящие в данную категорию, будут также удалены.",
            );
            if (agree) {
                await this.$apollo
                    .mutate({
                        mutation: menuMixins.mutations.deletePersonCategory,
                        variables: { id },
                        fetchPolicy: "no-cache",
                    })
                    .then((response) => {
                        const success = response.data.deletePersonCategory;
                        if (success === true) {
                            this.categories.splice(index, 1);
                        }
                    })
                    .catch((e) => {
                        if (e.graphQLErrors) {
                            const codeError =
                                e.graphQLErrors[0].extensions.code;
                            alert(codeError);
                        } else {
                            console.error(e);
                        }
                        //this.$appError(codeError, 'Личность не найдена');
                    });
            }
        },
    },
};
</script>

<style lang="scss">
@import "menu";
</style>
