<template lang="pug">
    .ai-landing
        Loading(v-if="isLoading")
        template(v-else)
            router-view
</template>

<script>
import LandingHeader from "@/components/landing/landing-header/landing-header";
import LayoutDecor from "@/components/ui/layout-decor/layout-decor";
import LandingBanner from "@/components/landing/landing-banner/landing-banner";
import LandingAdvantages from "@/components/landing/landing-advantages/landing-advantages";
import LandingSteps from "@/components/landing/landing-steps/landing-steps";
import LandingMission from "@/components/landing/landing-mission/landing-mission";
import LandingReviews from "@/components/landing/reviews/reviews";
import LandingAbout from "@/components/landing/landing-about/landing-about.vue";
import AiHeader from "@/components/ai-landings/ai-header/ai-header.vue";
import AiFooter from "@/components/ai-landings/ai-footer/ai-footer.vue";

export default {
    name: "AILayout",
    components: {
        AiFooter,
        AiHeader,
        LandingAbout,
        LandingReviews,
        LandingMission,
        LandingSteps,
        LandingAdvantages,
        LandingBanner,
        LayoutDecor,
        LandingHeader,
    },
    data() {
        return {
            isLoading: true,
        };
    },
    created() {
        this.isLoading = false;
    },
    methods: {},
};
</script>

<style lang="scss">
.ai-landing {
    position: relative;
    min-height: 660px;
    background: #000;

    .section {
        display: flex;
        justify-content: center;
    }

    .wrap {
        width: 100%;
        max-width: 1150px;
        @media (max-width: 1149px) {
            max-width: 880px;
        }
        @media (max-width: 879px) {
            max-width: 700px;
        }
        @media (max-width: 699px) {
            max-width: 100%;
        }
    }

    .ui-button {
        text-transform: initial;
    }
}
</style>
