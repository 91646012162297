<template lang="pug">
    .merlin-dating-pay
        ._dialog
            ._content
                ._close-btn(@click="closeModal")
                    svg-icon(icon-name="cross")._close-icon
                transition-group(name="fade" tag="div")._content-animation  
                    loading(v-if="isLoading" key="loading")._loading
                    ._content-inner(v-else key="content")
                        ._head
                            ._head-ctrl.-prev(:class="prevPlan" @click="activePlan = prevPlan")
                                svg-icon(icon-name="chevron-left")._head-ctrl-icon
                                ._head-ctrl-text {{ prevPlan }}
                            ._head-active-plan Подписка {{ activePlan }}        
                            ._head-ctrl.-next(:class="nextPlan" @click="activePlan = nextPlan")
                                ._head-ctrl-text {{ nextPlan }}
                                svg-icon(icon-name="chevron-right")._head-ctrl-icon
                        ._content-wrap
                            ._advantages
                                VueSlickCarousel(:arrows="false" :dots="false" ref="slider" @afterChange="changeActiveIndex" :draggable="false")
                                    ._advantages-item(v-for="adv in advantages" :key="adv.name" v-if="adv.availableForPlans.indexOf(activePlan) >= 0")
                                        svg-icon(:icon-name="adv.icon" added-catalog="merlin-dating-subscriptions" :fill="`url(#${activePlan}_gradient`")._advantages-icon
                                        ._advantages-name {{ adv.name }}
                            ._advantages-ctrl
                                ._advantages-ctrl-btn(@click="slider.prev()")
                                    svg-icon(icon-name="chevron-left")._advantages-ctrl-icon
                                ._advantages-pagination
                                    ._advantages-pagination-dot(v-for="(dot, index) in advantages" :key="dot.name" :class="{active: index === activeAdvantages}" v-if="dot.availableForPlans.indexOf(activePlan) >= 0" @click="slider.goTo(index)")
                                ._advantages-ctrl-btn(@click="slider.next()")
                                    svg-icon(icon-name="chevron-right")._advantages-ctrl-icon
        
                            ._plans
                                ._plan(v-for="(plan, index) in activePlanData" :key="plan.id" :class="[activePlan, {active: plan.active}]" @click="changePlan(plan)")
                                    ._plan-group
                                        ._plan-period(v-html="periods[plan.date_modifier]")
                                        ._plan-label(v-if="index === activePlanData.length - 1")
                                            svg-icon(icon-name="fire" added-catalog="merlin-dating-subscriptions")._plan-label-icon
                                            ._plan-label-text Лучшее
                                    ._plan-group
                                        ._plan-sale(v-if="index > 0") Экономия {{ getSaleSize(activePlanData[0], index) }}                  
                                        ._plan-price {{ plan.price }} Р 
                            
                            ui-button(:text="`Оплатить Merlin Dating ${activePlan} — ${selectedPlan.price} Р`" :class="activePlan" @click.native="goToPay")._pay-btn                         

        svg
            defs
                linearGradient(id="plus_gradient" x1="162.5" y1="11.6667" x2="-21.6667" y2="120" gradientUnits="userSpaceOnUse")
                    stop(offset="0.567708" stop-color="#9777F5")
                    stop(offset="1" stop-color="#F4E303")
                linearGradient(id="gold_gradient" x1="162.5" y1="11.6667" x2="-21.6667" y2="120" gradientUnits="userSpaceOnUse")
                    stop(offset="0.395833" stop-color="#F4E303")
                    stop(offset="0.780208" stop-color="#9777F5")
                linearGradient(id="platinum_gradient" x1="162.5" y1="11.6667" x2="-21.6667" y2="120" gradientUnits="userSpaceOnUse")
                    stop(offset="0.15" stop-color="#9777F5")
                    stop(offset="0.69" stop-color="#31BCF7")
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import merlinAppSubscriptionPlansQuery from "@/graphql/queries/merlinAppSubscriptionPlans.query.graphql";
import merlinAppSubscribeToPlanQuery from "@/graphql/queries/merlinAppSubscribeToPlan.query.graphql";

import gql from "graphql-tag";
import UiButton from "@/components/ui/ui-button/ui-button";

export default {
    name: "MerlinDatingPay",
    components: {
        UiButton,
        VueSlickCarousel,
    },
    data() {
        return {
            isLoading: true,
            availablePlans: ["gold", "platinum", "plus"],
            activePlan: "platinum",
            advantages: [
                {
                    name: "Безлимитные анализы на совместимость",
                    icon: "unlimited-compare",
                    availableForPlans: ["gold", "platinum", "plus"],
                },
                {
                    name: "Безлимитные анализы с совместимостью черт характера",
                    icon: "unlimited-character",
                    availableForPlans: ["gold", "platinum", "plus"],
                },
                {
                    name: "Режим инкогнито для людей с тарифом Free",
                    icon: "anonymous",
                    availableForPlans: ["gold", "platinum", "plus"],
                },
                {
                    name: "Нет рекламы",
                    icon: "no-ads",
                    availableForPlans: ["gold", "platinum", "plus"],
                },
                {
                    name: "Безлимитные анализы с рекомендациями для знакомства",
                    icon: "unlimited-recommendations",
                    availableForPlans: ["gold", "platinum"],
                },
                {
                    name: "Твои сообщения видят в первую очередь",
                    icon: "messages",
                    availableForPlans: ["gold", "platinum"],
                },
                {
                    name: "Подбор наиболее совместимых с тобой людей",
                    icon: "get-peoples",
                    availableForPlans: ["platinum"],
                },
                {
                    name: "Тебя видят в первую очередь",
                    icon: "favorite",
                    availableForPlans: ["platinum"],
                },
                {
                    name: "Виджет в чате с описанием характера собеседника",
                    icon: "widget",
                    availableForPlans: ["platinum"],
                },
                {
                    name: "Процент совместимости вместо иконок отношений",
                    icon: "percent",
                    availableForPlans: ["platinum"],
                },
            ],
            activeAdvantages: 0,
            gold: [],
            platinum: [],
            plus: [],
            periods: {
                "+1 month": "МЕСЯЧНАЯ<br/>подписка",
                "+6 months": "6-МЕСЯЧНАЯ<br/>подписка",
                "+1 year": "Годовая<br/>подписка",
            },
        };
    },
    computed: {
        activePlanData: (state) =>
            state[state.activePlan].sort((a, b) =>
                a.price > b.price ? 1 : -1,
            ),
        selectedPlan: (state) =>
            state.activePlanData.find((plan) => plan.active),
        slider: (state) => state.$refs.slider,
        prevPlan: function () {
            const activeIndex = this.availablePlans.indexOf(this.activePlan);

            if (activeIndex === 0) {
                return this.availablePlans[this.availablePlans.length - 1];
            }

            return this.availablePlans[activeIndex - 1];
        },
        nextPlan: function () {
            const activeIndex = this.availablePlans.indexOf(this.activePlan);

            if (activeIndex === this.availablePlans.length - 1) {
                return this.availablePlans[0];
            }

            return this.availablePlans[activeIndex + 1];
        },
    },
    watch: {
        activePlan() {
            this.activeAdvantages = 0;
            this.slider.goTo(0);
        },
    },
    created() {
        this.getPlans();
        
        this.disableBodyScroll();
        document.body.classList.add("modal");
    },
    destroyed() {
        this.enableBodyScroll();
        document.body.classList.remove("modal");
    },
    methods: {
        async getPlans() {
            await this.$apollo
                .query({
                    query: gql(merlinAppSubscriptionPlansQuery),
                    fetchPolicy: "no-cache",
                })
                .then((r) => {
                    this.gold = r.data.merlinAppSubscriptionPlans
                        .filter((plan) => plan.level === "GOLD")
                        .map((plan, index) => ({
                            ...plan,
                            active: index === 0,
                        }));
                    this.platinum = r.data.merlinAppSubscriptionPlans
                        .filter((plan) => plan.level === "PLATINUM")
                        .map((plan, index) => ({
                            ...plan,
                            active: index === 0,
                        }));
                    this.plus = r.data.merlinAppSubscriptionPlans
                        .filter((plan) => plan.level === "PLUS")
                        .map((plan, index) => ({
                            ...plan,
                            active: index === 0,
                        }));

                    setTimeout(() => {
                        this.isLoading = false;
                    }, 1000);
                })
                .catch((e) => {
                    if (e.graphQLErrors[0].extensions.code === 1500) {
                        this.getPlans();
                    }
                });
        },
        closeModal() {
            this.$emit("close");
        },
        changeActiveIndex(index) {
            this.activeAdvantages = index;
        },
        getSaleSize(mounthly, activePeriod) {
            const mounthlyPrice = mounthly.price;
            let period = 6;

            if (this.activePlanData[activePeriod].date_modifier === "+1 year") {
                period = 12;
            }

            const everyMonth = period * mounthlyPrice;
            const everyPeriod = this.activePlanData[activePeriod].price;
            const saleNumber = everyMonth - everyPeriod;

            return `${Math.ceil((saleNumber * 100) / everyMonth)}%`;
        },
        changePlan(plan) {
            this[this.activePlan].map((plan) => (plan.active = false));
            plan.active = true;
        },
        async goToPay() {
            console.log(this.selectedPlan);
            await this.$apollo
                .query({
                    query: gql(merlinAppSubscribeToPlanQuery),
                    variables: {
                        planId: this.selectedPlan.id,
                    },
                })
                .then((r) => {
                    let url = r.data.merlinAppSubscribeToPlan;

                    if (process.env.NODE_ENV === "development") {
                        url = `${url}&isTest=true`;
                    }

                    window.location.href = url;
                });
        },
    },
};
</script>

<style lang="scss" src="./merlin-dating-pay.scss"></style>
