<template lang="pug">
    .change-photos
        ._content
            ._head(
                v-if="(!anfasPhoto || !profilePhoto) && photoType === 'anfas and profile'"
            )
                gift-timer(:time="giftTimer" v-if="showGift")
            invite-friends._another-photo(v-if="anotherPerson" :text="anotherPersonText" :show-share-icons="photoType !== 'avatar'")
            ._content
                ._photo(v-if="photoType === 'anfas and profile'")
                    ._photo-group(
                        v-if="showAnfas" :class="{ 'change-photos__photo-group_valid': haveAnfasPhoto && anfas.valid && !anfas.loading && uploadLimitIsReached('anfas') === false, 'change-photos__photo-group_have-photo': (anfasPhoto && anfas.valid) || anfas.loading, 'change-photos__photo-group_invalid': !anfas.valid && !anfas.loading }"
                    )
                        img#anfas-photo._visible-photo(
                            :src="anfas.preview || anfasPhoto",
                            v-if="haveAnfasPhoto"
                        )
                        label._photo-zone(
                            v-bind:for="[uploadLimitIsReached('anfas') === false ? 'anfas' : '']",
                            ref="anfasPhoto",
                            :class="{ 'change-photos__photo-zone__dragging': isDraggingPhoto && !uploadLimitIsReached('anfas') }"
                        )
                            input#anfas._photo-input(
                                ref="anfasInput",
                                type="file",
                                accept="image/*",
                                :disabled="uploadLimitIsReached('anfas')",
                                v-on="!uploadLimitIsReached('anfas') ? { drop: ($event) => dropHandler($event, 'anfasPhoto') } : { drop: ($event) => $event.preventDefault() }",
                                @change="uploadPhoto"
                            )
                            ._choose-photo-content(v-if="!haveAnfasPhoto")
                                svg-icon._choose-photo-icon(icon-name="upload-photo")
                                ._choose-photo-title {{ isDraggingPhoto ? anfas.dragText : !isMobileView ? anfas.desktopText : anfas.mobileText }}
                                    ._choose-photo-arrow-box
                                        svg-icon._choose-photo-arrow-icon(icon-name="upload-arrow")
                            ._choose-photo.-valid(
                                v-else-if="haveAnfasPhoto && anfas.valid && anfasPhoto && uploadLimitIsReached('anfas') === false"
                            )
                                template(v-if="!isDraggingPhoto") {{ locale("another") }}
                                span(v-if="isDraggingPhoto" v-html="locale('anotherAnfasDrag')")
                                svg-icon._choose-photo-arrow-icon(icon-name="upload-arrow")
                            ._choose-photo-valid(
                                v-else-if="haveAnfasPhoto && anfas.valid && uploadLimitIsReached('anfas') === false"
                            ) {{ successText }}
                                svg-icon(icon-name="check")
                            ._choose-photo.-invalid(v-else-if="!anfas.valid") {{ locale("didntFit") }}
                                svg-icon(icon-name="decline")
                            ._choose-photo(v-else-if="anfasPhoto" v-html="locale('limit')")
                            ._choose-photo-loading(v-if="anfas.loading")
                                Loading
                            ._photo-zone-decor.-top.-left
                            ._photo-zone-decor.-top.-right
                            ._photo-zone-decor.-bottom.-left
                            ._photo-zone-decor.-bottom.-right
                        //._camera(v-if="pageSize >= 991")
                        //  button._camera-btn(
                        //    @click.stop="showCameraUnface",
                        //    :disabled="!userHasWebcam"
                        //  )
                        //    span._camera-btn-text Или сделайте снимок
                        //    svg-icon._camera-btn-icon(icon-name="photo")
                        //    ._camera-btn-decor.-top.-left
                        //    ._camera-btn-decor.-top.-right
                        //    ._camera-btn-decor.-bottom.-left
                        //    ._camera-btn-decor.-bottom.-right

                    ._photo-group(
                        v-if="showProfile" :class="{ 'change-photos__photo-group_valid': haveProfilePhoto && profile.valid && !profile.loading && uploadLimitIsReached('profile') === false, 'change-photos__photo-group_have-photo': (profilePhoto && profile.valid) || profile.loading, 'change-photos__photo-group_invalid': !profile.valid && !profile.loading }"
                    )
                        img#profile-photo._visible-photo(
                            :src="profile.preview || profilePhoto",
                            v-if="haveProfilePhoto"
                        )
                        label._photo-zone(
                            v-bind:for="[uploadLimitIsReached('profile') === false ? 'profile' : '']",
                            ref="profilePhoto",
                            :class="{ 'change-photos__photo-zone__dragging': isDraggingPhoto && !uploadLimitIsReached('profile') }"
                        )
                            input#profile._photo-input(
                                type="file",
                                accept="image/*",
                                :disabled="uploadLimitIsReached('profile')",
                                v-on="!uploadLimitIsReached('profile') ? { drop: ($event) => dropHandler($event, 'profilePhoto') } : { drop: ($event) => $event.preventDefault() }",
                                @change="uploadPhoto"
                            )
                            ._choose-photo-content(v-if="!haveProfilePhoto")
                                svg-icon.change-photos__choose-photo-icon(icon-name="profile")
                                ._choose-photo-title {{ isDraggingPhoto ? profile.dragText : !isMobileView ? profile.desktopText : profile.mobileText }}
                                    ._choose-photo-arrow-box
                                        svg-icon._choose-photo-arrow-icon(icon-name="upload-arrow")
                            ._choose-photo.-valid(
                                v-else-if="haveProfilePhoto && profile.valid && profilePhoto && uploadLimitIsReached('profile') === false"
                            )
                                template(v-if="!isDraggingPhoto") {{ locale("another") }}
                                span(v-if="isDraggingPhoto" v-html="locale('anotherProfileDrag')")
                                svg-icon._choose-photo-arrow-icon(icon-name="upload-arrow")
                            ._choose-photo.-valid(
                                v-else-if="haveProfilePhoto && profile.valid && uploadLimitIsReached('profile') === false"
                            ) {{ successText }}
                                svg-icon(icon-name="check")
                            ._choose-photo.-invalid(v-else-if="!profile.valid") {{ locale("didntFit") }}
                                svg-icon(icon-name="decline")
                            ._choose-photo(v-else-if="profilePhoto" v-html="locale('limit')")
                            ._choose-photo-loading(v-if="profile.loading")
                                Loading
                            ._photo-zone-decor.-top.-left
                            ._photo-zone-decor.-top.-right
                            ._photo-zone-decor.-bottom.-left
                            ._photo-zone-decor.-bottom.-right
                        //._camera(v-if="pageSize > 991")
                        //  button._camera-btn(
                        //    @click.stop="showCameraProfile",
                        //    :disabled="!userHasWebcam"
                        //  )
                        //    span._camera-btn-text Или сделайте снимок
                        //    svg-icon._camera-btn-icon(icon-name="photo")
                        //    ._camera-btn-decor.-top.-left
                        //    ._camera-btn-decor.-top.-right
                        //    ._camera-btn-decor.-bottom.-left
                        //    ._camera-btn-decor.-bottom.-right

                ._photo(v-else-if="photoType === 'avatar'")
                    ._photo-group(
                        :class="{ 'change-photos__photo-group_valid': avatar.valid, 'change-photos__photo-group_have-photo': avatarPhoto && !avatar.valid }"
                    )
                        img#avatar-photo._visible-photo(
                            :src="avatar.preview || avatarPhoto",
                            v-if="avatarPhoto || avatar.preview"
                        )
                        label._photo-zone(
                            for="avatar",
                            ref="avatarPhoto",
                            :class="{ 'change-photos__photo-zone__dragging': isDraggingPhoto }"
                        )
                            input#avatar._photo-input(
                                type="file",
                                accept="image/*",
                                @drop="dropHandler($event, 'avatarPhoto')",
                                @change="uploadPhoto"
                            )
                            ._choose-photo-content(v-if="!avatar.valid && !avatarPhoto")
                                svg-icon.change-photos__choose-photo-icon(icon-name="upload-photo")
                                ._choose-photo-title {{ isDraggingPhoto ? avatar.dragText : avatar.text }}
                                    ._choose-photo-arrow-box
                                        svg-icon._choose-photo-arrow-icon(icon-name="upload-arrow")
                            ._choose-photo.-valid(v-else-if="avatar.valid") {{ successText }}
                                svg-icon(icon-name="check")
                            ._choose-photo.-valid.-avatar(v-else-if="avatarPhoto")
                                template(v-if="!isDraggingPhoto") Загрузить другое
                                template(v-if="isDraggingPhoto") Перетащить другое
                                br/ фото
                                template(v-if="isDraggingPhoto") &nbsp;сюда
                                svg-icon._choose-photo-arrow-icon(icon-name="upload-arrow")
                            ._choose-photo-loading(v-if="avatar.loading")
                                Loading
                            ._photo-zone-decor.-top.-left
                            ._photo-zone-decor.-top.-right
                            ._photo-zone-decor.-bottom.-left
                            ._photo-zone-decor.-bottom.-right

                ._footer
                    ._example-link(
                        v-if="photoType === 'anfas and profile' && !hideExpample",
                        @click="changePhotoExamplesStatus(true)"
                    ) {{ locale("example") }}
                    ._footer-text(v-if="!hideExpample") {{ titles[photoType] }}
                    ._footer-text(v-else)
            transition(name="fade")
                photo-examples._photo-examples(v-if="showExampleStatus" @closeExamples="changePhotoExamplesStatus(false)")
        portal(to="modal-container", v-if="isCameraUnfaceActive")
            camera-modal(@close="hideCameraUnface")
                person-webcam(
                    type="unface",
                    category-id="anfas",
                    @close="hideCameraUnface",
                    @error="hideCameraUnface",
                    @capture="uploadPhotoWebcamAdapter"
                )
        portal(to="modal-container", v-if="isCameraProfileActive")
            camera-modal(@close="hideCameraProfile")
                person-webcam(
                    type="profile",
                    category-id="profile",
                    @close="hideCameraProfile",
                    @error="hideCameraProfile",
                    @capture="uploadPhotoWebcamAdapter"
                )
</template>

<script>
import CircleAnimation from "../../circle/circle";
import { photoUploadMixins } from "../../../mixins/photoUploadMixins";
import dragAndDropMixin from "../../../mixins/dragAndDrop.mixin";
import PhotoExamples from "../photo-examples/photo-examples";
import CameraModal from "@/components/modals/camera-modal/camera-modal";
import PersonWebcam from "@/components/person/person-webcam/person-webcam";
import InviteFriends from "./invite-friends/invite-friends";
import { detectDevice, DEVICE_TYPE } from "@/utils/navigator-utils";
import GiftTimer from "./gift-timer/gift-timer";

export default {
    name: "ChangePhotos",
    components: {
        GiftTimer,
        PhotoExamples,
        CircleAnimation,
        CameraModal,
        PersonWebcam,
        InviteFriends,
    },
    mixins: [photoUploadMixins, dragAndDropMixin],
    metaInfo() {
        return {
            title: "Мои фото для анализа Merlin AI - Merlinface.com",
            meta: [
                {
                    name: "description",
                    content:
                        "Здесь отображаются мои фото для анализа в социальной сети Merlinface.com",
                    vmid: "description",
                },
                {
                    property: "og:description",
                    content:
                        "Здесь отображаются мои фото для анализа в социальной сети Merlinface.com",
                    vmid: "og_description",
                },
                {
                    name: "robots",
                    content: "noindex,nofollow",
                },
                {
                    name: "googlebot",
                    content: "noindex",
                },
            ],
        };
    },
    props: {
        hideExpample: {
            type: Boolean,
            default: false,
            required: false,
        },
        photoType: {
            type: String,
            required: false,
            default: "anfas and profile",
        },
        showCtrl: {
            required: false,
            default: "",
            type: String,
        },
        showAnfas: {
            required: false,
            default: true,
            type: Boolean,
        },
        showProfile: {
            required: false,
            default: true,
            type: Boolean,
        },
        showGift: {
            required: false,
            default: true,
            type: Boolean,
        },
        successText: {
            required: false,
            default: "Фото подходит",
            type: String,
        },
    },
    data: () => ({
        isCameraUnfaceActive: false,
        isCameraProfileActive: false,
        showExampleStatus: false,
        loading: false,
        loadingText: "",
        emit: false,
        dailyUploadPhotoLimit: 3,
        changed: false,
        anfas: {
            havePhoto: true,
            valid: true,
            loading: false,
            preview: "",
            photo: null,
        },
        profile: {
            havePhoto: true,
            valid: true,
            loading: false,
            preview: "",
            photo: null,
        },
        avatar: {
            havePhoto: true,
            valid: false,
            loading: false,
            preview: "",
        },
        titles: {
        },
        anotherPerson: false,
    }),
    computed: {
        // TODO: судя по стору у тебя что userUuid что и personUuid одинаковые, зачем?
        // Это создает большую путаницу в коде, т.е. если я захочу получить uuid пользователя, то я по логике
        // должен обратиться к user/uuid, но это отдаст мне uuid личности пользователя, а не его user uuid
        externalId: (state) => state.$store.getters["user/externalIdPresent"],
        userUuid: function () {
            let personId = this.$store.getters["user/uuid"];
            if (!personId) {
                personId = this.$store.getters["person/uuid"];
            }
            return personId;
        },
        personUuid: (state) => state.$store.getters["person/uuid"],
        showInner: function () {
            return !!(this.anfas.preview && this.profile.preview);
        },
        avatarPhoto: function () {
            return this.$store.getters["user/photos"]["avatar"];
        },
        anfasPhoto: function () {
            const photo = this.$store.getters["user/photos"]["anfas"];
            return photo?.includes("dummy") ? null : photo;
        },
        profilePhoto: function () {
            const photo = this.$store.getters["user/photos"]["profile"];
            return photo?.includes("dummy") ? null : photo;
        },
        page: function () {
            return this.$store.getters["page"];
        },
        pageSize: function () {
            return this.$store.getters["windowWidth"];
        },
        userLimits: function () {
            return this.$store.getters["user/myLimits"];
        },
        haveAnfasPhoto: function () {
            return !!(this.anfas?.preview || this.anfasPhoto);
        },
        haveProfilePhoto: function () {
            return !!(this.profile?.preview || this.profilePhoto);
        },
        giftTimer: () => localStorage.giftTimer || 5,
        anotherPersonText: (state) =>
            state.photoType === "anfas and profile"
                ? ""
                : state.locale("avatar.help"),
    },
    asyncComputed: {
        async userHasWebcam() {
            return await detectDevice(DEVICE_TYPE.VIDEO);
        },
    },
    mounted() {
        this.anfas = {
            havePhoto: true,
            valid: true,
            loading: false,
            desktopText: this.locale("anfas.desktopText"),
            mobileText: this.locale("anfas.mobileText"),
            dragText: this.locale("anfas.dragText"),
            preview: "",
            photo: null,
        };
        this.profile = {
            havePhoto: true,
            valid: true,
            desktopText: this.locale("profile.desktopText"),
            mobileText: this.locale("profile.mobileText"),
            dragText: this.locale("profile.dragText"),
            loading: false,
            preview: "",
            photo: null,
        };
        this.avatar = {
            havePhoto: true,
            valid: false,
            text: this.locale("avatar.desktopText"),
            dragText: this.locale("avatar.dragText"),
            loading: false,
            preview: "",
        };
        this.titles = {
            avatar: this.locale("avatar.title"),
            "anfas and profile": this.locale("anfas.title"),
        };
    },
    methods: {
        dropHandler(event, ref) {
            event.preventDefault();
            event.target.files = event.dataTransfer.files;
            this.uploadPhoto(event);
            this.$refs[ref].classList.remove(
                "change-photos__photo-zone__dragging",
            );
        },
        changePhotoExamplesStatus(status) {
            this.showExampleStatus = status;
        },
        showCameraUnface() {
            this.isCameraUnfaceActive = true;
        },
        hideCameraUnface() {
            this.isCameraUnfaceActive = false;
        },
        showCameraProfile() {
            this.isCameraProfileActive = true;
        },
        hideCameraProfile() {
            this.isCameraProfileActive = false;
        },
        uploadLimitIsReached(type) {
            switch (type) {
                case "anfas":
                    if (
                        this.userLimits &&
                        this.userLimits.uploadAnfasPhoto <= 0
                    ) {
                        return true;
                    }
                    break;
                case "profile":
                    if (
                        this.userLimits &&
                        this.userLimits.uploadProfilePhoto <= 0
                    ) {
                        return true;
                    }
                    break;
            }
            return false;
        },
        isTokenPresent() {
            return this.$store.getters["auth/token"] !== "";
        },
    },
};
</script>

<style lang="scss">
@import "change-photos";

button {
    position: relative;
}
</style>
