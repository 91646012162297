<template lang="pug">
.friends-filter
	._ctrl(v-if="pageWidth > 991")
		._ctrl-item(
			:class="{ 'friends-filter__ctrl-item_active': tab === 'ALL' }",
			@click="tab = 'ALL'"
		) {{ locale("gender.all") }}
		._ctrl-item(
			:class="{ 'friends-filter__ctrl-item_active': tab === 'M' }",
			@click="tab = 'M'"
		) {{ locale("gender.male") }}
		._ctrl-item(
			:class="{ 'friends-filter__ctrl-item_active': tab === 'F' }",
			@click="tab = 'F'"
		) {{ locale("gender.female") }}
	._content
		._item
			._item-title {{ locale("by-age-title") }}
			Select(
				:placeholder="ageName",
				:value="ageId",
				field-id="ageId",
				field-name="ageName",
				:options="ageList",
				@changeModel="changeModel"
			)

		._item(v-if="page !== 'search'")
			._item-title {{ locale("by-person-type-title") }}
			Select(
				:placeholder="typeName",
				:value="typeId",
				field-id="typeId",
				field-name="typeName",
				:options="typeList",
				@changeModel="changeModel"
			)

		._item(v-if="page === 'celebrity'")
			._item-title {{ locale("by-person-category-title") }}
			Select(
				:placeholder="personCategoriesName",
				:value="personCategoriesId",
				field-id="personCategoriesId",
				field-name="personCategoriesName",
				:options="personCategoriesList",
				@changeModel="changeModel"
			)

		._item(v-if="isUserAuth")
			._item-title {{ locale("sort-by-title") }}
			Select(
				:placeholder="sortName",
				:value="sortId",
				field-id="sortId",
				field-name="sortName",
				:options="sortList",
				@changeModel="changeModel"
			)
		._item(v-if="isUserAuth && pageWidth <= 991")
			._item-title {{ locale("sort-by-gender-title") }}
			Select(
				:placeholder="genderName",
				:value="gender",
				field-id="gender",
				field-name="genderName",
				:options="genderList",
				@changeModel="changeModel"
			)

		button._reset(@click="resetFilter") {{ locale("reset-text") }}
</template>

<script>
import { QUERY_PARAMS_FILTER } from "@/components/person-filter/person-filter-params";
import gql from "graphql-tag";
import getPersonCategoriesQuery from "@/graphql/queries/getPersonCategories.query.graphql";

export default {
    name: "FriendsFilter",
    data: () => ({
        tab: "ALL",
        gender: "ALL",
        genderName: "Все",
        ageId: "ALL",
        ageName: "Любой",
        sortId: "DATE",
        sortName: "Дате добавления",
        typeId: "ALL",
        typeName: "Любой",
        personCategoriesId: "ALL",
        personCategoriesName: "Все",
        personCategoriesList: [
            {
                id: "ALL",
                name: "Все",
            },
        ],
        timerId: null,
    }),
    computed: {
        typeList: (state) => state.locale("typeList"),
        ageList: (state) => [
            {
                id: "ALL",
                name: state.locale("age.any"),
            },
            {
                id: "AGE_18_24",
                name: state.locale("age.interval_1"),
            },
            {
                id: "AGE_25_34",
                name: state.locale("age.interval_2"),
            },
            {
                id: "AGE_35_44",
                name: state.locale("age.interval_3"),
            },
            {
                id: "AGE_45_MORE",
                name: state.locale("age.interval_4"),
            },
        ],
        genderList: (state) => [
            { id: "ALL", name: state.locale("gender.all") },
            { id: "M", name: state.locale("gender.male") },
            { id: "F", name: state.locale("gender.female") },
        ],
        page: (state) => state.$store.getters["page"],
        personTypeText: (state) =>
            state.$route.params.personType === "celebrity"
                ? " знаменитостей"
                : " друзей",
        filter: (state) => ({
            gender: state.tab,
            age: state.ageId,
            leaderType: state.typeId,
            sort: state.sortId,
            categories: +state.personCategoriesId
                ? [+state.personCategoriesId]
                : [],
            compare: state.comparisonId,
        }),
        sortList() {
            if (this.page === "search") {
                return this.locale("searchSortList");
            } else {
                return this.locale("searchList");
            }
        },
    },
    watch: {
        sortId(val) {
            this.saveParamToQuery(QUERY_PARAMS_FILTER.SORT, val);
        },
        ageId(val) {
            this.saveParamToQuery(QUERY_PARAMS_FILTER.AGE, val);
        },
        typeId(val) {
            this.saveParamToQuery(QUERY_PARAMS_FILTER.LEADER_TYPE, val);
        },
        personCategoriesId(val) {
            this.saveParamToQuery(
                QUERY_PARAMS_FILTER.CATEGORY,
                +val ? [+val] : null,
            );
        },
        tab: function (val) {
            this.saveParamToQuery(QUERY_PARAMS_FILTER.GENDER, val);
            this.emitFilterChangedEvent();
        },
        gender: function (val) {
            this.saveParamToQuery(QUERY_PARAMS_FILTER.GENDER, val);
            this.emitFilterChangedEvent();
        },
    },
    created() {
        this.syncStateWithQueryParams();
    },
    mounted() {
        this.ageName = this.locale("age.any");
        this.typeName = this.locale("any");
        this.sortName = this.locale("sort-by-default");
        this.personCategoriesName = this.locale("all");
        this.personCategoriesList[0].name = this.locale("all");
        this.getPersonCategories();
    },
    methods: {
        changeModel(selectValues) {
            this[selectValues.fieldId] = selectValues.fieldIdValue;
            this[selectValues.fieldName] = selectValues.fieldNameValue;
            this.emitFilterChangedEvent();
        },
        async getPersonCategories() {
            await this.$apollo
                .query({
                    query: gql`
                        ${getPersonCategoriesQuery}
                    `,
                })
                .then((r) => {
                    this.personCategoriesList = Object.assign(
                        r.data.getPersonCategories,
                        this.personCategoriesList,
                    );
                });
        },
        syncStateWithQueryParams() {
            const query = this.$route.query;

            const savedSort = query[QUERY_PARAMS_FILTER.SORT];
            if (savedSort) {
                const option = this.sortList.find(
                    (opt) => opt.id === savedSort,
                );

                if (option) {
                    this.sortName = option.name;
                    this.sortId = option.id;
                }
            }

            const savedAge = query[QUERY_PARAMS_FILTER.AGE];
            if (savedAge) {
                const option = this.ageList.find((opt) => opt.id === savedAge);

                if (option) {
                    this.ageName = option.name;
                    this.ageId = option.id;
                }
            }

            const savedLeaderType = query[QUERY_PARAMS_FILTER.LEADER_TYPE];
            if (savedLeaderType) {
                const option = this.typeList.find(
                    (opt) => opt.id === savedLeaderType,
                );

                if (option) {
                    this.typeName = option.name;
                    this.typeId = option.id;
                }
            }

            const savedCategory = query[QUERY_PARAMS_FILTER.CATEGORY];
            if (savedCategory) {
                const option = this.personCategoriesList.find(
                    (opt) => opt.id === savedCategory,
                );

                if (option) {
                    this.personCategoriesName = option.name;
                    this.personCategoriesId = option.id;
                }
            }

            const savedGender = query[QUERY_PARAMS_FILTER.GENDER];
            if (savedGender) {
                const isValidGender =
                    savedGender === "M" ||
                    savedGender === "F" ||
                    savedGender === "ALL";
                if (isValidGender) {
                    this.tab = savedGender;
                }
            }

            this.emitFilterChangedEvent();
        },
        resetFilter() {
            this.tab = "ALL";

            this.ageId = "ALL";
            this.ageName = this.locale("any");

            this.sortId = "DATE";
            this.sortName = this.locale("sort-by-default");

            this.typeId = "ALL";
            this.typeName = this.locale("any");

            this.personCategoriesId = "ALL";
            this.personCategoriesName = this.locale("all");

            this.emitFilterChangedEvent();
        },
        saveParamToQuery(key, value) {
            const query = { ...this.$route.query };
            if (value) {
                query[key] = value.toString();
            } else {
                delete query[key];
            }
            this.$router.push({ path: this.$route.path, query });
        },
        emitFilterChangedEvent() {
            this.$store.dispatch("filterResults", this.filter);
        },
    },
};
</script>

<style lang="scss" scoped>
@import "friends-filter";
</style>
